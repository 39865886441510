<template>
  <ImageWrapper background="migration">
    <MetaTags :meta-tags="metaTags" />

    <MigrateForm
      :email="email"
      @toggle-sign-in-up="toggle"
    />
  </ImageWrapper>
</template>

<script>
import { metaTags } from '@/mixins/metaTags'

import Layout from '@/Layouts/ReducedLayout'

import MetaTags from '@/Components/Shared/MetaTags'
import ImageWrapper from '@/Components/Shared/Objects/ImageWrapper'

import MigrateForm from '@/Components/Auth/MigrateForm'

export default {

  components: {
    MetaTags,
    ImageWrapper,
    MigrateForm
  },

  mixins: [metaTags],
  layout: Layout,

  props: {
    email: String
  },

  methods: {
    toggle() {
      this.$inertia.visit(this.$routes.new_user_session())
    }
  }
}
</script>
