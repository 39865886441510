<template>
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.9887 18.945L14.0769 13.6573C16.4779 10.3362 16.2605 5.53221 13.4248 2.47955C10.3537 -0.826517 5.37444 -0.826517 2.30333 2.47955C-0.767777 5.78562 -0.767777 11.1458 2.30333 14.4519C5.13904 17.5046 9.60158 17.7385 12.6867 15.1538L17.5985 20.4415C17.9737 20.8454 18.6016 20.8513 18.9855 20.4381C19.3721 20.0219 19.3709 19.3563 18.9887 18.945ZM12.0346 3.97663C14.3379 6.45619 14.3379 10.4763 12.0346 12.9559C9.73123 15.4354 5.99679 15.4354 3.69346 12.9559C1.39013 10.4763 1.39013 6.45619 3.69346 3.97663C5.99679 1.49708 9.73123 1.49708 12.0346 3.97663Z"
    />
  </svg>
</template>

<docs>
  To know more information on how to use icons see [here](/builds/#Icon)

  ```jsx
    <Icon name="Linkedin" class="text-4xl"></Icon>
  ```
</docs>
