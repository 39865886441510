<template>
  <nav
    class="navbar"
    :class="{ 'navbar--is-transparent': isTransparent && dropdownIsActive }"
    data-navbar
  >
    <div class="navbar-container minHeight">
      <div
        class="items-center"
        :class="{ 'flex lg:justify-start': searchBar, 'justify-center  flex': !searchBar }"
      >
        <!-- Menu Mobile -->
        <MenuMobile
          :seasonal-is-active="seasonalIsActive"
          :categories="categories"
        />

        <div
          class="items-center"
          :class="{
            'hidden lg:flex lg:justify-start': searchBar,
            'justify-center  flex': !searchBar
          }"
        >
          <!-- Logo -->
          <LogoSwitcher
            testid="link-ca-logo-root"
            class="navbar__logo"
          />
        </div>
      </div>

      <div
        class="flex flex-row items-center lg:flex-1"
        :class="{ 'lg:hidden': searchBar }"
      >
        <!-- Menu Desktop -->
        <MenuDesktop
          :seasonal-is-active="seasonalIsActive"
          :categories="categories"
          :is-categories-dropdown="isCategoriesDropdown"
          :is-seasonal-dropdown="isSeasonalDropdown"
          @toggle-categories-dropdown="toggleCategoriesDropdown"
          @toggle-seasonal-dropdown="toggleSeasonalDropdown"
        />
      </div>

      <div
        class="flex flex-row items-center"
        :class="{ 'flex-1': searchBar }"
      >
        <!-- Navbar search -->
        <NavSearch
          :search-bar="searchBar"
          @open="handleOpen"
          @close="handleClose"
        />

        <CartDrawer />

        <!-- Profile -->
        <AccountDesktop
          :is-account-dropdown="isAccountDropdown"
          class="hidden lg:flex"
          @toggle-account-dropdown="toggleAccountDropdown"
        />

        <!-- Profile Menu -->
        <AccountMobile class="lg:hidden" />
      </div>
    </div>
  </nav>
</template>

<script>
  import currentUser from '@/mixins/currentUser'
  import { router } from '@inertiajs/vue3'

  import LogoSwitcher from '@/Components/Shared/Objects/Navbar/LogoSwitcher'
  import MenuDesktop from '@/Components/Shared/Objects/Navbar/Desktop/Index'
  import AccountDesktop from '@/Components/Shared/Objects/Navbar/Desktop/AccountLink'
  import MenuMobile from '@/Components/Shared/Objects/Navbar/Mobile/Index'
  import AccountMobile from '@/Components/Shared/Objects/Navbar/Mobile/AccountLink'

  import CartDrawer from '@/Components/Shared/Objects/Navbar/CartDrawer'
  import NavSearch from '@/Components/Shared/Objects/Navbar/NavSearch'

  /**
   * Navbar component for Create Academy
   * @displayName Navbar
   */
  export default {
    name: 'Navbar',

    components: {
      CartDrawer,
      LogoSwitcher,
      MenuDesktop,
      AccountDesktop,
      MenuMobile,
      AccountMobile,
      NavSearch
    },

    mixins: [currentUser],

    props: {
      dualtone: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        // toggle the seasonal tab
        // this will be later on connected into the CMS
        seasonalIsActive: false,

        // toggle transparency on navbar
        isTransparent: false,

        // toggle to show categories dropdown (desktop)
        isCategoriesDropdown: false,

        // toggle to show collaborations dropdown (desktop)
        isSeasonalDropdown: false,

        // toggle to show account dropdown (desktop)
        isAccountDropdown: false,

        searchBar: false,

        scrollThreshold: 50 // New property to control when the navbar becomes opaque
      }
    },

    computed: {
      categories() {
        return this.$page.props.categories_navigation
      },

      dropdownIsActive() {
        return !this.isCategoriesDropdown && !this.isSeasonalDropdown && !this.isAccountDropdown
      }
    },

    beforeMount() {
      window.addEventListener('scroll', this.scrollToTop)
    },

    mounted() {
      if (this.dualtone) {
        this.isTransparent = true
        // Add this line to check scroll position on mount
        this.scrollToTop()
      }
      // Add a resize event listener to adjust the threshold on window resize
      window.addEventListener('resize', this.updateScrollThreshold)
      this.updateScrollThreshold()
    },

    created() {
      this.i18nScope = { scope: 'pages.navbar' }
      this.removeNavigateListener = router.on('navigate', () => {
        this.isCategoriesDropdown = false
        this.isSeasonalDropdown = false
        this.isAccountDropdown = false
      })
    },

    beforeUnmount() {
      this.removeNavigateListener()
      // Remove the resize event listener
      window.removeEventListener('resize', this.updateScrollThreshold)
    },

    methods: {
      handleOpen() {
        this.searchBar = !this.searchBar
      },
      handleClose() {
        this.searchBar = false
      },
      scrollToTop() {
        if (this.dualtone) {
          // Change the threshold to a smaller value
          this.isTransparent = !(window.scrollY > this.scrollThreshold)
        }
      },

      toggleCategoriesDropdown() {
        this.isCategoriesDropdown = !this.isCategoriesDropdown

        if (this.isSeasonalDropdown) {
          this.isSeasonalDropdown = false
        }
        if (this.isAccountDropdown) {
          this.isAccountDropdown = false
        }
      },

      toggleSeasonalDropdown() {
        this.isSeasonalDropdown = !this.isSeasonalDropdown
        if (this.isCategoriesDropdown) {
          this.isCategoriesDropdown = false
        }
        if (this.isAccountDropdown) {
          this.isAccountDropdown = false
        }
      },

      toggleAccountDropdown() {
        this.isAccountDropdown = !this.isAccountDropdown

        if (this.isCategoriesDropdown) {
          this.isCategoriesDropdown = false
        }
        if (this.isSeasonalDropdown) {
          this.isSeasonalDropdown = false
        }
      },

      updateScrollThreshold() {
        // Adjust the threshold based on the window height
        this.scrollThreshold = Math.min(50, window.innerHeight * 0.03)
      }
    }
  }
</script>

<docs>
  ### ✎ Navbar

  ```jsx
    <Navbar></Navbar>
  ```
</docs>
