<template>
  <!-- class="flex flex-col lg:flex-row items-stretch bg-blue-500 mt-14" -->
  <div
    data-testid="access-all-course-banner"
    :class="[
      'flex',
      'flex-col',
      'lg:flex-row',
      'items-stretch',
      'backgroundState',
      'bg-neutral-200',
      'md:bg-transparent'
    ]"
    :style="{ backgroundImage: `url(${imageUrl})` }"
  >
    <!-- Text Content - Second for smaller screens, remains on the left for lg and above -->
    <div class="container py-14 lg:py-36 relative">
      <div
        class="banner-content flex flex-col justify-center text-left gap-2 lg:gap-4 w-full lg:w-112 lg:mb-0"
      >
        <div class="mb-2">
          <div class="flex items-center justify-start gap-4 mb-5">
            <img
              class="h-3.5 lg:h-5"
              src="@/assets/images/backgrounds/banner/trustpilot.png"
              alt="trustpilot rating"
            >
            <p class="text-sm lg:text-xl font-normal text-black">
              Rated <span class="font-medium">4.8/5</span> on Trustpilot
            </p>
          </div>
          <div class="mb-2 lg:mb-4 font-medium text-3xl lg:text-4xl text-black font-serif">
            Not Sure Where to Start?
          </div>
          <div
            data-testid="monthly-price"
            class="font-normal text-base lg:text-xl text-black"
          >
            We’ve chosen some of our very best lessons for you to enjoy for free.
          </div>
        </div>
        <div class="mt-2 lg:mt-4 text-left">
          <div class="inline-block text-center w-full xs:w-auto">
            <Link
              class="button button--red inlineBlockBtn w-full xs:w-96 max-w-full"
              data-testid="start-learning-button"
              href="/free-lessons"
            >
              <!-- <span>Join for {{ getGeoPrice(membershipPlan.monthlyPrice) }}/month</span> -->
              <span class="flex gap-1 items-center justify-center">WATCH A FREE LESSON </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
    <div class="block md:hidden">
      <img
        class="object-cover w-full"
        :src="respimageUrl"
        alt="banner mobile image"
      >
    </div>
  </div>
</template>

<script>
  import mixpanel from '@/mixins/mixpanel'
  import imgSrcset from '@/mixins/imgSrcset'
  import monetize from '@/mixins/monetize'
  import { Link } from '@inertiajs/vue3'

  export default {

    components: {
      Link
    },
    mixins: [mixpanel, imgSrcset, monetize],

    props: {
      category: {
        type: Object,
        required: true
      },

      membershipPlan: {
        type: Object,
        default: null
      },

      geoProps: {
        type: Object,
        required: true
      },

      customImage: {
        type: Boolean,
        default: false
      },

      component: {
        type: String,
        default: null
      },
      imageUrl: {
        type: [String, Object],
        default: null
      },
      respimageUrl: {
        type: [String, Object],
        default: null
      }
    },

    methods: {
      getGeoPrice(gbpBasePrice) {
        const decimalPlaces = this.geoProps.currency === 'USD' ? 0 : 2

        return this.geoMonetize(
          gbpBasePrice * this.geoProps.exchangeRate,
          this.geoProps.currencySymbol,
          decimalPlaces
        ).replace(/\.00$/, '')
      }
    }
  }
</script>
<style scoped>
  .backgroundState {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
  }
  .backgroundState:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 100%;
    background: linear-gradient(
      89.78deg,
      rgba(247, 243, 237, 0.765) 0.19%,
      rgba(247, 243, 237, 0.675) 53.75%,
      rgba(247, 243, 237, 0) 99.81%
    );
  }
  @media (max-width: 1024px) {
    .backgroundState:before {
      width: 70%;
    }
  }
  @media (max-width: 768px) {
    .backgroundState {
      background-image: none !important;
    }
    .backgroundState:before {
      background: transparent;
    }
  }
</style>
