<template>
  <MenuPanel
    :name="$I18n.t('my_account', i18nScope)"
    :current-panel="isToggleAccountMenu"
    back-button-active
    @click-back="$emit('toggleAccountMenu')"
    @click-close="$emit('toggleAccountMenu') && $emit('toggleGeneralMenu')"
  >
    <!-- <div class="flex flex-row items-center">
      <div class="w-12 h-12 overflow-hidden rounded-sm">
        <img loading="lazy" :src="currentUser.account.avatar_path" :alt="$I18n.t('link_labels.account_avatar', i18nScope)" class="image-cover">
      </div>

      <div class="profile__info ml-2">
        <p class="text-blue-800 text-xs font-light mb-1">{{ $I18n.t('welcome', i18nScope) }}</p>
        <p data-testid="current-user-desktop" class="font-normal">{{ currentUser.account.name }}</p>
      </div>
    </div> -->

    <div class="flex flex-col">
      <Link
        v-if="hasEnrollments && !isSubscribed"
        class="text-sm tracking-wider uppercase font-medium lg:text-white lg:hover:text-neutral-400 text-blue-800 py-2"
        :href="$routes.root({ anchor: 'my-courses' })"
        @click="$emit('toggleGeneralMenu')"
      >
        {{ $I18n.t('home', i18nScope) }}
      </Link>

      <Link
        :href="$routes.user_collections()"
        class="text-sm tracking-wider uppercase font-medium lg:text-white lg:hover:text-neutral-400 text-blue-800 py-2 hover:underline"
      >
        {{ $I18n.t('collections', i18nScope) }}
      </Link>

      <!-- <Link data-testid="navbar-link-collections" :href="$routes.root()" class="menu-link py-2">{{ $I18n.t('collections', i18nScope) }}</Link> -->
      <!-- <a data-testid="navbar-link-rewards" href="https://page.createacademy.com/members-rewards" target="_blank" class="text-sm tracking-wider uppercase font-medium lg:text-white lg:hover:text-neutral-400 text-blue-800 py-2 hover:underline">{{ $I18n.t('offers.rewards.title', i18nScope) }}</a> -->
    </div>

    <Divider
      color="light"
      spacing="xs"
    />

    <ProfileLinks
      screen="mobile"
      class=""
    />

    <MenuItem
      data-testid="navbar-link-support"
      :href="$routes.support()"
      :body="$I18n.t('support', i18nScope)"
    />

    <LogoutButton class="subheading--medium text-danger py-1" />
    <Divider
      color="light"
      spacing="xs"
    />
  </MenuPanel>
</template>

<script>
  import currentUser from '@/mixins/currentUser'
  import { Link } from '@inertiajs/vue3'

  import Divider from '@/Components/Shared/Divider'
  import LogoutButton from '@/Components/Shared/LogoutButton'
  import MenuItem from '@/Components/Shared/Objects/Navbar/Mobile/MenuItem'
  import MenuPanel from '@/Components/Shared/Objects/Navbar/Mobile/MenuPanel'
  import ProfileLinks from '@/Components/Shared/Objects/Navbar/ProfileLinks'

  export default {

    components: {
      Divider,
      LogoutButton,
      MenuItem,
      MenuPanel,
      ProfileLinks,
      Link
    },
    mixins: [currentUser],

    props: {
      isToggleAccountMenu: {
        type: Boolean,
        required: true
      }
    },

    emits: ['toggleAccountMenu', 'toggleGeneralMenu'],

    created() {
      this.i18nScope = { scope: 'pages.navbar' }
    }
  }
</script>
