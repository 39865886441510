<template>
  <svg
    width="31"
    height="42"
    viewBox="0 0 31 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Icon"
      d="M0 0H30.3333V42L15.1667 28.4517L0 42V0Z"
      fill="white"
    />
  </svg>
</template>
