<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.77236 2H6.4521L6.32477 2.29385L5.60658 3.95122H3V4.92683H21.5366V3.95122H18.93L18.2118 2.29385L18.0845 2H17.7642H6.77236ZM17.8667 3.95122L17.444 2.97561H7.09261L6.66985 3.95122H17.8667ZM4.96691 6.39024H3.97561L4.1513 7.36585L6.78669 22H17.7499L20.3853 7.36585L20.561 6.39024H19.5697H4.96691ZM5.14261 7.36585L7.6023 21.0244H16.9343L19.394 7.36585H5.14261ZM9.25238 16.7932V10.0069H10.228V16.7932H9.25238ZM14.1758 10.0069V16.7932H15.1514V10.0069H14.1758Z"
    />
  </svg>
</template>

<docs>
  To know more information on how to use icons see [here](/packs/#Icon)

  ```jsx
    <Icon name="Delete" class="text-4xl"></Icon>
  ```
</docs>
