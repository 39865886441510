<template>
  <p
    v-if="errors.length"
    class="font-normal text-sm text-danger mt-1"
    :data-testid="`error-${testid}`"
    v-html="capitalizedError"
  />
</template>

<script>
import { inputErrors } from '@/mixins/inputErrors'

/**
 * InputError component for Create Academy
 * @displayName InputError
 */
export default {

  name: 'InputError',
  mixins: [inputErrors],

  props: {
    testid: String
  }
}
</script>


<docs>
  ### ✎ Input Error
  This component is usually used attachaed a `input-group` component like a `input` or `checkbox`.

  ```jsx
    <InputError :errors="['This is an error']" />
  ```
</docs>
