<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.51208 7.16531C6.98076 4.165 9.22217 1.97778 11.8 1.97778C14.3778 1.97778 16.6192 4.165 17.0879 7.16531C15.6158 6.05698 13.7846 5.4 11.8 5.4C9.81538 5.4 7.9842 6.05698 6.51208 7.16531ZM5.44736 8.11032C5.54888 4.23197 8.28902 1 11.8 1C15.311 1 18.0511 4.23197 18.1526 8.11032C19.6685 9.69122 20.6 11.8368 20.6 14.2C20.6 19.0601 16.6601 23 11.8 23C6.93989 23 3 19.0601 3 14.2C3 11.8368 3.9315 9.69122 5.44736 8.11032ZM11.8 22.0222C16.1201 22.0222 19.6222 18.5201 19.6222 14.2C19.6222 9.87991 16.1201 6.37778 11.8 6.37778C7.47991 6.37778 3.97778 9.87991 3.97778 14.2C3.97778 18.5201 7.47991 22.0222 11.8 22.0222ZM12.7778 13.9384C13.3623 13.6003 13.7556 12.9683 13.7556 12.2444C13.7556 11.1644 12.88 10.2889 11.8 10.2889C10.72 10.2889 9.84444 11.1644 9.84444 12.2444C9.84444 12.9683 10.2377 13.6003 10.8222 13.9384V17.1333H12.7778V13.9384Z"
    />
  </svg>
</template>

<docs>
  To know more information on how to use icons see [here](/packs/#Icon)

  ```jsx
    <Icon name="Lock" class="text-4xl"></Icon>
  ```
</docs>
