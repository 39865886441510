<template>
  <div>
    <div
      v-if="show"
      class="dialog-box"
      @click.self="close"
    >
      <div>
        <div class="dialog-content customModal">
          <div class="model-header relative gap-x-5">
            <div class="flex flex-col">
              <h4 class="text-lg uppercase font-medium text-left tracking-wider">
                {{ title }}
              </h4>
              <p class="text-sm font-normal text-left">
                {{ description }}
              </p>
            </div>
            <div
              v-if="edit"
              v-show="collection.slug!=='default_collection'"
              class="flex gap-x-1 items-center justify-center cursor-pointer group"
              @click="handleDelete"
            >
              <span class="relative">
                <Icon
                  :data-testid="''"
                  name="Delete"
                  class="text-sm text-red-700  relative -top-0.5"
                />
              </span>
              <p
                class="text-base font-normal  text-red-700 group-hover:underline"
              >
                Delete
              </p>
            </div>
          </div>
          <div class="modal-content relative flex flex-col gap-y-5">
            <div class="relative px-4">
              <input-group
                ref="inputs"
                v-model="collectionName"
                testid=""
                :placeholder="'Name your collection'"
                :value="collectionName"
                :maxlength="maxCharacters"
                class="w-full font-medium text-base"
                @input="handleChange($event.target.value)"
              />
              <p class="flex justify-end text-xs">
                {{ remainingCharacters() }}
              </p>
            </div>
            <div class="flex items-center justify-center gap-4 mb-4">
              <button
                class="border border-1 button--small  uppercase"
                @click="close"
              >
                Cancel
              </button>
              <button
                :disabled="!collectionName ? true : null"
                class="button--small button--blue text-white uppercase"
                @click="handleCreate"
              >
                {{ edit? 'Update' :'Create' }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <delete-warning
      ref="warningDialogBox"
      :title="'Delete collection'"
      :description="'Are you sure you want to delete this collection? You won’t be able to recover it.'"
      :collection="collection"
      :handle-open-model="open"
    />
  </div>
</template>

<script>
import InputGroup from '../Forms/InputGroup.vue'
import Icon from '../Icon.vue'
import DeleteWarning from './DeleteWarning.vue'
export default {
  components: { InputGroup, Icon, DeleteWarning },

  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required:true
    },
    lessonId: {
      type: Number
    },
    edit: {
      type: Boolean
    },
    collection: {
      type: Object
    },
    fromLesson: {
      type:Boolean
    },
    handleRefresh:Function

  },
  data() {
    return {
      show: false,
      collectionName: this.collection?.name ?? '',
       maxCharacters: 60
    }
  },

  methods: {
    open(value) {
      this.show = true
      if (value) {
        this.collectionName = value?.name
      }
    },
    close() {
      this.collectionName = this.collection?.name ? this.collection?.name : ''
      this.show = false
      if (this.fromLesson) {
        this.handleRefresh()
      }
    },
    handleDelete() {
      this.$refs.warningDialogBox.open()
      this.close()
    },

    handleCreate() {
      if (this.edit) {
      const path = this.$routes.user_collection(`${this.collection?.id}`)
        this.$inertia.put(
          `${path}`,
          { name: this.collectionName, lesson_id: this.lessonId ?? '' },
          {
            preserveScroll: true,
            onSuccess: () => {
              this.close()
            },

            onError: () => {
              console.error('Failed to delete Lesson')
            }
          }
        )
      } else {

          const path = this.$routes.user_collections()
          this.$inertia.post(
            `${path}`,
            { name: this.collectionName, lesson_id: this.lessonId ?? '' },
            {
              preserveScroll: true,
              onSuccess: () => {
                this.close()
              },

              onError: () => {
                console.error('Failed to delete Lesson')
              }
            }
          )
      }
    },

    handleChange(e) {
      this.collectionName = e.trimStart()
    },

    remainingCharacters() {
       return `${this.collectionName?.length ?? 0}/60`
    }
  }

}
</script>
