<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.3895 6.77664L12.1395 2.27664C12.0502 2.24096 11.9505 2.24096 11.8612 2.27664L0.61122 6.77664C0.541511 6.80441 0.481735 6.85245 0.439623 6.91456C0.397511 6.97667 0.375 7.04998 0.375 7.12502C0.375 7.20005 0.397511 7.27336 0.439623 7.33547C0.481735 7.39758 0.541511 7.44562 0.61122 7.47339L11.8612 11.9734C11.9505 12.0091 12.0502 12.0091 12.1395 11.9734L18.5647 9.40314C18.6555 9.36518 18.7278 9.29304 18.7659 9.20227C18.804 9.11151 18.8049 9.00941 18.7684 8.918C18.7318 8.82659 18.6608 8.7532 18.5707 8.71369C18.4805 8.67417 18.3784 8.67168 18.2865 8.70677L12.0003 11.2211L1.75985 7.12502L12.0003 3.02889L22.2408 7.12502L21.2362 7.52664C21.1905 7.54491 21.1488 7.57201 21.1135 7.6064C21.0782 7.64079 21.0501 7.68179 21.0306 7.72705C21.0112 7.77232 21.0009 7.82097 21.0002 7.87023C20.9996 7.91949 21.0087 7.96839 21.027 8.01414C21.0452 8.05989 21.0723 8.10159 21.1067 8.13687C21.1411 8.17214 21.1821 8.2003 21.2274 8.21973C21.2727 8.23916 21.3213 8.24948 21.3706 8.25011C21.4198 8.25074 21.4687 8.24166 21.5145 8.22339L23.3895 7.47339C23.4592 7.44562 23.519 7.39758 23.5611 7.33547C23.6032 7.27336 23.6257 7.20005 23.6257 7.12502C23.6257 7.04998 23.6032 6.97667 23.5611 6.91456C23.519 6.85245 23.4592 6.80441 23.3895 6.77664Z"
      fill="black"
    />
    <path
      d="M18.375 10.125C18.2755 10.125 18.1802 10.1645 18.1098 10.2348C18.0395 10.3052 18 10.4005 18 10.5V14.817C16.2837 16.1494 14.1728 16.8726 12 16.8726C9.82724 16.8726 7.71629 16.1494 6 14.817V10.5C6 10.4005 5.96049 10.3052 5.89016 10.2348C5.81984 10.1645 5.72446 10.125 5.625 10.125C5.52554 10.125 5.43016 10.1645 5.35984 10.2348C5.28951 10.3052 5.25 10.4005 5.25 10.5V15C5.25 15.0565 5.26275 15.1122 5.28729 15.163C5.31184 15.2139 5.34754 15.2585 5.39175 15.2936C7.26457 16.8006 9.59619 17.6221 12 17.6221C14.4038 17.6221 16.7354 16.8006 18.6083 15.2936C18.6525 15.2585 18.6882 15.2139 18.7127 15.163C18.7373 15.1122 18.75 15.0565 18.75 15V10.5C18.75 10.4005 18.7105 10.3052 18.6402 10.2348C18.5698 10.1645 18.4745 10.125 18.375 10.125Z"
      fill="black"
    />
    <path
      d="M20.6249 13.95V8.325C20.6249 8.23779 20.5944 8.15331 20.5389 8.0861C20.4833 8.01889 20.406 7.97315 20.3204 7.95675L12.0704 6.38175C12.0216 6.3715 11.9713 6.37105 11.9224 6.38046C11.8735 6.38986 11.8269 6.40892 11.7854 6.43652C11.7439 6.46412 11.7084 6.4997 11.6808 6.5412C11.6532 6.5827 11.6342 6.62927 11.6249 6.67821C11.6155 6.72714 11.616 6.77745 11.6263 6.82619C11.6365 6.87494 11.6564 6.92115 11.6848 6.96211C11.7132 7.00308 11.7494 7.03799 11.7914 7.0648C11.8334 7.09161 11.8803 7.10978 11.9294 7.11825L19.8749 8.63513V13.95C19.039 14.2706 18.9779 15.3484 19.3034 16.182L18.7525 20.9573C18.7452 21.0226 18.7552 21.0888 18.7815 21.1491C18.8078 21.2094 18.8496 21.2617 18.9025 21.3008C19.292 21.5929 19.7657 21.7508 20.2525 21.7508C20.7393 21.7508 21.213 21.5929 21.6025 21.3008C21.6554 21.2617 21.6972 21.2094 21.7235 21.1491C21.7498 21.0888 21.7598 21.0226 21.7525 20.9573L21.1964 16.1816C21.5219 15.3476 21.4604 14.2703 20.6249 13.95ZM20.2499 14.625C20.7108 14.625 20.6624 15.36 20.5338 15.8003C20.3459 15.8314 20.1542 15.8314 19.9664 15.8003C19.8374 15.3596 19.789 14.625 20.2499 14.625ZM19.5239 20.8125L20.0144 16.5604C20.1707 16.5816 20.3291 16.5816 20.4854 16.5604L20.9759 20.8125C20.753 20.9336 20.5035 20.997 20.2499 20.997C19.9963 20.997 19.7467 20.9336 19.5239 20.8125Z"
      fill="black"
    />
  </svg>
</template>

  <docs>
    To know more information on how to use icons see [here](/packs/#Icon)

    ```jsx
      <Icon name="Check" class="text-4xl"></Icon>
    ```
  </docs>
