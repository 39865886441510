<template>
  <div class="id-hero-animation">
    <!-- Existing Hero Content -->
    <div class="flex flex-col md:flex-row bg-white">
      <!-- Left Section - full width on mobile -->
      <div
        class="w-full md:w-6/12 relative"
        style="background-color: #e8ebf0"
      >
        <div
          class="relative z-10 p-6 md:p-12 md:pl-20 2xl:pl-56 h-full flex flex-col justify-center"
        >
          <h1
            class="font-serif text-3xl lg:text-4xl text-black font-medium mb-8 text-center md:text-left text-balance"
          >
            Start your creative
            <br>
            learning journey
          </h1>
          <div class="w-full max-w-3xl">
            <div
              v-for="course in courses"
              :key="course.id"
              class="flex items-start"
              style="margin-bottom: 32px"
            >
              <div
                class="flex-none"
                style="margin-right: 16px"
              >
                <svg
                  class="mt-2"
                  width="18"
                  height="15"
                  viewBox="0 0 18 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16 2L5.55904 13L2 9.2504"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-linecap="square"
                  />
                </svg>
              </div>
              <div>
                <h2
                  class="text-lg tracking-widest lg:tracking-normal font-medium uppercase lg:normal-case lg:text-2xl lg:font-serif text-black"
                  style="margin-bottom: 4px"
                >
                  {{ course.title }}
                </h2>
                <p class="text-black text-base lg:text-xl font-normal">
                  {{ course.description }}
                </p>
              </div>
            </div>
          </div>
          <ButtonLink
            data-testid="start-learning-link"
            href="/get-started"
            class="mx-auto md:ml-0 w-full xs:w-auto md:w-96 mt-4 lg:mt-6 max-w-full"
            variant="red"
          >
            START LEARNING
          </ButtonLink>
        </div>
      </div>

      <!-- Right Section - full width on mobile -->
      <div class="w-full md:w-6/12 relative overflow-hidden bg-white h-[642px]">
        <!-- Left Fade effect -->
        <div
          class="absolute pointer-events-none z-20 hidden md:block"
          aria-hidden="true"
          :style="{
            width: '23rem',
            background: `linear-gradient(to right,
              rgba(232, 235, 240, 0) 0%,
              rgba(232, 235, 240, 1) 0%,
              rgba(255, 255, 255, 0) 100%
            )`,
            left: '-12rem',
            top: 0,
            bottom: 0
          }"
        />

        <!-- Right Fade effect (mirrored) -->
        <div
          class="absolute pointer-events-none z-20 hidden md:block"
          aria-hidden="true"
          :style="{
            width: '23rem',
            background: `linear-gradient(to left,
              rgba(232, 235, 240, 0) 0%,
              rgba(232, 235, 240, 1) 0%,
              rgba(255, 255, 255, 0) 100%
            )`,
            right: '-12rem',
            top: 0,
            bottom: 0
          }"
        />

        <!-- Rest of the right section content -->
        <div
          class="flex flex-col h-full"
          style="gap: 3px"
        >
          <!-- Top Row -->
          <div
            class="id-hero-animation-top-row overflow-hidden hidden md:block"
            style="height: 384px"
          >
            <div
              class="infinite-scroll-container top-row h-full"
              :style="{ '--duration': animationDurations.top }"
            >
              <div
                class="flex h-full"
                style="gap: 3px"
              >
                <template v-for="(item, index) in duplicatedImages.top">
                  <div
                    v-if="item.type === 'split'"
                    :key="`top-split-${index}`"
                    class="flex-none w-80 h-96 relative flex flex-col"
                    style="gap: 3px"
                  >
                    <div class="flex-1 relative">
                      <img
                        :src="item.upper"
                        :alt="`Inspiration image ${index + 1} top`"
                        class="object-cover absolute inset-0 w-full h-full"
                        loading="lazy"
                        decoding="async"
                      >
                    </div>
                    <div class="flex-1 relative">
                      <img
                        :src="item.lower"
                        :alt="`Inspiration image ${index + 1} bottom`"
                        class="object-cover absolute inset-0 w-full h-full"
                        loading="lazy"
                        decoding="async"
                      >
                    </div>
                  </div>
                  <div
                    v-else
                    :key="`top-${index}`"
                    class="flex-none w-80 h-96 relative"
                  >
                    <img
                      :src="item.src"
                      :alt="`Inspiration image ${index + 1}`"
                      class="object-cover absolute inset-0 w-full h-full"
                      loading="lazy"
                      decoding="async"
                    >
                  </div>
                </template>
              </div>
              <!-- Duplicate for seamless loop -->
              <div
                class="flex h-full"
                style="gap: 3px"
              >
                <template v-for="(item, index) in duplicatedImages.top">
                  <div
                    v-if="item.type === 'split'"
                    :key="`top-split-${index}`"
                    class="flex-none w-80 h-96 relative flex flex-col"
                    style="gap: 3px"
                  >
                    <div class="flex-1 relative">
                      <img
                        :src="item.upper"
                        :alt="`Inspiration image ${index + 1} top`"
                        class="object-cover absolute inset-0 w-full h-full"
                        loading="lazy"
                        decoding="async"
                      >
                    </div>
                    <div class="flex-1 relative">
                      <img
                        :src="item.lower"
                        :alt="`Inspiration image ${index + 1} bottom`"
                        class="object-cover absolute inset-0 w-full h-full"
                        loading="lazy"
                        decoding="async"
                      >
                    </div>
                  </div>
                  <div
                    v-else
                    :key="`top-${index}`"
                    class="flex-none w-80 h-96 relative"
                  >
                    <img
                      :src="item.src"
                      :alt="`Inspiration image ${index + 1}`"
                      class="object-cover absolute inset-0 w-full h-full"
                      loading="lazy"
                      decoding="async"
                    >
                  </div>
                </template>
              </div>
            </div>
          </div>

          <!-- Bottom Row -->
          <div
            class="id-hero-animation-bottom-row overflow-hidden"
            style="height: 255px"
          >
            <div
              class="infinite-scroll-container bottom-row h-full"
              :style="{ '--duration': animationDurations.bottom }"
            >
              <div
                class="flex h-full"
                style="gap: 3px"
              >
                <div
                  v-for="(image, index) in duplicatedImages.bottom"
                  :key="`bottom-${index}`"
                  :class="[
                    'flex-none h-60 relative',
                    image.width === 'double-width' ? '' : image.width
                  ]"
                  :style="image.width === 'double-width' ? 'width: 36rem' : ''"
                >
                  <img
                    :src="image.src"
                    :alt="`Inspiration image ${index + 7}`"
                    class="object-cover absolute inset-0 w-full h-full"
                    loading="lazy"
                    decoding="async"
                  >
                </div>
              </div>
              <!-- Duplicate for seamless loop -->
              <div
                class="flex h-full"
                style="gap: 3px"
              >
                <div
                  v-for="(image, index) in duplicatedImages.bottom"
                  :key="`bottom-duplicate-${index}`"
                  :class="[
                    'flex-none h-60 relative',
                    image.width === 'double-width' ? '' : image.width
                  ]"
                  :style="image.width === 'double-width' ? 'width: 36rem' : ''"
                >
                  <img
                    :src="image.src"
                    :alt="`Inspiration image ${index + 7}`"
                    class="object-cover absolute inset-0 w-full h-full"
                    loading="lazy"
                    decoding="async"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ButtonLink from '@/Components/Shared/ButtonLink'
  import cdn from '@/mixins/cdn'

  export default {
    name: 'HeroAnimation',
    components: {
      ButtonLink
    },

    mixins: [cdn],

    data() {
      return {
        courses: [
          {
            id: 'USP1',
            title: 'Expert knowledge',
            description: 'Learn from world-renowned designers, makers and gardeners'
          },
          {
            id: 'USP2',
            title: 'At your own pace',
            description: 'Watch bite-sized lessons at a time that suits you'
          },
          {
            id: 'USP3',
            title: 'Unlimited access',
            description: 'Over 1200 detailed video lessons and workbooks'
          }
        ],
        images: {
          topFull: [],
          topSplit: {
            upper: [],
            lower: []
          },
          bottom: []
        },
        isVisible: false,
        observer: null
      }
    },

    computed: {
      duplicatedImages() {
        const topRowImages = []
        const fullImagesCount = this.images.topFull.length

        // Just need one set of images for each row since CSS will handle the loop
        for (let i = 0; i < fullImagesCount * 2; i++) {
          if (i % 3 === 0) {
            const upperIndex = Math.floor(i / 3) % this.images.topSplit.upper.length
            const lowerIndex = Math.floor(i / 3) % this.images.topSplit.lower.length
            topRowImages.push({
              type: 'split',
              upper: this.images.topSplit.upper[upperIndex],
              lower: this.images.topSplit.lower[lowerIndex]
            })
          } else {
            const fullIndex = i % fullImagesCount
            topRowImages.push({
              type: 'full',
              src: this.images.topFull[fullIndex]
            })
          }
        }

        return {
          top: topRowImages,
          bottom: this.images.bottom
        }
      },
      animationDurations() {
        const baselineDuration = 80
        const baselineImageCount = 10

        // Set default speed to 0.5x of original
        const speedMultiplier = 2 // 1/0.5 to make it half speed

        const topDuration =
          baselineDuration *
          (this.duplicatedImages.top.length / baselineImageCount) *
          speedMultiplier
        const bottomDuration =
          baselineDuration *
          (this.duplicatedImages.bottom.length / baselineImageCount) *
          speedMultiplier

        return {
          top: `${topDuration}s`,
          bottom: `${bottomDuration}s`
        }
      }
    },
    mounted() {
      // Create intersection observer
      this.observer = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            this.isVisible = entry.isIntersecting
          })
        },
        {
          rootMargin: '50px 0px', // Start loading slightly before visible
          threshold: 0.1
        }
      )

      // Start observing the component
      this.observer.observe(this.$el)
    },

    beforeUnmount() {
      // Cleanup
      if (this.observer) {
        this.observer.disconnect()
      }
    },

    created() {
      this.images.topFull = [
        this.imageUrl('hero/top/full/1.webp'),
        this.imageUrl('hero/top/full/2.webp'),
        this.imageUrl('hero/top/full/3.webp'),
        this.imageUrl('hero/top/full/4.webp'),
        this.imageUrl('hero/top/full/5.webp'),
        this.imageUrl('hero/top/full/6.webp')
      ]

      this.images.topSplit.upper = [
        this.imageUrl('hero/top/split/upper/1.webp'),
        this.imageUrl('hero/top/split/upper/2.webp'),
        this.imageUrl('hero/top/split/upper/3.webp'),
        this.imageUrl('hero/top/split/upper/4.webp'),
        this.imageUrl('hero/top/split/upper/5.webp')
      ]

      this.images.topSplit.lower = [
        this.imageUrl('hero/top/split/lower/1.webp'),
        this.imageUrl('hero/top/split/lower/2.webp'),
        this.imageUrl('hero/top/split/lower/3.webp'),
        this.imageUrl('hero/top/split/lower/4.webp'),
        this.imageUrl('hero/top/split/lower/5.webp')
      ]

      this.images.bottom = [
        { src: this.imageUrl('hero/bottom/1v3.webp'), width: 'w-80' },
        { src: this.imageUrl('hero/bottom/2v3.webp'), width: 'w-80' },
        { src: this.imageUrl('hero/bottom/3v3.webp'), width: 'w-80' },
        { src: this.imageUrl('hero/bottom/4v3.webp'), width: 'w-80' },
        { src: this.imageUrl('hero/bottom/5v3.webp'), width: 'w-80' },
        { src: this.imageUrl('hero/bottom/6v3.webp'), width: 'w-80' },
        { src: this.imageUrl('hero/bottom/7v3.webp'), width: 'w-80' },
        { src: this.imageUrl('hero/bottom/8v3.webp'), width: 'w-80' },
        { src: this.imageUrl('hero/bottom/9v3.webp'), width: 'w-80' },
        { src: this.imageUrl('hero/bottom/10v3.webp'), width: 'w-80' },
        { src: this.imageUrl('hero/bottom/11v3.webp'), width: 'w-80' },
        { src: this.imageUrl('hero/bottom/12v3.webp'), width: 'w-80' },
        { src: this.imageUrl('hero/bottom/13v3.webp'), width: 'w-80' },
        { src: this.imageUrl('hero/bottom/14v3.webp'), width: 'w-80' },
        { src: this.imageUrl('hero/bottom/15v3.webp'), width: 'w-80' },
        { src: this.imageUrl('hero/bottom/16v3.webp'), width: 'w-80' },
        { src: this.imageUrl('hero/bottom/17v3.webp'), width: 'w-80' },
        { src: this.imageUrl('hero/bottom/18v3.webp'), width: 'w-80' },
        { src: this.imageUrl('hero/bottom/19v3.webp'), width: 'w-80' },
        { src: this.imageUrl('hero/bottom/20v3.webp'), width: 'w-80' }
      ]
    }
  }
</script>

<style scoped>
  .w-80 {
    width: 18rem;
  }
  .h-96 {
    height: 24rem;
  }
  .h-60 {
    height: 16rem;
  }

  .min-h-screen {
    margin-top: 0;
    padding-top: 0;
  }

  @keyframes slideRight {
    0% {
      transform: translateX(calc(-50% - 1.5px));
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes slideLeft {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-50% - 1.5px));
    }
  }

  /* Top row moves right */
  .infinite-scroll-container.top-row {
    display: inline-flex;
    animation: slideRight var(--duration) linear infinite;
    animation-play-state: v-bind(isVisible ? 'running': 'paused');
  }

  /* Bottom row moves left */
  .infinite-scroll-container.bottom-row {
    display: inline-flex;
    animation: slideLeft var(--duration) linear infinite;
    animation-play-state: v-bind(isVisible ? 'running': 'paused');
  }

  .infinite-scroll-container > div {
    display: flex;
    margin-right: 3px;
  }

  .infinite-scroll-container .flex {
    display: flex;
    gap: 0;
  }
</style>
