<template>
  <svg
    width="30"
    height="38"
    viewBox="0 0 30 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.85347 10.9425C6.6346 5.942 10.3703 2.29662 14.6667 2.29662C18.963 2.29662 22.6987 5.942 23.4799 10.9425C21.0263 9.09529 17.9744 8.00032 14.6667 8.00032C11.359 8.00032 8.30699 9.09529 5.85347 10.9425ZM4.07893 12.5175C4.24813 6.05361 8.81504 0.666992 14.6667 0.666992C20.5183 0.666992 25.0852 6.05361 25.2544 12.5175C27.7808 15.1524 29.3333 18.7284 29.3333 22.667C29.3333 30.7672 22.7668 37.3337 14.6667 37.3337C6.56649 37.3337 0 30.7672 0 22.667C0 18.7284 1.5525 15.1524 4.07893 12.5175ZM14.6667 35.704C21.8668 35.704 27.7037 29.8671 27.7037 22.667C27.7037 15.4668 21.8668 9.62995 14.6667 9.62995C7.46651 9.62995 1.62963 15.4668 1.62963 22.667C1.62963 29.8671 7.46651 35.704 14.6667 35.704ZM16.2963 22.231C17.2705 21.6674 17.9259 20.6141 17.9259 19.4077C17.9259 17.6077 16.4667 16.1485 14.6667 16.1485C12.8666 16.1485 11.4074 17.6077 11.4074 19.4077C11.4074 20.6141 12.0628 21.6674 13.037 22.231V27.5559H16.2963V22.231Z"
      fill="#9E6C21"
    />
  </svg>
</template>

<docs>
  To know more information on how to use icons see [here](/packs/#Icon)

  ```jsx
    <Icon name="Lock" class="text-4xl"></Icon>
  ```
</docs>
