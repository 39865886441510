<template>
  <div class="flex flex-col items-center">
    <MetaTags :metaTags="metaTags" />

    <div class="w-full max-w-full px-4 xl:px-0 xl:max-w-screen-lg min-h-screen flex flex-col">
      <div class="flex items-center justify-center max-w-screen-lg w-full my-6">
        <Logotype class="w-auto h-7 navbar__logo" />
      </div>
      <div class="flex flex-col gap-5 lg:gap-9 w-full flex-1 items-center justify-center -mt-20">
        <div class="flex flex-col gap-5 lg:gap-3 w-full">
          <div class="mb-3 lg:mb-8 mx-auto">
            <Icon
              name="Loader"
              class=" text-black text-3xl lg:text-5xl animate-spin-loader duration-150 "
            />
          </div>
          <h4
            class="font-serif text-3xl leading-9 lg:text-5xl lg:leading-tight font-medium text-black text-center w-128 max-w-full mx-auto"
          >
            Just a moment, we're setting up your account.
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

import { metaTags } from '@/mixins/metaTags'
import mixpanel from '@/mixins/mixpanel'

import Layout from '@/Layouts/ReducedLayout'
import Logotype from '@/Components/Shared/Logotype'
import Icon from '@/Components/Shared/Icon'
import MetaTags from '@/Components/Shared/MetaTags'

export default {
  layout: Layout,

  mixins: [
    metaTags,
    mixpanel
  ],

  components: {
    MetaTags,
    Logotype,
    Icon
  },

  props: {
    user: {
      type: Object,
      required: true
    },
    sessionId: {
      type: String,
      default: null
    }
  },

  async mounted() {
    sessionStorage.removeItem('onboarding_email')
    sessionStorage.removeItem('selectedTopics')
    const response = await axios.post(
      this.$routes.onboarding_create_membership(this.user.token),
      {
        session_id: this.sessionId
      }
    )

    if (response.data.status === 'success') {
      // Track successful payment and membership creation
      this.trackEvent('Paid Subscription', {
        chatbot_variant: window.chatbotTestVariant || 'not_set',
        status: 'success',
        payment_confirmed: true
      })
      
      window.location.href = response.data.url
    }
  }
}
</script>
