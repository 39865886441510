<template>
  <div class="bg-white lg:bg-neutral-100 memberSlider">
    <div class="container pb-12 py-12 lg:py-24">
      <div class="flex flex-col mb-2 lg:mb-0 gap-1 lg:gap-3">
        <h2
          class="text-3xl lg:text-4xl font-serif font-medium text-black text-balance text-center capitalize"
        >
          Hear from our community of creative learners
        </h2>
        <!-- <p class="text-center text-base lg:text-xl block lg:hidden text-black font-normal">
          What our community of creators has to say about our courses.
        </p> -->
      </div>
      <div class="testimonial-grid mt-8 lg:mt-14 relative">
        <div class="video-wrapper">
          <div
            v-for="(testimonial, index) in testimonials"
            :key="index"
            class="video-item"
          >
            <div class="bg-neutral-200 overflow-hidden video-container">
              <div
                class="relative w-full h-0"
                style="padding-top: 177.78%"
              >
                <VerticalVideo
                  :video-id="testimonial.videoId"
                  :reference-id="testimonial.referenceId"
                  :image-id="testimonial.imageId"
                  :account-id="accountId"
                  :player-id="playerId"
                  :unique-id="`video-${index}`"
                  class="absolute inset-0"
                  :insta-id="testimonial.instaId"
                  @video-play="trackVideoPlay(index)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent, computed } from 'vue'
  import VerticalVideo from './VerticalVideo.vue'
  import mixpanelMixin from '@/mixins/mixpanel'

  export default defineComponent({
    name: 'MemberSlider',
    components: {
      VerticalVideo
    },
    mixins: [mixpanelMixin],
    data() {
      return {
        accountId: '6222962662001',
        playerId: 'awlLVI5lZ'
      }
    },
    computed: {
      testimonials() {
        return [
          {
            videoId: '6363076111112',
            referenceId: '0a072ebe-4ec6-4260-992e-03174e94e217',
            imageId: '512ac181-be3a-4ef4-adc3-8d958cf53580',
            instaId: '@tamgina'
          },
          {
            videoId: '6363075133112',
            referenceId: 'c972b11e-92e6-4402-bcfc-42ef5f7c9a07',
            imageId: '33d41c4e-830f-49ca-9edf-f9d416eb9048',
            instaId: '@lauranormington03'
          },
          {
            videoId: '6363075510112',
            referenceId: '1d2e8b53-54ae-4fc5-b363-fabb38274c76',
            imageId: '4416dbf7-f6cd-4064-9545-09409d419bdd',
            instaId: '@jolaing_studio'
          },
          {
            videoId: '6363075819112',
            referenceId: '4afa3c2a-51f8-4f00-8d74-47a785aa2f2f',
            imageId: 'cd9753bb-291a-4fd8-979a-5a4b19bc2529',
            instaId: '@nicoladesigns'
          }
        ]
      }
    },
    methods: {
      trackVideoPlay(index) {
        const videoId = this.testimonials[index].videoId
        const instaId = this.testimonials[index].instaId
        this.trackEvent('UGC Video Played', {
          videoId,
          index,
          instaId
        })
      }
    }
  })
</script>

<style scoped>
  .testimonial-grid {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }

  .video-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }

  .video-item {
    flex: 0 0 calc(50% - 10px);
    max-width: calc(50% - 10px);
  }

  .video-container {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }

  @media (min-width: 640px) {
    .video-item {
      flex: 0 0 calc(50% - 10px);
      max-width: calc(50% - 10px);
    }
  }

  @media (min-width: 768px) {
    .video-item {
      flex: 0 0 calc(50% - 10px);
      max-width: calc(50% - 10px);
    }
  }

  @media (min-width: 1024px) {
    .video-item {
      flex: 0 0 calc(25% - 15px);
      max-width: calc(25% - 15px);
    }
    .video-wrapper {
      gap: 20px;
    }

    .video-container {
      max-width: 100%;
    }
  }
</style>
