<template>
  <div
    data-testid="general-announcement"
    :class="{'cursor-pointer': clickable}"
    class="general-announcement"
    @click="handleGeneralAnnoucementClick"
  >
    <!-- Overflow on the Page -->
    <div class="pt-20 sm:pt-16 lg:pt-12" />

    <!-- Announcement Component -->
    <div
      data-testid="general-announcement-campaign"
      :class="campaignBackgroundColor"
      class="fixed h-20 sm:h-16 lg:h-12 top-0 left-0 w-full z-40 flex justify-center items-center"
      data-announcement
    >
      <div class="container text-center py-3">
        <p
          data-testid="general-announcement-campaign-text-wrapper"
          :class="campaignTextColor"
          class="subheading--medium"
        >
          <template v-if="showSignupWall">
            <SignupWall
              :gen-announcement="genAnnouncementObj"
            />

            <Countdown
              v-if="genAnnouncementObj.countdownActive"
              :gen-announcement="genAnnouncementObj"
            />
          </template>

          <template v-else>
            <Campaign
              v-if="genAnnouncementObj.campaignTextBody"
              :gen-announcement="genAnnouncementObj"
            />

            <Coupon
              v-if="genAnnouncementObj.couponActive"
              :gen-announcement="genAnnouncementObj"
            />

            <Countdown
              v-if="genAnnouncementObj.countdownActive"
              :gen-announcement="genAnnouncementObj"
            />

            <Cta
              v-if="ctaEnabled"
              :gen-announcement="genAnnouncementObj"
            />
          </template>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import currentUser from '@/mixins/currentUser'
import generalAnnouncement from '@/mixins/generalAnnouncement'

import SignupWall from '@/Components/Shared/Campaigns/GeneralAnnouncement/SignupWall'
import Campaign from '@/Components/Shared/Campaigns/GeneralAnnouncement/Campaign'
import Coupon from '@/Components/Shared/Campaigns/GeneralAnnouncement/Coupon'
import Countdown from '@/Components/Shared/Campaigns/GeneralAnnouncement/Countdown'
import Cta from '@/Components/Shared/Campaigns/GeneralAnnouncement/Cta'

import AuthModal from '@/Components/Shared/AuthModal'
import { openModal } from 'jenesius-vue-modal'

export default {

  components: {
    SignupWall,
    Campaign,
    Coupon,
    Countdown,
    Cta
  },
   mixins: [
    currentUser,
    generalAnnouncement
  ],

  data() {
    return {
      form: this.$inertia.form({})
    }
  },

  computed: {
    ctaEnabled() {
      return this.genAnnouncementObj.ctaActive && this.genAnnouncementObj.ctaHref
    },

    clickable() {
      return this.showSignupWall || this.ctaEnabled
    },

    ctaWindowOpenParam() {
      return this.genAnnouncementObj.ctaOpenNewTab ? '_blank' : '_self'
    }
  },

  methods: {
    openSignInModal() {
      openModal(AuthModal,
        {
          next: window.location.href,
          queryParameters: `?origin=${this.$routes.general_subscription_users()}`,
          generalAnnouncement: this.genAnnouncementObj,
          extraAction: {
            type: 'general-announcement'
          }
        }
//        {
//          width: '95%',
//          maxWidth: 500
//        }
      )
    },

    handleGeneralAnnoucementClick(){
      if(this.showSignupWall){
        this.openSignInModal()
      } else if(this.ctaEnabled) {
        window.open(this.genAnnouncementObj.ctaHref, this.ctaWindowOpenParam)
      }
    }
  }
}
</script>
