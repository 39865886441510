<template>
  <ImageWrapper background="password">
    <MetaTags :meta-tags="metaTags" />

    <SetPasswordForm :reset-password-token="resetPasswordToken" />
  </ImageWrapper>
</template>

<script>
import { metaTags } from '@/mixins/metaTags'

import Layout from '@/Layouts/ReducedLayout'

import MetaTags from '@/Components/Shared/MetaTags'
import ImageWrapper from '@/Components/Shared/Objects/ImageWrapper'
import SetPasswordForm from '@/Components/Auth/SetPasswordForm'

export default {

  components: {
    MetaTags,
    ImageWrapper,
    SetPasswordForm
  },

  mixins: [metaTags],
  layout: Layout,

  props: {
    resetPasswordToken: {
      type: String,
      required: true
    }
  }
}
</script>
