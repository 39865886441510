<template>
  <div
    data-testid="add-campaign-to-cart-modal"
    class="add-campaign-to-cart-modal bg-grayscale-100 relative"
  >
    <div class="z-10 absolute top-4 right-4">
      <button
        data-testid="add-campaign-to-cart-modal-close"
        @click="handleClose"
      >
        <Icon
          name="close"
          class="text-green-500 text-2xl"
        />
      </button>
    </div>

    <div class="flex flex-col items-center bg-yellow-100 p-6">
      <p
        data-testid="gift-preview-amount"
        class="text-6xl font-serif text-center text-green-500"
      >
        {{ selectedCampaign.humanizedPrice }}
      </p>
      <p class="overline--medium text-center text-green-500">
        {{ $I18n.t('gift_preview.gift_voucher', i18nScope) }}
      </p>
      <div class="h-px w-8 bg-green-500 mt-4 mx-auto" />
    </div>

    <div class="p-8 ">
      <GiftForm
        :campaigns="campaigns"
        :pre-selected-campaign="preSelectedCampaign"
        :is-modal="isModal"
        :gtm-page-name="gtmPageName"
      />
    </div>
  </div>
</template>

<script>
import GiftForm from '@/Components/Shared/Gifts/Form'
import Icon from '@/Components/Shared/Icon'
import { closeModal } from 'jenesius-vue-modal'

export default {
  components: {
    GiftForm,
    Icon
  },

  props: {
    campaigns: {
      type: Array,
      default: () => []
    },
    preSelectedCampaign: {
      type: String,
      required: true
    },
    isModal: {
      type: Boolean,
      default: true
    },
    gtmPageName: {
      type: String,
      required: true
    }
  },

  computed: {
    selectedCampaign() {
      return this.campaigns.find(campaign => campaign.slug === this.preSelectedCampaign)
    }
  },

  created() {
    this.i18nScope = { scope: 'pages.gifts' }
  },

  methods: {
    handleClose() {
      closeModal()
    }
  }
}
</script>

<style>
/* Implement the styles that were previously passed to openModal */
.modal-item:has(.add-campaign-to-cart-modal) {
  width: 95% !important;
  max-width: 500px !important;
}
</style>
