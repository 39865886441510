<template>
  <svg
    width="230"
    height="148"
    viewBox="0 0 230 148"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    alt="Create Academy Monogram"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M156.885 0.841553L214.312 130.811C218.079 139.305 221.964 143.023 229 143.023V144.681H185.773V143.023C192.184 143.023 196.32 140.542 196.32 135.987C196.32 134.334 195.832 132.443 194.664 129.779L146.944 23.3495L156.885 0.841553ZM111.063 103.871H112.961V135.659C101.835 142.585 87.8097 146.342 71.2533 146.342C26.3697 146.342 0 118.807 0 75.0403C0 31.2737 26.3697 3.73901 71.2533 3.73901C87.8097 3.73901 101.84 7.4953 112.961 14.4215V46.0056H111.024C106.031 20.8207 92.6038 5.94434 67.6694 5.94434C35.546 5.94434 19.7241 32.6292 19.7241 75.0403C19.7241 117.451 35.5502 144.136 67.6694 144.136C92.6717 144.136 106.103 129.179 111.063 103.871Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Monogram'
}
</script>
