<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22 6.5H2V5.5H22V6.5ZM2 12.5V11.5H22V12.5H2ZM22 17.5V18.5H2V17.5H22Z"
    />
  </svg>
</template>

<docs>
  This icon is directly being used inside the navbar (mobile version) - it can be activated to open/close.
  ```jsx
    <Icon name="Menu" class="text-4xl"/>
  ```
</docs>
