<template>
  <ImageWrapper background="password">
    <MetaTags :meta-tags="metaTags" />

    <ForgotPasswordForm />
  </ImageWrapper>
</template>

<script>
import { metaTags } from '@/mixins/metaTags'

import Layout from '@/Layouts/ReducedLayout'

import MetaTags from '@/Components/Shared/MetaTags'
import ImageWrapper from '@/Components/Shared/Objects/ImageWrapper'
import ForgotPasswordForm from '@/Components/Auth/ForgotPasswordForm'

export default {

  components: {
    MetaTags,
    ImageWrapper,
    ForgotPasswordForm
  },

  mixins: [metaTags],
  layout: Layout
}
</script>
