<template>
  <svg
    width="34"
    height="28"
    viewBox="0 0 34 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.39648 26.4998C4.6055 22.8152 9.44695 20.2934 17.0003 20.2934C24.5536 20.2934 29.395 22.8152 31.6041 26.4998M23.0003 7.49976C23.0003 10.8135 20.314 13.4998 17.0003 13.4998C13.6866 13.4998 11.0003 10.8135 11.0003 7.49976C11.0003 4.18605 13.6866 1.49976 17.0003 1.49976C20.314 1.49976 23.0003 4.18605 23.0003 7.49976Z"
      stroke="#18336C"
      stroke-width="2.107"
      stroke-linecap="square"
    />
  </svg>
</template>

<docs>
  To know more information on how to use icons see [here](/packs/#Icon)

  ```jsx
    <Icon name="Arrow" class="text-4xl"></Icon>
  ```
</docs>
