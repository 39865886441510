<template>
  <div
    class="hero"
    :class="[height && 'hero--is-' + height]"
  >
    <!-- Hero image -->
    <img
      :id="`hero-image-${uid}`"
      data-testid="hero-image"
      :alt="`${imageAlt}`"
      :src="bannerImage.url"
      :srcset="bannerImage.srcset"
      class="hero__img"
      fetchpriority="high"
    >

    <!-- Hero Content -->
    <div
      class="hero__content"
      :class="[
        verticalAlign && 'justify-' + verticalAlign,
        darken ? 'gradient--overlay' : 'hero__content--partial-darken'
      ]"
    >
      <div class="container flex flex-col items-center h-full">
        <div class="text-center max-w-[1200px] mt-auto pb-2">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import imgSrcset from '@/mixins/imgSrcset'

  export default {
    name: 'Hero',

    mixins: [imgSrcset],

    props: {
      height: {
        type: String,
        default: 'large'
      },
      darken: {
        type: Boolean,
        default: true
      },
      verticalAlign: {
        type: String,
        default: 'end'
      },
      uid: {
        type: [Number, String],
        required: true
      },
      imageSrc: {
        type: [String, Object],
        required: true
      },
      imageAlt: {
        type: String,
        required: true
      }
    },

    computed: {
      bannerImage() {
        if (this.imageSrc) {
          return this.imgSrcsetBanner(this.imageSrc)
        } else {
          return { url: null, srcset: [] }
        }
      }
    }
  }
</script>

<style>
  .hero {
    @apply relative w-full overflow-hidden;
    height: 75vh;
  }

  .hero--is-medium {
    height: 60vh;
  }

  .hero--is-small {
    height: 45vh;
  }

  .hero__img {
    @apply absolute inset-0 w-full h-full object-cover;
  }

  .hero__content {
    @apply absolute inset-0 flex flex-col;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
  }

  .hero__content--partial-darken {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
  }
</style>
