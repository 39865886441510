<template>
  <div class="w-full relative">
    <div class="container px-4 lg:px-16 py-10 lg:py-14 flex flex-col">
      <div class="flex items-center justify-between mb-5 lg:mb-16">
        <div class="text-center flex-grow mx-2 sm:mx-4">
          <h2 class="text-4xl lg:text-5xl text-blue-800 font-serif">
            Meet your instructors
          </h2>
          <p class="text-lg lg:text-2xl text-black font-normal tracking-wider lg:tracking-normal">
            Learn from some of the most celebrated interiors designers working today.
          </p>
        </div>
      </div>
      <div
        ref="swiperContainer"
        class="designer-carousel instructors-swiper"
      >
        <div class="swiper-wrapper">
          <div
            v-for="(card, index) in designerCards"
            :key="index"
            class="swiper-slide !w-auto"
          >
            <a
              :href="card.href"
              class="block h-full"
            >
              <div class="relative overflow-hidden h-96 lg:h-160">
                <img
                  :src="card.image"
                  :alt="card.name"
                  class="w-full h-full object-cover object-center"
                >
                <div class="absolute bottom-0 left-0 right-0 p-4 custom-gradient">
                  <h2 class="text-white text-2xl font-normal uppercase tracking-wide">
                    {{ card.name }}
                  </h2>
                  <p class="text-white text-lg tracking-wide"><i>teaches </i>{{ card.title }}</p>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="flex items-center justify-between mt-5 lg:mt-8">
          <div
            ref="prevEl"
            class="custom-swiper-button instructors-prev bg-white w-12 h-12 flex items-center justify-center border border-blue-800"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="23"
              viewBox="0 0 44 46"
              fill="none"
              class="navigation-arrow"
            >
              <path
                d="M18.5 44.8789L1 22.9391M1 22.9391L18.5 0.999245M1 22.9391L43 22.9391"
                stroke="#0E1E38"
                stroke-linecap="square"
              />
            </svg>
          </div>

          <div
            ref="nextEl"
            class="custom-swiper-button instructors-next bg-white w-12 h-12 flex items-center justify-center border border-blue-800"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="23"
              viewBox="0 0 44 46"
              fill="none"
              class="navigation-arrow"
            >
              <path
                d="M25.5 1L43 22.9398M43 22.9398L25.5 44.8797M43 22.9398L1 22.9398"
                stroke="#0E1E38"
                stroke-linecap="square"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import Swiper from 'swiper/bundle'
import 'swiper/swiper-bundle.css'
import { getS3Url } from '../../../s3utils'

export default defineComponent({
  name: 'Instructors',
  setup() {
    const swiperContainer = ref(null)
    const prevEl = ref(null)
    const nextEl = ref(null)
    let swiper = null

    const designerCards = ref([
      {
        image: getS3Url('0.jpg'),
        name: 'RITA KONIG',
        title: 'INTERIOR DESIGN',
        href: '/courses/rita-konig'
      },
      {
        image: getS3Url('1.jpg'),
        name: 'NINA CAMPBELL',
        title: 'DECORATING',
        href: '/courses/nina-campbell'
      },
      {
        image: getS3Url('2.jpg'),
        name: 'MILES REDD',
        title: 'DRAMATIC INTERIORS',
        href: '/courses/miles-redd'
      },
      {
        image: getS3Url('7.jpg'),
        name: 'ALIDAD',
        title: 'TIMELESS INTERIORS',
        href: '/courses/alidad'
      },
      {
        image: getS3Url('14.jpg'),
        name: 'AMANDA LINDROTH',
        title: 'INTERIORS FOR ENTERTAINING',
        href: 'courses/amanda-lindroth'
      },
      {
        image: getS3Url('8.jpg'),
        name: 'KATE WATSON-SMYTH',
        title: 'DESIGNING ON A BUDGET',
        href: '/courses/kate-watson-smyth'
      },
      {
        image: getS3Url('30.jpg'),
        name: 'RITA KONIG',
        title: 'ADVANCED INTERIOR DESIGN',
        href: '/courses/rita-konig'
      },
      {
        image: getS3Url('6.jpg'),
        name: 'LISA DAWSON',
        title: 'RESOURCEFUL STYLING',
        href: '/courses/lisa-dawson'
      },
      {
        image: getS3Url('gabby.jpg'),
        name: 'GABBY DEEMING',
        title: 'PATTERN AND COLOUR',
        href: '/courses/gabby-deeming'
      },
      {
        image: getS3Url('18.jpg'),
        name: 'KATHRYN IRELAND',
        title: 'EDITED INTERIORS',
        href: '/courses/kathryn-ireland'
      },
      {
        image: getS3Url('3.jpg'),
        name: 'EDWARD BULMER',
        title: 'PIGMENTS, PAINTS & PALLETS',
        href: 'courses/edward-bulmer'
      },
      {
        image: getS3Url('11.jpg'),
        name: '2LG STUDIO',
        title: 'BOLD DESIGN',
        href: '/courses/2lg-studio'
      },
      {
        image: getS3Url('4.jpg'),
        name: 'ANNIE SLOAN',
        title: 'FURNITURE PAINTING',
        href: '/courses/annie-sloan'
      },
      {
        image: getS3Url('13.jpg'),
        name: 'WILLOW CROSSLEY',
        title: 'FLORISTRY',
        href: '/courses/willow-crossley'
      },
      {
        image: getS3Url('9.jpg'),
        name: 'MOLLY MAHON',
        title: 'BLOCK PRINTING',
        href: '/courses/molly-mahon'
      },
      {
        image: getS3Url('17.jpg'),
        name: 'MICAELA SHARP',
        title: 'MODERN UPHOLSTERY',
        href: '/courses/micaela-sharp'
      },
      {
        image: getS3Url('16.jpg'),
        name: 'JESS WHEELER',
        title: 'BOTANICAL METALWORK',
        href: '/courses/jess-wheeler'
      },
      {
        image: getS3Url('10.jpg'),
        name: 'JULIUS ARTHUR',
        title: 'CONTEMPORARY QUILT MAKING',
        href: '/courses/julius-arthur'
      },
      {
        image: getS3Url('12.jpg'),
        name: 'TESS NEWALL',
        title: 'DECORATIVE PAINTING',
        href: '/courses/tess-newall'
      }
    ])

    onMounted(() => {
      swiper = new Swiper(swiperContainer.value, {
        slidesPerView: 1.2,
        spaceBetween: 10,
        initialSlide: 0,
        watchSlidesProgress: true,
        navigation: {
          prevEl: prevEl.value,
          nextEl: nextEl.value
        },
        breakpoints: {
          640: {
            slidesPerView: 3,
            spaceBetween: 15
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 20
          }
        },
        on: {
          init: function () {
            if (this.navigation && this.navigation.prevEl) {
              this.navigation.prevEl.classList.add('swiper-button-disabled')
            }
          }
        }
      })
    })

    return {
      swiperContainer,
      prevEl,
      nextEl,
      designerCards
    }
  }
})
</script>

<style scoped>
.custom-gradient {
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0.3) 50%,
    rgba(0, 0, 0, 0) 100%
  );
}
.designer-carousel .swiper-slide {
  height: auto;
}

.custom-swiper-button {
  position: relative;
  width: 48px;
  height: 48px;
  cursor: pointer;
  flex-shrink: 0;
  z-index: 10;
  background: #ffffff;
}

.custom-swiper-button::after {
  display: none;
}

/* Optional: Add hover effect */
.custom-swiper-button:hover svg path {
  stroke: #4a5568;
}

.custom-swiper-button svg {
  width: 100%;
  height: 100%;
}

.designer-carousel {
  margin: 0 -5px;
  padding: 0 5px;
}

@media (max-width: 640px) {
  .designer-carousel {
    margin: 0 -10px;
    padding: 0 10px;
  }
}

.navigation-arrow {
  transform: scale(0.6);
  transform-origin: center;
}

.swiper-button-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>