<template>
  <div
    data-testid="category"
    class="h-64 sm:h-128"
    :class="{ 'lg:h-144' : smallerGrid }"
  >
    <Link
      :data-testid="`category-${category.slug}-link`"
      :href="$routes.category(category.slug)"
      class="relative h-full w-full flex justify-center items-end p-6"
      @click="onLinkClick"
    >
      <div class="absolute top-0 left-0 w-full h-full overflow-hidden">
        <img
          loading="lazy"
          :data-testid="`category-${category.slug}-image`"
          :data-src="category.thumbnail"
          :alt="category.name"
          class="lazyload image-cover"
        >
      </div>

      <Button
        :data-testid="`category-${category.slug}-name`"
        inverse
        size="small"
        class="relative"
      >
        {{ $I18n.t( category.slug , i18nScope) }}
      </Button>
    </Link>
  </div>
</template>

<script>
import gtm from '@/mixins/gtm'
import mixpanel from '@/mixins/mixpanel'

import { Link } from '@inertiajs/vue3'
import Button from '@/Components/Shared/Button'

export default {

  components: {
    Link,
    Button
  },
  mixins: [
    gtm,
    mixpanel
  ],

  props: {
    category: {
      type: Object,
      required: true
    },
    gtmPageName: {
      type: String,
      required: true
    },
    gtmSectionName: {
      type: String,
      required: true
    },
    smallerGrid: {
      type: Boolean,
      default: false
    }
  },

  created() {
    this.i18nScope = { scope: 'pages.home.categories' }
  },

  methods: {
    onLinkClick() {
      if (this.gtmPageName) {
        this.gtmAddCategoryInteraction(this.gtmPageName, this.category.name)
      }

      this.trackEvent('go_to_category_page', {
        page: window.location.pathname,
        component: this.gtmSectionName,
        category: this.category.name
      })
    }
  }
}
</script>
