<template>
  <div
    data-testid="subscription-confirmation-modal"
    class="subscription-confirmation-modal bg-grayscale-100 p-8 relative"
  >
    <div class="absolute top-6 right-6">
      <button
        data-testid="subscription-confirmation-modal-close"
        @click="handleClose"
      >
        <Icon
          name="close"
          class="text-2xl text-blue-800"
        />
      </button>
    </div>
    <div class="flex flex-col items-center text-center">
      <Icon
        name="Success"
        class="title-xl text-blue-800 mb-2"
      />
      <h1
        data-testid="subscription-confirmation-modal-title"
        class="title-medium text-blue-800 mb-6"
      >
        {{ $I18n.t('title', i18nScope) }}
      </h1>
      <p class="mb-6">
        {{ $I18n.t('first_paragraph', i18nScope) }}
      </p>

      <p
        class="mb-6"
        v-html="$I18n.t('second_paragraph', i18nScope)"
      />

      <Button
        data-testid="subscription-confirmation-modal-button"
        class="mx-auto"
        @click="handleClose"
      >
        {{ $I18n.t('button', i18nScope) }}
      </Button>
    </div>
  </div>
</template>

<script>
import Icon from '@/Components/Shared/Icon'
import Button from '@/Components/Shared/Button'
import { closeModal } from 'jenesius-vue-modal'

export default {
  components: {
    Icon,
    Button
  },

  data() {
    return {
      showSignIn: false
    }
  },

  created() {
    this.i18nScope = { scope: 'pages.home.subscription_confirmation_modal' }
  },

  methods: {
    handleClose() {
      closeModal()
    }
  }
}
</script>

<style>
/* Implement the styles that were previously passed to openModal */
.modal-item:has(.subscription-confirmation-modal) {
  width: 95% !important;
  max-width: 500px !important;
}
</style>
