<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.5073 2H3V2.5073V17.4261V17.9145L3.48804 17.933L6.88787 18.0621L6.08388 20.6177L5.64899 22L6.85109 21.1907L11.6897 17.9333H21.7699H22.2772V17.4261V2.5073V2H21.7699H3.5073ZM4.01459 16.9377V3.01459H21.2626V16.9188H11.5348H11.38L11.2515 17.0052L7.48659 19.5398L8.05514 17.7326L8.25459 17.0987L7.59048 17.0735L4.01459 16.9377ZM17.3156 6.20067L10.4497 12.8283L7.86718 10.431L7.17694 11.1747L10.1114 13.8986L10.4634 14.2253L10.8089 13.8918L18.0203 6.93064L17.3156 6.20067Z"
    />
  </svg>
</template>

<docs>
  To know more information on how to use icons see [here](/packs/#Icon)

  ```jsx
    <Icon name="Success" class="text-4xl"></Icon>
  ```
</docs>
