<template>
  <SplitBanner
    :title="category.name"
    :description="category.description"
    :image="bannerImage"
    :image-id="`banner-image-${category.slug}`"
    :image-alt="`${category.name} banner image`"
    :background-color="backgroundColor"
  />
</template>

<script>
import SplitBanner from '@/Components/Shared/SplitBanner.vue'
import imgSrcset from '@/mixins/imgSrcset'

export default {
  components: {
    SplitBanner
  },

  mixins: [imgSrcset],

  props: {
    category: {
      type: Object,
      required: true
    },
    backgroundColor: {
      type: String,
      default: 'bg-gray-100'
    }
  },

  computed: {
    bannerImage() {
      if (this.category.bannerImagePath) {
        return this.imgSrcsetBanner(this.category.bannerImagePath)
      } else {
        return { url: null, srcset: [] }
      }
    }
  }
}
</script>
