<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.58296 7H4.52863C4.61783 5.45361 4.91228 4.02907 5.36644 2.88228C3.91752 3.77135 2.87513 5.25848 2.58296 7ZM8.5 0C4.08172 0 0.5 3.58172 0.5 8C0.5 12.4183 4.08172 16 8.5 16C12.9183 16 16.5 12.4183 16.5 8C16.5 3.58172 12.9183 0 8.5 0ZM8.5 2C8.42395 2 8.26787 2.03173 8.0347 2.26184C7.79723 2.4962 7.53751 2.8849 7.29782 3.44417C6.90914 4.3511 6.62491 5.58559 6.53237 7H10.4676C10.3751 5.58559 10.0909 4.3511 9.70218 3.44417C9.46249 2.8849 9.20277 2.4962 8.9653 2.26184C8.73213 2.03173 8.57605 2 8.5 2ZM12.4714 7C12.3822 5.45361 12.0877 4.02907 11.6336 2.88228C13.0825 3.77135 14.1249 5.25848 14.417 7H12.4714ZM10.4676 9H6.53237C6.62491 10.4144 6.90914 11.6489 7.29782 12.5558C7.53751 13.1151 7.79723 13.5038 8.0347 13.7382C8.26787 13.9683 8.42395 14 8.5 14C8.57605 14 8.73213 13.9683 8.9653 13.7382C9.20277 13.5038 9.46249 13.1151 9.70218 12.5558C10.0909 11.6489 10.3751 10.4144 10.4676 9ZM11.6336 13.1177C12.0877 11.9709 12.3822 10.5464 12.4714 9H14.417C14.1249 10.7415 13.0825 12.2287 11.6336 13.1177ZM5.36644 13.1177C4.91228 11.9709 4.61783 10.5464 4.52863 9H2.58296C2.87513 10.7415 3.91752 12.2287 5.36644 13.1177Z"
    />
  </svg>
</template>

<docs>
  To know more information on how to use icons see [here](/builds/#Icon)

  ```jsx
    <Icon name="Linkedin" class="text-4xl"></Icon>
  ```
</docs>
