import Layout from '@/Layouts/Layout'
import { createApp, h } from 'vue'
import { createInertiaApp } from '@inertiajs/vue3'
import I18n from './i18n.js.erb'
import Routes from './routes.js'
import { appsignal } from './appsignal'
import { errorHandler } from '@appsignal/vue'

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'stylesheets/application'
import 'vendor/lazysizes.min.js'
import '../styles/brightcove-custom.css'

// check if the XSRF-TOKEN cookie isn't set and if a reload has happened to get the cookie
// the JSON.parse forces the value to be a boolean
if (
  document.cookie.indexOf('XSRF-TOKEN') < 0 &&
  !JSON.parse(localStorage?.getItem('xsrf-reload'))
) {
  // prevents endless loops with 404 responses
  localStorage?.setItem('xsrf-reload', true)

  // browser lost the authenticity token (issue related to safari-mobile)
  location.reload()
} else {
  localStorage?.setItem('xsrf-reload', false)

  const el = document.getElementById('app')

  // Pages that are regular Rails templates don't have any Vue component to
  // be mounted (and the #app element doesn't exist).
  // Without this check, a console error is shown in those situations.
  if (el) {
    createInertiaApp({
      initialPage: JSON.parse(el.dataset.page),
      resolve: name => {
              return import(`@/Pages/${name}`)
                .then(module => {
                  const page = module.default
                  page.layout = page.layout === undefined ? Layout : page.layout
                  return page
                })
                .catch(error => {
                  console.error(`Failed to load component: ${name}`, error)
                  // Fallback to require for any components that need it
                  const page = require(`@/Pages/${name}`).default
                  page.layout = page.layout === undefined ? Layout : page.layout
                  return page
                })
      },
      setup({ el, App, props, plugin }) {
        const app = createApp({
          render: () => h(App, props)
        })

        // Setup error handling
        app.config.errorHandler = errorHandler(appsignal, app)

        // Register the Inertia plugin
        app.use(plugin, {
          preserveState: true
        })

        // Setup routes
        app.config.globalProperties.$routes = Routes

        // Setup translations
        I18n.locale = 'en'
        app.config.globalProperties.$I18n = I18n

        // mount app
        app.mount(el)
      }
    })
  }
}
