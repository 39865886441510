<template>
  <div>
    <MetaTags :meta-tags="metaTags" />
    <div data-testid="banner-section">
      <SplitBanner
        :title="collection.name"
        :description="showBannerDescription ? collection.description : ''"
        background-color="bg-blue-500"
      >
        <template #actions>
          <div v-if="isMyCollection">
            <button
              v-if="collection.slug!=='default_collection'"
              class="button button border-white text-white bg-transparent hover:bg-white hover:bg-opacity-20"
              data-testid="lesson-new-collection-button"
              @click="openDialog"
            >
              Edit
              <Icon
                name="Pencil"
                class="text-xl ml-2"
                :is-stroke-icon="true"
              />
            </button>
          </div>
        </template>
      </SplitBanner>
    </div>
    <!-- Content -->

    <section
      data-testid=""
      class=" bg-white"
    >
      <div class="container">
        <Link
          :href="$routes.user_collections()"
          class="flex items-center"
        >
          <span
            class="text-xs font-medium uppercase underline text-grayscale-900"
          >
            <Icon
              name="Arrow"
              class="mb-1 transform rotate-180"
            />
            Back to collections
          </span>
        </Link>
        <div class="grid grid-cols-12 gap-8 lg:gap-12 pt-4 w-full">
          <template v-if="collection.lessons">
            <div
              v-for="items in collection.lessons"
              :key="items.id"
              class=" col-span-12 sm:col-span-6 md:col-span-4 2xl:col-span-3   relative "
            >
              <div class="relative  h-auto flex-shrink-0">
                <div>
                  <Link
                    :data-testid="`${items.id}-link`"
                    :href="$routes.course_lesson(items.course_slug.slug, items.slug)"
                    class="flex relative w-full h-48"
                  >
                    <!-- THUMBNAIL -->
                    <img
                      loading="lazy"
                      :src="items.poster"
                      class="lazyload image-cover"
                    >

                    <!-- PLAY BUTTON -->
                    <div
                      v-if="!preLaunch"
                      class="flex justify-center items-center absolute inset-0 cursor-pointer bg-grayscale-100 bg-opacity-0 hover:bg-opacity-30 transition-colors"
                    >
                      <div class="bg-grayscale-100 bg-opacity-80 w-16 h-16 rounded-full flex justify-center items-center">
                        <Icon
                          name="PlayFull"
                          class="text-blue-800 text-xl relative left-0.5"
                        />
                      </div>
                    </div>
                  </Link>

                  <div class="mt-3 text-grayscale-700">
                    <div
                      v-if="!singleCourse"
                      class="flex flex-row flex-nowrap justify-between"
                    >
                      <Link
                        :data-testid="`${items.id}-link`"
                        :href="$routes.course_lesson(items.course_slug.slug, items.slug)"
                        class="flex flex-col"
                      >
                        <p
                          class="font-normal text-black"
                        >
                          {{ items.title }}
                        </p>
                        <p class="text-sm text-black">
                          With {{ items.teacher_name }}
                        </p>
                      </Link>

                      <CollectionDropDown
                        ref="menudropdown"
                        :lesson-id="items.id"
                        :is-bookmarked="true"
                        :icon-only="true"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <template v-else>
            <div class=" col-span-12 h-80 flex items-center justify-center">
              <p class="text-lg text-opacity-80 text-center mt-28">
                No Saved Lessons
              </p>
            </div>
          </template>
        </div>
      </div>
    </section>

    <CategoryMembershipBanner
      v-if="!isSubscribed"
      v-bind="{...categoryMembershipBannerProps }"
      :custom-image="true"
      component="Collections Promotional Banner"
    />
    <dialog-box
      ref="dialogBox"
      :title="'Edit collection'"
      :edit="true"
      :collection="collection"
      :description="'Rename or delete your collection'"
    />
  </div>
</template>
<script>
import { metaTags } from '@/mixins/metaTags'
import statuses from '@/mixins/statuses'
import currentUser from '@/mixins/currentUser'


import MetaTags from '@/Components/Shared/MetaTags'
import { Link  } from '@inertiajs/vue3'
import CollectionDropDown from '../../Components/Shared/SavedLessons/CollectionDropDown.vue'
import DialogBox from '../../Components/Shared/SavedLessons/DialogBox.vue'
import {formatTimeAgo} from '@/mixins/dateFormat'
import Icon from '../../Components/Shared/Icon.vue'
import SplitBanner from '@/Components/Shared/SplitBanner.vue'
import CategoryMembershipBanner from '@/Components/Shared/Categories/CategoryMembershipBanner.vue'

export default {

  components: {
    MetaTags,
    Link,
    CollectionDropDown,
    DialogBox,
    Icon,
    SplitBanner,
    CategoryMembershipBanner
},

  mixins: [
    metaTags,
    statuses,
    currentUser
  ],
  props: {
    collection: {
      type: Object,
      default: () => {
        return {}
      }
    },
    singleCourse: {
      type: Boolean,
      default: false
    },
    categoryMembershipBannerProps: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    linkComponent() {
      return (this.preLaunch && this.singleCourse) ? 'div' : 'Link'
    },
    isMyCollection() {
      return this.currentUser && this.collection.user.email === this.currentUser.email
    },
    showBannerDescription() {
      const collectionName = this.collection.name?.trim().toLowerCase() || ''
      const defaultName = 'my saved lessons'
      return collectionName !== defaultName
    }
  },

  created() {
    this.i18nScope = { scope: 'pages.user_collection' }
  },

  methods: {
    handleRemoveLesson(id) {
      const path = this.$routes.destroy_lesson_by_id_user_collections()
      this.$inertia.delete(`${path}?id=${id}`, {
        preserveScroll: true,
        onSuccess: () => {
          this.$inertia.get()
        },
        onError: () => {
          console.error('Failed to delete Lesson')
        }
      })
    },
    openDialog() {
      this.$refs.dialogBox.open()
    },
     formattedCreatedAt(value) {
      return formatTimeAgo(value)
    }
  }
}
</script>
