<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.5565 2C20.9051 2 22 3.09492 22 4.44352V19.5565C22 20.9051 20.9051 22 19.5565 22H4.44352C3.09492 22 2 20.9051 2 19.5565V4.44352C2 3.09492 3.09488 2 4.44352 2H19.5565V2ZM8.26801 18.5343V9.71723H5.33676V18.5343H8.26801ZM18.8359 18.5343V13.4781C18.8359 10.7698 17.3899 9.50988 15.4617 9.50988C13.9068 9.50988 13.2104 10.365 12.8204 10.9656V9.71723H9.88988C9.92875 10.5446 9.88988 18.5343 9.88988 18.5343H12.8204V13.6102C12.8204 13.3466 12.8393 13.0832 12.917 12.8948C13.1285 12.3684 13.611 11.8232 14.4207 11.8232C15.4806 11.8232 15.9053 12.632 15.9053 13.8168V18.5343H18.8359V18.5343ZM6.82219 5.4657C5.8193 5.4657 5.16406 6.12504 5.16406 6.98922C5.16406 7.83523 5.79938 8.51273 6.7834 8.51273H6.8023C7.82438 8.51273 8.46059 7.83523 8.46059 6.98922C8.44164 6.12625 7.82617 5.46758 6.82219 5.4657V5.4657Z"
    />
  </svg>
</template>

<docs>
  To know more information on how to use icons see [here](/packs/#Icon)

  ```jsx
    <Icon name="Linkedin" class="text-4xl"></Icon>
  ```
</docs>
