<template>
  <div
    class="search_field anonSearchBar"
    :class="{ searchExpand : searchBar }"
  >
    <div
      data-testid="navbar-search-link"
      class="search_field--icon "
      :class="{ searchIconClose: searchBar }"
      @click="$emit('open')"
    >
      <Icon
        name="Search"
        class=" text-white"
      />
    </div>
    <div
      v-if="searchBar"
      :class="{ searchOpen: searchBar }"
      class="search_field--block"
    >
      <SearchGroup
        ref="searchGroup"
        testid="search"
        placeholder="Search"
        :should-focus="searchBar"
        :handle-close="handleClose"
        type="text"
        autocapitalize="off"
        @close="handleClose"
      />
    </div>
  </div>
</template>

<script>
import Icon from '@/Components/Shared/Icon'
import SearchGroup from '@/Components/Shared/Forms/SearchGroup'

export default {
  components: {
    Icon,
    SearchGroup
  },
  props: {
    searchBar: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close', 'open'],
  methods: {
    handleClose() {
      this.$emit('close')
    }
  }
}
</script>
<style scoped>
.searchExpand {
  flex:1;
}
</style>
