<template>
  <MenuPanel
    :name="$I18n.t('seasonal.title', i18nScope)"
    :current-panel="isToggleSeasonalMenu"
    class="overflow-y-scroll"
    back-button-active
    @click-back="$emit('toggleSeasonalMenu')"
    @click-close="$emit('toggleSeasonalMenu') && $emit('toggleGeneralMenu') "
  >
    <SeasonalList screen="mobile" />
  </MenuPanel>
</template>

<script>
import SeasonalList from '@/Components/Shared/Objects/Navbar/SeasonalList'
import MenuPanel from '@/Components/Shared/Objects/Navbar/Mobile/MenuPanel'

export default {
  components: {
    SeasonalList,
    MenuPanel
  },

  props: {
    categories: Array,

    isToggleSeasonalMenu: {
      type: Boolean,
      required: true
    }
  },

  emits: ['toggleSeasonalMenu', 'toggleGeneralMenu'],
  created() {
    this.i18nScope = { scope: 'pages.navbar' }
  }
}
</script>
