<template>
  <div class="w-full">
    <form
      :action="googleFormAction"
      method="post"
      class="mb-4"
    >
      <input
        type="hidden"
        name="authenticity_token"
        :value="formAuthenticityToken"
      >
      <Button
        data-testid="button-google"
        type="submit"
        variant="google"
        block
      >
        {{ $I18n.t('sign_with_google', i18nScope) }}
      </Button>
    </form>

    <form
      :action="facebookFormAction"
      method="post"
    >
      <input
        type="hidden"
        name="authenticity_token"
        :value="formAuthenticityToken"
      >
      <Button
        data-testid="button-facebook"
        type="submit"
        variant="facebook"
        block
      >
        {{ $I18n.t('sign_with_facebook', i18nScope) }}
      </Button>
    </form>
  </div>
</template>

<script>
import Button from '@/Components/Shared/Button'


export default {
  components: {
    Button
  },

  props: {
    formAuthenticityToken: {
      type: String,
      required: true
    },
    i18nScope: {
      type: Object,
      required: true
    },
    redirectUri: {
      type: String,
      default: null
    }
  },

  computed: {
    googleFormAction() {
      let url = this.$routes.user_google_oauth2_omniauth_authorize()
      if (this.redirectUri) {
        const encodedRedirectUri = encodeURIComponent(this.redirectUri)
        url += `?origin=${encodedRedirectUri}`
      }
      return url
    },
    facebookFormAction() {
      let url = this.$routes.user_facebook_omniauth_authorize()
      if (this.redirectUri) {
        const encodedRedirectUri = encodeURIComponent(this.redirectUri)
        url += `?origin=${encodedRedirectUri}`
      }
      return url
    }
  }
}
</script>
