<template>
  <div>
    <MetaTags :meta-tags="metaTags" />

    <Tabs />

    <div class="md:mt-14 container-sm">
      <h1 class="title-large text-blue-800 mb-8">
        {{ $I18n.t('title', i18nScope) }}
      </h1>

      <NotificationPreferencesForm :account="account" />
    </div>
  </div>
</template>

<script>
import { metaTags } from '@/mixins/metaTags'

import MetaTags from '@/Components/Shared/MetaTags'
import NotificationPreferencesForm from '@/Components/User/NotificationPreferencesForm'
import Tabs from '@/Components/User/Tabs'

export default {

  components: {
    MetaTags,
    NotificationPreferencesForm,
    Tabs
  },
  mixins: [metaTags],

  props: {
    account: {
      type: Object,
      required: true
    }
  },

  created() {
    this.i18nScope = { scope: 'pages.users.notifications' }
  }
}
</script>
