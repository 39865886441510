<template>
  <svg
    width="31"
    height="20"
    viewBox="0 0 31 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_9499_7315)">
      <path
        d="M27.6685 3.13345C27.5224 2.52781 27.2375 1.97566 26.8421 1.53199C26.4467 1.08832 25.9546 0.768615 25.4148 0.604703C23.4387 0 15.4853 0 15.4853 0C15.4853 0 7.53163 0.018304 5.55548 0.623007C5.01569 0.786929 4.52359 1.10665 4.12818 1.55034C3.73277 1.99403 3.44786 2.5462 3.30181 3.15187C2.70407 7.09148 2.4722 13.0945 3.31822 16.8765C3.46429 17.4822 3.74921 18.0343 4.14461 18.478C4.54002 18.9217 5.03212 19.2414 5.5719 19.4053C7.54804 20.01 15.5016 20.01 15.5016 20.01C15.5016 20.01 23.455 20.01 25.431 19.4053C25.9708 19.2414 26.4629 18.9217 26.8584 18.478C27.2538 18.0344 27.5387 17.4822 27.6848 16.8765C28.3153 12.9314 28.5095 6.93199 27.6685 3.13345Z"
        fill="black"
      />
      <path
        d="M12.8867 14.7733L21.1211 10.0038L12.8867 5.23438V14.7733Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_9499_7315">
        <rect
          width="31"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<docs>
  To know more information on how to use icons see [here](/builds/#Icon)

  ```jsx
    <Icon name="Linkedin" class="text-4xl"></Icon>
  ```
</docs>
