<template>
  <div class="input-group">
    <input
      :id="computedId"
      type="radio"
      class="input-radio"
      :value="option"
      :checked="option === modelValue ? true : null"
      @change="$emit('update:modelValue', option)"
    >

    <label
      :for="computedId"
      :data-testid="`label-${testid}`"
      class="radio-label"
    >
      <span>{{ label }}</span>
    </label>

    <InputError
      :testid="testid"
      :errors="errors"
    />
  </div>
</template>

<script>
import InputError from '@/Components/Shared/Forms/InputError'

/**
 * Radio component for Create Academy
 * @displayName Radio
 */
export default {
  name: 'Radio',

  components: {
    InputError
  },

  props: {
    id: {
      type: String,
      default: null
    },
    option: {
      type: String,
      required: true
    },
    modelValue: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    errors: {
      type: Array,
      default: () => []
    },
    testid: String
  },
  emits: ['update:modelValue'],
  computed: {
    computedId() {
      return this.id || `radio-input-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`
    }
  }
}
</script>

<docs>
  ### ✎ Radio Button

  ```jsx
    <Radio>Hey there I'm a radio button</Radio>
  ```
  ---
</docs>
