<template>
  <div>
    <MetaTags :meta-tags="metaTags" />

    <!-- Main Image/Video Header -->
    <div data-testid="banner-section">
      <Banner
        :uid="course.slug"
        :image-src="course.bannerImagePath"
        :image-alt="course.name"
        :video-id="course.bannerVideoId"
        :video-auto-play="true"
        :brightcove="brightcove.sliderPlayer"
        top-padding="80"
        bottom-padding="224"
        lg-top-padding="80"
        lg-bottom-padding="10"
      >
        <CourseHero
          :course="course"
          :brightcove="brightcove.lessonPlayer"
          :gtm-page-name="currentPage"
          :ab-test-variants="abTestVariants"
          :membership-plan="membershipPlan"
        />
      </Banner>
    </div>

    <CourseOutcome
      v-if="outcomes.length > 0"
      :outcomes="outcomes"
    />

    <SubscribePreOrderBanner
      v-if="showSubscribePreOrderBanner"
      ref="get-offer"
      :course="course"
    />

    <CourseDetailSection
      :course="course"
      :categories="categories"
      :curriculum="curriculum"
      :campaigns="campaigns"
      :gtm-page-name="currentPage"
    />

    <!-- Course teacher section -->
    <CourseTeacherSection
      :course="course"
      :show-sale-tag="!enrolled && !comingSoon"
    />

    <div class="container pb-8">
      <!-- Course objectives section -->
      <section
        v-if="!enrolled && objectives.length"
        data-testid="objectives-section"
        class="flex flex-row justify-around flex-wrap mb-4 sm:mb-8"
      >
        <Box
          v-for="(objective, index) in objectives"
          :key="index"
          spacing="xs"
          class="w-full lg:w-1/3 sm:max-w-sm border-none"
        >
          <h3 class="title-medium text-center text-blue-800 mb-4">
            {{ objective.title }}
          </h3>
          <p class="text-center text-lg mb-8 lg:mb-0">
            {{ objective.description }}
          </p>

          <Divider
            v-if="index != Object.keys(objectives).length - 1"
            spacing="xs"
            class="lg:hidden"
          />
        </Box>
      </section>

      <!-- CTA -->
      <template v-if="!isSubscribed && !enrolled">
        <div class="flex flex-col-reverse lg:flex-row gap-3 lg:gap-4 justify-center pb-8">
          <!-- MembershipButton -->
          <div
            v-if="purchasable || course.releaseDate"
            class="flex flex-col gap-2"
          >
            <SubscribeButton
              ref="subscribeButton"
              :membership-plan="membershipPlan"
              :button-border="true"
              component="Course & Trailer CTA"
            />

            <div
              style="max-width: 289px"
              class="font-normal text-lg"
            >
              Access all courses with an annual subscription.
            </div>
          </div>

          <!-- PurchaseButton -->
          <PurchaseButton
            test-id="group-button-submit-purchase"
            click="onCoursePurchaseActionClick('purchase')"
            :course="course"
            :enable-upsell="true"
            :in-cart="course.inCart"
            :button-border="true"
            :gtm-page-name="currentPage"
            gtm-section-name="Course Objectives"
            :ab-test-variants="abTestVariants"
            block
            @show-upsell="showUpsellOfferModal"
            ref="purchaseButton"
          />
        </div>

        <UpsellOfferModal
          ref="upsellModal"
          :membership-plan="membershipPlan"
          :header-image="headerImageUrl"
          @accept-upsell-offer="handleAcceptUpsell"
          @decline-upsell-offer="handleDeclineUpsell"
        />
        <Divider spacing="xl" />
      </template>

      <!-- Featured Collections -->
      <!-- <template v-if="filteredCollections.length">

        <FeaturedCollections
          :collections="filteredCollections"
          :brightcove="brightcove.lessonPlayer"
          :gtmPageName="currentPage"
        />

        <Divider spacing="xl" />
      </template> -->

      <!-- Testemonials / Trustpilot -->
      <template v-if="published && !enrolled">
        <Testimonials class="my-8" />
        <Divider spacing="xl" />
      </template>
    </div>

    <!-- Gift Banner / Live courses only -->
    <!-- <GiftingBanner
      v-if="published"
    /> -->

    <!-- FAQs -->
    <div
      class="container"
      :class="[{ 'pb-0': published }, { 'pt-0': comingSoon || preLaunch }]"
    >
      <template v-if="!enrolled">
        <SerifHeader
          :title="$I18n.t('faq', i18nScope)"
          content-align="center"
        />

        <Faq
          :gtm-page-name="currentPage"
          class="max-w-5xl mx-auto"
        />

        <Divider
          v-if="published"
          spacing="xl"
        />
      </template>
    </div>

    <!-- Gift Banner / Upcoming & Pre-sale courses -->
    <!-- <GiftingBanner v-if="comingSoon || preLaunch" /> -->

    <!-- Recommendations -->
    <RelatedCoursesSection
      :course-id="course.id"
      :brightcove="brightcove.lessonPlayer"
      :membership-plan="membershipPlan"
      :gtm-page-name="currentPage"
    />
  </div>
</template>

<script>
// Mixins
import brightcove from '@/mixins/brightcove'
import currentUser from '@/mixins/currentUser'
import featureFlags from '@/mixins/featureFlags'
import generalAnnouncement from '@/mixins/generalAnnouncement'
import gtm from '@/mixins/gtm'
import { metaTags } from '@/mixins/metaTags'
import statuses from '@/mixins/statuses'
import mixpanel from '@/mixins/mixpanel'

import Layout from '@/Layouts/TransparentLayout'

// Shared
import Banner from '@/Components/Shared/Banner'
import Divider from '@/Components/Shared/Divider'
import Faq from '@/Components/Shared/Faq/Index'
import MetaTags from '@/Components/Shared/MetaTags'
// import FeaturedCollections from '@/Components/Shared/Collections/Index'

// Campaigns related
import AddCampaignToCartModal from '@/Components/Shared/Gifts/AddCampaignToCartModal'
// import CampaignAnnouncement from '@/Components/Shared/Campaigns/CampaignAnnouncement'
// import GiftingBanner from '@/Components/Shared/Gifts/Banner'
// import SubscribeUpcomingBanner from '@/Components/Shared/Campaigns/UpcomingBanner'

// AB Testing bits
import abTest from '@/mixins/abTest'
import upsellMixin from '../../mixins/upsellMixin'
import UpsellOfferModal from '../../Components/Shared/Courses/UpsellOfferModal.vue'
import { openModal } from 'jenesius-vue-modal'
import { defineAsyncComponent } from 'vue'

export default {

  mixins: [
    brightcove,
    currentUser,
    featureFlags,
    generalAnnouncement,
    gtm,
    metaTags,
    statuses,
    mixpanel,
    abTest,
    upsellMixin
  ],
  layout: Layout,

  components: {
    Banner,
    Divider,
    Faq,
    MetaTags,
    CourseHero: defineAsyncComponent(() => import('@/Components/Shared/Courses/Hero')),
    PurchaseButton: defineAsyncComponent(() => import('@/Components/Shared/Courses/PurchaseButton')),
    SubscribeButton: defineAsyncComponent(() => import('@/Components/Shared/Courses/SubscribeButton')),
    SubscribePreOrderBanner: defineAsyncComponent(() => import('@/Components/Shared/Campaigns/PreOrderBanner')),
    CourseDetailSection: defineAsyncComponent(() =>
      import('@/Components/Shared/Courses/OptimsedComponents/CourseDetailSection')
    ),
    Testimonials: defineAsyncComponent(() => import('@/Components/Shared/Testimonials/Show')),
    SerifHeader: defineAsyncComponent(() => import('@/Components/Shared/SectionHeader/Serif')),
    Box: defineAsyncComponent(() => import('@/Components/Shared/Box')),
    CourseOutcome: defineAsyncComponent(() => import('@/Components/Shared/Courses/OptimsedComponents/CourseOutcome')),
    CourseTeacherSection: defineAsyncComponent(() =>
      import('@/Components/Shared/Courses/OptimsedComponents/CourseTeacherSection')
    ),
    RelatedCoursesSection: defineAsyncComponent(() =>
      import('@/Components/Shared/Courses/OptimsedComponents/RelatedCoursesSection')
    ),
    UpsellOfferModal
  },

  props: {
    course: {
      type: Object,
      required: true
    },
    categories: {
      type: Array,
      default: () => []
    },
    objectives: {
      type: Array,
      default: () => []
    },
    campaigns: {
      type: Array,
      default: () => []
    },
    membershipPlan: {
      type: Object,
      default: null
    },
    // userAcTags: {
    //   type: Array,
    //   required: true
    // },
    // preLaunchTag: {
    //   type: String,
    //   required: true
    // },
    curriculum: {
      type: Object,
      default: () => ({})
      // },
      // viewingOrderCourses: {
      //   type: Array,
      //   default: () => []
      // },
      // collections: {
      //   type: Array,
      //   default: () => []
    }
  },

  data() {
    return {
      metaTags: {},
      headerImageUrl: this.randomCourseCategoryHeaderImage(this.course.categories)
    }
  },

  computed: {
    showSubscribePreOrderBanner() {
      return this.preLaunch && !this.enrolled
    },
    currentPage() {
      return 'PDP'
    },
    courseGift() {
      return this.campaigns.find(
        element => element.unformatted_price === this.course.unformatted_price
      )
    },
    outcomes() {
      return [this.course.outcome1, this.course.outcome2, this.course.outcome3].filter(o => o)
    }
    // showViewingOrder() {
    //   // There should be at least 2 courses in order to show the viewing order section
    //   return this.viewingOrderCourses.length > 1
    // },
    // filteredCollections() {
    //   // if showViewingOrder is true, then show just 1 collection. Otherwise, show all collections
    //   return this.showViewingOrder
    //     ? this.collections.slice(0, 1)
    //     : this.collections
    // }
  },

  watch: {
    course: {
      handler(newCourse) {
        if (newCourse) {
          this.metaTags = this.buildCourseMetaTags(newCourse)
        }
      },
      immediate: true
    }
  },

  created() {
    this.i18nScope = { scope: 'pages.courses.show' }
  },

  mounted() {
    this.gtmAddProductDetailView(this.course)
  },

  methods: {
    scrollTo(refName) {
      var element = this.$refs[refName]
      var offset = 200
      var top = element.offsetTop - offset

      window.scrollTo({
        top: top,
        left: 0,
        behavior: 'smooth'
      })
    },

    getUserType() {
      if (!this.currentUser) {
        return 'anonymous'
      }

      if (this.isSubscribed) {
        return 'member'
      }

      if (this.enrolled) {
        return 'single purchaser'
      }

      return 'prospect'
    },

    openAddCampaignToCartModal(componentName) {
      this.trackEvent('gift_modal_course', {
        page: window.location.pathname,
        component: componentName,
        course: this.course.name,
        course_id: this.course.id,
        teacher: this.course.teacher.name,
        userType: this.getUserType()
      })

      openModal(
        AddCampaignToCartModal,
        {
          campaigns: this.campaigns,
          preSelectedCampaign: this.courseGift.slug,
          gtmPageName: this.currentPage
        }
//        {
//          width: '95%',
//          maxWidth: 500
//        }
      )
    },

    buildCourseMetaTags(course) {
      if (!course) return {}
      
      return {
        title: `${course.name} | Create Academy`,
        description: course.description || 'Learn creative skills from expert instructors at Create Academy.',
        keywords: `${course.name.toLowerCase()} course, ${course.teacher?.fullName?.toLowerCase() || 'expert'} instructor, online learning`,
        schema: JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'Course',
          'name': course.name,
          'description': course.description,
          'provider': {
            '@type': 'Organization',
            'name': 'Create Academy',
            'sameAs': typeof document !== 'undefined' ? document.location.origin : ''
          },
          'instructor': {
            '@type': 'Person',
            'name': course.teacher?.fullName
          }
        })
      }
    }
  }
}
</script>
