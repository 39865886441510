<template>
  <div>
    <MetaTags :meta-tags="metaTags" />

    <div data-testid="banner-section">
      <CategorySplitBanner
        :category="category"
        :background-color="backgroundColor"
      />
    </div>

    <template v-if="!isSubscribed">
      <BannerStrip
        v-if="bannerStrip.active"
        :banner-strip="bannerStrip"
      />
    </template>

    <CampaignAnnouncement v-if="showBuyOneGiftOne" />

    <section v-if="category.flatCourseList && courses.length">
      <div class="py-16 flex flex-col md:flex-row md:flex-wrap w-full px-16">
        <CategoryCourseTeaser
          v-for="(course, index) in courses"
          :key="course.id"
          :course="course"
          :brightcove="brightcove.lessonPlayer"
          :class="classNames"
          :gtm-page-name="currentPage"
          :gtm-section-name="gtmSectionExploreCourses"
          :gtm-position="index + 1"
        />
      </div>
    </section>

    <section v-else>
      <template
        v-for="(theme, index) in themes"
        :key="theme.id"
      >
        <div
          :class="[
            index % 2 === 0 ? 'bg-white' : backgroundColor,
            'bg-opacity-5',
          ]"
        >
          <div class="container">
            <h2 class="title-largest mb-8">
              {{ theme.name }}
            </h2>
            <!-- <div>{{ theme.description }}</div> -->

            <div
              class="flex flex-col md:flex-row md:flex-wrap w-full mx-0 md:-mx-3"
            >
              <CategoryCourseTeaser
                v-for="(course, index) in theme.courses"
                :key="course.id"
                :course="course"
                :brightcove="brightcove.lessonPlayer"
                :class="classNames"
                :gtm-page-name="currentPage"
                :gtm-section-name="gtmSectionExploreCourses"
                :gtm-position="index + 1"
              />
            </div>
          </div>
        </div>
      </template>
    </section>
    <template v-if="!isSubscribed">
      <CategoryMembershipBanner
        v-if="category.showMembershipBanner"
        :category="category"
        :membership-plan="membershipPlan"
        :geo-props="geoProps"
        component="Category Promotional Banner"
      />
    </template>
    <template v-if="!isSubscribed">
      <!-- Seasonal Banner -->
      <SeasonalBanner
        v-if="seasonalBanner.active"
        :seasonal-banner="seasonalBanner"
      />
    </template>

    <template v-if="upcomingCourses.length && category.showUpcomingCourses">
      <div class="container">
        <!-- Upcoming Courses -->
        <SerifHeader
          :title="'Upcoming Courses'"
          heading-level="h3"
          content-align="center"
        />

        <Upcoming
          :courses="upcomingCourses"
          :brightcove="brightcove.lessonPlayer"
          :gtm-page-name="currentPage"
          :gtm-section-name="gtmSectionUpcomingCourses"
        />
      </div>
    </template>

    <GiftingBanner v-if="category.showGiftBanner" />
  </div>
</template>

<script>
import { metaTags } from '@/mixins/metaTags'
import brightcove from '@/mixins/brightcove'
import currentUser from '@/mixins/currentUser'
import statuses from '@/mixins/statuses'
import featureFlags from '@/mixins/featureFlags'
import gtm from '@/mixins/gtm'
import mixpanel from '@/mixins/mixpanel'

import MetaTags from '@/Components/Shared/MetaTags'
import SerifHeader from '@/Components/Shared/SectionHeader/Serif'
import CategoryCourseTeaser from '@/Components/Shared/Categories/CategoryCourseTeaser'
import Upcoming from '@/Components/Shared/Upcoming/Index'
import GiftingBanner from '@/Components/Shared/Gifts/Banner'
import CampaignAnnouncement from '@/Components/Shared/Campaigns/CampaignAnnouncement'
import BannerStrip from '@/Components/Shared/Campaigns/CategoryBannerStrip'
import SeasonalBanner from '@/Components/Shared/SeasonalBanner'
import CategorySplitBanner from '@/Components/Shared/Categories/CategorySplitBanner.vue'

import Layout from '@/Layouts/TransparentLayout'
import monetize from '@/mixins/monetize'
import CategoryMembershipBanner from '@/Components/Shared/Categories/CategoryMembershipBanner'

export default {

  components: {
    MetaTags,
    SerifHeader,
    CategoryCourseTeaser,
    Upcoming,
    GiftingBanner,
    CampaignAnnouncement,
    BannerStrip,
    SeasonalBanner,
    CategoryMembershipBanner,
    CategorySplitBanner
  },

  mixins: [
    metaTags,
    brightcove,
    currentUser,
    statuses,
    featureFlags,
    gtm,
    mixpanel,
    monetize
  ],
  layout: Layout,

  props: {
    category: {
      type: Object,
      required: true
    },
    themes: {
      type: Array,
      default: () => {
        return []
      }
    },
    membershipPlan: {
      type: Object,
      default: null
    },
    courses: {
      type: Array,
      default: () => {
        return []
      }
    },
    upcomingCourses: {
      type: Array,
      default: () => {
        return []
      }
    },
    relatedCategories: {
      type: Array,
      default: () => {
        return []
      }
    },
    about: {
      type: Object,
      default: () => ({})
    },
    seasonalBanner: {
      type: Object,
      default: () => ({})
    },
    bannerStrip: {
      type: Object,
      default: () => ({})
    },
    geoProps: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      metaTags: {},
      classNames: 'w-full md:w-1/2 lg:w-1/3 p-0 md:p-3 mb-8',
      i18nScope: { scope: 'pages.category' }
    }
  },

  computed: {
    backgroundColor() {
      return this.getTailwindColorClass(this.category.primaryColour)
    },
    filteredTalentCourses() {
      return this.themes.flatMap((theme) =>
        theme.courses.filter((course) => !this.isComingSoon(course))
      )
    },
    currentPage() {
      return 'PLP'
    },
    gtmSectionExploreCourses() {
      return 'Explore our Courses'
    },
    gtmSectionUpcomingCourses() {
      return `Upcoming ${this.category.name} Courses`
    },
    gtmSectionExploreCategories() {
      return 'Explore our Categories'
    },
    showBannerStrip() {
      return !this.showBuyOneGiftOne && true
    }
  },

  watch: {
    category: {
      handler(newCategory) {
        if (newCategory) {
          this.metaTags = this.buildCategoryMetaTags(newCategory)
        }
      },
      immediate: true
    }
  },

  created() {
  },

  mounted() {
    this.gtmAddProductListingImpressions(this.currentPage, [
      {
        name: this.gtmSectionExploreCourses,
        impressions: this.filteredTalentCourses
      },
      {
        name: this.gtmSectionUpcomingCourses,
        impressions: this.upcomingCourses
      }
    ])
  },

  methods: {
    getGeoPrice(gbpBasePrice) {
      return this.geoMonetize(
        gbpBasePrice * this.geoProps.exchangeRate,
        this.geoProps.currencySymbol,
        2
      )
    },

    getTailwindColorClass(color) {
      const tailwindColorHash = {
        red: 'bg-red-800',
        green: 'bg-green-500',
        yellow: 'bg-yellow-800',
        blue: 'bg-blue-500'
      }
      return color ? tailwindColorHash[color.toLowerCase()] : 'bg-green-500'
    },

    buildCategoryMetaTags(category) {
      if (!category) return {}
      
      return {
        title: `${category.name} | Create Academy`,
        description: category.description || 'Explore our range of online creative courses. Learn from expert instructors at your own pace.',
        keywords: `${category.name.toLowerCase()} courses, online learning, expert instruction`,
        schema: JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'CollectionPage',
          'name': category.name,
          'description': category.description || 'Explore our range of online creative courses',
          'url': typeof document !== 'undefined' ? document.location.origin + document.location.pathname : ''
        })
      }
    }
  }
}
</script>
