<template>
  <div class="w-full lg:w-4/12">
    <Box
      data-testid="order-breakdown"
      :title="$I18n.t('details_order_breakdown', i18nScope)"
      color="dark"
      spacing="xs"
      class="mb-6"
    >
      <div class="flex flex-row justify-between mb-2">
        <p class="font-normal">
          {{ $I18n.t('summary_total_amount', i18nScope) }}
        </p>
        <p data-testid="order-summary-amount">
          {{ order.humanized_amount_without_discount }}
        </p>
      </div>

      <div
        class="flex flex-row justify-between opacity-50"
        :class="{ 'opacity-100' : order.voucher_discount }"
      >
        <div class="flex flex-row flex-wrap items-center">
          <p class="font-normal mr-2">
            {{ $I18n.t('summary_discount', i18nScope) }}
          </p>

          <Tags
            v-if="order.promotion_tier_id"
            data-testid="order-summary-promotion-banner"
            class="mb-2 mr-2"
            color="success"
            size="small"
            lower-case
          >
            <span class="text-xs tracking-wider">{{ order.promotion_tier_banner || $I18n.t('bundle_discount', i18nScope) }}</span>
          </Tags>
          <Tags
            v-if="order.voucher_code"
            data-testid="order-summary-voucher-code"
            class="mb-2 mr-2"
            color="success"
            size="small"
            lower-case
          >
            <span class="text-xs tracking-wider">{{ order.voucher_code }}</span>
          </Tags>
        </div>
        <p data-testid="order-summary-discount">
          {{ order.humanized_discount }}
        </p>
      </div>

      <Divider color="light" />

      <div class="flex flex-row justify-between">
        <div class="flex-col">
          <p class="text-lg font-normal">
            {{ $I18n.t('summary_grand_total', i18nScope) }}
          </p>
          <p
            data-testid="order-summary-billed-at"
            class="text-xs"
          >
            {{ $I18n.t('billed', i18nScope) }} {{ order.humanized_payment_confirmed_at }}
          </p>
        </div>

        <p
          data-testid="order-summary-grand-total"
          class="text-lg font-normal"
        >
          {{ order.humanized_amount }}
        </p>
      </div>
    </Box>

    <!-- PAYMENT INFORMATION -->
    <Box
      data-testid="order-details-payment-box"
      :title="$I18n.t('details_payment_title', i18nScope)"
      spacing="xs"
      class="mb-6"
    >
      <template v-if="order.card">
        <p class="font-normal mb-2">
          {{ $I18n.t('details_payment_debit_credit_card', i18nScope) }}
        </p>
        <p
          data-testid="order-details-payment-card"
          class="mb-4"
        >
          **** ***** ***** {{ order.card }}
        </p>

        <img
          loading="lazy"
          :src="creditCardsMark"
          :alt="$I18n.t('details_payment_debit_credit_card', i18nScope)"
          class="w-40 mb-2"
        >
      </template>
      <template v-else>
        <p
          data-testid="order-details-no-payment"
          class="text-sm"
        >
          {{ $I18n.t('details_no_payment', i18nScope) }}
        </p>
      </template>
    </Box>

    <!-- BILLING INFORMATION -->
    <Box
      data-testid="order-details-billing-box"
      :title="$I18n.t('details_billing_title', i18nScope)"
      spacing="xs"
      class="mb-6"
    >
      <p
        data-testid="order-details-billing-name"
        class="font-normal mb-2"
      >
        {{ order.billing_name }}
      </p>
      <p data-testid="order-details-billing-address">
        {{ order.billing_address }}
      </p>
      <p data-testid="order-details-billing-city-postcode-country">
        {{ order.billing_city_post_code_country }}
      </p>
    </Box>

    <p
      data-testid="order-details-billing-note"
      class="text-sm"
    >
      {{ $I18n.t('details_billing_note', i18nScope) }}
    </p>
  </div>
</template>

<script>
import Box from '@/Components/Shared/Box'
import Divider from '@/Components/Shared/Divider'
import Tags from '@/Components/Shared/Tags'

export default {

  components: {
    Divider,
    Box,
    Tags
  },

  props: {
    order: {
      type: Object,
      required: true
    }
  },
  created() {
    this.i18nScope = { scope: 'pages.users.purchase_item' },
    this.creditCardsMark = require('@/assets/images/CreditCardsMark.svg')
  }
}
</script>
