<template>
  <div class="container mx-auto px-0 md:px-4 py-8">
    <MetaTags :meta-tags="metaTags" />
    <!-- Title & Subhead -->
    <div class="text-center px-4 md:px-0 mb-10">
      <div class="title-largest mb-1">
        Upgrade for all courses
      </div>
      <div class="font-normal text-xl mb-3">
        Get <span class="text-yellow-800 font-bold">25% off</span> your first year of membership
      </div>
    </div>

    <!-- Plan Options -->
    <div class="flex flex-col md:flex-row justify-center text-center flex-wrap gap-0 md:gap-8 mb-8 md:border md:border-black/20 p-0 md:p-6 w-full sm:w-4/5 lg:w-2/3 xl:w-1/2 mx-auto">
      <!-- Plan / Plan Slug Name 'annual' or 'monthly'   -->

      <div class="bg-green-900 flex-1  px-6 py-9">
        <div class="border-white/10 pricing-card-header pb-6 mb-4 border-b ">
          <h2 class="text-white heading--widest heading--medium mb-2">
            {{ annualPlan.name }}
          </h2>
          <div class="text-white text-sm font-normal">
            {{ annualPlan.description }}
          </div>
        </div>
        <p class="text-grayscale-500 grayscale-lineThrough text-lg mb-2 font-extralight text-lineThrough">
          <span class="font-normal">{{ hasDiscount ? monetize(annualPlan.price * 100) : '' }}</span>
        </p>
        <p class="text-white title-xl font-normal mb-2 ">
          {{ monetize(annualPlan.discountedPrice * 100) }}
        </p>
        <p class="text-white subheading--widest subheading--medium mb-4 ">
          {{ annualPlan.slug === 'monthly' ? 'PER MONTH' : 'PER YEAR' }}
        </p>
        <p class="text-white overline mb-6">
          {{ hasDiscount ? 'THANKS TO YOUR LOYALTY DISCOUNT' : '' }}
        </p>
        <Button
          :data-testid="`membership-plan-select-annual`"
          :loading="processing"
          size="small"
          type="button"
          class="mx-auto bg-greenBtn"
          @click="goToCheckout(annualPlan.slug)"
        >
          Select plan
        </Button>
      </div>

      <!-- Body text & Plan Benefits -->
      <div class="text-center px-4 md:px-0 mt-8 md:mt-0  w-full md:w-auto md:max-w-sm">
        <p class="mb-5 subheading--wide subheading--medium text-center md:text-left text-grayscale-900">
          With an All Access Membership you'll enjoy:
        </p>
        <ul class="text-center pl-4 flex flex-col gap-3">
          <li class="relative flex items-start">
            <Icon
              name="CircleCheck"
              class="text-base fill_none mr-4"
            />
            <span class="text-sm text-left font-normal text-grayscale-900 flex-1">Full access to all courses (900+ lessons)</span>
          </li>
          <li class="relative flex items-start">
            <Icon
              name="CircleCheck"
              class="text-base fill_none mr-4"
            />
            <span class="text-sm text-left font-normal text-grayscale-900 flex-1">Instant access to new course releases</span>
          </li>
          <li class="relative flex items-start">
            <Icon
              name="CircleCheck"
              class="text-base fill_none mr-4"
            />
            <span class="text-sm text-left font-normal text-grayscale-900 flex-1">New learning content each month</span>
          </li>
          <li class="relative flex items-start">
            <Icon
              name="CircleCheck"
              class="text-base fill_none mr-4"
            />
            <span class="text-sm text-left font-normal text-grayscale-900 flex-1">Retain lifetime access to any previously purchased courses</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

  <script>
    import Button from '@/Components/Shared/Button'
  import Icon from '@/Components/Shared/Icon'
  import { metaTags } from '@/mixins/metaTags'
  import MetaTags from '@/Components/Shared/MetaTags'
  import monetize from '@/mixins/monetize'

  export default {
    components: {
      Button,
      Icon,
      MetaTags
    },
    mixins: [
      metaTags,
      monetize
    ],
    props: {
      membershipPlans: {
        type: Array
      }
    },
    computed: {
      annualPlan() {
        // Return the annual plan from the membershipPlans array
        return this.membershipPlans.find(plan => plan.slug === 'annual')
      },
      sortedMembershipPlans() {
          // Sort the membershipPlans array based on plans.slug
          return this.membershipPlans.slice().sort((a, b) => {
              if (a.slug === 'annual' && b.slug !== 'annual') {
                  return -1 // "annual" plans come first
              } else if (a.slug !== 'annual' && b.slug === 'annual') {
                  return 1 // "annual" plans come first
              }
          })
      }
    },

    created() {
    this.i18nScope = { scope: 'pages.membership' }
  },
    methods: {
      goToCheckout(planType) {
        // Navigate to the checkout page, passing the plan type as a parameter.
        this.$inertia.visit(`/membership/checkout?plan=${planType}`)
      }
    }
  }
  </script>
