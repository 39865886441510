<template>
  <div
    id="tabs-section"
    class="w-full -mt-72 pt-72 md:-mt-128 md:pt-128 lg:-mt-24 lg:pt-28"
  >
    <div class="tab-menu tab-menu--transparent">
      <a
        :class="tabStyle(true)"
        href="#tabs-section"
        class="tab-menu__link w-full"
      >
        {{ $I18n.t('materials', i18nScope) }}
      </a>
      <a
        v-if="false"
        :class="tabStyle(false)"
        href=""
        class="tab-menu__link"
      >
        {{ $I18n.t('comments', i18nScope) }}
      </a>
    </div>

    <div class="tab-menu__content">
      <MaterialsSection
        :course="course"
        :lesson="lesson"
        :is-mobile="isMobile"
      />

      <!-- One or the other -->
      <CommentSection v-if="false" />
    </div>
  </div>
</template>

<script>
import MaterialsSection from '@/Components/Courses/Lessons/MaterialsSection'
import CommentSection from '@/Components/Courses/Lessons/CommentSection'

export default {

  components: {
    MaterialsSection,
    CommentSection
  },

  props: {
    course: {
      type: Object,
      required: true
    },
    lesson: {
      type: Object,
      required: true
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.i18nScope = { scope: 'pages.courses.lessons.show' }
  },

  methods: {
    tabStyle(active) {
      return {
        'tab-menu__active': active
      }
    }
  }
}
</script>
