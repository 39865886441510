<template>
  <div>
    <img
      loading="lazy"
      :src="`https://www.awin1.com/sread.img?tt=ns&tv=2&merchant=56915&amount=${order.unformatted_amount}&ch=aw&parts=DEFAULT:${order.unformatted_amount}&ref=${order.id}&cr=GBP&testmode=0`"
      border="0"
      width="0"
      height="0"
      style="display: none"
    >

    <ImageWrapper background="confirmation">
      <MetaTags :meta-tags="metaTags" />

      <ConfirmationDetails
        :order="order"
        :orderable="orderable"
      />
    </ImageWrapper>
  </div>
</template>

<script>
import { metaTags } from '@/mixins/metaTags'

import Layout from '@/Layouts/ReducedLayout'

import MetaTags from '@/Components/Shared/MetaTags'
import ImageWrapper from '@/Components/Shared/Objects/ImageWrapper'
import ConfirmationDetails from '@/Components/Orders/ConfirmationDetails'

export default {

  components: {
    MetaTags,
    ImageWrapper,
    ConfirmationDetails
  },

  mixins: [metaTags],
  layout: Layout,

  props: {
    order: {
      type: Object,
      required: true
    },
    orderable: {
      type: Object,
      required: true
    }
  },

  created() {
    this.initializeAwinTracking()
  },

  methods: {
    initializeAwinTracking() {
      window.AWIN = window.AWIN || {}
      window.AWIN.Tracking = window.AWIN.Tracking || {}
      window.AWIN.Tracking.Sale = {}
      window.AWIN.Tracking.Sale.amount = this.order.unformatted_amount
      window.AWIN.Tracking.Sale.channel = 'aw'
      window.AWIN.Tracking.Sale.orderRef = this.order.id
      window.AWIN.Tracking.Sale.parts =
        'DEFAULT:' + this.order.unformatted_amount
      window.AWIN.Tracking.Sale.currency = 'GBP'
      window.AWIN.Tracking.Sale.test = '0'
    }
  }
}
</script>
