<template>
  <div class="px-4 sm:px-12 py-3 sm:py-4 flex justify-center">
    <div
      :class="[isMembershipGift ? 'bg-blue-300' : 'bg-red-300']"
      class="py-2 sm:py-3 px-8 sm:px-16 inline-block"
    >
      <div class="text-base font-normal tracking-widest font-sans leading-5 whitespace-normal sm:whitespace-nowrap uppercase text-center">
        <span 
          class="block sm:inline"
          :class="[isMembershipGift ? 'text-black' : 'text-red-500']"
        >
          USE CODE:
        </span>
        <span 
          class="block sm:inline"
          :class="[isMembershipGift ? 'text-black' : 'text-red-500']"
        >
          {{ code }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import useGiftType from './useGiftType'

export default {
  mixins: [useGiftType],
  
  props: {
    campaign: {
      type: Object,
      required: true
    },
    code: {
      type: String,
      required: true
    }
  }
}
</script> 