<template>
  <div
    data-testid="categories-section"
    :class="[smallerGrid ? 'flex flex-col sm:flex-row flex-wrap' : 'flex-grid-4']"
    class="pb-6"
  >
    <Category
      v-for="category in categories"
      :key="category.slug"
      :category="category"
      :gtm-page-name="gtmPageName"
      :gtm-section-name="gtmSectionName"
      :smaller-grid="smallerGrid"
      :class="[smallerGrid ? 'flex-grid-3__compact' : 'flex-grid-4--item']"
    />
  </div>
</template>

<script>
import Category from '@/Components/Shared/Categories/Show'

export default {
  components: {
    Category
  },

  props: {
    categories: Array,

    gtmPageName: {
      type: String,
      required: true
    },

    gtmSectionName: {
      type: String,
      required: true
    },

    smallerGrid: {
      type: Boolean,
      default: false
    }
  }
}
</script>
