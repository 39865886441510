<template>
  <Head>
    <title>{{ title }}</title>
    <meta
      name="description"
      :content="description"
    >
    <meta
      v-if="noindex"
      name="robots"
      content="noindex"
    >
    <meta
      v-if="keywords"
      name="keywords"
      :content="keywords"
    >

    <link
      v-if="!disableCanonical"
      rel="canonical"
      :href="canonicalUrl"
    >
    <component
      :is="script"
      v-if="schema"
      type="application/ld+json"
    >
      {{ schema }}
    </component>
  </Head>
</template>

<script>
import { Head } from '@inertiajs/vue3'

export default {

  components: {
    Head
  },
  props: {
    metaTags: {
      type: Object,
      required: true
    },
    disableCanonical: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    title() {
      return this.metaTags?.title || ''
    },
    description() {
      return this.metaTags?.description || ''
    },
    keywords() {
      return this.metaTags?.keywords || ''
    },
    noindex() {
      return this.metaTags?.noindex || false
    },
    schema() {
      return this.metaTags?.schema || null
    },
    canonicalUrl() {
      let url = document.location.protocol + '//' + document.location.hostname + document.location.pathname
      if (url.slice(-1) === '/') {
        url = url.slice(0, -1)
      }
      return url
    }
  }
}
</script>
