<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.54297 2.5H3.05026H21.3129H21.8202V3.0073V17.9261V18.4333H21.3129H11.2326L6.39406 21.6907L5.19196 22.5L5.62685 21.1177L6.43084 18.5621L3.03101 18.433L2.54297 18.4145V17.9261V3.0073V2.5ZM3.55756 3.51459V17.4377L7.13345 17.5735L7.79755 17.5987L7.59811 18.2326L7.02956 20.0398L10.7945 17.5052L10.9229 17.4188H11.0778H20.8056V3.51459H3.55756ZM11.6307 12.6459V6.05107H12.6453V12.6459H11.6307ZM11.6307 14.1678V15.1824H12.6453V14.1678H11.6307Z"
    />
  </svg>
</template>

<docs>
  To know more information on how to use icons see [here](/packs/#Icon)

  ```jsx
    <Icon name="Warning" class="text-4xl"></Icon>
  ```
</docs>
