<template>
  <svg
    width="22"
    height="23"
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5518 13.5625L11.458 12.5312V8.21913M19.708 11.5C19.708 16.0563 16.0144 19.75 11.458 19.75C6.90166 19.75 3.20801 16.0563 3.20801 11.5C3.20801 6.94365 6.90166 3.25 11.458 3.25C16.0144 3.25 19.708 6.94365 19.708 11.5Z"
      stroke="#9E6C21"
      stroke-linecap="square"
    />
  </svg>
</template>

  <docs>
    To know more information on how to use icons see [here](/packs/#Icon)

    ```jsx
      <Icon name="Check" class="text-4xl"></Icon>
    ```
  </docs>
