<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.9597 11.624C22.9597 17.6992 18.0348 22.624 11.9597 22.624C5.88458 22.624 0.959717 17.6992 0.959717 11.624C0.959717 5.54889 5.88458 0.624023 11.9597 0.624023C18.0348 0.624023 22.9597 5.54889 22.9597 11.624Z"
    />
  </svg>
</template>

<docs>
  To know more information on how to use icons see [here](/packs/#Icon)

  ```jsx
    <Icon name="Roundel" class="text-4xl"></Icon>
  ```
</docs>
