<template>
  <div
    class="container py-4"
    data-order-toggler
    @click="$emit('click')"
  >
    <div class=" flex flex-row cursor-pointer">
      <Icon
        name="Chevron"
        class="mr-4 transition-transform"
        :class="{'transform -rotate-180 ': rotate}"
      />
      <h2 class="subheading--medium">
        {{ title }}
      </h2>
    </div>

    <slot />
  </div>
</template>

<script>
import Icon from '@/Components/Shared/Icon'

export default {
  components: {
    Icon
  },

  props: {
    title: {
      type: String,
      required: true
    },

    amount: {
      type: Number,
      required: true
    },

    rotate: {
      type: Boolean,
      default: false
    }
  },

  emits: ['click']
}
</script>
