<template>
  <div
    :class="containerClassNames"
    v-bind="$attrs"
  >
    <Card
      v-if="firstCourseIsUpsellOffer"
      :title="upsellOfferCourse.ctaTitle"
      :cta-title="upsellOfferCourse.ctaTitle"
      :cta-subtitle="upsellOfferCourse.ctaSubtitle"
      :description="upsellOfferCourse.description"
      :class="[
        'flex-grid-3'
      ]"
    />
    <Course
      v-for="(course, index) in coursesWithoutUpsellOffer"
      :key="course.id"
      :course="course"
      :brightcove="brightcove"
      :class="classNames"
      :heading-level="headingLevel"
      :gtm-page-name="gtmPageName"
      :gtm-section-name="gtmSectionName"
      :gtm-position="index + 1"
      :show-purchase-button="showPurchaseButton"
      :membership-plan="membershipPlan"
      :ab-test-variants="abTestVariants"
    />
  </div>
</template>

<script>
import gtm from '@/mixins/gtm'
import abTest from '@/mixins/abTest'
import Course from '@/Components/Shared/Talents/Show'
import Card from '../Card/Card.vue'

export default {

  components: {
    Course,
    Card
  },
  mixins: [
    gtm,
    abTest
  ],

  props: {
    courses: {
      type: Array,
      default: () => []
    },
    compact: {
      type: Boolean,
      default: false
    },
    brightcove: {
      type: Object,
      required: true
    },
    headingLevel: {
      type: String,
      default: 'h2'
    },
    gtmPageName: {
      type: String,
      required: true
    },
    gtmSectionName: {
      type: String,
      required: true
    },
    showPurchaseButton: {
      type: Boolean,
      default: false
    },
    membershipPlan: {
      type: Object,
      default: null,
      required: false
    },
    isSlider: {
      type: Boolean,
      default: false
    }
  },

  computed: {

    coursesWithoutUpsellOffer() {
      return this.courses.filter(course => !course.isUpsellOffer)
    },
    upsellOfferCourse() {
      return this.courses.find(course => course.isUpsellOffer)
    },
    firstCourseIsUpsellOffer() {
      return this.courses?.length > 0 && this.courses[0]?.isUpsellOffer || false
    },
    containerClassNames() {
      return [
        this.isSlider ? 'swiper-wrapper' : 'flex flex-row flex-wrap'
      ]
    },

    classNames() {
      return [
        this.isSlider ? 'swiper-slide w-full sm:w-1/2 md:w-1/3 xl:w-1/4' : 'flex-grid-3',
        { 'flex-grid-3__compact': this.compact }
      ]
    }
  },

  mounted() {

  }
}
</script>
