<template>
  <div>
    <MetaTags :meta-tags="metaTags" />

    <div data-testid="banner-section">
      <Banner
        uid="gifts"
        :image-src="bannerImage"
        :image-alt="$I18n.t('banner_image_alt', i18nScope)"
        height="small"
        :darken="false"
      />
    </div>

    <div class="bg-yellow-100">
      <div class="container flex flex-col items-center text-center text-green-500">
        <h1
          data-testid="gifts-title"
          class="title-xl"
        >
          {{ section.title }}
        </h1>

        <Signature class="h-16 lg:h-24 w-auto -m-2" />

        <h2
          data-testid="gifts-subtitle"
          class="max-w-prose font-normal text-xl leading-relaxed lg:text-2xl lg:leading-relaxed mt-10 lg:mt-16"
        >
          {{ section.subtitle }}
        </h2>
      </div>
    </div>

    <div class="container-sm max-w-2xl pb-0">
      <GiftForm
        :campaigns="campaigns"
        :gtm-page-name="currentPage"
      />
    </div>

    <div class="container pt-0">
      <Divider spacing="xl" />

      <SerifHeader
        :title="$I18n.t('faqs_title', i18nScope)"
        color="black"
        content-align="center"
      />

      <Faq
        :gtm-page-name="currentPage"
        :questions="questions"
        class="max-w-5xl mx-auto"
      />

      <Divider spacing="xl" />

      <Testimonials />

      <Divider spacing="xl" />

      <SerifHeader
        :title="$I18n.t('explore_categories_title', i18nScope)"
        color="black"
        content-align="center"
      />

      <Categories
        :categories="categories"
        :gtm-page-name="currentPage"
        :gtm-section-name="gtmSectionExploreCategories"
      />
    </div>
  </div>
</template>

<script>
import Layout from '@/Layouts/TransparentLayout'

import { metaTags } from '@/mixins/metaTags'
import brightcove from '@/mixins/brightcove'
import gtm from '@/mixins/gtm'
import imgSrcset from '@/mixins/imgSrcset'
import statuses from '@/mixins/statuses'

import Banner from '@/Components/Shared/Banner'
import Categories from '@/Components/Shared/Categories/Index'
import Divider from '@/Components/Shared/Divider'
import Faq from '@/Components/Shared/Faq/Index'
import GiftForm from '@/Components/Shared/Gifts/Form'
import MetaTags from '@/Components/Shared/MetaTags'
import SerifHeader from '@/Components/Shared/SectionHeader/Serif'
import Signature from '@/Components/Shared/JoyfullLearningSignature'
import Testimonials from '@/Components/Shared/Testimonials/Show'

export default {

  components: {
    Banner,
    Categories,
    Divider,
    Faq,
    GiftForm,
    MetaTags,
    SerifHeader,
    Signature,
    Testimonials
  },

  mixins: [
    metaTags,
    brightcove,
    statuses,
    imgSrcset,
    gtm
  ],
  layout: Layout,

  props: {
    categories: {
      type: Array,
      default: () => []
    },
    campaigns: {
      type: Array,
      default: () => []
    },
    section: {
      type: Object,
      default: () => ({})
    },
    questions: {
      type: Array,
      default: () => [
        {
          'q': 'What course can I buy with a gift voucher?',
          'a': 'All gift vouchers are redeemable across any of our courses. Simply pass on the voucher code to the recipient and they can choose which course to use it on.'
        },
        {
          'q': 'Can I choose who will receive the gift voucher?',
          'a': 'Yes! On the checkout page when you’re purchasing a gift voucher you will be asked if you want to receive the gift code to your inbox or for the code to be emailed directly to your loved one.'
        },
        {
          'q': 'How do I receive the gift voucher?',
          'a': 'Within 20 minutes of purchasing your gift voucher, you will be emailed a unique voucher code straight to your inbox.'
        },
        {
          'q': 'When will my gift voucher expire?',
          'a': 'Our gift vouchers are valid for 12 months from the date of purchase.'
        },
        {
          'q': 'How long will the recipient have access to their chosen course for?',
          'a': 'Once the gift voucher has been redeemed, your recipient will have lifetime access to their chosen course.'
        }
      ]
    }
  },

  computed: {
    currentPage() {
      return 'Gifts'
    },
    gtmSectionGifts() {
      return 'Choose your gift amount'
    },
    gtmSectionExploreCategories() {
      return 'Explore our Categories'
    }
  },

  created() {
    this.i18nScope = { scope: 'pages.gifts' }
    this.defaultCampaign = this.campaigns.find(element => element.unformatted_price === 127)

    const bannerDefaultImage = {
      url: require('@/assets/images/backgrounds/gift-banner-background.jpg'),
      sizes: {
        large: require('@/assets/images/backgrounds/gift-banner-background.jpg'),
        medium: require('@/assets/images/backgrounds/gift-banner-background-medium.jpg'),
        small: require('@/assets/images/backgrounds/gift-banner-background-small.jpg')
      }
    }

    this.bannerImage = this.imgSrcsetBanner(this.section.image || bannerDefaultImage)

    this.gtmAddProductListingImpressions(this.currentPage, [{ name: this.gtmSectionGifts, impressions: this.campaigns }])
  }
}
</script>
