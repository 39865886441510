<template>
  <svg
    width="8"
    height="6"
    viewBox="0 0 8 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.45215 2.91741L3.19336 4.65813L6.49651 1.35498"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<docs>
    To know more information on how to use icons see [here](/builds/#Icon)

    ```jsx
      <Icon name="Lock" class="text-4xl"></Icon>
    ```
  </docs>
