<template>
  <svg
    width="19"
    height="25"
    viewBox="0 0 19 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.84902 21.0714V5.64287M8.84902 21.0714L7.74916 19.5532C7.12809 18.6959 6.2861 18.2143 5.40777 18.2143H2.63874C2.18148 18.2143 1.81152 17.7026 1.81152 17.0714V5.07145C1.81152 4.44026 2.18221 3.92859 2.63946 3.92859H5.82136C6.6997 3.92859 7.54206 4.41022 8.16313 5.26753L8.84902 6.2143L9.53491 5.26753C10.156 4.41022 10.9983 3.92859 11.8767 3.92859H15.4725C15.9298 3.92859 16.3005 4.44026 16.3005 5.07145V17.0714C16.3005 17.7026 15.9298 18.2143 15.4725 18.2143H12.2907C11.4123 18.2143 10.57 18.6959 9.94888 19.5532L8.84902 21.0714Z"
      stroke="#9E6C21"
      stroke-linecap="square"
    />
  </svg>
</template>

<docs>
  To know more information on how to use icons see [here](/packs/#Icon)

  ```jsx
    <Icon name="Check" class="text-4xl"></Icon>
  ```
</docs>
