<template>
  <div class="collections-listings container pt-6 pb-18">
    <MetaTags :meta-tags="metaTags" />

    <UserCollectionsGrid
      :collections="collectionsWithLessons"
      :heading="$I18n.t('heading', i18nScope)"
      :can-edit="true"
    >
      <template #actions>
        <button
          class="button button button--blue button--blue"
          data-testid="lesson-new-collection-button"
          @click="openDialog"
        >
          New Collection
        </button>
      </template>
    </UserCollectionsGrid>

    <Divider
      v-if="hasCreateAcademyLessons"
      spacing="xl"
    />

    <UserCollectionsGrid
      v-if="hasCreateAcademyLessons"
      :collections="createAcademyCollectionsWithLessons"
      heading="Create Academy Collections"
    />
    <dialog-box
      ref="dialogBox"
      :title="'Create a new collection'"
      :description="'Organise your saved lessons into personalised collections'"
    />
    <CategoryMembershipBanner
      v-if="!isSubscribed"
      v-bind="{...categoryMembershipBannerProps }"
      :custom-image="true"
      component="Collections Promotional Banner"
    />
  </div>
</template>
<script>
import { metaTags } from '@/mixins/metaTags'

import MetaTags from '@/Components/Shared/MetaTags'
import Divider from '../../Components/Shared/Divider.vue'
import DialogBox from '../../Components/Shared/SavedLessons/DialogBox.vue'
import {formatTimeAgo} from '@/mixins/dateFormat'
import UserCollectionsGrid from '@/Components/UserCollections/UserCollectionsGrid.vue'
import currentUser from '@/mixins/currentUser'
import CategoryMembershipBanner from '@/Components/Shared/Categories/CategoryMembershipBanner.vue'

export default {
  components: {
    MetaTags,
    Divider,
    DialogBox,
    UserCollectionsGrid,
    CategoryMembershipBanner
  },

  mixins: [metaTags, currentUser],
  props: {
    collections: {
      type: Array,
      default: () => {
        return []
      }
    },
    createAcademyCollections: {
      type: Array,
      default: () => {
        return []
      }
    },
    categoryMembershipBannerProps: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },

  data() {
    return {
      collectionItem:{},
      edit:true,
      collectionLessons: {},
      loading: false
    }
  },
  computed: {
    collectionsWithLessons() {
      const hasNoUserLessons = this.collections.every(collection =>
        !this.getLessonsForCollection(collection.id)?.length
      )

      const collections = this.collections.map((collection, index) => {
        const result = {
          ...collection,
          lessons: this.getLessonsForCollection(collection.id)
        }

        // Add customDescriptionHtml to first collection if conditions are met
        if (index === 0 && hasNoUserLessons && this.hasCreateAcademyLessons) {
          result.customDescriptionHtml = 'You haven\'t saved any lessons yet.<br/> Find some inspiration in the collections below.'
        }

        return result
      })

      return collections
    },
    createAcademyCollectionsWithLessons() {
      return this.createAcademyCollections.map(collection => ({
        ...collection,
        lessons: this.getLessonsForCollection(collection.id)
      }))
    },
    hasCreateAcademyLessons() {
      return this.createAcademyCollectionsWithLessons.some(collection =>
        collection.lessons.length > 0
      )
    }
  },
  mounted() {
    window.addEventListener('popstate', this.handlePopstate)
  },
  created() {
    this.i18nScope = { scope: 'pages.user_collection' }
    this.fetchAllCollectionsLessons()
  },
  methods: {
    openDialog() {
      this.$refs.dialogBox.open()
    },
     openSettingsDialog(item) {
      this.collectionItem = item
      this.$refs.collectionBox.open(item)
    },

   formattedCreatedAt(value) {
      return formatTimeAgo(value)
   },
     handlePopstate() {
      window.location.reload()
    },
    fetchLessonsForCollection(collectionId) {
      return fetch(this.$routes.user_collection_lessons(collectionId))
        .then(response => {
          if (!response.ok) throw new Error('Network response was not ok')
          return response.json()
        })
        .then(data => {
          if (data && data.lessons) {
            this.collectionLessons[collectionId] = data.lessons
          }
          return data.lessons
        })
        .catch(error => {
          console.error(`Error fetching lessons for collection ${collectionId}:`, error)
          return []
        })
    },

    fetchAllCollectionsLessons() {
      this.loading = true

      // Combine all collections and fetch in parallel
      const allCollections = [...this.collections, ...this.createAcademyCollections]

      Promise.all(
        allCollections.map(collection => this.fetchLessonsForCollection(collection.id))
      )
        .finally(() => {
          this.loading = false
        })
    },

    getLessonsForCollection(collectionId) {
      return this.collectionLessons[collectionId] || []
    }
  }
}
</script>
