<template>
  <div
    v-if="showSaleTag"
    data-testid="sale-tag"
    :class="[backgroundColor, textColor]"
    class="absolute top-4 py-2 px-1.5 z-10 gap-1 flex flex-row justify-center items-center"
  >
    <p
      data-testid="sale-tag-pre-text"
      class="text-base uppercase font-medium tracking-widest "
    >
      {{ saleTag.preText }}
    </p>
    <p
      data-testid="sale-tag-middle-text"
      class="text-base uppercase font-medium tracking-widest"
    >
      {{ saleTag.middleText }}
    </p>
    <p
      data-testid="sale-tag-after-text"
      class="text-base uppercase font-medium tracking-widest"
    >
      {{ saleTag.afterText }}
    </p>
  </div>
</template>

<script>
import saleTag from '@/mixins/saleTag'

export default {
  mixins: [
    saleTag
  ],

  props: {
    course: {
      type: Object,
      required: true
    }
  },

  computed: {
    showSaleTag() {
      const dateNow = Date.now()

      // Don't show if the sale tag has a start date AND current time is lower than the start date
      if (this.saleTag.startDate && dateNow < Date.parse(this.saleTag.startDate)) { return false }
      // Don't show if the sale tag has an end date AND current time is higher than the end date
      if (this.saleTag.endDate && dateNow > Date.parse(this.saleTag.endDate)) { return false }

      // If the sale tag is not associated with any specific course, return true
      if (!this.saleTag.courses || this.saleTag.courses === null ||this.saleTag.courses.length === 0  ) return true
      // Otherwise, return true if it is associated with the current course
      return this.saleTag.courses.includes(this.course.slug)
    }
  }
}
</script>
