<template>
  <div class="flex flex-col items-center pb-10">
    <MetaTags :meta-tags="metaTags" />

    <div class="w-full max-w-full xl:px-0 xl:max-w-screen-lg">
      <div
        class="flex items-center justify-center max-w-screen-lg w-full my-6 cursor-pointer"
        @click="navigateHome"
      >
        <Logotype class="w-auto h-7 navbar__logo" />
      </div>
      <div class="flex items-center justify-center gap-6 mb-9">
        <a
          class=" cursor-pointer"
          @click="goBack"
        >
          <Icon
            name="Arrow"
            class="text-base md:text-xl text-black leftArrow"
          />
        </a>
        <div class="bg-blue-300 h-1.5 w-8/12  sm:w-112 md:w-full rounded-md relative">
          <span class="absolute left-0 rounded-md top-0 h-full  bg-blue-800 w-3/4" />
        </div>
      </div>
      <div class="flex flex-col gap-5 px-4 lg:px-0 lg:gap-9 w-full">
        <div class="flex flex-col gap-5 lg:gap-3 w-full">
          <h4
            class="font-serif text-3xl leading-9 lg:text-4xl lg:leading-10 font-medium text-black text-center"
          >
            Subscribe to the UK’s most-loved creative learning platform
          </h4>
        </div>
      </div>
      <div class="flex items-stretch justify-start mt-2 lg:mt-9">
        <div class="flex-1 hidden lg:block bg-blue-300 ">
          <div class="w-full flex flex-col h-full">
            <img
              loading="lazy"
              alt=""
              class="image-cover w-auto max-w-full max-h-80 h-80rem"
              src="@/assets/images/backgrounds/onboarding-checkout-image.png"
            >
            <div class="flex flex-col gap-4 p-12 flex-1">
              <div class="mt-4 text-7xl title-gigantic transform rotate-180 text-right tracking-tighter mb-0">
                ,,
              </div>
              <p class="-mt-10 text-xl leading-8 tracking-wide text-black font-normal">
                The selection of interior designers and creatives on this site is really ‘The crème de la crème’!
              </p>
              <div class="flex items-center gap-3 text-base tracking-wider uppercase font-normal text-blue-800">
                <span>from GABRIELLE</span>
                <span>|</span>
                <span>TRUSTPILOT</span>
              </div>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="109"
                  height="15"
                  viewBox="0 0 109 15"
                  fill="none"
                >
                  <path
                    d="M7.58015 11.0952L10.8743 10.2484L12.2499 14.5492L7.58015 11.0952ZM15.1603 5.53661H9.36237L7.58015 0L5.79794 5.53661H0L4.69267 8.96737L2.91045 14.504L7.60313 11.0732L10.4906 8.96874L15.1603 5.53661Z"
                    fill="#00B67A"
                  />
                  <path
                    d="M31.045 11.0952L34.3392 10.2484L35.7147 14.5492L31.045 11.0952ZM38.6251 5.53661H32.8272L31.045 0L29.2628 5.53661H23.4648L28.1575 8.96737L26.3753 14.504L31.068 11.0732L33.9555 8.96874L38.6251 5.53661Z"
                    fill="#00B67A"
                  />
                  <path
                    d="M54.4984 11.0952L57.7926 10.2484L59.1681 14.5492L54.4984 11.0952ZM62.0785 5.53661H56.2806L54.4984 0L52.7161 5.53661H46.9182L51.6109 8.96737L49.8287 14.504L54.5213 11.0732L57.4088 8.96874L62.0785 5.53661Z"
                    fill="#00B67A"
                  />
                  <path
                    d="M77.97 11.0952L81.2642 10.2484L82.6397 14.5492L77.97 11.0952ZM85.5502 5.53661H79.7522L77.97 0L76.1878 5.53661H70.3899L75.0826 8.96737L73.3003 14.504L77.993 11.0732L80.8805 8.96874L85.5502 5.53661Z"
                    fill="#00B67A"
                  />
                  <path
                    d="M101.42 11.0952L104.714 10.2484L106.089 14.5492L101.42 11.0952ZM109 5.53661H103.202L101.42 0L99.6375 5.53661H93.8396L98.5323 8.96737L96.7501 14.504L101.443 11.0732L104.33 8.96874L109 5.53661Z"
                    fill="#00B67A"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-1 bg-white lg:bg-neutral-100 px-0 py-0 lg:px-9 lg:py-8">
          <div class="w-full px-4 pt-10 lg:pt-0 lg:px-0 bg-neutral-100 lg:bg-transparent">
            <p
              v-if="membershipPlan.discountPercent > 0"
              data-testid="onboarding_discount_percent"
              class="text-yellow-800 text-lg lg:text-base font-normal uppercase leading-5 tracking-wider mb-4 lg:mb-2.5"
            >
              {{ membershipPlan.discountPercent }}% off your first year
            </p>
            <div class="flex flex-col pb-2 border-b border-yellow-800 mb-0 lg:mb-2">
              <div class="flex items-baseline justify-start">
                <p
                  class="text-blue-500 font-serif text-6xl font-medium tracking-wider"
                  data-testid="onboarding_monthly_price"
                >
                  {{ getGeoRoundPrice(membershipPlan.monthlyPrice) }}
                </p>
                <p class="text-blue-500 text-sm font-normal tracking-wide">
                  / MONTH
                </p>
              </div>
              <p class="text-blue-800 text-sm font-normal tracking-wide">
                (Billed Annually)
              </p>
            </div>
          </div>
          <div class="flex flex-col gap-0 lg:gap-2">
            <div class="flex flex-col gap-1 bg-neutral-100 px-4 pb-10 lg:pb-0  lg:py-3">
              <p class="text-lg font-medium text-black mt-7 lg:mt-0">
                Your subscription includes:
              </p>
              <ul class="text-sm leading-6 font-normal pl-0 flex flex-col gap-1">
                <li
                  v-for="(include, index) in includes"
                  :key="index"
                  class="text-base leading-7 lg:text-lg text-black font-normal tracking-wide flex items-start"
                >
                  <Icon
                    name="Check"
                    class="mr-3 lg:mr-2 flex-24"
                  />{{ include }}
                </li>
              </ul>
            </div>
            <div class="flex flex-col gap-7 lg:gap-4 pt-10 px-4 lg:px-0 pb-5 lg:pb-0 lg:pt-4 w-full">
              <div class="flex items-center justify-between">
                <p class="text-base lg:text-lg lg:leading-5 font-medium">
                  Create Academy Annual Membership
                </p>
                <p
                  class="text-base lg:text-lg lg:leading-5 font-medium"
                  data-testid="onboarding_annual_membership_price"
                >
                  {{ getGeoPrice(membershipPlan.price) }}
                </p>
              </div>
              <div
                v-if="membershipPlan.discountPercent>0"
                class="flex items-center justify-between"
              >
                <p class="text-base lg:text-lg lg:leading-5 font-normal">
                  {{ membershipPlan.checkoutDescription }}
                </p>
                <p
                  class="text-base lg:text-lg lg:leading-5 font-normal"
                  data-testid="onboarding_discount_amount"
                >
                  {{ getGeoPrice(membershipPlan.price - membershipPlan.discountedPrice) }}
                </p>
              </div>
              <div class="flex flex-col gap-2">
                <div class="flex items-center justify-between">
                  <p class="text-base lg:text-lg lg:leading-5 font-normal">
                    Discount
                  </p>
                  <p
                    class="text-base lg:text-lg lg:leading-5 font-normal"
                    data-testid="onboarding_coupen_discount_amount"
                  >
                    {{ getGeoPrice(discount) }}
                  </p>
                </div>

                <CouponDetails
                  :validate-voucher-url="validateVoucherUrl"
                  class=""
                  @validated="onCouponValidated"
                  @reset="onCouponReset"
                />
              </div>
              <div class="flex items-center justify-between">
                <p class="text-base lg:text-lg lg:leading-5 font-semibold">
                  Total
                </p>
                <p
                  class="text-base lg:text-lg lg:leading-5 font-semibold"
                  data-testid="onboarding_total_amount"
                >
                  {{ getGeoPrice(totalAmount) }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="px-4 pb-10 lg:px-0 text-center lg:pb-0">
        <Button
          type="button"
          data-testid="onboarding_checkout_submit"
          class="w-full text-base max-w-15 mx-auto mt-0 lg:mt-5"
          @click="stripeCheckout()"
        >
          Next
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

import Layout from '@/Layouts/ReducedLayout'
import Logotype from '@/Components/Shared/Logotype'
import Icon from '@/Components/Shared/Icon'
import CouponDetails from '@/Components/Orders/CouponDetails'
import MetaTags from '@/Components/Shared/MetaTags'
import monetize from '@/mixins/monetize'
import currentUser from '@/mixins/currentUser'
import authForm from '@/mixins/authForm'
import { metaTags } from '@/mixins/metaTags'
import mixpanel from '@/mixins/mixpanel'

export default {

  components: {
    MetaTags,
    Logotype,
    Icon,
    CouponDetails
  },
  mixins: [
    metaTags,
    authForm,
    monetize,
    currentUser,
    mixpanel
  ],

  layout: Layout,

  props: {
    user: {
      type: Object,
      required: true
    },
    membershipPlan: {
      type: Object,
      required: true
    },
    geoProps: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      includes: [
        'Unlimited access to over 1,000 lessons',
        'Expert instruction from designers, makers, growers and chefs',
        'Downloadable workbooks and resources',
        'New content added each month'
      ],
      isGift: false,
      voucherCode: null,
      discount: 0,
      totalAmount: 0,
      selectedTopics:[]
    }
  },

  computed: {
    validateVoucherUrl() {
      return `/membership/${this.membershipPlan.id}/validate_voucher`
    }
  },

  created() {
    this.totalAmount = this.membershipPlan.discountedPrice
  },


  methods: {
    goBack() {
        this.selectedTopics = JSON.parse(sessionStorage.getItem('selectedTopics')) || []
        this.$inertia.visit(this.$routes.onboarding_courses(), {
          method: 'get',
          data: {
            topics: this.selectedTopics.join()
          }
        })
    },

    navigateHome() {
      this.$inertia.visit(this.$routes.root())
    },
    getGeoPrice(gbpBasePrice) {
      return this.geoMonetize(gbpBasePrice * this.geoProps.exchangeRate, this.geoProps.currencySymbol, 2)
    },
    getGeoRoundPrice(gbpBasePrice) {
      return this.geoMonetizeRound(gbpBasePrice * this.geoProps.exchangeRate, this.geoProps.currencySymbol, 2)
    },
    onCouponValidated(payload) {
      if (payload.valid && payload.order) {
        this.isGift = payload.isGift

        if (this.isGift) {
          this.discount = (payload.order.amount || 0) / 100
          this.totalAmount = 0
        } else {
          this.discount = (payload.order.total_discount_amount || 0) / 100
          this.totalAmount = (payload.order.total_amount||0) / 100
        }

        this.voucherCode = payload.redeemableCode?.id || null
      } else {
        this.onCouponReset()
      }
    },

    onCouponReset() {
      this.discount = 0
      this.totalAmount = this.membershipPlan.discountedPrice
      this.voucherCode = null
    },

    trackCheckout() {
      this.trackEvent('onboarding_checkout', {
        page: window.location.pathname,
        email: this.user.email,
        isGift: this.isGift,
        voucherCode: this.voucherCode,
        discount: this.discount,
        totalAmount: this.totalAmount
      })
    },

    async stripeCheckout() {
      this.trackCheckout()

      const path = this.isGift ? this.$routes.membership_redeem_gift() : this.$routes.onboarding_checkout(this.user.token)

      try {
        const response = await axios.post(
          path,
          {
            email: this.user.email,
            plan: {
              id: this.membershipPlan.id
            },
            voucher: {
              code: this.voucherCode
            },
            from: 'user_onboarding'
          }
        )

        if (response.data.status === 'success') {
          window.location.href = response.data.url
        }
      } catch (e) {
        if (e.response.status === 401) {
          window.location.href = `${this.$routes.new_user_session()}?redirect_to=${encodeURIComponent(window.location.pathname)}`
        }
      }
    }
  }
}
</script>
