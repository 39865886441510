<template>
  <div
    data-testid="gift-preview-pdf"
    class="w-voucher h-voucher bg-green-500 bg-gift-voucher bg-contain p-8 flex flex-col justify-between relative transform scale-95 -translate-x-4 -translate-y-5"
  >
    <!-- Gradient blur to add contrast to informational text on the bottom of the voucher -->
    <div class="absolute bottom-0 left-0 h-20 w-full bg-gradient-to-t from-grayscale-900 opacity-40" />

    <div class="flex flex-col items-center">
      <Logotype class="w-64 h-auto text-grayscale-100" />
      <Signature class="w-64 h-auto text-grayscale-100" />
    </div>

    <div class="flex flex-col items-center text-center">
      <div class="w-4/5 bg-yellow-100 rounded-3xl px-8 pt-4 pb-6 text-green-500 mb-6">
        <p
          data-testid="gift-preview-amount"
          class="text-6xl font-serif -mb-3"
        >
          £{{ value }}
        </p>
        <p class="overline--medium mb-6">
          {{ $I18n.t('gift_preview.gift_voucher', i18nScope) }}
        </p>

        <div class="h-px w-8 bg-green-500 mt-4 mb-2 mx-auto" />

        <p
          data-testid="gift-preview-message"
          class="font-normal break-words mb-6"
        >
          {{ message }}
        </p>

        <p class="inline-flex items-center leading-5">
          <span class="subheading mr-1">{{ $I18n.t('gift_preview.code_label', i18nScope) }}</span> <span class="font-medium">{{ voucherCode }}</span>
        </p>
      </div>

      <p class="w-10/12 font-normal text-sm text-grayscale-100">
        {{ $I18n.t('gift_preview.instructions', i18nScope) }}
      </p>
    </div>
  </div>
</template>

<script>
import Logotype from '@/Components/Shared/Logotype'
import Signature from '@/Components/Shared/JoyfullLearningSignature'

export default {
  components: {
    Logotype,
    Signature
  },

  props: {
    value: {
      type: Number,
      required: true
    },
    message: {
      type: String
    },
    code: {
      type: String
    }
  },

  computed: {
    voucherCode() {
      return this.code ? this.code : this.$I18n.t('gift_preview.code_example', this.i18nScope)
    }
  },

  created() {
    this.i18nScope = { scope: 'pages.gifts' }
  }
}
</script>
