<template>
  <div
    :id="sliderId"
    class="swiper-press-references relative w-full h-full px-9 mb-8 lg:mb-10 overflow-hidden"
  >
    <div class="grid grid-cols-4 gap-y-2 gap-x-8 lg:flex items-center justify-center lg:gap-x-12">
      <PressReference
        v-for="pressReference in pressReferences"
        :key="pressReference.id"
        class="col-span-2"
        :press-reference="pressReference"
      />
    </div>

    <!-- Slide navigation -->
    <!-- <div data-testid="pressReference-button-prev" class="swiper-button-prev"></div>
    <div data-testid="pressReference-button-next" class="swiper-button-next"></div> -->
  </div>
</template>

<script>
// import Swiper from 'swiper'

// Configure Swiper to use modules
// import SwiperCore, { Navigation, Pagination } from 'swiper/core'
// SwiperCore.use([ Navigation, Pagination])

import PressReference from '@/Components/Shared/PressReferences/Show'

export default {
  components: {
    PressReference
  },

  props: {
    pressReferences: {
      type: Array,
      default: () => []
    },
    sliderId: {
      type: String,
      required: true
    }
  },

  mounted() {
    // init Swiper
    // this.swiper = new Swiper(`#${this.sliderId}`, {

    //   pagination: {
    //     clickable: true
    //   },

    //   navigation: {
    //     nextEl: '.swiper-button-next',
    //     prevEl: '.swiper-button-prev'
    //   },

    //   watchOverflow: true,
    //   slidesPerView: 2,
    //   spaceBetween: 32,

    //   autoplay: {
    //     delay: 3000,
    //     disableOnInteraction: false
    //   },

    //   breakpoints: {
    //     640: {
    //       slidesPerView: 4
    //     },
    //     1024: {
    //       slidesPerView: 6
    //     }
    //   },

    //   modules: [Pagination]
    // })
  }
}
</script>
