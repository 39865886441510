<template>
  <div
    data-testid="campaign-announcement"
    class="w-full relative bg-blue-300 text-grayscale-100"
  >
    <div class="gift-box container py-8 md:py-6 flex flex-col pl-12 text-center">
      <Icon
        name="Gift"
        class="text-blue-800 mb-2 sm:mb-0 text-xl absolute top-3 left-3 z-10"
      />

      <p class="heading--medium mb-2">
        {{ $I18n.t('title', i18nScope) }}
      </p>
      <p class="text-lg">
        {{ $I18n.t('body', i18nScope) }}
      </p>
    </div>
  </div>
</template>

<script>
import Icon from '@/Components/Shared/Icon'

export default {
  components: {
    Icon
  },

  created() {
    this.i18nScope = { scope: 'pages.campaigns.announcement.course_campaign' }
  }
}
</script>
