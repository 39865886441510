<template>
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.8333 4.83333L13.1106 14.9521C13.0483 15.8243 12.3225 16.5 11.4481 16.5H4.55187C3.67748 16.5 2.95174 15.8243 2.88944 14.9521L2.16668 4.83333M6.33334 8.16667V13.1667M9.66668 8.16667V13.1667M10.5 4.83333V2.33333C10.5 1.8731 10.1269 1.5 9.66668 1.5H6.33334C5.87311 1.5 5.50001 1.8731 5.50001 2.33333V4.83333M1.33334 4.83333H14.6667"
      stroke="#313131"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<docs>
    To know more information on how to use icons see [here](/builds/#Icon)

    ```jsx
      <Icon name="Lock" class="text-4xl"></Icon>
    ```
  </docs>
