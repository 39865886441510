<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.358 5L22 12.2293L14.3479 19.1174L13.6062 18.2935L19.7385 12.7734L4 12.7734L4 11.6649L19.7903 11.6649L13.5962 5.80532L14.358 5Z"
    />
  </svg>
</template>

<docs>
  To know more information on how to use icons see [here](/packs/#Icon)

  ```jsx
    <Icon name="Arrow" class="text-4xl"></Icon>
  ```
</docs>
