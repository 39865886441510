import ConfirmationModal from '@/Components/Subscriptions/ConfirmationModal'
import { openModal } from 'jenesius-vue-modal'

export const subscriptionConfirmationModal = {
  beforeUnmount() {
    this.$inertia.on('navigate', () => {
      if (window.location.pathname === '/membership-confirmation') {
        this.openSubscriptionConfirmationModal()
      }
    })
  },

  methods: {
    openSubscriptionConfirmationModal() {
      openModal(ConfirmationModal,
        {}
        //{
        //  width: '95%',
        //  maxWidth: 500
        //}
      )
    }
  }
}

export default subscriptionConfirmationModal
