<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.5 5H2V5.5V18.7V19.2H2.5H20.6083H21.1083V18.7V5.5V5H20.6083H2.5ZM3 6.48531V18.2H20.1083V6.48531L11.8494 12.5274L11.5542 12.7433L11.2589 12.5274L3 6.48531ZM19.078 6H4.03028L11.5542 11.5043L19.078 6Z"
    />
  </svg>
</template>

<docs>
  To know more information on how to use icons see [here](/packs/#Icon)

  ```jsx
    <Icon name="Email" class="text-4xl"></Icon>
  ```
</docs>
