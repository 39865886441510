<template>
  <div class="flex items-center justify-center min-h-screen">
    <div class="relative w-full bg-black max-h-screen">
      <PlayerModal
        ref="playerModalRef"
        data-testid="trailer-modal"
        :title="course.teacher.name"
        :subtitle="course.name"
        :video-id="course.trailer"
        :brightcove="brightcove"
        :mixpanel="{
          type: 'trailer',
          videoId: course.trailer,
          courseName: course.name
        }"
        :gtm-page-name="gtmPageName"
        :gtm-section-name="gtmSectionName"
      >
        <PurchaseButton
          :course="course"
          :in-cart="itemInCart"
          :gtm-page-name="gtmPageName"
          ref="purchaseButton"
          :gtm-section-name="gtmSectionName"
          :enable-upsell="true"
          inverse
          @show-upsell="onShowUpsell"
          @success="onSuccess"
        />
        <div
          v-if="(course.purchasable || course.releaseDate) && enableUpsellModal"
          class="flex flex-col gap-2 hidden"
        >
          <SubscribeButton
            ref="subscribeButton"
            :membership-plan="membershipPlan"
            :button-border="true"
            component="Course & Trailer CTA"
          />
          <div
            style="max-width: 289px"
            class="font-normal text-lg"
          >
            Access all courses with an annual subscription.
          </div>
        </div>
      </PlayerModal>
      <UpsellOfferModal
        ref="upsellModal"
        :membership-plan="membershipPlan"
        :header-image="headerImageUrl"
        @accept-upsell-offer="handleAcceptUpsell"
        @decline-upsell-offer="handleDeclineUpsell"
        @show="pauseVideo"
        @hide="resumeVideo"
      />
    </div>
  </div>
</template>

<script>
  import statuses from '@/mixins/statuses'
  import brightcove from '@/mixins/brightcove'
  import gtm from '@/mixins/gtm'

  import PlayerModal from '@/Components/Shared/Trailer/Player'
  import PurchaseButton from '@/Components/Shared/Courses/PurchaseButton'
  import SubscribeButton from '@/Components/Shared/Courses/SubscribeButton'
  import UpsellOfferModal from '../Courses/UpsellOfferModal.vue'
  import upsellMixin from '../../../mixins/upsellMixin'
  import mixpanel from '@/mixins/mixpanel'

  export default {

    components: {
      PurchaseButton,
      SubscribeButton,
      PlayerModal,
      UpsellOfferModal
    },
    mixins: [mixpanel, brightcove, gtm, statuses, upsellMixin],

    props: {
      course: {
        type: Object,
        required: true
      },
      gtmPageName: {
        type: String,
        required: true
      },
      gtmSectionName: {
        type: String,
        required: true
      },
      enableUpsellModal: {
        type: Boolean,
        default: false,
        required: false
      },
      membershipPlan: {
        type: Object,
        default: null
      }
    },

    data() {
      return {
        itemInCart: this.course.inCart,
        headerImageUrl: this.randomCourseCategoryHeaderImage(this.course.categories) || ''
      }
    },
    created() {
      this.i18nScope = { scope: 'pages.courses.show' }
    },

    methods: {
      onShowUpsell() {
        if (this.enableUpsellModal) {
          this.showUpsellOfferModal()
        }
      },

      pauseVideo() {
        this.$refs.playerModalRef.pauseVideo()
      },
      resumeVideo() {
        this.$refs.playerModalRef.playVideo()
      },
      onSuccess() {
        this.itemInCart = true
      }
    }
  }
</script>
