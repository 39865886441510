<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="pencil-01">
      <path
        id="Icon"
        d="M4.80039 15.6004L9.00039 19.2004M4.20039 15.6004L16.0318 3.35582C17.3057 2.08192 19.3711 2.08191 20.645 3.35581C21.9189 4.62971 21.9189 6.69512 20.645 7.96902L8.40039 19.8004L2.40039 21.6004L4.20039 15.6004Z"
        stroke="#F6F7F9"
        stroke-linecap="square"
      />
    </g>
  </svg>
</template>
