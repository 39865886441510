<template>
  <svg
    width="25"
    height="34"
    viewBox="0 0 25 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 1H1L1.10781 32L12.8633 24.9811L24 31.4151V1Z"
      stroke="#0E1E38"
      stroke-width="1.5"
    />
  </svg>
</template>

<docs>
  To know more information on how to use icons see [here](/builds/#Icon)

  ```jsx
    <Icon name="BookMark" class="text-4xl"></Icon>
  ```
</docs>
