<template>
  <div class="container">
    <MetaTags :meta-tags="metaTags" />

    <div
      data-test-id="cart-page-summary"
      class="flex flex-row flex-nowrap"
    >
      <div class="cart-summary flex-grow lg:mr-4">
        <OrderSummary
          has-title
          :allow-removal="true"
          :orderable="orderable"
          :i18n-scope="i18nScope"
          :gtm-page-name="currentPage"
        />

        <p
          v-if="!cartHasItems"
          class="w-full font-normal text-left lg:text-center text-grayscale-400 py-6 lg:pt-32"
        >
          {{ $I18n.t('empty_state', i18nScope) }}
        </p>
      </div>

      <div class="hidden lg:block w-4/12 h-auto ml-8">
        <div class="bg-grayscale-100 text-blue-800 p-8">
          <h2 class="heading--medium mb-6">
            {{ $I18n.t('breakdown.title', i18nScope) }}
          </h2>

          <CartBreakdown
            :price="parseFloat(orderable.geoPrice.price)"
            :discount="promotionDiscount"
            :amount="amount"
            :promotion-banner="promotionBanner"
            :i18n-scope="i18nScope"
            :geo-price="orderable.geoPrice"
            :geo-discount-ratio="geoDiscountRatio"
            :geo-props="orderable.geoProps"
          >
            <CartCheckoutButton
              size="small"
              class="mb-6"
              :i18n-scope="i18nScope"
            />
          </CartBreakdown>
        </div>
      </div>

      <div class="lg:hidden z-20 fixed bottom-0 left-0 right-0 border-t border-blue-500 bg-blue-100 text-grayscale-700 ">
        <OrderSummaryToggler
          :title="$I18n.t('sections.order_breakdown_title', i18nScope)"
          :amount="amount"
          :rotate="!isToggleSummary"
          @click="toggleSummary"
        >
          <div
            class="flex flex-row justify-between my-4"
            :class="{ 'hidden' : isToggleSummary}"
          >
            <div class="flex-col">
              <p class="text-lg font-normal">
                {{ $I18n.t('breakdown.grand_total', i18nScope) }}
              </p>
              <p class="text-xs">
                {{ $I18n.t('breakdown.billed_today', i18nScope) }}
              </p>
            </div>

            <p
              data-testid="breakdown-orderable-grand-total"
              class="text-lg font-normal"
            >
              {{ monetize(amount) }}
            </p>
          </div>
        </OrderSummaryToggler>

        <div
          class="container py-6"
          :class="{'hidden' : !isToggleSummary}"
        >
          <CartBreakdown
            class="lg:hidden"
            :price="parseFloat(orderable.geoPrice.price)"
            :geo-price="orderable.geoPrice"
            :geo-discount-ratio="geoDiscountRatio"
            :discount="promotionDiscount"
            :amount="amount"
            :promotion-banner="promotionBanner"
            :i18n-scope="i18nScope"
          />
        </div>
        <OrderGrandTotal
          :amount="amount"
          :i18n-scope="i18nScope"
          :geo-price="orderable.geoPrice"
        />

        <CartCheckoutButton :i18n-scope="i18nScope" />
      </div>
    </div>

    <Divider spacing="xl" />

    <!-- Related courses section -->
    <template v-if="relatedCourses.length">
      <SerifHeader
        :title="$I18n.t('sections.related_courses_title', i18nScope)"
        content-align="center"
      />

      <Talents
        data-testid="related-courses-section"
        heading-level="h3"
        :courses="relatedCourses"
        :brightcove="brightcove.lessonPlayer"
        :gtm-page-name="currentPage"
        :show-purchase-button="true"
        :gtm-section-name="gtmSectionRelatedCourses"
        :ab-test-variants="abTestVariants"
      />
    </template>
  </div>
</template>

<script>
import { metaTags } from '@/mixins/metaTags'
import monetize from '@/mixins/monetize'
import currentUser from '@/mixins/currentUser'
import cart from '@/mixins/cart'
import brightcove from '@/mixins/brightcove'
import gtm from '@/mixins/gtm'

import Divider from '@/Components/Shared/Divider'
import SerifHeader from '@/Components/Shared/SectionHeader/Serif'
import Talents from '@/Components/Shared/Talents/Index'
import MetaTags from '@/Components/Shared/MetaTags'
import Layout from '@/Layouts/Layout'
import OrderSummary from '@/Components/Orders/OrderSummary'
import OrderSummaryToggler from '@/Components/Orders/OrderSummaryToggler'
import OrderGrandTotal from '@/Components/Orders/OrderGrandTotal'
import CartBreakdown from '@/Components/Cart/CartBreakdown'
import CartCheckoutButton from '@/Components/Cart/CartCheckoutButton'
// AB Testing bits
// import AbTest from '@/Components/Shared/AbTest'
import abTest from '@/mixins/abTest'

export default {

  components: {
    MetaTags,
    CartBreakdown,
    CartCheckoutButton,
    OrderGrandTotal,
    OrderSummaryToggler,
    OrderSummary,
    Divider,
    SerifHeader,
    Talents
  },

  mixins: [
    brightcove,
    cart,
    currentUser,
    metaTags,
    monetize,
    gtm,
    abTest
  ],
  layout: Layout,

  props: {
    promotion: {
      type: Object,
      required: false
    },
    relatedCourses: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      isToggleSummary: false
    }
  },

  computed: {
    promotionBanner() {
      return this.promotion?.banner
    },

    geoDiscountRatio() {
      return this.promotion?.discount?.percent_off / 100 ?? 0
    },

    promotionDiscount() {
      return this.promotion?.discount_amount || 0
    },

    amount() {
      return Math.max(this.orderable.price - this.promotionDiscount, 0)
    },

    currentPage() {
      return 'Basket'
    },
    cartHasItems(){
      return this.cartSize > 0
    },
    gtmSectionRelatedCourses() {
      return 'Related Courses'
    }
  },

  created() {
    this.i18nScope = { scope: 'pages.carts.show' }
    this.gtmAddViewCart(this.orderable, 'basket page')
    this.gtmAddProductListingImpressions(this.currentPage, [{ name: this.gtmSectionRelatedCourses, impressions: this.relatedCourses }])
  },

  methods: {
    toggleSummary() {
      this.isToggleSummary = !this.isToggleSummary
    }
  }
}
</script>
