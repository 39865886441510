<template>
  <BaseLayout>
    <Navbar :dualtone="true" />

    <div
      class="min-h-screen"
      :class="styles"
    >
      <FlashMessages />

      <slot />
    </div>

    <Footer />
  </BaseLayout>
</template>

<script>
import FlashMessages from '@/Components/Shared/Objects/FlashMessages'
import Navbar from '@/Components/Shared/Objects/Navbar'
import Footer from '@/Components/Shared/Objects/Footer'
import BaseLayout from '@/Layouts/BaseLayout'

export default {
  components: {
    BaseLayout,
    FlashMessages,
    Navbar,
    Footer
  },

  props: {
    styles: {
      type: String,
      required: false
    }
  }
}
</script>
