<template>
  <form
    novalidate="true"
    class="flex flex-row"
  >
    <div class="w-full lg:w-auto">
      <div
        class="flex flex-row w-full"
        :class="buttonBorder ? 'border border-blue-800' : 'border border-transparent'"
      >
        <ButtonLink
          v-bind="$attrs"
          data-testid="button-membership-landing"
          :href="currentUser ? $routes.membership_checkout() : $routes.onboarding()"
          type="submit"
          class="text-sm border-transparent buttonWidth"
          variant="light-blue"
          @click="trackSubscribeButton"
        >
          SUBSCRIBE
        </ButtonLink>

        <div class=" bg-white px-3.5 w-2/5">
          <div class="flex flex-col justify-center h-full items-center">
            <div class="flex flex-row items-center ">
              <span
                :class="{ 'font-medium': saleReduction, 'font-normal': !saleReduction }"
                class="text-base text-blue-800 whitespace-nowrap"
              >{{ membershipPlan.geoPriceDetails.monthlyPrice
              }}/MONTH</span>
            </div>

            <div
              v-if="saleReduction"
              class="text-xs text-red-800 uppercase font-normal text-center -mt-1"
            >
              <span>Save {{ membershipPlan.geoPriceDetails.discountAmount }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import ButtonLink from '@/Components/Shared/ButtonLink'
import currentUser from '@/mixins/currentUser'
import mixpanel from '@/mixins/mixpanel'

export default {

  components: {
    ButtonLink
  },
  mixins: [
    currentUser,
    mixpanel
  ],

  props: {
    membershipPlan: {
      type: Object,
      required: true
    },

    buttonBorder: {
      type: Boolean,
      default: false
    },

    component: {
      type: String,
      default: null
    }
  },

  computed: {
    saleReduction() {
      return this.membershipPlan && this.membershipPlan?.discountPercent ? true : false
    }
  },

  methods: {
    trackSubscribeButton() {
      this.trackEvent('subscribe_button', {
        userType: this.currentUser ? 'authed' : 'anonymous',
        page: window.location.pathname,
        component: this.component
      })
    },
    subscribe() {
      this.trackSubscribeButton()
      if (this.currentUser) {
        window.location.href = this.$routes.membership_checkout()
      } else {
        window.location.href = this.$routes.onboarding()
      }
    }
  }
}
</script>
