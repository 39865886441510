<template>
  <div class="flex flex-row flex-nowrap justify-between pt-10 pb-6">
    <a
      href="https://www.facebook.com/createacademyofficial"
      rel="noopener"
      target="_blank"
      class="flex flex-row items-center menu-icon"
      :aria-label="$I18n.t('facebook', i18nScope)"
    >
      <Icon
        name="Facebook"
        class="text-2xl mr-6"
      />
    </a>
    <a
      href="https://www.instagram.com/createacademyofficial"
      rel="noopener"
      target="_blank"
      class="flex flex-row items-center menu-icon"
      :aria-label="$I18n.t('instagram', i18nScope)"
    >
      <Icon
        name="Instagram"
        class="text-2xl mr-6"
      />
    </a>
    <a
      href="https://www.pinterest.co.uk/CreateAcademyOfficial"
      rel="noopener"
      target="_blank"
      class="flex flex-row items-center menu-icon"
      :aria-label="$I18n.t('pinterest', i18nScope)"
    >
      <Icon
        name="Pinterest"
        class="text-2xl mr-6"
      />
    </a>
    <a
      href="https://www.linkedin.com/company/createacademyofficial/"
      rel="noopener"
      target="_blank"
      class="flex flex-row items-center menu-icon"
      :aria-label="$I18n.t('linkedin', i18nScope)"
    >
      <Icon
        name="Linkedin"
        class="text-2xl mr-6"
      />
    </a>
  </div>
</template>


<script>
import Icon from '@/Components/Shared/Icon'

export default {
  components: {
    Icon
  },

  created() {
    this.i18nScope = { scope: 'pages.navbar.link_labels' }
  }
}
</script>
