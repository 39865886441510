<template>
  <svg
    width="41"
    height="42"
    viewBox="0 0 41 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M35.6614 20.59C36.3492 19.7006 34.9526 18.652 34.2649 19.5414C33.5771 20.4309 34.9737 21.4795 35.6614 20.59Z"
      fill="#6D1623"
    />
    <path
      d="M32.1776 19.7394C32.279 19.7282 32.3811 19.7218 32.4859 19.7176L32.2601 19.7183C32.5133 19.7163 32.7683 19.7463 33.0125 19.8073L32.7915 19.7506C32.976 19.7974 33.1554 19.8623 33.327 19.943C33.5174 20.0336 33.7988 20.0179 33.9888 19.9379C34.1907 19.8537 34.3985 19.6649 34.4708 19.4572C34.5431 19.2495 34.5658 19.0089 34.4679 18.8044L34.3537 18.6095C34.2564 18.4841 34.1307 18.3897 33.9822 18.3306C33.3549 18.0342 32.6287 17.9343 31.9415 18.0122C31.7191 18.0372 31.4941 18.174 31.3596 18.348C31.225 18.522 31.1466 18.7775 31.1854 18.99C31.2242 19.2025 31.3303 19.4306 31.5239 19.5629C31.7174 19.6951 31.9369 19.7595 32.1742 19.7324L32.1776 19.7394Z"
      fill="#6D1623"
    />
    <path
      d="M35.5599 19.8468C36.2476 18.9574 34.8511 17.9088 34.1633 18.7982C33.4755 19.6877 34.8721 20.7363 35.5599 19.8468Z"
      fill="#6D1623"
    />
    <path
      d="M18.6778 18.2307C16.7993 16.6806 14.021 16.5111 11.6814 16.7848C9.32713 17.0603 6.72802 18.4576 6.44412 21.0342C6.21789 23.091 7.78487 25.0098 9.70723 25.6411C11.8946 26.3578 14.2974 25.7169 16.2459 24.6302C18.6588 23.2853 20.6081 21.147 21.1411 18.408C21.354 17.3105 19.6098 17.1054 19.3962 18.1981C19.0354 20.0578 17.7369 21.5715 16.1938 22.6307C14.7745 23.604 12.9289 24.3751 11.1697 24.1894C9.80745 24.0441 8.38358 23.1279 8.19248 21.6964C7.95341 19.9103 9.90319 18.8332 11.4163 18.5722C13.4196 18.2252 15.9826 18.2678 17.6071 19.6097C18.4744 20.3265 19.5396 18.9432 18.675 18.2286L18.6778 18.2307Z"
      fill="#6D1623"
    />
    <path
      d="M16.101 16.7051C14.0937 16.2065 11.9813 15.9695 9.971 16.5995C8.0595 17.1988 6.41286 18.7916 6.01378 20.7579C5.6147 22.7242 6.69583 24.5576 8.29123 25.5546C10.0153 26.6308 12.1051 26.663 14.0016 26.0088C16.421 25.1752 18.2395 23.2574 19.7721 21.3039L18.2605 21.112C19.9169 25.3245 17.5797 29.7344 15.6214 33.4203C15.2133 34.1879 16.3263 34.958 16.9677 34.4311C18.3115 33.327 19.4869 32.0352 20.4325 30.584L18.9209 30.3921C19.7053 31.9241 19.9334 33.6104 19.5841 35.2953C19.3836 36.2625 20.9245 36.6335 21.2728 35.7206C23.2855 30.4515 23.0906 24.446 20.6915 19.3441C20.2187 18.3341 18.5893 18.9968 19.0649 20.0089C21.2744 24.7108 21.5015 30.2122 19.6424 35.0772L21.3311 35.5025C21.7335 33.5605 21.4563 31.4924 20.5524 29.7267C20.2797 29.1944 19.4291 28.9356 19.0408 29.5348C18.1788 30.8609 17.1366 32.0431 15.9104 33.0522L17.2567 34.0631C19.4592 29.9187 21.7393 25.1429 19.8948 20.4486C19.6503 19.8285 18.7833 19.745 18.3832 20.2567C17.2578 21.6951 15.9872 23.1555 14.3189 23.9942C12.9869 24.6662 11.3619 25.0092 9.93048 24.4453C8.72958 23.9715 7.64845 22.8061 7.70545 21.4649C7.76443 19.9898 9.0105 18.8122 10.3353 18.3094C12.1061 17.635 14.1134 17.9852 15.892 18.4256C16.3516 18.5392 16.8207 18.0925 16.8739 17.6696C16.94 17.156 16.5732 16.8195 16.1109 16.7038L16.101 16.7051Z"
      fill="#6D1623"
    />
    <path
      d="M20.97 29.6945C21.6578 28.8051 20.2612 27.7565 19.5734 28.6459C18.8857 29.5353 20.2823 30.5839 20.97 29.6945Z"
      fill="#6D1623"
    />
    <path
      d="M21.1402 29.5312C21.9313 27.7545 21.8051 25.7824 21.6506 23.8982C21.612 23.4457 21.1155 23.0773 20.6618 23.1558C20.1716 23.2417 19.8653 23.6492 19.9057 24.1336C19.941 24.5618 19.9742 24.9928 19.9969 25.4202C20.0076 25.6291 20.0133 25.8386 20.0191 26.0481C20.0176 26.1299 20.0225 26.1293 20.0191 26.0481C20.0199 26.1098 20.0179 26.1695 20.0188 26.2312C20.0163 26.3429 20.0132 26.4497 20.0107 26.5614C19.9913 26.9747 19.9488 27.3837 19.8683 27.7904C19.8476 27.8971 19.8219 28.0045 19.7985 28.1091C19.7705 28.2365 19.8384 27.9775 19.7999 28.1014C19.7825 28.1582 19.7679 28.2171 19.7483 28.2766C19.681 28.4837 19.6031 28.6871 19.5126 28.8899C19.3279 29.3057 19.5617 29.8654 19.9991 30.0192C20.4596 30.1772 20.9453 29.9831 21.1458 29.5354L21.1402 29.5312Z"
      fill="#6D1623"
    />
    <path
      d="M18.2066 16.4134C17.4401 14.8468 16.7656 13.1441 16.7824 11.3754C16.7984 9.75024 17.4558 8.06083 18.8572 7.12212C19.957 6.38489 21.5351 6.45648 22.5571 7.25873C23.7955 8.23219 23.4311 10.1765 23.1603 11.5144C22.7279 13.6608 21.8531 15.7284 20.6381 17.5623C20.0253 18.4862 21.3328 19.5072 22.0347 18.6109C23.267 17.0344 24.0382 15.1293 24.2679 13.1537C24.4907 11.2384 24.248 9.09148 23.3297 7.36726C22.7845 6.34234 21.9421 5.5876 20.7468 5.41488C19.746 5.27035 18.7142 5.58275 17.9048 6.16697C16.2472 7.36293 15.6148 9.45967 15.7849 11.4036C15.8879 12.555 16.2486 13.6815 16.6575 14.757C17.1214 15.9784 17.6332 17.1835 18.1231 18.394L19.4704 17.371C17.7495 15.9654 15.5917 15.0962 13.3378 15.1678C11.2168 15.2387 9.08493 16.3057 7.90294 18.0683C7.63972 18.4601 7.67062 18.9854 8.06725 19.2832C8.42478 19.5516 9.03632 19.5087 9.29953 19.1169C10.2433 17.708 11.8725 16.9142 13.5726 16.8852C15.3627 16.854 17.0535 17.6477 18.4046 18.7494C19.0927 19.3097 20.0673 18.5003 19.7519 17.7264C19.055 16.007 18.2064 14.2783 17.7544 12.4763C17.3824 10.9877 17.3604 9.37754 18.3667 8.11606C19.2101 7.05965 20.8605 6.62667 21.6679 7.95764C22.4753 9.2886 22.6878 11.1377 22.5494 12.6755C22.3878 14.4489 21.7431 16.1391 20.6396 17.5547L22.0362 18.6033C23.3454 16.6305 24.2779 14.471 24.8081 12.1753C25.2468 10.2605 25.564 7.87481 24.0894 6.30482C22.7644 4.89525 20.5738 4.5253 18.7775 5.21548C16.7524 5.99589 15.5179 8.0378 15.147 10.0646C14.7023 12.4725 15.5314 14.9287 16.5793 17.0733C17.0685 18.0737 18.6985 17.4158 18.2059 16.4085L18.2066 16.4134Z"
      fill="#6D1623"
    />
    <path
      d="M19.5775 19.0765L18.0807 17.6208C17.933 17.475 17.6275 17.4246 17.4304 17.4512C17.2184 17.4798 16.9725 17.6095 16.8484 17.7871C16.7244 17.9646 16.6335 18.202 16.6743 18.4291L16.7334 18.6438C16.7932 18.7891 16.887 18.9075 17.0127 19.0019L18.5095 20.4576C18.6573 20.6034 18.9627 20.6537 19.1599 20.6271C19.3718 20.5985 19.6178 20.4689 19.7418 20.2913C19.8659 20.1138 19.9567 19.8764 19.916 19.6493L19.8568 19.4346C19.797 19.2893 19.7032 19.1708 19.5775 19.0765Z"
      fill="#6D1623"
    />
    <path
      d="M20.2246 20.2608C24.1528 21.6952 28.691 21.3872 32.3574 19.3462C32.7703 19.1148 33.0332 18.6835 32.8365 18.2128C32.6757 17.8213 32.1069 17.5121 31.689 17.7441C28.4079 19.5723 24.4176 19.9425 20.8805 18.6532C19.8243 18.2663 19.1586 19.8752 20.2225 20.2636L20.2246 20.2608Z"
      fill="#6D1623"
    />
    <path
      d="M20.6115 20.4287C23.234 21.8389 26.3273 21.7951 29.2276 21.6413C32.1279 21.4875 35.1266 21.1892 37.8279 19.991C38.4791 19.7028 38.5892 18.6241 37.8241 18.3859C36.4246 17.9463 35.0913 17.3469 33.8582 16.5607L33.8564 17.939C34.8026 17.2349 35.6363 16.3851 36.312 15.4256C36.9878 14.466 35.6569 13.5322 34.9154 14.377C31.7697 17.9484 26.5534 18.197 22.1226 18.0006C21.6481 17.9806 21.2023 18.2658 21.1407 18.7566C21.0845 19.1947 21.4265 19.7002 21.9038 19.7224C27.0235 19.9471 32.7071 19.5167 36.3092 15.4235L34.9127 14.3749C34.3189 15.217 33.6214 15.942 32.7913 16.5587C32.3628 16.8788 32.3037 17.629 32.7895 17.937C34.1541 18.8044 35.6138 19.503 37.1655 19.9913L37.1617 18.3862C34.6376 19.5036 31.8721 19.7629 29.1442 19.9108C26.4162 20.0587 23.6773 20.1066 21.2689 18.8135C20.8519 18.5877 20.2891 18.8987 20.1222 19.2973C19.9257 19.7741 20.1917 20.2008 20.6087 20.4266L20.6115 20.4287Z"
      fill="#6D1623"
    />
    <path
      d="M20.8499 18.7511C21.5377 17.8617 20.1411 16.8131 19.4533 17.7025C18.7656 18.592 20.1621 19.6406 20.8499 18.7511Z"
      fill="#6D1623"
    />
    <path
      d="M20.0004 18.5024C22.3897 15.7208 24.8215 12.3075 24.0478 8.46087C23.9557 8.00321 23.5519 7.65195 23.059 7.71846C22.6104 7.77898 22.2102 8.23378 22.3029 8.6963C22.9846 12.0853 20.6916 15.0222 18.6038 17.4538C17.8685 18.3076 19.2701 19.3555 20.0004 18.5024Z"
      fill="#6D1623"
    />
    <path
      d="M6.51544 21.5187C7.13635 22.714 7.9748 23.8107 9.21875 24.4172C10.4627 25.0238 11.8108 24.8048 13.0654 24.4326C15.5162 23.7011 17.6153 22.0028 19.1057 19.9783C19.769 19.0748 18.3745 18.0235 17.7091 18.9297C16.4923 20.5833 14.8767 21.9418 12.9205 22.656C12.0645 22.9694 11.0152 23.2644 10.1254 22.9193C9.2355 22.5743 8.57433 21.6936 8.1399 20.8567C7.92302 20.4406 7.45837 20.1965 6.9924 20.388C6.58082 20.5549 6.29362 21.1033 6.5133 21.5215L6.51544 21.5187Z"
      fill="#6D1623"
    />
    <path
      d="M8.56013 22.3568C8.52793 22.3414 8.50065 22.3253 8.46845 22.3098L8.67202 22.4234C8.54124 22.347 8.42392 22.2589 8.31449 22.1549L8.47962 22.3182C8.36395 22.2052 8.26668 22.0797 8.18288 21.9426L8.29708 22.1375C8.21887 22.0045 8.15972 21.8641 8.12176 21.7133L8.18091 21.928C8.14427 21.787 8.12521 21.6461 8.12308 21.5004L8.12308 21.7231C8.12455 21.567 8.14148 21.4138 8.17877 21.2628L8.12045 21.481C8.13868 21.4117 8.16185 21.3418 8.18781 21.2739C8.26782 21.0677 8.27012 20.8249 8.18485 20.6212C8.09957 20.4175 7.90947 20.218 7.69916 20.1474C7.25685 19.9943 6.7319 20.1764 6.55249 20.6311C6.3154 21.2346 6.31343 21.8881 6.52358 22.4955C6.57288 22.6373 6.6455 22.7661 6.72092 22.8969C6.79633 23.0278 6.87668 23.1579 6.9797 23.2702C7.2462 23.5707 7.53324 23.7819 7.89438 23.9657C8.07988 24.057 8.3684 24.0378 8.5562 23.9605C8.75813 23.8764 8.96597 23.6875 9.03826 23.4799C9.11056 23.2722 9.13323 23.0316 9.0353 22.8271C8.93261 22.6059 8.7729 22.4642 8.54961 22.3533L8.56013 22.3568Z"
      fill="#6D1623"
    />
    <path
      d="M9.55966 23.2634C8.5809 22.5766 8.50054 21.1104 9.2077 20.1045C10.0161 18.9564 11.699 18.0588 13.1318 18.2614C14.2498 18.4198 14.4715 16.7001 13.3506 16.5396C11.2577 16.243 8.76948 17.514 7.67194 19.2702C6.57441 21.0264 6.75007 23.4199 8.4917 24.6446C9.40967 25.2901 10.4804 23.9111 9.55966 23.2634Z"
      fill="#6D1623"
    />
    <path
      d="M7.83785 21.9218C7.57562 21.5416 7.67847 20.929 7.73876 20.5027C7.82009 19.9351 7.99326 19.3971 8.24711 18.8804C8.729 17.8975 9.59718 17.1174 10.6352 16.7398C12.7594 15.9683 14.8598 17.3969 16.5242 18.5331C17.4501 19.1627 18.5237 17.7858 17.5922 17.1519C15.2591 15.5617 12.4457 13.9645 9.57926 15.2964C8.17923 15.9479 7.09368 17.1063 6.49037 18.5088C5.88705 19.9114 5.56487 21.704 6.43684 22.9743C7.07155 23.9006 8.46911 22.8411 7.83785 21.9218Z"
      fill="#6D1623"
    />
    <path
      d="M10.1331 17.3769C10.1851 17.3155 10.2398 17.2562 10.2973 17.1989C10.2043 17.2906 10.2823 17.2183 10.3362 17.1714C10.4397 17.0832 10.5439 16.9999 10.6543 16.9256C10.7095 16.8885 10.7647 16.8513 10.8199 16.8142C11.0343 16.6739 10.6737 16.8834 10.9034 16.7633C11.0228 16.7002 11.1457 16.6441 11.2714 16.59C11.3971 16.5359 11.5263 16.4888 11.6561 16.4466C11.733 16.4213 11.8126 16.3982 11.8923 16.3751C11.7739 16.4084 11.9022 16.3738 11.9557 16.3616C12.2356 16.2966 12.5236 16.2553 12.8086 16.2292C13.1634 16.1986 13.2238 16.2004 13.5571 16.2148C13.8699 16.227 14.1798 16.2545 14.4888 16.2944C15.1245 16.3744 15.7563 16.4995 16.3852 16.6224C16.8366 16.7125 17.3313 16.3068 17.3671 15.8665C17.4077 15.3687 17.0863 14.9964 16.6041 14.9007C15.2247 14.6291 13.8227 14.3754 12.4127 14.526C11.0026 14.6767 9.67208 15.2669 8.74147 16.3277C8.43932 16.6727 8.53265 17.2886 8.90578 17.5425C9.31523 17.8238 9.81389 17.7441 10.1381 17.3763L10.1331 17.3769Z"
      fill="#6D1623"
    />
    <path
      d="M19.6898 29.6255L19.6267 29.9755C19.6095 30.0891 19.6169 30.1995 19.656 30.3031C19.676 30.4142 19.7222 30.5143 19.7973 30.6056C19.8681 30.7024 19.956 30.7772 20.068 30.8264C20.1645 30.8901 20.2703 30.9302 20.3919 30.9386L20.6176 30.9378C20.7704 30.9172 20.9081 30.859 21.0313 30.7682L21.1975 30.6047C21.2936 30.4804 21.3498 30.3392 21.3716 30.1854L21.4347 29.8354C21.452 29.7218 21.4446 29.6114 21.4055 29.5079C21.3854 29.3968 21.3392 29.2966 21.2642 29.2053C21.1933 29.1085 21.1054 29.0337 20.9934 28.9845C20.8969 28.9209 20.7911 28.8807 20.6695 28.8724L20.4438 28.8731C20.291 28.8938 20.1533 28.9519 20.0301 29.0428L19.864 29.2062C19.7678 29.3305 19.7117 29.4717 19.6898 29.6255Z"
      fill="#6D1623"
    />
    <path
      d="M14.7756 35.2961C16.7784 34.1105 18.3444 32.2737 19.212 30.1353C19.423 29.617 18.6061 29.2918 18.3979 29.8122C17.5856 31.8219 16.1253 33.4935 14.2455 34.6056C13.7597 34.8913 14.2968 35.5784 14.7777 35.2933L14.7756 35.2961Z"
      fill="#6D1623"
    />
    <path
      d="M14.3375 35.583C14.8339 35.5234 15.2869 35.2916 15.716 35.0506C16.1452 34.8096 16.589 34.5098 16.9776 34.1728C17.766 33.49 18.3955 32.6703 18.8572 31.7421C19.1081 31.2406 18.2918 30.9203 18.043 31.4189C17.6538 32.1964 17.1077 32.9084 16.4475 33.4823C16.122 33.7662 15.7701 34.0215 15.3981 34.24C15.0261 34.4584 14.6387 34.674 14.2261 34.7222C13.6629 34.7883 13.7792 35.6484 14.3425 35.5823L14.3375 35.583Z"
      fill="#6D1623"
    />
    <path
      d="M14.1232 35.518C14.4459 35.1578 14.8451 34.8813 15.2124 34.572C15.5796 34.2626 15.9253 33.9241 16.2458 33.5666C16.8955 32.8406 17.4293 32.019 17.8476 31.1412C18.088 30.6362 17.2739 30.3131 17.0335 30.818C16.6324 31.6564 16.1096 32.4296 15.4772 33.1161C14.8448 33.8026 14.0461 34.2988 13.427 34.991C13.0524 35.4126 13.7529 35.9342 14.1253 35.5153L14.1232 35.518Z"
      fill="#6D1623"
    />
    <path
      d="M13.7626 35.8237C15.0535 35.5531 16.0625 34.7563 16.9691 33.8522C17.8225 32.9998 19.0844 31.8647 18.5238 30.54C18.3036 30.0254 17.4903 30.3578 17.7105 30.8724C17.9168 31.3591 17.6346 31.8327 17.3237 32.2061C16.9808 32.621 16.5924 33.0149 16.2063 33.3886C15.4852 34.0871 14.6567 34.753 13.6441 34.9664C13.0924 35.0804 13.2066 35.9433 13.7605 35.8265L13.7626 35.8237Z"
      fill="#6D1623"
    />
    <path
      d="M13.7482 35.9442C14.7974 36.0204 15.8031 35.6447 16.6381 35.0273C17.1154 34.6784 17.5372 34.2529 17.9261 33.807C18.135 33.571 18.3329 33.3266 18.5328 33.0794C18.72 32.8488 18.9345 32.617 19.0154 32.324C19.0801 32.0976 19.0121 31.8544 18.7711 31.7608C18.5721 31.6812 18.2611 31.7751 18.1991 32.0037C18.1293 32.2481 17.9249 32.444 17.7677 32.6359C17.6104 32.8278 17.4602 33.0163 17.2996 33.2013C16.959 33.5961 16.5947 33.9644 16.1782 34.2803C15.5159 34.7829 14.708 35.1368 13.8591 35.0757C13.2989 35.035 13.1846 35.8965 13.7496 35.9366L13.7454 35.9421L13.7482 35.9442Z"
      fill="#6D1623"
    />
    <path
      d="M35.0009 18.4532C36.2009 16.9355 36.7768 15.0147 36.6332 13.0993C36.6084 12.7489 36.0447 12.5701 35.8503 12.8957C35.4701 13.5358 35.1186 14.1844 34.7 14.8001C34.2814 15.4157 33.7952 16.033 33.2905 16.6058C32.9158 17.0275 33.6163 17.549 33.9888 17.1301C34.4956 16.5545 34.969 15.9538 35.3983 15.3244C35.8276 14.6949 36.1705 14.0574 36.5486 13.42L35.7657 13.2164C35.8932 14.9013 35.3617 16.5935 34.3047 17.9262C33.9552 18.3667 34.6535 18.891 35.003 18.4505L35.0009 18.4532Z"
      fill="#6D1623"
    />
  </svg>
</template>

<docs>
    To know more information on how to use icons see [here](/packs/#Icon)

    ```jsx
      <Icon name="Instagram" class="text-4xl"></Icon>
    ```
  </docs>
