<template>
  <div class="w-full">
    <div class="w-full flex flex-wrap items-center">
      <div class="w-full lg:w-1/2 2xl:w-5/12 ">
        <img
          loading="lazy"
          :src="course.image"
        >
      </div>
      <div class="w-full lg:w-1/2 2xl:w-5/12 pt-8 lg:pl-16 lg:pt-0">
        <h4
          data-testid="course-title"
          class="title-medium text-left text-grayscale-900 mt-0 mb-2"
        >
          {{ course.name }}
        </h4>

        <div class="text-lg mb-4 line-clamp-6 break-words">
          {{ course.shortSummary }}
        </div>

        <a
          :href="$routes.course(course.slug)"
          class="button button--blue w-max"
        >
          <!-- {{ course.buttonText }} -->
          Explore course
        </a>
      </div>
    </div>

    <Divider
      class="w-full h-px"
      height="regular"
      spacing="xl"
    />
  </div>
</template>
<script>

import Divider from '@/Components/Shared/Divider'

export default {
  components: {
    Divider
  },

  props: {
    course: {
      type: Object,
      default: () => { }
    }
  }
}
</script>
