<template>
  <svg
    width="34"
    height="36"
    viewBox="0 0 34 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.6667 1.5L33 18M33 18L19.6667 34.5M33 18L0.999997 18"
      stroke="#0E1E38"
      strokeLinecap="square"
    />
  </svg>
</template>

  <docs>
    To know more information on how to use icons see [here](/packs/#Icon)

    ```jsx
      <Icon name="Check" class="text-4xl"></Icon>
    ```
  </docs>
