<template>
  <div class="menu__account relative">
    <template v-if="currentUser">
      <div
        ref="triggerBtn"
        data-testid="navbar-profile"
        :aria-expanded="isOpen.toString()"
        :aria-label="$I18n.t('link_labels.go_to_account', i18nScope)"
        class="relative w-12 h-12 overflow-hidden rounded-full cursor-pointer ml-6"
        @click.stop="toggleDropdown"
      >
        <img
          loading="lazy"
          :src="currentUser.account.avatar_path"
          :alt="$I18n.t('link_labels.account_avatar', i18nScope)"
          class="image-cover"
        >
      </div>

      <!-- Dropdown menu -->
      <div
        ref="dropdown"
        class="dropdown-menu absolute w-60 top-12 -right-6 bg-blue-800 shadow-sm z-50 origin-top"
        @click="handleDropdownClick"
      >
        <div class="px-6 py-4">
          <Link
            v-if="hasEnrollments && !isSubscribed"
            data-testid="navbar-link-courses-desktop"
            :href="$routes.root({ anchor: 'my-courses' })"
            class="menu-link py-2 block"
          >
            {{ $I18n.t('home', i18nScope) }}
          </Link>

          <Link
            :href="$routes.user_collections()"
            class="menu-link py-2 block"
          >
            {{ $I18n.t('collections', i18nScope) }}
          </Link>

          <Divider
            color="light"
            spacing="xs"
          />

          <ProfileLinks />

          <Divider
            color="light"
            spacing="xs"
          />

          <Link
            data-testid="navbar-link-support"
            :href="$routes.support()"
            class="menu-link py-2 block"
          >
            {{ $I18n.t('support', i18nScope) }}
          </Link>

          <LogoutButton
            data-testid="logout-button"
            class="subheading--medium text-danger py-1 text-left outline-none border-0 focus:outline-none"
          />
        </div>
      </div>
    </template>
    <template v-else>
      <div class="flex justify-between items-center gap-2">
        <Link
          data-testid="navbar-link-signin"
          :href="$routes.new_user_session()"
          :aria-label="$I18n.t('link_labels.sign_in', i18nScope)"
          class="menu-link px-3 mx-3"
        >
          Log In
        </Link>
        <ButtonLink
          v-show="!isSubscribed"
          v-bind="$attrs"
          data-testid="button-membership-navbar"
          :href="$routes.onboarding()"
          type="submit"
          class="text-sm border-transparent"
          variant="white"
          outline
          @click="trackSubscribeButton"
        >
          SUBSCRIBE
        </ButtonLink>
      </div>
    </template>
  </div>
</template>

<script>
  import currentUser from '@/mixins/currentUser'
  import mixpanel from '@/mixins/mixpanel'
  import { Link } from '@inertiajs/vue3'
  import Divider from '@/Components/Shared/Divider'
  import ProfileLinks from '@/Components/Shared/Objects/Navbar/ProfileLinks'
  import LogoutButton from '@/Components/Shared/LogoutButton'
  import ButtonLink from '@/Components/Shared/ButtonLink'

  export default {

    components: {
      Link,
      Divider,
      ProfileLinks,
      LogoutButton,
      ButtonLink
    },
    mixins: [currentUser, mixpanel],

    data() {
      return {
        isOpen: false,
        i18nScope: { scope: 'pages.navbar' }
      }
    },

    mounted() {
      // Store the full height of the dropdown
      this.$nextTick(() => {
        if (this.$refs.dropdown) {
          const dropdown = this.$refs.dropdown
          dropdown.style.setProperty('--expanded-height', `${dropdown.scrollHeight}px`)
        }
      })
      // Add click outside listener
      document.addEventListener('click', this.handleClickOutside)
    },

    beforeUnmount() {
      // Clean up listener
      document.removeEventListener('click', this.handleClickOutside)
    },

    methods: {
      toggleDropdown() {
        this.isOpen = !this.isOpen

        if (this.isOpen) {
          this.$nextTick(() => {
            if (this.$refs.dropdown) {
              const dropdown = this.$refs.dropdown
              dropdown.style.setProperty('--expanded-height', `${dropdown.scrollHeight}px`)
            }
          })
        }
      },

      handleClickOutside(event) {
        if (
          this.isOpen &&
          this.$refs.dropdown &&
          !this.$refs.dropdown.contains(event.target) &&
          !this.$refs.triggerBtn.contains(event.target)
        ) {
          this.isOpen = false
        }
      },

      trackSubscribeButton() {
        this.trackEvent('subscribe_button', {
          userType: this.currentUser ? 'authed' : 'anonymous',
          page: window.location.pathname,
          component: 'Nav Bar'
        })
      },

      handleDropdownClick() {
        // Close dropdown when clicking any item inside
        this.isOpen = false
      }
    }
  }
</script>

<style scoped>
  .dropdown-menu {
    height: 0;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    transition:
      height 1s ease,
      opacity 0.3s ease 0.7s,
      visibility 0s linear 1s;
  }

  div[aria-expanded='true'] + .dropdown-menu {
    height: var(--expanded-height);
    visibility: visible;
    opacity: 1;
    transition:
      height 1s ease,
      opacity 0.3s ease,
      visibility 0s linear;
  }
</style>
