<template>
  <div class="w-full bg-white overflow-hidden flex flex-col h-full">
    <img
      :src="course.imagePath"
      :alt="course.title"
      class="w-full h-72  md:h-96 lg:h-144 object-cover object-center"
    >
    <div class="px-5 py-4 lg:px-8 lg:py-6 flex flex-col sm:flex-row h-full">
      <div class="flex-grow">
        <h2 class="text-xl lg:text-4xl  font-serif font-medium text-blue-800 mb-0 lg:mb-2 line-clamp-2">
          {{ course.title }}
        </h2>
        <p
          class="text-black mb-4 text-xs lg:text-xl max-w-prose text-left font-normal line-clamp-3 sm:line-clamp-4"
        >
          {{ course.description }}
        </p>
      </div>
      <div
        class="flex flex-row justify-start lg:flex-col lg:justify-center text-yellow-800 text-xs sm:text-sm mt-auto sm:mt-0 sm:ml-4"
      >
        <div class="flex items-center mr-4 lg:mr-0 lg:mb-2 text-sm lg:text-xl font-normal">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            class="mr-2"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M16.7619 3.00047V3H15.8095V3.00047H8.19048H7.2381H2.95238H2V3.95285V20.1433V21.0957H2.95238H21.0476H22V20.1433V3.95285V3.00047H21.0476H16.7619ZM15.8095 3.95285L15.8095 20.1429H16.7619L16.7619 3.95285H21.0476V20.1433H8.19048V3.95285H15.8095ZM7.2381 20.1433V3.95285H2.95238V20.1433H7.2381ZM6.08607 5.62734C6.08607 6.11091 5.69406 6.50292 5.21049 6.50292C4.72692 6.50292 4.33491 6.11091 4.33491 5.62734C4.33491 5.14378 4.72692 4.75177 5.21049 4.75177C5.69406 4.75177 6.08607 5.14378 6.08607 5.62734ZM5.21049 14.6749C5.69406 14.6749 6.08607 14.2829 6.08607 13.7993C6.08607 13.3157 5.69406 12.9237 5.21049 12.9237C4.72692 12.9237 4.33491 13.3157 4.33491 13.7993C4.33491 14.2829 4.72692 14.6749 5.21049 14.6749ZM6.08607 9.71356C6.08607 10.1971 5.69406 10.5891 5.21049 10.5891C4.72692 10.5891 4.33491 10.1971 4.33491 9.71356C4.33491 9.22999 4.72692 8.83798 5.21049 8.83798C5.69406 8.83798 6.08607 9.22999 6.08607 9.71356ZM5.21049 18.7611C5.69406 18.7611 6.08607 18.3691 6.08607 17.8855C6.08607 17.402 5.69406 17.01 5.21049 17.01C4.72692 17.01 4.33491 17.402 4.33491 17.8855C4.33491 18.3691 4.72692 18.7611 5.21049 18.7611ZM18.1906 18.0387C18.1906 17.5551 18.5826 17.1631 19.0662 17.1631C19.5498 17.1631 19.9418 17.5551 19.9418 18.0387C19.9418 18.5222 19.5498 18.9142 19.0662 18.9142C18.5826 18.9142 18.1906 18.5222 18.1906 18.0387ZM19.0662 8.99113C18.5826 8.99113 18.1906 9.38314 18.1906 9.8667C18.1906 10.3503 18.5826 10.7423 19.0662 10.7423C19.5498 10.7423 19.9418 10.3503 19.9418 9.8667C19.9418 9.38314 19.5498 8.99113 19.0662 8.99113ZM18.1906 13.9525C18.1906 13.4689 18.5826 13.0769 19.0662 13.0769C19.5498 13.0769 19.9418 13.4689 19.9418 13.9525C19.9418 14.436 19.5498 14.828 19.0662 14.828C18.5826 14.828 18.1906 14.436 18.1906 13.9525ZM19.0662 4.90491C18.5826 4.90491 18.1906 5.29692 18.1906 5.78049C18.1906 6.26406 18.5826 6.65606 19.0662 6.65606C19.5498 6.65606 19.9418 6.26406 19.9418 5.78049C19.9418 5.29692 19.5498 4.90491 19.0662 4.90491Z"
              fill="#9E6C21"
            />
          </svg>
          {{ course.lessons }} lessons
        </div>
        <div class="flex items-center mr-4 lg:mr-0 lg:mb-2 text-sm lg:text-xl font-normal">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            class="mr-2"
          >
            <path
              d="M12.1055 8.83333H9.3555M15.8752 14.3867L20.5252 16.6771C21.0072 16.9705 21.513 16.7976 21.5 16.1856L21.4674 8.09104C21.4262 7.42667 21.0341 7.24539 20.4568 7.55242L15.8621 9.64057M5.25 18.5H13.6055C14.8481 18.5 15.8555 17.5051 15.8555 16.2778L15.8752 13.4275L15.8555 7.72222C15.8555 6.49492 14.8481 5.5 13.6055 5.5H5.25C4.00736 5.5 3 6.49492 3 7.72222V16.2778C3 17.5051 4.00736 18.5 5.25 18.5Z"
              stroke="#9E6C21"
              stroke-linecap="square"
            />
          </svg>
          {{ course.courses }} courses
        </div>
        <div class="flex items-center text-sm lg:text-xl font-normal">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            class="mr-2"
          >
            <path
              d="M15.875 14.25L12.5 13.125V8.42087M21.5 12C21.5 16.9706 17.4706 21 12.5 21C7.52944 21 3.5 16.9706 3.5 12C3.5 7.02944 7.52944 3 12.5 3C17.4706 3 21.5 7.02944 21.5 12Z"
              stroke="#9E6C21"
              stroke-linecap="square"
            />
          </svg>
          {{ course.duration }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CourseCard',
    props: {
      course: {
        type: Object,
        required: true
      }
    }
  }
</script>

<style scoped>
  .line-clamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .line-clamp-3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .line-clamp-4 {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
</style>
