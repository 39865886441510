<template>
  <div class="w-full">
    <template v-if="!hasAccessToWorkbooks">
      <p class="text-blue-800 text-base leading-7 tracking-wide font-light font-sans text-left">
        {{ noAccessWorkbookMessage }}
      </p>
    </template>
    <template v-else-if="emptyDocuments && !lesson.materials_description">
      <p
        data-testid="materials-section-no-materials"
        class="text-blue-800 text-base leading-7 tracking-wide font-light font-sans text-left"
      >
        {{ $I18n.t('no_materials', i18nScope) }}
      </p>
    </template>
    <template v-else>
      <div
        v-if="lesson.materials_description"
        class="mb-4 materials-description"
      >
        <div
          class="text-blue-800 text-base leading-7 tracking-wide font-light font-sans text-left"
          v-html="sanitizedMaterialsDescription"
        />
      </div>

      <div class="flex flex-row flex-wrap py-4">
        <!-- Jane Scotter course exception -->
        <CustomDocument v-if="hasAditionalDocument" />

        <div
          v-for="(doc, index) in lesson.documents_urls"
          :key="doc"
          class="w-full"
        >
          <div
            v-if="hasAccessToDownloadWorkbooks"
            class="w-full flex flex-col md:flex-row md:justify-between md:items-center mt-2 md:mt-0 md:mb-2"
          >
            <a
              :data-testid="`materials-section-download-workbook-${index}`"
              :href="doc"
              download="download"
              class="button button--blue-outline button--small link--blue"
              @click="onDownloadClick"
            >
              {{ $I18n.t(isMobile ? 'pdf_download' : 'pdf_cannot_see_download_link', i18nScope) }}
            </a>
          </div>

          <!-- If mobile or materials_description exists, don't show PDF -->
          <template v-if="course.id === 3 && lesson.id === 5">
            <Workbook1 />
          </template>
          <template v-else-if="!isMobile && !lesson.materials_description">
            <object
              :data-testid="`materials-section-workbook-${index}`"
              :data="getPdfUrl(doc, index)"
              type="application/pdf"
              width="100%"
              height="650px"
            />
          </template>

          <div v-if="!hasAccessToDownloadWorkbooks">
            {{ $I18n.t('purchase_to_get_materials', i18nScope) }}
          </div>

          <Divider
            color="light"
            spacing="lg"
            block
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import currentUser from '@/mixins/currentUser'
  import statuses from '@/mixins/statuses'
  import mixpanel from '@/mixins/mixpanel'

  import CustomDocument from '@/Components/Courses/Lessons/CustomDocument'
  import Divider from '@/Components/Shared/Divider'
  import Workbook1 from '@/Components/Courses/Lessons/workbooks/1.vue'
  const WORKBOOK_DOWNLOAD_EVENT = 'workbook-download'

  export default {

    components: {
      CustomDocument,
      Divider,
      Workbook1
    },

    mixins: [currentUser, statuses, mixpanel],

    props: {
      course: {
        type: Object,
        required: true
      },
      lesson: {
        type: Object,
        required: true
      },
      isMobile: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        targetCourseId: 123, // Replace with the actual course ID you're targeting
        i18nScope: { scope: 'pages.courses.lessons.show' }
      }
    },

    computed: {
      emptyDocuments() {
        return this.lesson.documents_urls.length === 0 && !this.hasAditionalDocument
      },

      noAccessWorkbookMessage() {
        if (this.earlyBird && !this.currentUser?.active_membership) {
          return 'The Materials is only available to subscribers'
        }
        return this.$I18n.t(
          this.published ? 'purchase_to_get_materials' : 'not_published',
          this.i18nScope
        )
      },

      hasAccessToWorkbooks() {
        return (
          this.isAdmin ||
          (this.published && (this.enrolled || !this.lesson.is_first_lesson)) ||
          (this.currentUser?.active_membership && this.earlyBird)
        )
      },

      hasAccessToDownloadWorkbooks() {
        return (
          this.isAdmin ||
          (this.published && this.enrolled) ||
          (this.currentUser?.active_membership && this.earlyBird)
        )
      },

      hasAditionalDocument() {
        return this.course.slug === 'jane-scotter'
      },

      sanitizedMaterialsDescription() {
        return this.lesson?.materials_description
          ? this.sanitizeHTML(this.lesson.materials_description)
          : ''
      }
    },
    created() {
      this.i18nScope = { scope: 'pages.courses.lessons.show' }
    },

    mounted() {},

    methods: {
      onDownloadClick() {
        this.trackEvent(WORKBOOK_DOWNLOAD_EVENT)
      },
      // getPdfUrl constructs the URL for the PDF iframe.
      // We append '#toolbar=0' to the document URL to hide the PDF viewer's toolbar,
      // which includes the download button.
      // This is effective in browsers that support this PDF viewer feature.
      getPdfUrl(documentUrl, index) {
        // Append '#toolbar=0' to the URL if the user does not have access to the workbooks
        return `${documentUrl}`
      },
      sanitizeHTML(html) {
        // Basic sanitization, you might want to use a more robust solution
        return html.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '')
      }
    }
  }
</script>

<style>
  .materials-description h1 {
    font-size: 24px;
    font-family: Saol CA;
  }
</style>
