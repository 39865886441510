<template>
  <ImageWrapper background="sign-up">
    <MetaTags :meta-tags="metaTags" />

    <SignUpForm
      :extra-action="extraAction"
      content-text-key="free_lessons"
      @toggle-sign-in-up="toggle"
    />
  </ImageWrapper>
</template>

<script>
import { metaTags } from '@/mixins/metaTags'

import Layout from '@/Layouts/ReducedLayout'

import MetaTags from '@/Components/Shared/MetaTags'
import ImageWrapper from '@/Components/Shared/Objects/ImageWrapper'
import SignUpForm from '@/Components/Auth/SignUpForm'

export default {

  components: {
    MetaTags,
    ImageWrapper,
    SignUpForm
  },

  mixins: [metaTags],
  layout: Layout,

  data() {
    return {
      extraAction: {
        type: 'signup-page'
      }
    }
  },

  methods: {
    toggle() {
      this.$inertia.visit(this.$routes.new_user_session())
    }
  }
}
</script>
