<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.7619 3.00047V3H15.8095V3.00047H8.19048H7.2381H2.95238H2V3.95285V20.1433V21.0957H2.95238H21.0476H22V20.1433V3.95285V3.00047H21.0476H16.7619ZM15.8095 3.95285L15.8095 20.1429H16.7619L16.7619 3.95285H21.0476V20.1433H8.19048V3.95285H15.8095ZM7.2381 20.1433V3.95285H2.95238V20.1433H7.2381ZM6.08607 5.62734C6.08607 6.11091 5.69406 6.50292 5.21049 6.50292C4.72692 6.50292 4.33491 6.11091 4.33491 5.62734C4.33491 5.14378 4.72692 4.75177 5.21049 4.75177C5.69406 4.75177 6.08607 5.14378 6.08607 5.62734ZM5.21049 14.6749C5.69406 14.6749 6.08607 14.2829 6.08607 13.7993C6.08607 13.3157 5.69406 12.9237 5.21049 12.9237C4.72692 12.9237 4.33491 13.3157 4.33491 13.7993C4.33491 14.2829 4.72692 14.6749 5.21049 14.6749ZM6.08607 9.71356C6.08607 10.1971 5.69406 10.5891 5.21049 10.5891C4.72692 10.5891 4.33491 10.1971 4.33491 9.71356C4.33491 9.22999 4.72692 8.83798 5.21049 8.83798C5.69406 8.83798 6.08607 9.22999 6.08607 9.71356ZM5.21049 18.7611C5.69406 18.7611 6.08607 18.3691 6.08607 17.8855C6.08607 17.402 5.69406 17.01 5.21049 17.01C4.72692 17.01 4.33491 17.402 4.33491 17.8855C4.33491 18.3691 4.72692 18.7611 5.21049 18.7611ZM18.1906 18.0387C18.1906 17.5551 18.5826 17.1631 19.0662 17.1631C19.5498 17.1631 19.9418 17.5551 19.9418 18.0387C19.9418 18.5222 19.5498 18.9142 19.0662 18.9142C18.5826 18.9142 18.1906 18.5222 18.1906 18.0387ZM19.0662 8.99113C18.5826 8.99113 18.1906 9.38314 18.1906 9.8667C18.1906 10.3503 18.5826 10.7423 19.0662 10.7423C19.5498 10.7423 19.9418 10.3503 19.9418 9.8667C19.9418 9.38314 19.5498 8.99113 19.0662 8.99113ZM18.1906 13.9525C18.1906 13.4689 18.5826 13.0769 19.0662 13.0769C19.5498 13.0769 19.9418 13.4689 19.9418 13.9525C19.9418 14.436 19.5498 14.828 19.0662 14.828C18.5826 14.828 18.1906 14.436 18.1906 13.9525ZM19.0662 4.90491C18.5826 4.90491 18.1906 5.29692 18.1906 5.78049C18.1906 6.26406 18.5826 6.65606 19.0662 6.65606C19.5498 6.65606 19.9418 6.26406 19.9418 5.78049C19.9418 5.29692 19.5498 4.90491 19.0662 4.90491Z"
    />
  </svg>
</template>

<docs>
  To know more information on how to use icons see [here](/packs/#Icon)

  ```jsx
    <Icon name="Video" class="text-4xl"></Icon>
  ```
</docs>
