<template>
  <div class="input-group">
    <input
      :id="computedId"
      :data-testid="`input-${testid}`"
      :checked="modelValue || null"
      type="checkbox"
      class="input-checkbox"
      @input="toggleChecked()"
    >

    <label
      :for="computedId"
      :data-testid="`label-${testid}`"
    >
      <span> <slot /> </span>
    </label>

    <InputError
      :testid="testid"
      :errors="errors"
    />
  </div>
</template>

<script>
import InputError from '@/Components/Shared/Forms/InputError'

/**
 * Checkbox component for Create Academy
 * @displayName Checkbox
 */
export default {
  name: 'Checkbox',

  components: {
    InputError
  },

  props: {
    id: {
      type: String,
      default: null
    },
    modelValue: {
      type: Boolean,
      default: null
    },
    errors: {
      type: Array,
      default: () => []
    },
    testid: String
  },
  emits: ['update:modelValue'],

  data() {
    return {
      checked: this.modelValue
    }
  },

  computed: {
    computedId() {
      return this.id || `checkbox-input-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`
    }
  },

  methods: {
    toggleChecked() {
      this.$emit('update:modelValue', !this.modelValue)
    }
  }
}
</script>

<docs>
  ### ✎ Checkbox

  ```jsx
    <Checkbox> I want to receive offers and promotions</Checkbox>
  ```
</docs>
