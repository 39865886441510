<template>
  <div class="flex flex-col justify-center items-center">
    <div class="mb-4 w-full">
      <h1
        data-testid="signup-form-title"
        class="title-largest text-blue-800 mb-2"
      >
        {{ contentText.title }}
      </h1>
      <p class="font-normal">
        {{ contentText.subTitle }}
      </p>
    </div>

    <form
      class="w-full"
      novalidate="true"
      @submit.prevent="submit"
    >
      <InputGroup
        v-model="form.user.email"
        testid="email"
        :errors="form.errors.email"
        :label="$I18n.t('form.inputs.email.label', i18nScope)"
        :placeholder="$I18n.t('form.inputs.email.placeholder', i18nScope)"
        type="email"
        autofocus
        autocapitalize="off"
      />

      <InputGroup
        v-model="form.user.password"
        testid="password"
        :errors="form.errors.password"
        :label="$I18n.t('form.inputs.password.label', i18nScope)"
        :placeholder="$I18n.t('form.inputs.password.placeholder', i18nScope)"
        type="password"
      />

      <Button
        data-testid="button-submit"
        :loading="form.processing"
        type="submit"
        block
      >
        {{ $I18n.t('form.button', i18nScope) }}
      </Button>
    </form>

    <TextNote class="text-center  mt-2 w-full">
      <Link
        class="text-base font-normal"
        data-testid="link-forgot-password"
        :href="$routes.new_user_password()"
      >
        {{ $I18n.t('forgot_password', i18nScope) }}
      </Link>
    </TextNote>

    <Divider
      class="w-full"
      label="or"
    />

    <SocialButtons
      :form-authenticity-token="formAuthenticityToken"
      :i18n-scope="i18nScope"
      :query-parameters="queryParameters"
    />

    <TextNote class="text-grayscale-900 text-base font-normal mt-4 w-full text-center">
      {{ $I18n.t('not_a_member', i18nScope) }}

      <CustomLink
        data-testid="link-signup"
        class="underline"
        :href="newUserRegistrationUrl"
        :fake="isModal"
        @click="$emit('toggleSignInUp')"
      >
        {{ $I18n.t('sign_up', i18nScope) }}
      </CustomLink>
    </TextNote>
  </div>
</template>

<script>
import mixpanel from '@/mixins/mixpanel'
import authForm from '@/mixins/authForm'
import cart from '@/mixins/cart'

import { Link } from '@inertiajs/vue3'
import { closeModal } from 'jenesius-vue-modal'

const STORAGE_NAME = 'ca_sign_in_email'

const CONTENT_TEXT = {
  default: {
    title: 'Log in to Create Academy',
    subTitle: null
  },
  free_lessons: {
    title: 'Log in to Create Academy',
    subTitle: null
  },
  gifts: {
    title: 'You got a creative gift!',
    subTitle: 'Sign in to your account and you\'ll see your gift waiting for you.'
  }
}

export default {

  components: {
    Link
  },
  mixins: [
    cart,
    authForm,
    mixpanel
  ],

  props: {
    contentTextKey: {
      type: String,
      default: 'default'
    }
  },

  emits: ['toggleSignInUp'],

  data() {
    return {
      form: {
        user: {
          email: localStorage?.getItem(STORAGE_NAME),
          password: null,
          remember_me: true
        },
        extra_action: this.extraAction,
        errors: {}
      }
    }
  },

  computed: {
    contentText() {
      return CONTENT_TEXT[this.contentTextKey]
    },
    newUserRegistrationUrl() {
      return this.$routes.new_user_registration() + window.location.search
    }
  },

  created() {
    this.i18nScope = { scope: 'pages.auth.sign_in' }
  },

  methods: {
    submit() {
      const { email, password } = this.form.user
      this.form.errors = {}

      const actualPageComponent = this.$page.component

      // Validate required fields before sending the request
      if (email && password) {
        this.$inertia.post(this.$routes.user_session(), this.form, {
          headers: {
            'X-NEXT-PATH': this.getQueryParam('redirect_to') ?? this.next
          },
          onSuccess: (page) => {
            // If we stayed in the same page, means sign in failed, so we return
            if (!this.isModal && page.component === actualPageComponent) return

            // Otherwise sign in succeeded, we we save the email to the local storage
            localStorage?.setItem(STORAGE_NAME, email)
            closeModal()

            this.trackEvent('user_signed_in', this.mixpanelCartData(this.orderable))
          }
        })
      }

      if (!email) {
        this.form.errors.email = [this.$I18n.t('activerecord.errors.models.user.attributes.email.blank')]
      }
      if (!password) {
        this.form.errors.password = [this.$I18n.t('activerecord.errors.models.user.attributes.password.blank')]
      }
    }
  }
}
</script>
