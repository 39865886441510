export const CAMPAIGNS = {
  CHRISTMAS: {
    startDate: '2024-12-01T00:00:00',
    endDate: '2024-12-27T23:59:59',
    heading: 'CHRISTMAS SALE',
    backgroundClass: 'bg-christmas'
  },

  DEFAULT: {
    heading: 'ACCESS ALL COURSES'
  }
} 