<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.8029 11.0957L5.35359 4.64648L4.64648 5.35359L11.0957 11.8029L4.64648 18.2521L5.35359 18.9592L11.8029 12.51L18.2521 18.9592L18.9592 18.2521L12.51 11.8029L18.9592 5.35359L18.2521 4.64648L11.8029 11.0957Z"
    />
  </svg>
</template>

<docs>
  To know more information on how to use icons see [here](/packs/#Icon)

  ```jsx
    <Icon name="Close" class="text-4xl"></Icon>
  ```
</docs>
