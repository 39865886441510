<template>
  <div>
    <MetaTags :meta-tags="metaTags" />

    <Tabs />

    <div class="md:mt-14 container-sm">
      <h1
        data-testid="title"
        class="title-large text-blue-800"
      >
        {{ $I18n.t('title', i18nScope) }}
      </h1>

      <div class="flex flex-col divide-y divide-blue-800">
        <PurchasesHistory
          v-if="orders.length > 0"
          :orders="orders"
        />

        <template v-else>
          <div class="py-8">
            <h2 class="w-full flex-grow overline--medium text-yellow-800">
              Your Recent Purchases
            </h2>

            <p
              data-testid="no-purchases"
              class="my-10 text-lg text-opacity-80"
            >
              {{ $I18n.t('no_purchases', i18nScope) }}
            </p>
          </div>
        </template>

        <MembershipDetails :membership-plan="membershipPlan" />

        <BillingInformation
          v-if="paymentMethod"
          :payment-method="paymentMethod"
        />

        <PaymentDetails
          v-if="paymentMethod"
          :payment-method="paymentMethod"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { metaTags } from '@/mixins/metaTags'

  import BillingInformation from '@/Components/User/BillingInformation'
  import MembershipDetails from '@/Components/User/BillingMembershipDetails'
  import MetaTags from '@/Components/Shared/MetaTags'
  import PaymentDetails from '@/Components/User/PaymentDetails'
  import Tabs from '@/Components/User/Tabs'
  import PurchasesHistory from '@/Components/User/PurchasesHistory'

  export default {

    components: {
      BillingInformation,
      MembershipDetails,
      MetaTags,
      PaymentDetails,
      Tabs,
      PurchasesHistory
    },
    mixins: [metaTags],

    props: {
      orders: {
        type: Array,
        required: true
      },
      membershipPlan: {
        type: Object,
        default: null
      },
      paymentMethod: {
        type: Object,
        default: null
      }
    },

    created() {
      this.i18nScope = { scope: 'pages.users.purchases' }
    }
  }
</script>
