<template>
  <component
    :is="iconComponent"
    role="img"
    :alt="iconComponent"
    class="inline-block"
    :class="{ 'fill-current': !isStrokeIcon }"
    style="height: 1.2em; width: 1.2em"
  />
</template>

<script>
import Arrow from '@/Components/Icons/Arrow'
import Basket from '@/Components/Icons/Basket'
import Close from '@/Components/Icons/Close'
import Charity from '@/Components/Icons/Charity'
import Check from '@/Components/Icons/Check'
import Chevron from '@/Components/Icons/Chevron'
import Current from '@/Components/Icons/Current'
import Delete from '@/Components/Icons/Delete'
import Email from '@/Components/Icons/Email'
import Facebook from '@/Components/Icons/Facebook'
import File from '@/Components/Icons/File'
import Finished from '@/Components/Icons/Finished'
import Gift from '@/Components/Icons/Gift'
import Google from '@/Components/Icons/Google'
import Info from '@/Components/Icons/Info'
import Instagram from '@/Components/Icons/Instagram'
import Lesson from '@/Components/Icons/Lesson'
import LessonPlan from '@/Components/Icons/LessonPlan'
import Link from '@/Components/Icons/Link'
import Linkedin from '@/Components/Icons/Linkedin'
import Lock from '@/Components/Icons/Lock'
import LockBanner from '@/Components/Icons/LockBanner'
import Menu from '@/Components/Icons/Menu'
import Play from '@/Components/Icons/Play'
import Pencil from '@/Components/Icons/Pencil'
import PencilOnCircle from '@/Components/Icons/PencilOnCircle'
import PlayFull from '@/Components/Icons/PlayFull'
import Pinterest from '@/Components/Icons/Pinterest'
import Profile from '@/Components/Icons/Profile'
import Reply from '@/Components/Icons/Reply'
import Roundel from '@/Components/Icons/Roundel'
import Search from '@/Components/Icons/Search'
import SecureBadge from '@/Components/Icons/SecureBadge'
import Success from '@/Components/Icons/Success'
import Twitter from '@/Components/Icons/Twitter'
import Unfinished from '@/Components/Icons/Unfinished'
import Upload from '@/Components/Icons/Upload'
import Video from '@/Components/Icons/Video'
import Warning from '@/Components/Icons/Warning'
import Website from '@/Components/Icons/Website'
import Youtube from '@/Components/Icons/Youtube'

import Plus from '@/Components/Icons/Plus'
import BookMark from '@/Components/Icons/BookMark'
import BookMarked from '@/Components/Icons/BookMarked'
import BookMarkNoStroke from '@/Components/Icons/BookMarkNoStroke'
import CollectionCheck from '@/Components/Icons/CollectionCheck'
import CollectionDelete from '@/Components/Icons/CollectionDelete'
import CollectionLock from '@/Components/Icons/CollectionLock'
import LessonCount from '@/Components/Icons/LessonCount'
import Settings from '@/Components/Icons/Settings'
import CurriculumPlay from '@/Components/Icons/CurriculumPlay'
import CircleCheck from '@/Components/Icons/CircleCheck'
import UserProfile from '@/Components/Icons/UserProfile'
import Clock from '@/Components/Icons/Clock'
import GiftRibbon from '@/Components/Icons/GiftRibbon'
import Triangle from '@/Components/Icons/Triangle'
import GiftRibbonWhite from '@/Components/Icons/GiftRibbonWhite'
import TriangleActive from '@/Components/Icons/TriangleActive'
import Star from '@/Components/Icons/Star'
import Loader from '@/Components/Icons/Loader'
import Key from '@/Components/Icons/Key'
import Education from '@/Components/Icons/Education'
import Infinity from '@/Components/Icons/Infinity'
import SwiperRight from '@/Components/Icons/SwiperRight'
import SwiperLeft from '@/Components/Icons/SwiperLeft'
import AccordionMinus from '@/Components/Icons/AccordionMinus'
import AccordionPlus from '@/Components/Icons/AccordionPlus'
import LessonCountNew from '@/Components/Icons/LessonCountNew'
import Duration from '@/Components/Icons/Duration'
import LessonCountHover from '@/Components/Icons/LessonCountHover'
import DurationHover from '@/Components/Icons/DurationHover'
/**
 * Icon component for Create Academy
 * @displayName Icon
 */
export default {
  name: 'Icon',

  components: {
    Arrow,
    Basket,
    Close,
    Charity,
    Check,
    Chevron,
    Current,
    Delete,
    Email,
    Facebook,
    File,
    Finished,
    Gift,
    Google,
    Info,
    Instagram,
    Lesson,
    LessonPlan,
    Link,
    Linkedin,
    Lock,
    Menu,
    Pencil,
    PencilOnCircle,
    Play,
    PlayFull,
    Pinterest,
    Profile,
    Reply,
    Roundel,
    Search,
    SecureBadge,
    Success,
    Twitter,
    Unfinished,
    Upload,
    Video,
    Warning,
    Website,
    Youtube,
    Plus,
    BookMark,
    BookMarked,
    BookMarkNoStroke,
    CollectionCheck,
    CollectionDelete,
    CollectionLock,
    LessonCount,
    Settings,
    CurriculumPlay,
    CircleCheck,
    UserProfile,
    Clock,
    LockBanner,
    GiftRibbon,
    GiftRibbonWhite,
    Star,
    Triangle,
    TriangleActive,
    Loader,
    Key,
    Education,
    Infinity,
    SwiperRight,
    SwiperLeft,
    AccordionPlus,
    AccordionMinus,
    LessonCountNew,
    Duration,
    LessonCountHover,
    DurationHover
  },

  props: {
    /**
     * The selector of the icon. Name needs to match with imported icon components.
     * @values icon-name
     */
    name: {
      type: String,
      default: null,
      required: true
    },
    isStrokeIcon: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      iconComponent: this.name
    }
  }
}
</script>

<docs>
  ### ✎ How to add a new icon
  1. Make sure your icon is in a single path.
  2. Add a new component to `Components/Icons/NewIcon.vue` with the name of the Icon.
  3. Paste the svg inside the component you just createad and make sure the icon doesn't have inside any tag `fill=#color` inside
  4. Import the icon on Icon component
  5. Ready to use!

  ---

  ### ✎ Add an Icon
  The base icon naturally inherits the color and size of element it's inserted into. To see which icons are available see [IconList](/packs/#icon-list).

  ```jsx
    <Icon name="google"></Icon>
  ```
  ---

  ### ✎ Change Icon color
  The color of the icon can be switched by changing the text color. To see more info on how to change text-color in tailwind see [here](https://tailwindcss.com/docs/text-color).

  ```jsx
    <Icon name="google" class="text-grayscale-400 mr-2"></Icon>

    <Icon name="google" class="text-yellow-500 mr-2"></Icon>

    <Icon name="google" class="text-blue-800 mr-2"></Icon>
  ```
  ---

  ### ✎ Change Icon size
  The size of the icon can be switched by changing the text font size. To see more info on how to change text-color in tailwind see [here](https://tailwindcss.com/docs/font-size).

  ```jsx
    <Icon name="google" class="text-xs mr-2"></Icon>

    <Icon name="google" class="text-base mr-2"></Icon>

    <Icon name="google" class="text-4xl mr-2"></Icon>
  ```
  ---
</docs>
