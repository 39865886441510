<template>
  <div class="bg-white border-b border-red-500 py-6 lg:py-8">
    <div
      class="flex justify-between items-center cursor-pointer"
      @click="openQuestion"
    >
      <p
        class=" text-xs uppercase lg:text-base font-medium tracking-widest text-black opacity-75"
        v-html="question.q"
      />
      <Icon
        name="Chevron"
        class="text-red-500 text-xl ml-4 flex-shrink-0"
        :class="{ 'transform rotate-180': isToggleQuestion }"
      />
    </div>
    <div
      id="faq-answer-wrapper"
      data-testid="faq-answer-wrapper"
      class="mt-4"
      :class="{ hidden: !isToggleQuestion }"
    >
      <p
        data-testid="faq-answer"
        class="text-sm lg:text-base text-black font-normal"
        v-html="question.a"
      />
    </div>
  </div>
</template>

<script>
import gtm from '@/mixins/gtm'

import Icon from '@/Components/Shared/Icon'

export default {

  components: {
    Icon
  },
  mixins: [gtm],

  props: {
    question: {
      type: Object,
      required: true
    },
    gtmPageName: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      // toggle to open/close question
      isToggleQuestion: false
    }
  },

  methods: {
    openQuestion() {
      this.isToggleQuestion = !this.isToggleQuestion

      if (this.isToggleQuestion) {
        this.gtmAddFaqInteraction(this.question.q, this.gtmPageName)
      }
    }
  }
}
</script>
