<template>
  <div
    v-if="show"
    class="dialog-box"
    @click.self="close"
  >
    <div>
      <div class="dialog-content customModal">
        <div class="model-header relative gap-x-5">
          <div class="flex flex-col">
            <h4 class="text-lg uppercase font-medium text-left tracking-wider">
              {{ title }}
            </h4>
            <p class="text-sm font-normal text-left">
              {{ description }}
            </p>
          </div>
        </div>
        <div class="modal-content relative flex flex-col gap-y-5">
          <div class="flex items-center justify-center gap-4 mb-4">
            <button
              class="border border-1 button--small  uppercase"
              @click="close"
            >
              Cancel
            </button>
            <button
              class="button--small button--blue text-white uppercase"
              @click="handleRemove"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required:true
    },
    lessonId: {
      type: Number
    },
    edit: {
      type: Boolean
    },

     collection: {
      type: Object
    },

    handleOpenModel:Function
  },
  data() {
    return {
      show: false,
      collectionName: this.collection?.name
    }
  },
  methods: {
    open() {
      this.show = true
    },
    close() {
      this.handleOpenModel()
      this.show = false
    },


    handleRemove() {
        const path = this.$routes.user_collection(`${this.collection?.id}`)
        this.$inertia.delete(
          `${path}`,

          {
            preserveScroll: true,
            onSuccess: () => {
              this.show = false
              this.$routes.user_collections()
            },

            onError: () => {
              console.error('Failed to delete Lesson')
            }
          }
        )
    },
    handleChange(e) {
      this.collectionName = e
    }
  }
}
</script>
