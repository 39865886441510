<template>
  <div
    :id="containerUniqueId"
    class="w-full h-full"
  >
    <!-- Placeholder for mobile screens -->
    <div
      v-if="!isDesktop"
      class="relative w-full h-full cursor-pointer block md:hidden"
      @click="openVideoModal"
    >
      <img
        :src="thumbnailUrl"
        alt="Video Thumbnail"
        class="w-full h-full object-cover"
      >
      <span
        v-if="!isPlaying"
        class="absolute right-1.5 bottom-1.5 font-normal bg-blue-300 py-1 px-3 text-xs text-blue-800"
      >{{ instaId }}</span>
      <div class="absolute inset-0 flex items-center justify-center">
        <button class="p-4 outline-none focus:outline-none hover:outline-none">
          <svg
            class="w-9 h-9"
            width="53"
            height="59"
            viewBox="0 0 53 59"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M51.4983 26.9277L4.38722 0.377712C3.47444 -0.135587 2.36144 -0.123787 1.46044 0.401312C0.553556 0.932311 0 1.89991 0 2.95011V56.05C0 57.1002 0.553556 58.0678 1.46044 58.5988C1.91978 58.8643 2.43211 59 2.94444 59C3.43911 59 3.93967 58.8761 4.38722 58.6224L51.4983 32.0725C52.4229 31.5474 53 30.568 53 29.5001C53 28.4322 52.4229 27.4528 51.4983 26.9277Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
    </div>

    <!-- Video Modal -->
    <div
      :class="{
        'fixed inset-0 bg-black bg-opacity-80 z-50': !isDesktop && isModalOpen,
        relative: isDesktop
      }"
      class="w-full h-full flex items-center justify-center"
    >
      <div class="relative w-full h-full">
        <!-- Close Button for mobile screens -->
        <button
          v-if="!isDesktop && isModalOpen"
          class="absolute top-4 right-4 text-white z-10"
          @click="closeVideoModal"
        >
          <svg
            class="w-6 h-6"
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.6654 1.5L1.33203 19.5M18.6654 19.5L1.33203 1.5"
              stroke="black"
              stroke-linecap="square"
            />
          </svg>
        </button>

        <!-- Loading spinner -->
        <div
          v-if="isLoading"
          class="absolute inset-0 flex items-center justify-center bg-black"
        >
          <div
            class="w-8 h-8 border-4 border-blue-300 border-t-transparent rounded-full animate-spin"
          />
        </div>

        <iframe
          :id="playerUniqueId"
          :src="videoUrl"
          class="w-full h-full"
          allowfullscreen
          allow="autoplay; encrypted-media; picture-in-picture"
          :sandbox="iframeSandboxAttributes"
          loading="lazy"
          @load="onVideoLoad"
        />

        <span
          v-if="!isPlaying"
          class="absolute right-3 font-normal bottom-3 bg-blue-300 py-1 px-3 text-base text-blue-800"
        >{{ instaId }}</span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'VerticalVideo',
    props: {
      videoId: {
        type: String,
        required: true
      },
      referenceId: {
        type: String,
        required: true
      },
      imageId: {
        type: String,
        required: true
      },
      accountId: {
        type: String,
        required: true
      },
      playerId: {
        type: String,
        required: true
      },
      uniqueId: {
        type: String,
        required: true
      },
      instaId: {
        type: String,
        required: true
      }
    },

    emits: ['video-play'],
    data() {
      return {
        containerUniqueId: `container-${this.uniqueId}`,
        playerUniqueId: `player-${this.uniqueId}`,
        isModalOpen: false,
        isDesktop: window.innerWidth >= 768,
        isPlaying: false,
        thumbnailUrl: null,
        isLoading: false
      }
    },
    computed: {
      videoUrl() {
        const baseUrl = `https://players.brightcove.net/${this.accountId}/${this.playerId}_default/index.html?videoId=${this.videoId}`
        return !this.isDesktop && this.isModalOpen ? `${baseUrl}&autoplay=true` : baseUrl
      },

      iframeSandboxAttributes() {
        return 'allow-scripts allow-same-origin allow-presentation allow-popups allow-popups-to-escape-sandbox allow-forms'
      }
    },
    mounted() {
      this.loadThumbnail()
      window.addEventListener('resize', this.updateScreenSize)
    },
    beforeUnmount() {
      window.removeEventListener('resize', this.updateScreenSize)
    },

    methods: {
      loadThumbnail() {
        this.thumbnailUrl = `https://cf-images.eu-west-1.prod.boltdns.net/v1/static/${this.accountId}/${this.referenceId}/${this.imageId}/1280x720/match/image.jpg`
      },
      openVideoModal() {
        if (!this.isDesktop) {
          this.isLoading = true
          this.isModalOpen = true
          this.isPlaying = true
        }
      },
      closeVideoModal() {
        this.isModalOpen = false
        this.isPlaying = false
      },
      updateScreenSize() {
        this.isDesktop = window.innerWidth >= 768
      },
      onVideoLoad() {
        this.isLoading = false
        this.$emit('video-loaded')
      }
    }
  }
</script>

<style scoped>
  iframe {
    border: 0;
    aspect-ratio: 16 / 9;
    background: black;
  }
</style>
