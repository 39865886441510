<template>
  <div>
    <MetaTags :meta-tags="metaTags" />

    <div class="container-sm">
      <SerifHeader
        :title="$I18n.t('migration_title', i18nScope)"
        content-align="center"
      />

      <p
        class="text-xl  whitespace-pre-line mb-14"
        v-html="description"
      />

      <ButtonLink
        :href="$routes.new_user_migration()"
        class="mx-auto"
      >
        {{ $I18n.t('migration_cta', i18nScope) }}
      </ButtonLink>

      <Divider spacing="xl" />

      <template
        v-for="faq in faqs"
        :key="faq.id"
      >
        <h3
          v-if="faq.section.title"
          :key="faq.id"
          class="heading mb-8 text-yellow-800"
        >
          {{ faq.section.title }}
        </h3>
        <Faq
          class="mb-14"
          :questions="faq.section.questions"
          gtm-page-name="Migration"
        />

        <ButtonLink
          :href="$routes.new_user_migration()"
          class="mx-auto lg:hidden"
        >
          {{ $I18n.t('migration_cta', i18nScope) }}
        </ButtonLink>
      </template>
    </div>
  </div>
</template>
<script>
import { metaTags } from '@/mixins/metaTags'

import ButtonLink from '@/Components/Shared/ButtonLink'
import Divider from '@/Components/Shared/Divider'
import Faq from '@/Components/Shared/Faq/Index'
import MetaTags from '@/Components/Shared/MetaTags'
import SerifHeader from '@/Components/Shared/SectionHeader/Serif'

export default {

  components: {
    ButtonLink,
    Divider,
    Faq,
    MetaTags,
    SerifHeader
  },
  mixins: [
    metaTags
  ],

  props: {
    description: {
      type: String,
      default: () => { return 'We\'ve been working hard behind the scenes to create a new and improved website to take your Create Academy learning experience up a level. <br><br>Our old website was holding us back and limiting what we were able to offer you, so we’ve created a new platform that allows us to expand the way you can interact with our instructors and bring you new and improved learning experiences - all with the same beautifully curated content.<br><br><span class="font-normal">What do I need to do?</span><br>Simply click on the link below to set up your new account, follow the instructions on your screen to gain access to your previously purchased courses, and you\'re good to go.' }
    },
    faqs: {
      type: Array,
      default: () => [
        {
          'section': {
            'title': 'What else do you need to know',
            'questions': [
              {
                'q': 'Will this mean I\'ll lose my previously purchased courses?',
                'a': 'Absolutely not! You will be able to access all your existing courses, plus exciting new features and content.'
              },
              {
                'q': 'Will I still be able to watch courses on the old platform?',
                'a': 'No you won’t. That’s why it is so important for you to make this change. In order for Create Academy to keep providing you with our courses, new and old, you need to set up your account on the new platform.'
              },
              {
                'q': 'Will my course comments be moved to the new platform?',
                'a': 'We are unable to migrate course comments across to our new platform. We have, however, refreshed our course workbooks to include all of the recommended resources, tips, tricks and suppliers mentioned in our comments section so you don’t miss out.'
              },
              {
                'q': 'Where do I go if I need help?',
                'a': 'As with any new site there may be some teething problems, so we thank you in advance for your patience and support. We\'re on a mission to bring you the most joyful learning experiences going, so if there’s something you want the platform to be able to do, something you want to learn or someone brilliant you want to learn from, we want to know. Please contact our support at <a href="mailto:info@createacademy.com">info@createacademy.com</a> with any feedback or questions you may have.'
              }
            ]
          }
        }
      ]
    }
  },

  created() {
    this.i18nScope = { scope: 'pages.static' }
  }
}
</script>
