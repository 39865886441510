<template>
  <div class="section--overlay">
    <div class="bg-green-800">
      <div class="container py-12 lg:py-24 relative">
        <div>
          <h2
            class="text-3xl lg:text-4xl font-serif font-medium text-white text-center mb-0 lg:mb-3"
          >
            Over 400 Hours of Creative Online Learning
          </h2>
          <p
            class="text-center hidden lg:block text-base lg:text-xl text-white font-normal max-w-3xl mx-auto"
          >
            Discover our full library of courses, lessons and workbooks
          </p>
          <div class="mx-auto text-center">
            <ButtonLink
              data-testid="explore-all-courses-link"
              href="/courses"
              class="mx-auto w-full xs:w-auto md:w-96 mt-4 lg:mt-6 max-w-full"
              variant="red"
              inverse
            >
              EXPLORE ALL COURSES
            </ButtonLink>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-green-100 lg:bg-yellow-100">
      <div
        data-testid="home-about-section-image"
        class="flex flex-col w-full mx-auto"
      >
        <img
          width="1464"
          height="570"
          class="w-full h-80 lg:h-auto object-cover"
          :src="imageUrl('about-img.webp')"
          alt="Alt Home About Us"
        >
      </div>
      <div class="container py-12 lg:py-24 relative">
        <div class="flex flex-col gap-4 lg:gap-12">
          <div class="flex flex-col mb-2 lg:mb-0 gap-1 lg:gap-3">
            <p
              class="hidden lg:block mx-auto text-center text-base uppercase font-medium tracking-widest"
            >
              ABOUT US
            </p>
            <h2
              class="text-3xl lg:text-4xl font-serif font-medium text-black text-center text-balance mb-4"
            >
              The Create Academy story
            </h2>
            <p class="text-center text-base lg:text-xl text-black font-normal max-w-3xl mx-auto">
              Create Academy was founded with a passion for visual learning and a desire to uncover
              how exceptional creatives work. As creatives ourselves, we're building a platform that
              reimagines online learning—designed for creatives, by creatives—to inspire, connect,
              and encourage.
            </p>
          </div>
          <div class="grid grid-cols-12 gap-5 lg:gap-9">
            <div
              class="col-span-12 lg:col-span-4 xl:col-span-3 xl:col-start-2 xl:left-14 relative 2xl:left-0 2xl:col-span-2 2xl:col-start-4 flex flex-col items-center justify-center"
            >
              <div class="mb-2 lg:mb-6 text-center">
                <img
                  width="60"
                  height="80"
                  class="h-10 lg:h-20 mx-auto"
                  :src="imageUrl('illustration-img1.svg')"
                  alt="Variety Of Courses"
                >
              </div>
              <h4
                class="font-serif text-black mb-1 lg:mb-2 text-xl lg:text-3xl font-medium text-center"
              >
                Build Creative <br class="hidden lg:block">
                Confidence
              </h4>
              <!-- <p class="text-center text-sm lg:text-base text-black font-normal w-96 max-w-full mx-auto text-balance">Explore countless creative paths in home and garden, from interior design to craft and beyond.</p> -->
            </div>
            <div
              class="col-span-12 lg:col-span-4 xl:col-span-3 2xl:col-span-2 xl:left-14 relative 2xl:left-0 flex flex-col items-center justify-center"
            >
              <div class="mb-2 lg:mb-6 text-center">
                <img
                  width="60"
                  height="80"
                  class="h-10 lg:h-20 mx-auto"
                  :src="imageUrl('illustration-img2.svg')"
                  alt="Guidance"
                >
              </div>
              <h4
                class="font-serif text-black mb-1 lg:mb-2 text-xl lg:text-3xl font-medium text-center"
              >
                Leave A <br class="hidden lg:block">Positive Impact
              </h4>
              <!-- <p class="text-center text-sm lg:text-base text-black font-normal w-96 max-w-full mx-auto text-balance">Learn directly from industry legends who've mastered their craft through years of experience.</p> -->
            </div>
            <div
              class="col-span-12 lg:col-span-4 xl:col-span-3 2xl:col-span-2 xl:left-14 relative 2xl:left-0 flex flex-col items-center justify-center"
            >
              <div class="mb-2 lg:mb-6 text-center lg:h-20 flex items-center justify-center">
                <img
                  width="60"
                  height="80"
                  class="h-10 w-auto lg:w-16 lg:h-auto mx-auto"
                  :src="imageUrl('illustration-img3.png')"
                  alt="Transformational Advice"
                >
              </div>
              <h4
                class="font-serif text-black mb-1 lg:mb-2 text-xl lg:text-3xl font-medium text-center"
              >
                Grant <br class="hidden lg:block">
                Access
              </h4>
              <!-- <p class="text-center text-sm lg:text-base text-black font-normal w-96 max-w-full mx-auto text-balance">Turn expert knowledge into real-world skills that uplift your everyday life and experiences.</p> -->
            </div>
          </div>
          <div class="mx-auto text-center w-full">
            <ButtonLink
              data-testid="find-out-more-link"
              href="/about-us"
              class="mx-auto w-full xs:w-auto md:w-96 max-w-full"
              variant="red"
            >
              FIND OUT MORE
            </ButtonLink>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import mixpanel from '@/mixins/mixpanel'
  import ButtonLink from '@/Components/Shared/ButtonLink'
  import cdn from '@/mixins/cdn'

  export default {
    components: {
      ButtonLink
    },

    mixins: [cdn],

    created() {
      this.i18nScope = { scope: 'pages.home.section_titles' }
    }
  }
</script>
