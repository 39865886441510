<template>
  <div
    data-testid="checkout-error-modal"
    class="checkout-error-modal bg-grayscale-100 p-8 relative"
  >
    <div class="absolute top-6 right-6">
      <button
        data-testid="checkout-error-modal-close"
        @click="handleClose"
      >
        <Icon
          name="close"
          class="text-2xl text-red-800"
        />
      </button>
    </div>
    <div class="flex flex-col items-center text-center">
      <Icon
        name="Error"
        class="title-xl text-red-800 mb-2"
      />
      <h1
        data-testid="checkout-error-modal-title"
        class="title-medium text-red-800 mb-6"
      >
        {{ $I18n.t('error_title', i18nScope) }}
      </h1>
      <p class="mb-6">
        {{ errorMessage }}
      </p>
        
      <Button
        data-testid="checkout-error-modal-button"
        class="mx-auto"
        @click="handleClose"
      >
        {{ $I18n.t('button_close', i18nScope) }}
      </Button>
    </div>
  </div>
</template>
  
  <script>
  import Icon from '@/Components/Shared/Icon'
  import Button from '@/Components/Shared/Button'
  import { closeModal } from 'jenesius-vue-modal'
  
  export default {
    components: {
      Icon,
      Button
    },
    
    props: {
      errorMessage: {
        type: String,
        required: true
      }
    },
  
    created() {
      this.i18nScope = { scope: 'pages.membership.checkout_error_modal' }
    },
  
    methods: {
      handleClose() {
        closeModal()
      }
    }
  }
  </script>
<style>
  .modal-item:has(.checkout-error-modal) {
    width: 95% !important;
    max-width: 500px !important;
  }
</style>