<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.5546 5.61744C12.7112 3.46086 16.1762 3.46086 18.3328 5.61744C19.4615 6.74619 20.0357 8.27753 19.9235 9.76879C19.8651 11.0692 19.3542 12.3742 18.3328 13.3956L17.6256 12.6885C18.4601 11.854 18.8783 10.7881 18.9249 9.71707L18.9252 9.70874L18.9259 9.70044C19.0174 8.51041 18.5593 7.25823 17.6256 6.32454C15.8596 4.55849 13.0277 4.55849 11.2617 6.32454L10.5546 5.61744ZM13.3828 18.345C11.2262 20.5016 7.76118 20.5016 5.6046 18.345C4.47585 17.2163 3.90167 15.685 4.01382 14.1937C4.07228 12.8933 4.58315 11.5883 5.60461 10.5669L6.31171 11.274C5.47725 12.1084 5.05907 13.1743 5.0125 14.2454L5.01214 14.2537L5.0115 14.262C4.91996 15.4521 5.37802 16.7043 6.31171 17.6379C8.07777 19.404 10.9096 19.404 12.6757 17.6379L13.3828 18.345ZM15.5043 7.73853L8.07966 15.1631L8.78677 15.8703L16.2114 8.44564L15.5043 7.73853Z"
    />
  </svg>
</template>

<docs>
  To know more information on how to use icons see [here](/packs/#Icon)

  ```jsx
    <Icon name="Link" class="text-4xl"></Icon>
  ```
</docs>
