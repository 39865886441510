<template>
  <div class="comment">
    <div class="comment-wrapper flex flex-row">
      <div class="comment__avatar w-10 h-10 md:w-16 md:h-16 mr-4 flex-shrink-0">
        <!-- CUSTOMER AVATAR -->
        <img
          loading="lazy"
          src="https://cdn.dribbble.com/users/59947/screenshots/15720035/media/89ae3a971ca23b399d75319fce5e4532.jpg?compress=1&resize=600x450"
          class="image-cover rounded-sm"
        >
      </div>

      <div class="comment__content">
        <div class="comment_header flex flex-row justify-between flex-wrap mb-2">
          <!-- NAME OF THE CUSTOMER -->
          <p class="font-normal text-yellow-500 mr-4">
            <!-- IF CUSTOMER IS ADMIN ON CA -->
            <!-- <span v-if="" class="bg-yellow-500 text-grayscale-100 text-xs p-1 rounded-sm uppercase mr-2">Team Member</span> -->
            Alison Brie Larson
          </p>

          <div class="flex flex-row">
            <!-- DATE OF THE COMMENT - HUMANIZED -->
            <p class="font-normal text-inactive text-sm mr-4">
              a month ago
            </p>

            <!-- IF REPLY = SHOW .comment-reply-editor -->
            <Icon
              name="Reply"
              class="text-lg text-yellow-500 mr-4 cursor-pointer"
            />

            <!-- IF LINK = COPY TO CLIPBOARD LINK TO THE COMMENT -->
            <Icon
              name="Link"
              class="text-lg text-blue-800 cursor-pointer"
            />
          </div>
        </div>

        <!-- COMMENT CONTENT -->
        <p>Hi, we are just about to organise a new kitchen at home, and we live what we can see of Amelias kitchen. Would there be any zoomed out pictures to see more of the range wall and the chimney detail? Also love the colour of the units. Would you be able to let me know the colour used (sorry to merge the Rita Koenig course with that of Amelia, enjoying both !)</p>

        <!-- IF COMMENT HAS IMAGES -->
        <div class="comment__images flex flex-row flex-wrap mt-4">
          <img
            loading="lazy"
            src="https://cdn.dribbble.com/users/403485/screenshots/15720802/media/66fb7f8014d69f0c27c6954c3a156fc3.png?compress=1&resize=1000x750"
            class="w-10 h-auto md:w-16  mr-2 flex-shrink-0 object-cover rounded-sm"
          >
          <img
            loading="lazy"
            src="https://cdn.dribbble.com/users/918572/avatars/small/fa6cd206db7d348977249cc44aa6b04a.jpg?1601154271"
            class=" w-10 h-auto md:w-16  mr-2 flex-shrink-0 object-cover rounded-sm"
          >
          <img
            loading="lazy"
            src="https://cdn.dribbble.com/users/59947/screenshots/15720035/media/89ae3a971ca23b399d75319fce5e4532.jpg?compress=1&resize=600x450"
            class=" w-10 h-auto md:w-16  mr-2 flex-shrink-0 object-cover rounded-sm"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/Components/Shared/Icon'

export default {
  components: {
    Icon
  }
}
</script>
