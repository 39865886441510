<template>
  <div>
    <TextNote class="mb-10">
      <Link
        data-testid="link-signin"
        :href="$routes.new_user_session()"
      >
        {{ $I18n.t('sign_in', i18nScope) }}
      </Link>
    </TextNote>

    <h1 class="title-large text-blue-800 mb-6">
      {{ $I18n.t('title', i18nScope) }}
    </h1>

    <form
      novalidate="true"
      @submit.prevent="form.put($routes.user_password())"
    >
      <InputGroup
        v-model="form.user.reset_password_token"
        testid="reset_password_token"
        :errors="form.errors.reset_password_token"
        :hidden="true"
      />

      <InputGroup
        v-model="form.user.password"
        testid="password"
        :errors="form.errors.password"
        :label="$I18n.t('form.inputs.password.label', i18nScope)"
        :placeholder="$I18n.t('form.inputs.password.placeholder', i18nScope)"
        type="password"
      />

      <Button
        data-testid="button-submit"
        :loading="form.processing"
        type="submit"
        block
      >
        {{ $I18n.t('form.button', i18nScope) }}
      </Button>
    </form>
  </div>
</template>

<script>
import { Link } from '@inertiajs/vue3'
import InputGroup from '@/Components/Shared/Forms/InputGroup'
import Button from '@/Components/Shared/Button'
import TextNote from '@/Components/Shared/TextNote'

export default {

  components: {
    Link,
    InputGroup,
    Button,
    TextNote
  },

  props: {
    resetPasswordToken: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      form: this.$inertia.form({
        user: {
          reset_password_token: this.resetPasswordToken,
          password: null
        }
      })
    }
  },
  created() {
    this.i18nScope = { scope: 'pages.auth.set_password' }
  }
}
</script>
