<template>
  <svg
    width="47"
    height="48"
    viewBox="0 0 47 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Group 1707479002">
      <g id="Group 1707479005">
        <g id="Group 3235">
          <rect
            id="Rectangle 2872"
            width="46.4"
            height="48"
            rx="23.2"
            fill="white"
            fill-opacity="0.68"
          />
        </g>
        <g id="Group 1707479004">
          <path
            id="Icon"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M30.7023 11.6287C31.5397 10.7909 32.8977 10.7903 33.7358 11.6275L37.4372 15.3246C38.2756 16.162 38.2762 17.5207 37.4385 18.3588L19.9654 35.8408C19.6666 36.1398 19.2861 36.3438 18.8718 36.4273L11.0664 38L12.642 30.2041C12.7255 29.7906 12.9292 29.4109 13.2274 29.1126L30.7023 11.6287Z"
            fill="#0E1E38"
          />
          <line
            id="Line 311"
            x1="28.8975"
            y1="14.9775"
            x2="34.0898"
            y2="20.1698"
            stroke="white"
            stroke-width="0.5"
          />
        </g>
      </g>
    </g>
  </svg>
</template>