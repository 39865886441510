import AuthModal from '@/Components/Shared/AuthModal'
import { openModal } from 'jenesius-vue-modal'

export default {
  methods: {
    openSignInModal(nextRoute, contentTextKey = undefined) {
      let path = nextRoute || this.$router.currentRoute.path // Use path instead of fullPath
      let queryParameters = `?origin=${encodeURIComponent(path)}`

      if (!this.currentUser) {
        queryParameters += '&authentication=Standard' // Append additional parameters
      }

      openModal(AuthModal, {
        contentTextKey: contentTextKey,
        next: path,
        queryParameters: queryParameters
      })//, {
//        width: '95%',
//        maxWidth: 500
//      })
    }
  }
}
