<template>
  <svg
    width="33"
    height="34"
    viewBox="0 0 33 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.9575 20.7502L16.3325 18.8752V11.035M31.3325 17.0002C31.3325 25.2845 24.6168 32.0002 16.3325 32.0002C8.04825 32.0002 1.33252 25.2845 1.33252 17.0002C1.33252 8.71597 8.04825 2.00024 16.3325 2.00024C24.6168 2.00024 31.3325 8.71597 31.3325 17.0002Z"
      stroke="#003319"
      stroke-width="2.107"
      stroke-linecap="square"
    />
  </svg>
</template>

  <docs>
    To know more information on how to use icons see [here](/packs/#Icon)

    ```jsx
      <Icon name="Arrow" class="text-4xl"></Icon>
    ```
  </docs>
