<template>
  <div class="navbar-mobile mr-6">
    <!-- Menu -->
    <div class="navbar-mobile__menu">
      <div
        class="menu__toggler flex items-center"
        @click="toggleGeneralMenu"
      >
        <Icon
          name="Menu"
          class="text-white"
        />
      </div>

      <GeneralMenu
        :categories="categories"
        :is-toggle-general-menu="isToggleGeneralMenu"
        :seasonal-is-active="seasonalIsActive"
        @toggle-general-menu="toggleGeneralMenu"
        @toggle-seasonal-menu="toggleSeasonalMenu"
      />

      <SeasonalMenu
        v-if="seasonalIsActive"
        :categories="categories"
        :is-toggle-seasonal-menu="isToggleSeasonalMenu"
        @toggle-general-menu="toggleGeneralMenu"
        @toggle-seasonal-menu="toggleSeasonalMenu"
      />
    </div>
  </div>
</template>

<script>

import currentUser from '@/mixins/currentUser'
import overflowLogic from '@/mixins/overflowLogic'
import { router } from '@inertiajs/vue3'

import Icon from '@/Components/Shared/Icon'
import GeneralMenu from '@/Components/Shared/Objects/Navbar/Mobile/GeneralMenu'
import SeasonalMenu from '@/Components/Shared/Objects/Navbar/Mobile/SeasonalMenu'
import AccountMenu from '@/Components/Shared/Objects/Navbar/Mobile/AccountMenu'

export default {

  components: {
    Icon,
    GeneralMenu,
    SeasonalMenu
  },
  mixins: [currentUser, overflowLogic],

  props: {
    categories: Array,
    seasonalIsActive: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      isToggleGeneralMenu: false,
      isToggleSeasonalMenu: false
    }
  },

  created() {
    this.i18nScope = { scope: 'pages.navbar' }
    this.removeNavigateListener = router.on('navigate', () => {
      this.isToggleGeneralMenu = false
      if (this.isToggleSeasonalMenu) {
        this.setScrollOverflow('hidden')
      } else {
        this.setScrollOverflow('auto')
      }
    })
  },

  beforeUnmount() {
    this.removeNavigateListener()
  },

  methods: {
    toggleGeneralMenu() {
      this.isToggleGeneralMenu = !this.isToggleGeneralMenu
    },

    toggleSeasonalMenu() {
      this.isToggleSeasonalMenu = !this.isToggleSeasonalMenu
    }
  }
}
</script>
