<template>
  <div
    data-testid="category-banner-strip"
    class="w-full relative"
    :class="[backgroundColor, textColor]"
  >
    <div class="flex flex-col py-12 px-6 text-center">
      <p
        data-testid="category-banner-strip-title"
        class="bigheading mb-2"
      >
        {{ bannerStrip.title }}
      </p>
      <p
        data-testid="category-banner-strip-text"
        class="text-xl tracking-wide"
      >
        {{ bannerStrip.text }}
        <span
          v-if="bannerStrip.highlightedText"
          data-testid="category-banner-strip-highlightedText"
          class="text-lg uppercase underline"
        >{{ bannerStrip.highlightedText }}</span>
      </p>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    bannerStrip: {
      type: Object,
      required: true
    }
  },

  computed: {
    backgroundColor() {
      return `bg-${this.bannerStrip.backgroundColor}`
    },

    textColor() {
      return `text-${this.bannerStrip.textColor}`
    }
  }
}
</script>
