<template>
  <li
    :key="instructor.id"
    class="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3 flex flex-col items-center"
  >
    <div class="flex flex-col items-center">
      <Link
        :data-testid="`${instructor.id}-link`"
        :href="$routes.instructor(instructor.slug)"
        class="flex flex-col items-center"
      >
        <div class="w-64 h-64 sm:w-72 sm:h-72 md:w-60 md:h-60 xl:w-80 xl:h-80 rounded-full bg-grayscale-300 mb-8">
          <img
            loading="lazy"
            :data-testid="`${instructor.id}-avatar`"
            :src="instructor.avatar"
            :alt="$I18n.t('instructor_avatar', i18nScope)"
            class="image-cover w-64 h-64 sm:w-72 sm:h-72 md:w-60 md:h-60 xl:w-80 xl:h-80 rounded-full"
          >
        </div>
        <span class="w-full block title-medium text-grayscale-900 text-center mb-0 hover:underline">
          {{ instructor.name }}
        </span>
        <span class="text-sm font-normal tracking-wide uppercase text-grayscale-500 block text-center w-64 sm:w-72 md:w-60 xl:w-80">
          {{ instructor.shortByline ? instructor.shortByline : instructor.byline }}
        </span>
      </Link>
    </div>
  </li>
</template>

<script>
import { Link } from '@inertiajs/vue3'

export default {
  components: { Link },
  props: ['instructor']
}
</script>
