<template>
  <div
    class="input-group"
    :required="required || null"
  >
    <label
      v-if="label"
      class="block mb-2"
    >{{ label }}</label>

    <div
      v-for="option in options"
      :key="option"
    >
      <input
        :id="inputId(option)"
        v-model="temp"
        :data-testid="inputTestId(option)"
        :value="option"
        type="checkbox"
        class="input-checkbox"
      >

      <label
        :for="inputId(option)"
        :data-testid="labelTestId(option)"
      >
        <span>{{ option }}</span>
      </label>
    </div>

    <InputError
      :testid="testid"
      :errors="errors"
    />
  </div>
</template>

<script>
import InputError from '@/Components/Shared/Forms/InputError'

/**
 * CheckboxGroup component for Create Academy
 * @displayName CheckboxGroup
 */
export default {
  name: 'CheckboxGroup',

  components: {
    InputError
  },

  props: {
    /**
     * The value for the input
     */
    modelValue: {
      type: Array,
      default: null
    },
    /**
     * The label for the input - this should be more short and informative.
     */
    label: {
      type: String,
      default: null
    },
    /**
     * Required field
     */
    required: {
      type: Boolean,
      default: false
    },
    /**
     * The options
     */
    selectedOptions: {
      type: Array,
      default: () => []
    },
    /**
     * The options
     */
    options: {
      type: Array,
      default: () => []
    },
    errors: {
      type: Array,
      default: () => []
    },
    testid: String
  },

  // Move emits outside of props
  emits: ['update:selectedOptions'],

  computed: {
    temp: {
      get() {
        return this.selectedOptions
      },
      set(newValue) {
        this.$emit('update:selectedOptions', newValue)
      }
    }
  },

  methods: {
    inputId(option) {
      return `checkbox-input-${option}`
    },
    inputTestId(option) {
      return `input-${this.testid}-${option.replace(/\s/g, '')}`
    },
    labelTestId(option) {
      return `label-${this.testid}-${option.replace(/\s/g, '')}`
    }
  }
}
</script>

<docs>
  ### ✎ CheckboxGroup

  ```jsx
    <CheckboxGroup :options="['Food', 'Interior Design', 'Garden']" label="This is my list of preferences" />
  ```
</docs>
