<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M20.103 21.9724L20.603 21.9724L20.603 21.4724L20.603 2.52759L20.603 2.02759L20.103 2.02759L9.70462 2.02759L9.48878 2.02759L9.34074 2.18466L7.01335 4.65421L4.54366 7.12393L4.39722 7.27038L4.39722 7.47748L4.39722 21.4724L4.39722 21.9724L4.89722 21.9724L20.103 21.9724ZM19.603 3.02759L19.603 20.9724L5.39722 20.9724L5.39722 8.01318L9.77588 8.01318L10.2759 8.01318L10.2759 7.51318L10.2759 3.02759L19.603 3.02759ZM9.27588 3.80598L9.27588 7.01318L6.06868 7.01318L9.27588 3.80598Z" />
  </svg>
</template>

<docs>
  To know more information on how to use icons see [here](/packs/#Icon)

  ```jsx
    <Icon name="File" class="text-4xl"></Icon>
  ```
</docs>
