<template>
  <div>
    <MetaTags :meta-tags="metaTags" />

    <div data-testid="banner-section">
      <Banner
        uid="contact-us"
        :image-src="ContactUsBanner"
        height="small"
        content-align="center"
        image-alt="Contact Us Banner"
      />
    </div>

    <div class="container-sm">
      <div v-if="formSubmitted">
        <h2 class="text-left mb-4 title-large">
          Thank you
        </h2>
        <p class="mb-4">
          We'll aim to get back to you within 48 hours from Monday to Friday.
        </p>
        <p class="mb-4">
          Enquiries sent over the weekend may take a little longer.
        </p>
      </div>
      <div v-else>
        <h1
          data-testid="contact-us-title"
          class="text-left mb-4 title-large"
        >
          Contact Us
        </h1>
        <p class="mb-8">
          Need help? Fill in the form below and a member of our team will be in touch to assist you.
        </p>

        <div class="flex flex-col md:flex-row">
          <div class="w-full md:w-2/3">
            <form @submit.prevent="submitForm">
              <p class="text-sm text-gray-600 mb-4">
                All fields are required
              </p>

              <div class="mb-4">
                <label
                  for="name"
                  class="block mb-2"
                >Full name <span class="text-red-500">*</span></label>
                <input
                  id="name"
                  v-model="form.name"
                  type="text"
                  class="w-full p-2 border rounded"
                  required
                >
              </div>
              <div class="mb-4">
                <label
                  for="email"
                  class="block mb-2"
                >Email <span class="text-red-500">*</span></label>
                <input
                  id="email"
                  v-model="form.email"
                  type="email"
                  class="w-full p-2 border rounded"
                  required
                  :disabled="isLoggedIn || null"
                >
              </div>
              <div class="mb-8">
                <label class="block mb-2">How can we help? <span class="text-red-500">*</span></label>
                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  <button
                    v-for="option in categoryOptions"
                    :key="option.value"
                    type="button"
                    class="p-4 bg-gray-100 hover:bg-gray-200 rounded-lg text-left transition duration-150 ease-in-out flex flex-col items-center"
                    :class="{ 'ring-2 ring-blue-500': form.category === option.value }"
                    @click="selectCategory(option.value)"
                  >
                    <span class="text-sm font-medium">{{ option.label }}</span>
                  </button>
                </div>
              </div>

              <div class="mb-4">
                <label
                  for="message"
                  class="block mb-2"
                >Message <span class="text-red-500">*</span></label>
                <textarea
                  id="message"
                  v-model="form.message"
                  rows="5"
                  class="w-full p-2 border rounded"
                  required
                />
              </div>
              <div
                v-if="errors"
                class="text-red-500 mt-2"
              >
                <p
                  v-for="(error, field) in errors"
                  :key="field"
                >
                  {{ field }}: {{ error.join(', ') }}
                </p>
              </div>
              <button
                type="submit"
                class="button--light-blue text-white tracking-widest px-4 py-2"
                :disabled="submitting || null"
              >
                {{ submitting ? 'Submitting...' : 'SUBMIT' }}
              </button>
            </form>
          </div>
          <div class="hidden md:block md:w-1/3 pl-20 mb-8 md:mb-0">
            <p class="mb-2">
              90 Wallis Road,
            </p>
            <p class="mb-2">
              London E9 5LN
            </p>
            <p>
              <a
                href="mailto:info@createacademy.com"
                class="text-blue-600 hover:underline"
              >
                info@createacademy.com
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { metaTags } from '@/mixins/metaTags'
  import MetaTags from '@/Components/Shared/MetaTags'
  import Banner from '@/Components/Shared/Banner'
  import Layout from '@/Layouts/TransparentLayout'

  export default {

    components: {
      Banner,
      MetaTags
    },

    mixins: [metaTags],
    layout: Layout,

    props: {
      user: Object,
      errors: Object,
      formSubmitted: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        form: {
          name: '',
          email: '',
          category: '',
          message: ''
        },
        browserInfo: this.getBrowserInfo(),
        categoryOptions: [
          { value: 'billing_enquiry', label: 'Billing' },
          { value: 'course', label: 'Course question' },
          { value: 'press', label: 'Press enquiry' },
          { value: 'website_feedback', label: 'Technical issue' },
          { value: 'work_with_you', label: 'Work with us' },
          { value: 'other', label: 'Other' }
        ],
        submitting: false,
        metaTags: {
          title: 'Contact Us | Create Academy',
          description:
            'Contact Create Academy - Get in touch with our team for support, inquiries, and more.',
          keywords: 'contact, support, help, create academy, inquiries'
        },
        ContactUsBanner: {
          url: '',
          sizes: {}
        }
      }
    },

    computed: {
      currentPage() {
        return 'Contact Us'
      },

      pageTitle() {
        return `${this.currentPage} | Create Academy`
      },

      isLoggedIn() {
        return !!this.user
      }
    },

    watch: {
      formSubmitted(newValue) {
        if (newValue) {
          this.$nextTick(() => {
            this.scrollToTop()
          })
        }
      }
    },

    mounted() {
      if (this.formSubmitted) {
        this.scrollToTop()
      }
      document.title = this.pageTitle
    },

    created() {
      // Pre-fill form fields if user is logged in
      if (this.user) {
        this.form.name = this.user.name || ''
        this.form.email = this.user.email || ''
      }

      this.ContactUsBanner = {
        url: require('@/assets/images/backgrounds/contact-us-large.jpg'),
        sizes: {
          large: require('@/assets/images/backgrounds/contact-us-large.jpg'),
          medium: require('@/assets/images/backgrounds/contact-us-medium.jpg'),
          small: require('@/assets/images/backgrounds/contact-us-small.jpg')
        }
      }

      // Set meta tags
      const pageTitle = `${this.currentPage} | Create Academy`

      // Use Inertia's page props
      if (this.$page && this.$page.props) {
        this.$page.props.metaTitle = pageTitle
      }

      this.setMetaTags({
        title: pageTitle,
        description:
          'Contact Create Academy - Get in touch with our team for support, inquiries, and more.',
        keywords: 'contact, support, help, create academy, inquiries'
      })
    },

    methods: {
      getBrowserInfo() {
        return {
          userAgent: navigator.userAgent,
          language: navigator.language,
          platform: navigator.platform,
          screenWidth: window.screen.width,
          screenHeight: window.screen.height
        }
      },
      selectCategory(category) {
        this.form.category = category
      },
      submitForm() {
        this.submitting = true
        const formData = {
          ...this.form,
          browserInfo: this.getBrowserInfo()
        }

        this.$inertia.post('/contact-us', formData, {
          onSuccess: () => {
            this.submitting = false
          },
          onError: (errors) => {
            this.submitting = false
          }
        })
      },

      scrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }
    }
  }
</script>

<style scoped>
  .text-red-500 {
    color: #ef4444;
    font-size: 0.75em;
    vertical-align: top;
  }
</style>
