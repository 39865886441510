<template>
  <svg
    width="31"
    height="29"
    viewBox="0 0 31 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.839844 14.5C10.9932 14.5 31.217 14.5 30.8847 14.5"
      stroke="#6D1623"
    />
    <path
      d="M15.8633 0.518066C15.8633 9.96683 15.8633 28.787 15.8633 28.4778"
      stroke="#6D1623"
    />
  </svg>
</template>

<docs>
  To know more information on how to use icons see [here](/packs/#Icon)

  ```jsx
    <Icon name="Check" class="text-4xl"></Icon>
  ```
</docs>
