import { reactive } from 'vue'

export const metaTags = {
  data() {
    return {
      metaTags: reactive({
        title: '',
        description: '',
        keywords: '',
        noindex: false,
        schema: null
      })
    }
  },

  methods: {
    setMetaTags(tags) {
      Object.assign(this.metaTags, tags)
    }
  }
}
