<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M16.2945 8.47655H13.1301V6.4022C13.1301 5.62062 13.6455 5.43895 14.0089 5.43895C14.3722 5.43895 16.2438 5.43895 16.2438 5.43895V2.01267L13.1681 2C9.75454 2 8.97719 4.55598 8.97719 6.19096V8.47655H7V12.0084H8.97296C8.97296 16.5416 8.97296 22 8.97296 22H13.1259C13.1259 22 13.1259 16.4867 13.1259 12.0084H15.9269L16.2945 8.47655Z" />
  </svg>
</template>

<docs>
  To know more information on how to use icons see [here](/packs/#Icon)

  ```jsx
    <Icon name="Facebook" class="text-4xl"></Icon>
  ```
</docs>
