<template>
  <div
    v-if="show"
    data-testid="countdown"
    class="inline"
  >
    <span data-testid="general-announcement-countdown-pre-text">
      {{ genAnnouncement.countdownPreText }} {{ formattedDays }}:{{ formattedHours }}:{{
        formattedMinutes
      }}:{{ formattedSeconds }}
    </span>
  </div>
</template>

<script>
  import { ref, computed, onMounted, onBeforeUnmount } from 'vue'

  export default {
    name: 'Countdown',
    props: {
      genAnnouncement: {
        type: Object,
        required: true
      }
    },
    setup(props) {
      const days = ref(0)
      const hours = ref(0)
      const minutes = ref(0)
      const seconds = ref(0)
      let intervalId = null

      const parseDate = dateString => {
        if (!dateString) return null

        // First try direct parsing
        let parsed = new Date(dateString).getTime()
        if (!isNaN(parsed)) return parsed

        // Split the date string into components
        const cleanDate = dateString
          .replace('T', ' ')
          .replace(/-/g, '/')
          .replace(/\s+/g, ' ')
          .trim()

        // Try parsing the cleaned date
        parsed = new Date(cleanDate).getTime()
        if (!isNaN(parsed)) return parsed

        // If all else fails, try manual parsing
        const [datePart, timePart] = cleanDate.split(' ')
        if (!datePart) return null

        const [year, month, day] = datePart.split('/').map(n => parseInt(n, 10))
        const [hours, minutes, seconds] = (timePart || '00:00:00')
          .split(':')
          .map(n => parseInt(n, 10))

        // Month is 0-based in JavaScript Date
        const manualDate = new Date(year, month - 1, day, hours || 0, minutes || 0, seconds || 0)
        parsed = manualDate.getTime()
        return !isNaN(parsed) ? parsed : null
      }

      const showAtDate = computed(() => parseDate(props.genAnnouncement.countdownStartDate))

      const hideAtDate = computed(() => parseDate(props.genAnnouncement.countdownEndDate))

      const show = computed(() => {
        const dateNow = Date.now()
        if (!props.genAnnouncement.campaignEndDate) return false
        if (showAtDate.value && dateNow < showAtDate.value) return false
        if (hideAtDate.value && dateNow > hideAtDate.value) return false
        return true
      })

      const updateCountdown = () => {
        const now = Date.now()
        const endTime = parseDate(props.genAnnouncement.campaignEndDate)

        if (!endTime) {
          clearInterval(intervalId)
          days.value = hours.value = minutes.value = seconds.value = 0
          return
        }

        const distance = endTime - now

        if (distance < 0) {
          clearInterval(intervalId)
          days.value = hours.value = minutes.value = seconds.value = 0
          return
        }

        days.value = Math.floor(distance / (1000 * 60 * 60 * 24))
        hours.value = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        minutes.value = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
        seconds.value = Math.floor((distance % (1000 * 60)) / 1000)
      }

      const formatTime = time => {
        return time < 10 ? `0${time}` : time
      }

      const formattedDays = computed(() => formatTime(days.value))
      const formattedHours = computed(() => formatTime(hours.value))
      const formattedMinutes = computed(() => formatTime(minutes.value))
      const formattedSeconds = computed(() => formatTime(seconds.value))

      // Add this test function in your setup() to verify parsing
      const testDateParsing = () => {
        const testDates = [
          '2024-03-20T10:00:00',
          '2024/03/20T10:00:00',
          '2024-03-20 10:00:00',
          '2024/03/20 10:00:00',
          props.genAnnouncement.campaignEndDate // your actual date
        ]

        console.log('Date parsing test results:')
        testDates.forEach(date => {
          const parsed = parseDate(date)
          console.log(`${date} -> ${parsed} (${parsed ? 'valid' : 'INVALID'})`)
        })
      }

      onMounted(() => {
        testDateParsing() // Add this line after your existing onMounted code
        updateCountdown()
        intervalId = setInterval(updateCountdown, 1000)
      })

      onBeforeUnmount(() => {
        clearInterval(intervalId)
      })

      return {
        formattedDays,
        formattedHours,
        formattedMinutes,
        formattedSeconds,
        show
      }
    }
  }
</script>
