import Appsignal from '@appsignal/javascript'

const environment = process.env.RAILS_ENV || process.env.NODE_ENV || 'development'

const appsignalKeys = {
  development: '7624fc8e-5260-4f0d-b9b4-99c5324db5ad',
  staging: '0b69f09c-2a8d-4426-bd6f-51eb9e65eca8',
  production: 'f3b7880f-f24d-4eba-8f0f-aacf01ace030'
}

export const appsignal = new Appsignal({
  key: appsignalKeys[environment] || appsignalKeys.development
})