<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.35355 10.9392L6.58571 14.1715L7.29282 13.4644L4.91419 11.0857L15.5584 11.0857V11.0739H17.1744C18.6913 11.0739 19.9066 12.2892 19.9066 13.8061C19.9066 14.6104 19.5644 15.3422 19.0195 15.8093L19.0132 15.8147L19.0071 15.8203C18.5265 16.2609 17.89 16.5382 17.1744 16.5382H13.4036V17.5382H17.1744C18.1544 17.5382 19.0255 17.1574 19.6767 16.5631C20.4491 15.8976 20.9066 14.8862 20.9066 13.8061C20.9066 11.7369 19.2436 10.0739 17.1744 10.0739H15.5584V10.0857L4.91422 10.0857L7.29282 7.70711L6.58571 7L3.35355 10.2321L3 10.5857L3.35355 10.9392Z"
    />
  </svg>
</template>

<docs>
  To know more information on how to use icons see [here](/packs/#Icon)

  ```jsx
    <Icon name="Reply" class="text-4xl"></Icon>
  ```
</docs>
