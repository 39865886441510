<template>
  <svg
    width="36"
    height="30"
    viewBox="0 0 36 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36.0003 3.82069C34.6405 4.42502 33.2053 4.80272 31.77 4.9538C33.2808 4.04732 34.4895 2.61205 35.0182 0.874618C33.583 1.70556 31.9966 2.30988 30.3347 2.68759C27.5397 -0.258495 22.8562 -0.409571 19.9101 2.38543C18.3993 3.74516 17.5684 5.7092 17.5684 7.7488C17.5684 8.35312 17.6439 8.88192 17.7195 9.4107C11.8273 9.10854 6.2373 6.31353 2.53581 1.7811C0.571755 5.10489 1.55378 9.4107 4.80203 11.6014C3.59338 11.6014 2.46027 11.2237 1.47825 10.6949V10.7704C1.47825 14.2453 3.97107 17.3425 7.3704 18.0223C6.31283 18.3245 5.17973 18.3245 4.04662 18.1734C5.02865 21.195 7.74811 23.2346 10.9208 23.3102C8.27689 25.3497 5.10419 26.4829 1.7804 26.4829C1.17608 26.4829 0.571753 26.4829 0.0429688 26.4073C3.44229 28.598 7.3704 29.7311 11.3741 29.7311C24.9713 29.7311 32.3743 18.4756 32.3743 8.73083C32.3743 8.42867 32.3743 8.1265 32.3743 7.7488C33.8096 6.61569 35.0182 5.3315 36.0003 3.82069Z"
    />
  </svg>
</template>

<docs>
  To know more information on how to use icons see [here](/packs/#Icon)

  ```jsx
    <Icon name="Twitter" class="text-4xl"></Icon>
  ```
</docs>
