<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.72 6.71997C15.1104 6.71997 13.3286 9.26397 11.6064 11.7244C9.95231 14.088 8.39039 16.32 5.28 16.32C2.89823 16.32 0.96 14.3817 0.96 12C0.96 9.61821 2.89823 7.67997 5.28 7.67997C7.76831 7.67997 9.22561 9.10844 10.4314 10.654C10.5936 10.8614 10.8951 10.8998 11.1043 10.7376C11.3136 10.5744 11.351 10.272 11.1878 10.0627C9.89855 8.41056 8.18784 6.72002 5.28 6.72002C2.36831 6.71997 0 9.08927 0 12C0 14.9107 2.36831 17.28 5.28 17.28C8.88961 17.28 10.6714 14.736 12.3936 12.2755C14.0477 9.91197 15.6096 7.67997 18.72 7.67997C21.1018 7.67997 23.04 9.61821 23.04 12C23.04 14.3817 21.1018 16.32 18.72 16.32C16.2317 16.32 14.7744 14.8915 13.5686 13.3459C13.4045 13.1385 13.104 13.0992 12.8957 13.2624C12.6864 13.4256 12.649 13.728 12.8122 13.9372C14.1015 15.5894 15.8122 17.2799 18.72 17.2799C21.6317 17.28 24 14.9107 24 12C24 9.08927 21.6317 6.71997 18.72 6.71997Z"
      fill="black"
    />
  </svg>
</template>

  <docs>
    To know more information on how to use icons see [here](/packs/#Icon)

    ```jsx
      <Icon name="Check" class="text-4xl"></Icon>
    ```
  </docs>
