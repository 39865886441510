<template>
  <div>
    <!-- Banner  -->
    <div class="bg-blue-800">
      <div class="container mx-auto pt-8 lg:pt-10">
        <MetaTags :meta-tags="metaTags" />

        <!-- Title & Subhead -->
        <section class="text-center">
          <div class="mx-auto w-full md:w-2/3 mb-6 lg:mb-14">
            <h1 class="membershipTitle font-serif mb-1.5 text-white">
              Unlimited access to creative learning
            </h1>
            <div
              class="text-lg tracking-wide lg:tracking-normal text-blue-100 leading-7 lg:text-2xl font-normal max-w-full lg:max-w-xl mx-auto"
            >
              Access over 1,000 lessons led by expert instructors
            </div>
          </div>
          <div
            data-testid="mem_banner_container_section"
            class="continue-watching mem_banner"
          >
            <div
              id="mem_banner"
              class="swiper-continue-watching relative w-full"
            >
              <div class="swiper-wrapper">
                <!-- <div class="flex items-stretch justify-center gap-8"> -->
                <div class="swiper-slide">
                  <div class="flex flex-col gap-4 hero_frame_size">
                    <div class="w-full h-full">
                      <img
                        loading="lazy"
                        :data-src="heroOne.url"
                        :srcset="heroOne.srcset"
                        :alt="$I18n.t('mem_banner_image_alt_1', i18nScope)"
                        class="image-cover lazyload"
                      >
                    </div>
                    <div
                      class="uppercase text-blue-100 text-base font-normal tracking-wide"
                    >
                      Transform your home with tips from the pros
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="flex flex-col gap-4 hero_frame_size">
                    <div class="w-full h-full">
                      <img
                        loading="lazy"
                        :data-src="heroTwo.url"
                        :srcset="heroTwo.srcset"
                        :alt="$I18n.t('mem_banner_image_alt_2', i18nScope)"
                        class="image-cover lazyload"
                      >
                    </div>
                    <div
                      class="uppercase text-blue-100 text-base font-normal tracking-wide"
                    >
                      Create your dream garden
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="flex flex-col gap-4 hero_frame_size">
                    <div class="w-full h-full">
                      <img
                        loading="lazy"
                        :data-src="heroThree.url"
                        :srcset="heroThree.srcset"
                        :alt="$I18n.t('mem_banner_image_alt_3', i18nScope)"
                        class="image-cover lazyload"
                      >
                    </div>
                    <div
                      class="uppercase text-blue-100 text-base font-normal tracking-wide"
                    >
                      Grow your own food and become a better chef
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="flex flex-col gap-4 hero_frame_size">
                    <div class="w-full h-full">
                      <img
                        loading="lazy"
                        :data-src="heroFour.url"
                        :srcset="heroFour.srcset"
                        :alt="$I18n.t('mem_banner_image_alt_4', i18nScope)"
                        class="image-cover lazyload"
                      >
                    </div>
                    <div
                      class="uppercase text-blue-100 text-base font-normal tracking-wide"
                    >
                      Make your own soft furnishings & crafted gifts
                    </div>
                  </div>
                </div>
                <!-- </div> -->
              </div>
            </div>
          </div>

          <div class="max-w-2xl mx-auto mt-6 lg:mt-16 text-center">
            <div
              class="font-normal text-base lg:text-lg text-blue-100 mb-2 lg:mb-3 tracking-wide lg:tracking-wider"
              v-html="membershipPlan.description"
            />
            <div
              class="flex flex-row flex-wrap w-full md:w-auto justify-center items-center gap-x-6 gap-y-4 mb-2 lg:mb-3"
            >
              <Link
                class="button inlineBlockLink w-80 max-w-full group"
                :href="$routes.gifts() + '#membershipSection'"
                @click="onGiftClick(1)"
              >
                <span class="text-base text-white group-hover:text-yellow-800">gift membership</span>
              </Link>
              <Link
                class="button button--yellow w-80 max-w-full inlineBlockLink"
                :href="$routes.membership_checkout()"
                @click="onCheckoutClick(1)"
              >
                <span class="text-base">Join for
                  {{ getGeoPrice(membershipPlan.monthlyPrice) }}/month</span>
              </Link>
            </div>
            <div class="font-light text-blue-300 text-xs lg:text-base">
              (Billed annually)
            </div>
          </div>
        </section>
      </div>
    </div>

    <!-- Instructors -->
    <div class="bg-white">
      <div class="container mx-auto">
        <MetaTags :meta-tags="metaTags" />

        <!-- Title & Subhead -->
        <section class="text-center py-0 md:py-16">
          <div class="mx-auto w-full md:w-2/3 mb-6 lg:mb-20">
            <h1 class="font-serif membershipHeading mb-2 lg:mb-6 text-blue-800">
              Unlock your creativity with
              <span class="hidden md:inline">the world's best creative professionals</span>
            </h1>
            <div
              class="text-base tracking-wide lg:tracking-normal text-blue-800 leading-6 font-sans lg:text-2xl font-normal lg:leading-8 max-w-full mx-auto"
            >
              We’ve partnered with
              <span class="text-yellow-800">over 30 celebrated makers</span>,
              designers, gardeners, photographers and chefs to deliver
              beautifully filmed, world-class creative courses.
            </div>
          </div>
          <div
            class="grid grid-cols-12 gap-x-2 gap-y-4 lg:gap-y-8 lg:gap-x-6 lg:mx-28 resp_colSpan"
          >
            <div class="col-span-6 md:col-span-4 flex flex-col gap-3 lg:gap-2">
              <div class="w-full h-full">
                <img
                  loading="lazy"
                  :data-src="instructorOne.url"
                  :srcset="instructorOne.srcset"
                  :alt="$I18n.t('mem_instructor_image_alt_1', i18nScope)"
                  class="h-88 image-cover lazyload"
                >
              </div>
              <div class="flex flex-col items-center justify-center">
                <div
                  class="text-blue-800 text-xl lg:text-2xl lg:leading-8 font-medium font-serif"
                >
                  Rita Konig
                </div>
                <div
                  class="text-blue-800 text-sm tracking-wide lg:tracking-normal lg:text-lg font-normal font-sans"
                >
                  The Ultimate Guide to Interior Design
                </div>
              </div>
            </div>

            <div class="col-span-6 md:col-span-4 flex flex-col gap-3 lg:gap-2">
              <div class="w-full h-full">
                <img
                  loading="lazy"
                  :data-src="instructorTwo.url"
                  :srcset="instructorTwo.srcset"
                  :alt="$I18n.t('mem_instructor_image_alt_2', i18nScope)"
                  class="h-88 image-cover lazyload"
                >
              </div>
              <div class="flex flex-col items-center justify-center">
                <div
                  class="text-blue-800 text-xl lg:text-2xl lg:leading-8 font-medium font-serif"
                >
                  Micaela Sharp
                </div>
                <div
                  class="text-blue-800 text-sm tracking-wide lg:tracking-normal lg:text-lg font-normal font-sans"
                >
                  A Complete Guide to Modern Upholstery
                </div>
              </div>
            </div>

            <div class="col-span-6 md:col-span-4 flex flex-col gap-3 lg:gap-2">
              <div class="w-full h-full">
                <img
                  loading="lazy"
                  :data-src="instructorThree.url"
                  :srcset="instructorThree.srcset"
                  :alt="$I18n.t('mem_instructor_image_alt_3', i18nScope)"
                  class="h-88 image-cover lazyload"
                >
              </div>
              <div class="flex flex-col items-center justify-center">
                <div
                  class="text-blue-800 text-xl lg:text-2xl lg:leading-8 font-medium font-serif"
                >
                  Gemma Moulton
                </div>
                <div
                  class="text-blue-800 text-sm tracking-wide lg:tracking-normal lg:text-lg font-normal font-sans"
                >
                  How to Make Beautiful Curtains
                </div>
              </div>
            </div>

            <div class="col-span-6 md:col-span-4 flex flex-col gap-3 lg:gap-2">
              <div class="w-full h-full">
                <img
                  loading="lazy"
                  :data-src="instructorFour.url"
                  :srcset="instructorFour.srcset"
                  :alt="$I18n.t('mem_instructor_image_alt_4', i18nScope)"
                  class="h-88 image-cover lazyload"
                >
              </div>
              <div class="flex flex-col items-center justify-center">
                <div
                  class="text-blue-800 text-xl lg:text-2xl lg:leading-8 font-medium font-serif"
                >
                  Bex Partridge
                </div>
                <div
                  class="text-blue-800 text-sm tracking-wide lg:tracking-normal lg:text-lg font-normal font-sans"
                >
                  How to Create Forever Flowers
                </div>
              </div>
            </div>

            <div class="col-span-6 md:col-span-4 flex flex-col gap-3 lg:gap-2">
              <div class="w-full h-full">
                <img
                  loading="lazy"
                  :data-src="instructorFive.url"
                  :srcset="instructorFive.srcset"
                  :alt="$I18n.t('mem_instructor_image_alt_5', i18nScope)"
                  class="h-88 image-cover lazyload"
                >
              </div>
              <div class="flex flex-col items-center justify-center">
                <div
                  class="text-blue-800 text-xl lg:text-2xl lg:leading-8 font-medium font-serif"
                >
                  Jess Wheeler
                </div>
                <div
                  class="text-blue-800 text-sm tracking-wide lg:tracking-normal lg:text-lg font-normal font-sans"
                >
                  Making Botanical Art with Metal
                </div>
              </div>
            </div>

            <div class="col-span-6 md:col-span-4 flex flex-col gap-3 lg:gap-2">
              <div class="w-full h-full">
                <img
                  loading="lazy"
                  :data-src="instructorSix.url"
                  :srcset="instructorSix.srcset"
                  :alt="$I18n.t('mem_instructor_image_alt_6', i18nScope)"
                  class="h-88 image-cover lazyload"
                >
              </div>
              <div class="flex flex-col items-center justify-center">
                <div
                  class="text-blue-800 text-xl lg:text-2xl lg:leading-8 font-medium font-serif"
                >
                  Jason Ingram
                </div>
                <div
                  class="text-blue-800 text-sm tracking-wide lg:tracking-normal lg:text-lg font-normal font-sans"
                >
                  Garden & Landscape Photography Masterclass
                </div>
              </div>
            </div>
          </div>

          <div class="max-w-xl mx-auto mt-6 lg:mt-10 text-center">
            <div
              class="font-normal text-yellow-800 text-base leading-7 lg:text-xl lg:leading-8 tracking-wide lg:tracking-wider"
            >
              and many more...
            </div>
          </div>
        </section>
      </div>
    </div>

    <!-- Steps -->
    <div class="bg-blue-300">
      <div class="container mx-auto">
        <MetaTags :meta-tags="metaTags" />

        <!-- Title & Subhead -->
        <section class="text-center py-0 md:py-16">
          <div class="mx-auto w-full lg:w-2/3 mb-6 lg:mb-16">
            <h1 class="font-serif membershipHeading text-blue-800">
              Your steps to creative learning
            </h1>
          </div>
          <div
            class="flex flex-col-reverse lg:flex-row items-center justify-between gap-2 lg:gap-8 mb-6 lg:mb-0 lg:mx-28"
          >
            <div class="flex-1 flex items-center justify-start">
              <div class="flex flex-col items-start justify-center text-left">
                <p
                  class="uppercase text-blue-800 text-xs lg:text-base font-medium tracking-wide lg:tracking-wider lg:mb-1"
                >
                  step 1
                </p>
                <p
                  class="text-blue-800 text-2xl lg:text-4xl font-serif font-medium mb-3 lg:mb-2"
                >
                  Become a member
                </p>
                <p
                  class="text-blue-800 font-normal text-base tracking-wide lg:tracking-normal lg:text-2xl font-sans"
                >
                  Our all access Membership gives you
                  <span class="text-yellow-800 font-medium">a full year</span>
                  of joyful learning with access to all current courses and new
                  releases.
                </p>
                <p
                  v-if="membershipPlan.discountPercent"
                  class="text-blue-800 text-xs lg:text-lg font-medium tracking-wide lg:tracking-widest uppercase mt-4 lg:mt-6"
                >
                  for the first year Get
                  <span class="text-yellow-800">{{ membershipPlan.discountPercent }} % off</span>
                </p>
              </div>
            </div>
            <div class="flex-1 flex items-center justify-center">
              <div class="w-full h-full max-h-96 max-w-xl">
                <img
                  loading="lazy"
                  :data-src="stepOne.url"
                  :srcset="stepOne.srcset"
                  :alt="$I18n.t('mem_steps_image_alt_1', i18nScope)"
                  class="image-cover lazyload"
                >
              </div>
            </div>
          </div>
          <div
            class="flex flex-col lg:flex-row items-center justify-between gap-2 lg:gap-8 mb-6 lg:mb-0 lg:mx-20"
          >
            <div class="flex-1 flex items-center justify-center">
              <div class="w-full h-full max-h-96 max-w-xl">
                <img
                  loading="lazy"
                  :data-src="stepTwo.url"
                  :srcset="stepTwo.srcset"
                  :alt="$I18n.t('mem_steps_image_alt_2', i18nScope)"
                  class="image-cover lazyload"
                >
              </div>
            </div>
            <div class="flex-1 flex items-center justify-start">
              <div class="flex flex-col items-start justify-center text-left">
                <p
                  class="uppercase text-blue-800 text-xs lg:text-base font-medium tracking-wide lg:tracking-wider lg:mb-1"
                >
                  step 2
                </p>
                <p
                  class="text-blue-800 text-2xl lg:text-4xl font-serif font-medium mb-3 lg:mb-2"
                >
                  Search by teacher or topic
                </p>
                <p
                  class="text-blue-800 font-normal text-base tracking-wide lg:tracking-normal lg:text-2xl font-sans"
                >
                  Craft your own learning journey by exploring
                  <span class="text-yellow-800 font-medium">over 1000 lessons </span>and saving collections based on your favourite topics.
                </p>
              </div>
            </div>
          </div>

          <div
            class="flex flex-col-reverse lg:flex-row items-center justify-between gap-2 lg:gap-8 mb-8 lg:mb-0 lg:mx-20"
          >
            <div class="flex-1 flex items-center justify-start">
              <div class="flex flex-col items-start justify-center text-left">
                <p
                  class="uppercase text-blue-800 text-xs lg:text-base font-medium tracking-wide lg:tracking-wider lg:mb-1"
                >
                  step 3
                </p>
                <p
                  class="text-blue-800 text-2xl lg:text-4xl font-serif font-medium mb-3 lg:mb-2"
                >
                  Watch and rewatch at anytime
                </p>
                <p
                  class="text-blue-800 font-normal text-base tracking-wide lg:tracking-normal lg:text-2xl font-sans"
                >
                  With
                  <span class="text-yellow-800 font-medium">
                    unlimited access</span>
                  to all lessons, workbooks and resources you can learn at your
                  own pace and revisit favourites whenever you need inspiration.
                </p>
              </div>
            </div>
            <div class="flex-1 flex items-center justify-center">
              <div class="w-full h-full max-h-96 max-w-xl">
                <img
                  loading="lazy"
                  :data-src="stepThree.url"
                  :srcset="stepThree.srcset"
                  :alt="$I18n.t('mem_steps_image_alt_3', i18nScope)"
                  class="image-cover lazyload"
                >
              </div>
            </div>
          </div>

          <div class="max-w-2xl mx-auto mt-10 lg:mt-14 text-center">
            <div
              class="font-normal text-base lg:text-lg text-blue-800 mb-2 lg:mb-3 tracking-wide lg:tracking-wider"
              v-html="membershipPlan.description"
            />
            <div
              class="flex flex-row flex-wrap w-full md:w-auto justify-center items-center gap-x-6 gap-y-4 mb-2 lg:mb-3"
            >
              <Link
                class="button button--yellow button--yellow-outline inlineBlockLink w-80 max-w-full group"
                :href="$routes.gifts() + '#membershipSection'"
                @click="onGiftClick(2)"
              >
                <span class="text-base">gift membership</span>
              </Link>
              <Link
                class="button button--yellow w-80 max-w-full inlineBlockLink"
                :href="$routes.membership_checkout()"
                @click="onCheckoutClick(2)"
              >
                <span class="text-base">Join for
                  {{ getGeoPrice(membershipPlan.monthlyPrice) }}/month</span>
              </Link>
            </div>
            <div class="font-light text-blue-800 text-xs lg:text-base">
              (Billed annually)
            </div>
          </div>
        </section>
      </div>
    </div>

    <!-- Our             Community -->
    <div class="bg-neutral-100 md:bg-white">
      <div class="container mx-auto">
        <MetaTags :meta-tags="metaTags" />

        <!-- Title & Subhead -->
        <section class="text-center py-0 md:py-16">
          <!-- Join our community -->
          <section class="w-full mx-auto flex flex-col">
            <div class="mx-auto w-full lg:w-2/3 mb-6 lg:mb-14 text-center">
              <div
                class="font-serif membershipHeading text-blue-800 mb-5 lg:mb-4"
              >
                Join our community of creative learners
              </div>
              <div
                class="text-base tracking-wide lg:tracking-normal text-blue-800 leading-6 lg:text-2xl font-normal lg:leading-8 max-w-xl mx-auto"
              >
                Our members have learnt new skills, discovered new passions and
                even changed careers.
              </div>
            </div>
            <div class="lg:flex lg:items-start mx-auto w-full gap-6 lg:gap-8">
              <!-- Block one -->
              <div class="w-full mb-6 lg:mb-0 lg:w-1/3">
                <div
                  class="title-gigantic leading-10 transform rotate-180 text-right tracking-tighter mb-0 text-yellow-800"
                >
                  ,,
                </div>
                <div
                  class="-mt-8 text-left lg:-mt-6 font-serif communityHead text-blue-800"
                >
                  I would never have been able to make this if it weren't for
                  Create Academy
                </div>
                <div
                  class="uppercase mt-2 lg:mt-4 text-left text-yellow-800 text-sm lg:text-base font-normal tracking-wide lg:tracking-wider"
                >
                  from Nicola
                </div>
                <div class="w-full communityPlayer mt-4 lg:mt-6">
                  <img
                    loading="lazy"
                    :data-src="communityBlockOne.url"
                    :srcset="communityBlockOne.srcset"
                    :alt="$I18n.t('banner_image_alt', i18nScope)"
                    class="image-cover lazyload"
                  >
                  <!-- <VideoPlayer
                    data-testid="instructor-video-player"
                    class="mb-4 lg:mb-8"
                    :isAutoPlay="false"
                    :options="{ accountId: 6222962662001, playerId: brightcove.lessonPlayer.playerId, videoId: 6341664173112, embedId: 'default' }"
                    :mixpanel="{ type: 'landing', videoId: 6341664173112 }"
                  /> -->
                </div>
              </div>
              <!-- Block two -->
              <div class="w-full mb-6 lg:mb-0 lg:w-1/3">
                <div
                  class="title-gigantic transform rotate-180 text-right tracking-tighter mb-0 text-green-500"
                >
                  ,,
                </div>
                <div
                  class="-mt-8 text-left lg:-mt-6 font-serif communityHead text-blue-800"
                >
                  I now have the confidence to understand scale, style &
                  function in a space
                </div>
                <div
                  class="uppercase mt-2 lg:mt-4 text-left text-green-500 text-sm lg:text-base font-normal tracking-wide lg:tracking-wider"
                >
                  from GINA
                </div>
                <div class="w-full communityPlayer mt-4 lg:mt-6">
                  <img
                    loading="lazy"
                    :data-src="communityBlockTwo.url"
                    :srcset="communityBlockTwo.srcset"
                    :alt="$I18n.t('banner_image_alt', i18nScope)"
                    class="image-cover lazyload"
                  >
                  <!-- <VideoPlayer
                    data-testid="instructor-video-player"
                    class="mb-4 lg:mb-8"
                    :isAutoPlay="false"
                    :options="{ accountId: 6222962662001, playerId: brightcove.lessonPlayer.playerId, videoId: 6341664154112, embedId: 'default' }"
                    :mixpanel="{ type: 'landing', videoId: 6341664154112 }"
                  /> -->
                </div>
              </div>
              <!-- Block three -->
              <div class="w-full mb-6 lg:mb-0 lg:w-1/3">
                <div
                  class="title-gigantic transform rotate-180 text-right tracking-tighter mb-0 text-blue-500"
                >
                  ,,
                </div>
                <div
                  class="-mt-8 text-left lg:-mt-6 font-serif communityHead text-blue-800"
                >
                  You won't be disappointed.
                </div>
                <div
                  class="uppercase mt-2 lg:mt-4 text-left text-blue-500 text-sm lg:text-base font-normal tracking-wide lg:tracking-wider"
                >
                  from MARLIE
                </div>
                <div class="w-full communityPlayer-h mt-4 lg:mt-6">
                  <img
                    loading="lazy"
                    :data-src="communityBlockThree.url"
                    :srcset="communityBlockThree.srcset"
                    :alt="$I18n.t('banner_image_alt', i18nScope)"
                    class="image-cover lazyload"
                  >
                  <!-- <VideoPlayer
                    data-testid="instructor-video-player"
                    class="mb-4 lg:mb-8"
                    :isAutoPlay="false"
                    :options="{ accountId: 6222962662001, playerId: brightcove.lessonPlayer.playerId, videoId: 6341662118112, embedId: 'default' }"
                    :mixpanel="{ type: 'landing', videoId: 6341662118112 }"
                  /> -->
                </div>
              </div>
            </div>
          </section>

          <div class="max-w-xl mx-auto mt-6 lg:mt-14 text-center">
            <div
              class="font-medium uppercase text-sm lg:text-lg lg:leading-8 tracking-wider lg:tracking-widest text-blue-800 mb-3"
            >
              Show us what you’ve made using
              <span class="text-yellow-800">#MakeWithCreate</span>
            </div>
          </div>
        </section>
      </div>
    </div>

    <!-- Leading Platform -->
    <div class="bg-blue-300">
      <div class="container mx-auto">
        <MetaTags :meta-tags="metaTags" />

        <!-- Title & Subhead -->
        <section class="text-center py-0 md:py-16 w-full">
          <div class="mx-auto w-full md:w-2/3 mb-6 lg:mb-16">
            <h1 class="font-serif membershipHeading text-blue-800">
              The UK's leading creative learning platform
            </h1>
          </div>
          <div class="grid grid-cols-12 gap-8 logoImgResize w-full">
            <div
              class="col-span-12 md:col-span-6 xl:col-span-3 flex flex-col gap-2 lg:gap-6 items-center justify-center"
            >
              <div class="w-auto">
                <img
                  loading="lazy"
                  :data-src="platformOne.url"
                  :srcset="platformOne.srcset"
                  :alt="$I18n.t('mem_platform_image_alt_1', i18nScope)"
                  class="max-w-full lazyload"
                >
              </div>
              <div class="text-blue-800 text-xl font-normal tracking-wide">
                “A favourite for creative learning…around myriad disciplines”
              </div>
            </div>

            <div
              class="col-span-12 md:col-span-6 xl:col-span-3 flex flex-col gap-2 lg:gap-6 items-center justify-center"
            >
              <div class="w-auto">
                <img
                  loading="lazy"
                  :data-src="platformTwo.url"
                  :srcset="platformTwo.srcset"
                  :alt="$I18n.t('mem_platform_image_alt_2', i18nScope)"
                  class="max-w-full lazyload"
                >
              </div>
              <div class="text-blue-800 text-xl font-normal tracking-wide">
                “The go-to website for creative online classes”
              </div>
            </div>

            <div
              class="col-span-12 md:col-span-6 xl:col-span-3 flex flex-col gap-2 lg:gap-6 items-center justify-center"
            >
              <div class="w-auto">
                <img
                  loading="lazy"
                  :data-src="platformThree.url"
                  :srcset="platformThree.srcset"
                  :alt="$I18n.t('mem_platform_image_alt_3', i18nScope)"
                  class="max-w-full lazyload"
                >
              </div>
              <div class="text-blue-800 text-xl font-normal tracking-wide">
                “Access to design-world pros willing to share their expertise”
              </div>
            </div>

            <div
              class="col-span-12 md:col-span-6 xl:col-span-3 flex flex-col gap-2 lg:gap-6 items-center justify-center"
            >
              <div class="w-auto">
                <img
                  loading="lazy"
                  :data-src="platformFour.url"
                  :srcset="platformFour.srcset"
                  :alt="$I18n.t('mem_platform_image_alt_1', i18nScope)"
                  class="max-w-full lazyload"
                >
              </div>
              <div class="text-blue-800 text-xl font-normal tracking-wide">
                “One of the best online learning platforms gift for your team”
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>

    <!-- Joyful Learning -->
    <div class="bg-white">
      <div class="container mx-auto">
        <MetaTags :meta-tags="metaTags" />

        <!-- Title & Subhead -->
        <section class="text-center py-0 md:py-16">
          <div class="mx-auto w-full md:w-2/3 mb-12 lg:mb-10">
            <h1 class="font-serif membershipHeading text-blue-800">
              Discover a world of joyful learning at Create Academy
            </h1>
          </div>

          <!-- tags -->
          <div class="whitespace-nowrap w-full overflow-x-auto">
            <div
              class="flex w-full flex-wrap items-center justify-start lg:justify-center gap-4"
            >
              <div
                class="flex items-center justify-center flex-nowrap gap-4 whitespace-nowrap"
              >
                <div
                  class="px-6 py-2.5 text-base uppercase font-medium text-blue-800 bg-blue-300"
                >
                  Learn Colour Theory
                </div>
                <div
                  class="px-6 py-2.5 text-base uppercase font-medium text-blue-800 bg-blue-300"
                >
                  Grow Your Own Food
                </div>
                <div
                  class="px-6 py-2.5 text-base uppercase font-medium text-blue-800 bg-blue-300"
                >
                  Master Floral Design
                </div>
                <div
                  class="px-6 py-2.5 text-base uppercase font-medium text-blue-800 bg-blue-300"
                >
                  Discover Healthy Recipes
                </div>
              </div>
              <div
                class="flex items-center justify-center flex-nowrap gap-4 whitespace-nowrap"
              >
                <div
                  class="px-6 py-2.5 text-base uppercase font-medium text-blue-800 bg-blue-300"
                >
                  Master flower growing & arranging
                </div>
                <div
                  class="px-6 py-2.5 text-base uppercase font-medium text-blue-800 bg-blue-300"
                >
                  Discover classic interior design
                </div>
                <div
                  class="px-6 py-2.5 text-base uppercase font-medium text-blue-800 bg-blue-300"
                >
                  Photography
                </div>
              </div>
              <div
                class="flex items-center justify-center flex-nowrap gap-4 whitespace-nowrap"
              >
                <div
                  class="px-6 py-2.5 text-base uppercase font-medium text-blue-800 bg-blue-300"
                >
                  Transform small spaces
                </div>
                <div
                  class="px-6 py-2.5 text-base uppercase font-medium text-blue-800 bg-blue-300"
                >
                  Create Your Own Home Decor
                </div>
                <div
                  class="px-6 py-2.5 text-base uppercase font-medium text-blue-800 bg-blue-300"
                >
                  Host with Confidence
                </div>
              </div>
              <div
                class="flex items-center justify-center flex-nowrap gap-4 whitespace-nowrap"
              >
                <div
                  class="px-6 py-2.5 text-base uppercase font-medium text-blue-800 bg-blue-300"
                >
                  Learn Landscape gardening & photograhpy
                </div>
                <div
                  class="px-6 py-2.5 text-base uppercase font-medium text-blue-800 bg-blue-300"
                >
                  Master modern styling
                </div>
              </div>
            </div>
          </div>
          <!-- Video Player -->
          <section class="pt-14 w-full">
            <VideoPlayer
              data-testid="instructor-video-player"
              class="mb-4 lg:mb-8"
              :is-auto-play="false"
              :options="{
                accountId: 6222962662001,
                playerId: brightcove.lessonPlayer.playerId,
                videoId: 6322144530112,
                embedId: 'default',
              }"
              :mixpanel="{ type: 'landing', videoId: 6322144530112 }"
            />
          </section>
          <div class="max-w-2xl mx-auto mt-10 lg:mt-14 text-center">
            <div
              class="font-normal text-base lg:text-lg text-blue-800 mb-2 lg:mb-3 tracking-wide lg:tracking-wider"
              v-html="membershipPlan.description"
            />
            <div
              class="flex flex-row flex-wrap w-full md:w-auto justify-center items-center gap-x-6 gap-y-4 mb-2 lg:mb-3"
            >
              <Link
                class="button button--yellow button--yellow-outline inlineBlockLink w-80 max-w-full group"
                :href="$routes.gifts() + '#membershipSection'"
                @click="onGiftClick(3)"
              >
                <span class="text-base">gift membership</span>
              </Link>
              <Link
                class="button button--yellow w-80 max-w-full inlineBlockLink"
                :href="$routes.membership_checkout()"
                @click="onCheckoutClick(3)"
              >
                <span class="text-base">Join for
                  {{ getGeoPrice(membershipPlan.monthlyPrice) }}/month</span>
              </Link>
            </div>
            <div class="font-light text-blue-800 text-xs lg:text-base">
              (Billed annually)
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { metaTags } from '@/mixins/metaTags'
import MetaTags from '@/Components/Shared/MetaTags'
import monetize from '@/mixins/monetize'
import currentUser from '@/mixins/currentUser'
import { Link } from '@inertiajs/vue3'
import VideoPlayer from '@/Components/Shared/VideoPlayer'
import mixpanel from '@/mixins/mixpanel'
import Swiper from 'swiper'
import abTest from '@/mixins/abTest'

export default {
  components: {
    MetaTags,
    Link,
    VideoPlayer
  },
  mixins: [metaTags, monetize, currentUser, mixpanel, abTest],
  props: {
    membershipPlan: {
      type: Object,
      required: true
    },
    brightcove: {
      type: Object,
      required: true
    },
    geoProps: {
      type: Object,
      required: true
    }
  },
  computed: {
    // AB TEST SETUP
    targetTest() {
      return 'membership_landing'
    },
    faqs() {
      return [
        {
          id: 1,
          question: 'When and where do Create Academy courses take place?',
          answer:
            'All of our courses are online, meaning that you are able to watch them whenever and wherever suits you best. There is no beginning or finish date and they’re completely self-paced so that you can stop, start and pause each lesson to fit around your own schedule.'
        },
        {
          id: 2,
          question: 'How will I get access to the courses?',
          answer:
            'You’ll access our full course library via your Create Academy account - so you’ll need to log in or create an account when joining all access membership.'
        },
        {
          id: 3,
          question: 'How long does my membership last?',
          answer: 'Membership renews on an annual basis.'
        },
        {
          id: 4,
          question: 'When will I be charged?',
          answer:
            'On the day you join membership, you\'ll pay upfront for the upcoming year, and your renewal date will be 12 months from the day you join.'
        },
        {
          id: 5,
          question: 'What if I\'ve previously purchased an individual course?',
          answer:
            'Any courses you already own before joining membership will not be affected, and you can continue to enjoy them as before. If you choose to end your membership you\'ll retain access to any courses you purchased before joining membership.'
        },
        {
          id: 6,
          question: 'How do I cancel my membership?',
          answer:
            'Your membership comes with a 30 day money back guarantee, so within 30 days of purchase you can cancel and get a refund by simply emailing info@createacademy.com. Similarly, if you would like to cancel so your membership doesn\'t automatically renew after 12 months, please email info@createacademy.com at any time.'
        }
      ]
    }
  },
  mounted() {
    this.trackEvent('membership_landing_view', {
      userType: this.getUserType(),
      abTest: this.activeAbTest(this.targetTest) // associate this event with the active AB test
    })
    // init Swiper
    this.swiper = new Swiper('#mem_banner', {
      slidesPerView: 1,
      spaceBetween: 16,
      breakpoints: {
        470: {
          slidesPerView: 2,
          spaceBetween: 16
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 16
        },
        880: {
          slidesPerView: 4,
          spaceBetween: 16
        },
        1299: {
          slidesPerView: 4,
          spaceBetween: 16
        }
      }
    })
  },
  created() {
    this.i18nScope = { scope: 'pages.membership' }

    this.heroOne = {
      url: require('@/assets/images/landing/mem/hero_frame_1.png'),
      sizes: {
        large: require('@/assets/images/landing/mem/hero_frame_1.png'),
        medium: require('@/assets/images/landing/mem/hero_frame_1.png'),
        small: require('@/assets/images/landing/mem/hero_frame_1.png')
      }
    }
    this.heroTwo = {
      url: require('@/assets/images/landing/mem/hero_frame_2.png'),
      sizes: {
        large: require('@/assets/images/landing/mem/hero_frame_2.png'),
        medium: require('@/assets/images/landing/mem/hero_frame_2.png'),
        small: require('@/assets/images/landing/mem/hero_frame_2.png')
      }
    }
    this.heroThree = {
      url: require('@/assets/images/landing/mem/hero_frame_3.png'),
      sizes: {
        large: require('@/assets/images/landing/mem/hero_frame_3.png'),
        medium: require('@/assets/images/landing/mem/hero_frame_3.png'),
        small: require('@/assets/images/landing/mem/hero_frame_3.png')
      }
    }
    this.heroFour = {
      url: require('@/assets/images/landing/mem/hero_frame_4.png'),
      sizes: {
        large: require('@/assets/images/landing/mem/hero_frame_4.png'),
        medium: require('@/assets/images/landing/mem/hero_frame_4.png'),
        small: require('@/assets/images/landing/mem/hero_frame_4.png')
      }
    }
    this.instructorOne = {
      url: require('@/assets/images/landing/mem/instructor_img_1.png'),
      sizes: {
        large: require('@/assets/images/landing/mem/instructor_img_1.png'),
        medium: require('@/assets/images/landing/mem/instructor_img_1.png'),
        small: require('@/assets/images/landing/mem/instructor_img_1.png')
      }
    }
    this.instructorTwo = {
      url: require('@/assets/images/landing/mem/instructor_img_2.webp'),
      sizes: {
        large: require('@/assets/images/landing/mem/instructor_img_2.webp'),
        medium: require('@/assets/images/landing/mem/instructor_img_2.webp'),
        small: require('@/assets/images/landing/mem/instructor_img_2.webp')
      }
    }
    this.instructorThree = {
      url: require('@/assets/images/landing/mem/instructor_img_3.png'),
      sizes: {
        large: require('@/assets/images/landing/mem/instructor_img_3.png'),
        medium: require('@/assets/images/landing/mem/instructor_img_3.png'),
        small: require('@/assets/images/landing/mem/instructor_img_3.png')
      }
    }
    this.instructorFour = {
      url: require('@/assets/images/landing/mem/instructor_img_4.png'),
      sizes: {
        large: require('@/assets/images/landing/mem/instructor_img_4.png'),
        medium: require('@/assets/images/landing/mem/instructor_img_4.png'),
        small: require('@/assets/images/landing/mem/instructor_img_4.png')
      }
    }
    this.instructorFive = {
      url: require('@/assets/images/landing/mem/instructor_img_5.png'),
      sizes: {
        large: require('@/assets/images/landing/mem/instructor_img_5.png'),
        medium: require('@/assets/images/landing/mem/instructor_img_5.png'),
        small: require('@/assets/images/landing/mem/instructor_img_5.png')
      }
    }
    this.instructorSix = {
      url: require('@/assets/images/landing/mem/instructor_img_6.png'),
      sizes: {
        large: require('@/assets/images/landing/mem/instructor_img_6.png'),
        medium: require('@/assets/images/landing/mem/instructor_img_6.png'),
        small: require('@/assets/images/landing/mem/instructor_img_6.png')
      }
    }
    this.stepOne = {
      url: require('@/assets/images/landing/mem/step_one.svg'),
      sizes: {
        large: require('@/assets/images/landing/mem/step_one.svg'),
        medium: require('@/assets/images/landing/mem/step_one.svg'),
        small: require('@/assets/images/landing/mem/step_one_mobile.svg')
      }
    }
    this.stepTwo = {
      url: require('@/assets/images/landing/mem/step_two.svg'),
      sizes: {
        large: require('@/assets/images/landing/mem/step_two.svg'),
        medium: require('@/assets/images/landing/mem/step_two.svg'),
        small: require('@/assets/images/landing/mem/step_two_mobile.svg')
      }
    }
    this.stepThree = {
      url: require('@/assets/images/landing/mem/step_three.svg'),
      sizes: {
        large: require('@/assets/images/landing/mem/step_three.svg'),
        medium: require('@/assets/images/landing/mem/step_three.svg'),
        small: require('@/assets/images/landing/mem/step_three_mobile.svg')
      }
    }

    this.communityBlockOne = {
      url: require('@/assets/images/landing/mem/community_one.webp'),
      sizes: {
        large: require('@/assets/images/landing/mem/community_one.webp'),
        medium: require('@/assets/images/landing/mem/community_one.webp'),
        small: require('@/assets/images/landing/mem/community_one.webp')
      }
    }

    this.communityBlockTwo = {
      url: require('@/assets/images/landing/mem/community_two.png'),
      sizes: {
        large: require('@/assets/images/landing/mem/community_two.png'),
        medium: require('@/assets/images/landing/mem/community_two.png'),
        small: require('@/assets/images/landing/mem/community_two.png')
      }
    }

    this.communityBlockThree = {
      url: require('@/assets/images/landing/mem/community_three.webp'),
      sizes: {
        large: require('@/assets/images/landing/mem/community_three.webp'),
        medium: require('@/assets/images/landing/mem/community_three.webp'),
        small: require('@/assets/images/landing/mem/community_three.webp')
      }
    }
    this.platformOne = {
      url: require('@/assets/images/landing/mem/telegraph.png'),
      sizes: {
        large: require('@/assets/images/landing/mem/telegraph.png'),
        medium: require('@/assets/images/landing/mem/telegraph.png'),
        small: require('@/assets/images/landing/mem/telegraph.png')
      }
    }
    this.platformTwo = {
      url: require('@/assets/images/landing/mem/sunday-times.png'),
      sizes: {
        large: require('@/assets/images/landing/mem/sunday-times.png'),
        medium: require('@/assets/images/landing/mem/sunday-times.png'),
        small: require('@/assets/images/landing/mem/sunday-times.png')
      }
    }
    this.platformThree = {
      url: require('@/assets/images/landing/mem/eliite-decor.png'),
      sizes: {
        large: require('@/assets/images/landing/mem/eliite-decor.png'),
        medium: require('@/assets/images/landing/mem/eliite-decor.png'),
        small: require('@/assets/images/landing/mem/eliite-decor.png')
      }
    }
    this.platformFour = {
      url: require('@/assets/images/landing/mem/architectural-digest.png'),
      sizes: {
        large: require('@/assets/images/landing/mem/architectural-digest.png'),
        medium: require('@/assets/images/landing/mem/architectural-digest.png'),
        small: require('@/assets/images/landing/mem/architectural-digest.png')
      }
    }

    this.featureBlockOne = {
      url: require('@/assets/images/landing/mem/featureOne.webp'),
      sizes: {
        large: require('@/assets/images/landing/mem/featureOne.webp'),
        medium: require('@/assets/images/landing/mem/featureOne.webp'),
        small: require('@/assets/images/landing/mem/featureOne.webp')
      }
    }

    this.featureBlockTwo = {
      url: require('@/assets/images/landing/mem/featureTwo.webp'),
      sizes: {
        large: require('@/assets/images/landing/mem/featureTwo.webp'),
        medium: require('@/assets/images/landing/mem/featureTwoMobile.webp'),
        small: require('@/assets/images/landing/mem/featureTwoMobile.webp')
      }
    }

    this.featureBlockThree = {
      url: require('@/assets/images/landing/mem/featureThree.webp'),
      sizes: {
        large: require('@/assets/images/landing/mem/featureThree.webp'),
        medium: require('@/assets/images/landing/mem/featureThree.webp'),
        small: require('@/assets/images/landing/mem/featureThree.webp')
      }
    }

    this.createStar = require('@/assets/images/CaStar.svg')
  },
  methods: {
    getUserType() {
      return this.currentUser ? 'authed' : 'anonymous'
    },
    getGeoPrice(gbpBasePrice) {
      return this.geoMonetize(
        gbpBasePrice * this.geoProps.exchangeRate,
        this.geoProps.currencySymbol,
        2
      )
    },
    onCheckoutClick(buttonOrder) {
      this.trackEvent('membership_checkout', {
        userType: this.getUserType(),
        buttonOrder: buttonOrder
      })
    },
    onGiftClick(buttonOrder) {
      this.trackEvent('membership_gift', {
        userType: this.getUserType(),
        buttonOrder: buttonOrder
      })
    }
  }
}
</script>
