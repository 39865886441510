<template>
  <div :class="['category-banner w-full flex flex-col lg:flex-row lg:items-stretch relative', backgroundColor, { 'category-banner-full-height': !dense }]">
    <div class="banner-content w-full lg:w-1/2 flex flex-col justify-end py-12">
      <div class="mt-24 lg:mt-0 px-6 lg:px-16 lg:mb-12">
        <h1 class="banner__title text-white">
          {{ title }}
        </h1>
        <p class="-mt-2 text-xl text-gray-200">
          {{ description }}
        </p>
      </div>
    </div>

    <div
      v-if="image.url"
      class="cat-image w-full lg:w-1/2 bg-opacity-20 banner-image h-96 lg:h-auto"
    >
      <img
        :id="imageId || null"
        loading="lazy"
        :data-testid="imageId || 'banner-image'"
        :alt="imageAlt || 'Banner image'"
        :src="image.url"
        :data-src="image.url"
        :srcset="image.srcset"
        :data-srcset="image.srcset"
        class="w-full h-full object-cover object-center"
        :class="classNames"
      >
    </div>

    <div
      class="absolute"
      style="top: 3rem; right: 67px;"
    >
      <slot name="actions" />
    </div>
  </div>
</template>

<script>
import mixpanel from '@/mixins/mixpanel'

export default {
  mixins: [mixpanel],

  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: false,
      default: ''
    },
    image: {
      type: Object,
      required: false,
      default: () => ({ url: null, srcset: null }),
      validator: (obj) => {
        return Object.prototype.hasOwnProperty.call(obj, 'url') &&
               Object.prototype.hasOwnProperty.call(obj, 'srcset')
      }
    },
    imageId: {
      type: String,
      default: null
    },
    imageAlt: {
      type: String,
      default: 'Banner image'
    },
    backgroundColor: {
      type: String,
      default: 'bg-blue-800'
    },
    classNames: {
      type: [String, Array, Object],
      default: ''
    },
    dense: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.category-banner {
  height: auto;
}

/* Tailwind breakpoint for large screens */
@media (min-width: 1024px) {
  .category-banner.category-banner-full-height {
    height: 60vh;
    max-height: 600px;
  }
}
</style>