<template>
  <div>
    <MetaTags :meta-tags="metaTags" />
    <Tabs />

    <div class="md:mt-14 container-sm">
      <h1 class="title-large text-blue-800 mb-8">
        {{ $I18n.t('title', i18nScope) }}
      </h1>

      <UpdatePasswordForm :with-random-pw="withRandomPw" />
    </div>
  </div>
</template>

<script>
import { metaTags } from '@/mixins/metaTags'

import MetaTags from '@/Components/Shared/MetaTags'
import UpdatePasswordForm from '@/Components/User/UpdatePasswordForm'
import Tabs from '@/Components/User/Tabs'

export default {

  components: {
    MetaTags,
    UpdatePasswordForm,
    Tabs
  },
  mixins: [metaTags],

  props: {
    withRandomPw: {
      type: Boolean,
      required: true
    }
  },

  created() {
    this.i18nScope = { scope: 'pages.users.password' }
  }
}
</script>
