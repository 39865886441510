<template>
  <svg
    width="47"
    height="43"
    viewBox="0 0 47 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M46.5 42.5V0L0.5 42.5H46.5Z"
      fill="#6D1623"
    />
  </svg>
</template>

<docs>
        To know more information on how to use icons see [here](/packs/#Icon)

        ```jsx
          <Icon name="Instagram" class="text-4xl"></Icon>
        ```
      </docs>
