<template>
  <component
    :is="itemComponent"
    data-testid="link-purchase-item"
    :href="$routes.order(order.id)"
    class="link--blue"
  >
    <div
      data-testid="purchase-history-row"
      class="flex flex-row items-start py-4"
    >
      <div class="flex flex-col w-full flex-grow pb-2 md:pb-0 md:pr-6">
        <div class="flex flex-row mb-2">
          <p
            data-testid="orderable-id"
            class="w-max py-1 px-2 bg-yellow-800 rounded-sm subheading--medium text-grayscale-100 mr-2"
          >
            #{{ order.id }}
          </p>
          <h1
            data-testid="orderable-name"
            class="title-medium"
          >
            {{ order.name || $I18n.t('course', i18nScope) }}
          </h1>
        </div>
        <p
          data-testid="order-payment-confirmed-at"
          class="text-sm"
        >
          {{ order.humanized_payment_confirmed_at }}
        </p>
      </div>

      <p
        data-testid="order-amount"
        class="font-normal text-lg w-auto flex-shrink-0"
      >
        {{ order.humanized_amount }}
      </p>
    </div>
  </component>
</template>

<script>
  import { Link } from '@inertiajs/vue3'

  export default {

    components: {
      Link
    },

    props: {
      order: {
        type: Object,
        required: true
      }
    },

    computed: {
      itemComponent() {
        return this.order.type == 'membership' ? 'div' : 'Link'
      }
    },
    created() {
      this.i18nScope = { scope: 'pages.users.purchases' }
    }
  }
</script>
