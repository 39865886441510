<template>
  <div
    data-testid="testimonials"
    class="pt-4"
  >
    <div
      data-testid="trustpilot-widget"
      class="trustpilot-widget"
      data-locale="en-GB"
      data-template-id="53aa8912dec7e10d38f59f36"
      data-businessunit-id="5dc19ef2cfdefd00017f401c"
      data-style-height="140px"
      data-style-width="100%"
      data-theme="light"
      data-stars="5"
      data-review-languages="en"
      data-text-color="#232b30"
    >
      <a
        href="https://uk.trustpilot.com/review/createacademy.com"
        target="_blank"
        rel="noopener"
      >Trustpilot</a>
    </div>
  </div>
</template>

<script>
const loadScript = (src) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = src
    script.onload = resolve
    script.onerror = reject
    document.head.appendChild(script)
  })
}

export default {
  name: 'Testimonials',
  mounted() {
    loadScript(
      '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'
    )
      .then(() => {
        const trustBox = document.querySelector('.trustpilot-widget')
        if (trustBox && window.Trustpilot) {
          window.Trustpilot.loadFromElement(trustBox)
        } else {
          console.error('Trustpilot element or Trustpilot library not found')
        }
      })
      .catch((error) => {
        console.error('Error loading Trustpilot script:', error)
      })
  }
}
</script>
