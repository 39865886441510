<template>
  <div>
    <OrderBreakdown
      :promotion-banner="promotionBanner"
      :show-discount-tag="discount > 0"
      :i18n-scope="i18nScope"
      :geo-price="geoPrice"
      :discount="discount"
      :geo-props="geoProps"
    />

    <slot />

    <img
      loading="lazy"
      :src="paymentServices"
      class="mx-auto"
    >
  </div>
</template>

<script>
import monetize from '@/mixins/monetize'
import OrderBreakdown from '@/Components/Orders/OrderBreakdown'

export default {
  components: {
    OrderBreakdown
  },

  mixins: [
    monetize
  ],

  props: {
    geoPrice: {
      type: Object,
      required: true
    },
    geoProps: {
      type: Object,
      required: false
    },
    discount: {
      type: Number,
      default: 0
    },

    promotionBanner: {
      type: String,
      default: ''
    },

    i18nScope: {
      type: Object,
      required: true
    }
  },

  created() {
    this.paymentServices = require('@/assets/images/PaymentServices.svg')
  }
}
</script>
