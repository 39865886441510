<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.01153 12C2.01153 6.48352 6.48352 2.01153 12 2.01153C17.5165 2.01153 21.9885 6.48352 21.9885 12C21.9885 17.5165 17.5165 21.9885 12 21.9885C6.48352 21.9885 2.01153 17.5165 2.01153 12ZM12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM12.3718 7.95379V8.96533H11.3603V7.95379H12.3718ZM12.3718 10.4826L12.3718 17.0576H11.3603L11.3603 10.4826H12.3718Z"
    />
  </svg>
</template>

<docs>
  To know more information on how to use icons see [here](/packs/#Icon)

  ```jsx
    <Icon name="Info" class="text-4xl"></Icon>
  ```
</docs>
