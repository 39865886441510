<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12.0082 2C6.48104 2 2 6.47368 2 11.9918C2 16.227 4.63591 19.8454 8.35913 21.301C8.26852 20.5115 8.19438 19.2944 8.39208 18.4309C8.5733 17.6497 9.56176 13.4638 9.56176 13.4638C9.56176 13.4638 9.26522 12.8635 9.26522 11.9836C9.26522 10.5938 10.0725 9.55757 11.0774 9.55757C11.9341 9.55757 12.3459 10.199 12.3459 10.9638C12.3459 11.8191 11.8023 13.102 11.514 14.2944C11.2751 15.2895 12.0165 16.1036 12.9967 16.1036C14.7759 16.1036 16.1433 14.2286 16.1433 11.5313C16.1433 9.13816 14.4217 7.46875 11.9588 7.46875C9.10872 7.46875 7.43656 9.59868 7.43656 11.8026C7.43656 12.6579 7.76605 13.5789 8.17791 14.0806C8.26028 14.1793 8.26852 14.2697 8.24381 14.3684C8.16967 14.6809 7.99669 15.3635 7.96374 15.5033C7.92256 15.6842 7.81547 15.7253 7.62602 15.6349C6.37396 15.051 5.59143 13.2336 5.59143 11.7615C5.59143 8.61184 7.88137 5.71711 12.2059 5.71711C15.6738 5.71711 18.3756 8.18421 18.3756 11.4901C18.3756 14.9359 16.201 17.7072 13.1861 17.7072C12.173 17.7072 11.2174 17.1809 10.8962 16.5559C10.8962 16.5559 10.3937 18.4638 10.2702 18.9326C10.0478 19.8043 9.4382 20.8898 9.02634 21.5559C9.96539 21.8438 10.9539 22 11.9917 22C17.5189 22 22 17.5263 22 12.0082C22.0164 6.47368 17.5354 2 12.0082 2Z" />
  </svg>
</template>

<docs>
  To know more information on how to use icons see [here](/packs/#Icon)

  ```jsx
    <Icon name="Pinterest" class="text-4xl"></Icon>
  ```
</docs>
