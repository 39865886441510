<template>
  <div class="py-8">
    <div class="flex flex-row mb-2">
      <p class="w-full flex-grow overline--medium text-yellow-800">
        {{ $I18n.t('item', i18nScope) }}
      </p>
      <p class="w-auto flex-shrink-0 overline--medium text-yellow-800">
        {{ $I18n.t('amount', i18nScope) }}
      </p>
    </div>

    <div class="flex flex-col divide-y divide-blue-800 divide-opacity-50">
      <PurchaseHistoryRow
        v-for="order in orders"
        :key="order.id"
        :order="order"
      />
    </div>
  </div>
</template>

<script>
  import PurchaseHistoryRow from '@/Components/User/PurchaseHistoryRow'

  export default {

    components: {
      PurchaseHistoryRow
    },

    props: {
      orders: {
        type: Array,
        required: true
      }
    },
    created() {
      this.i18nScope = { scope: 'pages.users.purchases' }
    }
  }
</script>
