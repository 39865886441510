<template>
  <section class="bg-white">
    <div class="flex flex-wrap items-center justify-between w-full gap-y-3">
      <div class="text-4xl font-serif text-left text-blue-800">
        {{ heading }}
      </div>
      <slot name="actions" />
    </div>
    <div class="collections-grid mt-8">
      <div
        v-for="item in parsedCollections"
        :key="item.id"
      >
        <component
          :is="item.lesson_count > 0 ? 'Link' : 'div'"
          :href="item.lesson_count > 0 ? $routes.user_collection(item.id) : null"
          :data-testid="item.lesson_count > 0 ? `${item.id}-link` : null"
          :class="{ 'cursor-pointer': item.lesson_count > 0 }"
        >
          <div
            class="collection-item-square"
            :class="{ 'bg-green-800': item.lesson_count === 0 }"
          >
            <template v-if="item.lesson_count == 0">
              <Icon
                name="BookMarkNoStroke"
                class="centered-icon"
              />
            </template>
            <template v-else>
              <div class="preview-grid">
                <div
                  v-for="(poster, index) in getFirstThreePosters(item.lessons)"
                  :key="index"
                  class="preview-grid-item"
                  :class="{ 'col-span-2': item.lesson_count === 1 && index === 0, 'bg-blue-500': !poster }"
                  :style="poster ? { backgroundImage: `url(${poster})`, backgroundSize: 'cover', backgroundPosition: 'center' } : {}"
                />
                <div
                  class="preview-grid-item bg-blue-500 relative"
                  :class="{ 'col-span-2': item.lesson_count < 3}"
                >
                  <Icon
                    name="BookMarkNoStroke"
                    class="centered-icon"
                  />
                </div>
              </div>
            </template>
            <div class="collection-item-actions">
              <template v-if="canEdit && item.slug !== 'default_collection'">
                <button
                  class="settings-button"
                  @click.prevent="openSettingsDialog(item)"
                >
                  <Icon
                    name="PencilOnCircle"
                    class="settings-icon"
                  />
                </button>
              </template>
            </div>
          </div>
        </component>

        <div class="collection-item-footer">
          <GridItemDetails
            :href="item.lesson_count > 0 ? $routes.user_collection(item.id) : null"
            :container-test-id="item.lesson_count > 0 ? `${item.id}-link` : null"
            :main-title="item.name"
          >
            <template #footer>
              <span
                v-if="item.customDescriptionHtml"
                class="normal-case"
                v-html="item.customDescriptionHtml"
              />
              <template v-else>
                {{ item.lesson_count }} Lesson{{ item.lesson_count > 1 || item.lesson_count === 0 ? 's' : '' }}
              </template>
            </template>
          </GridItemDetails>
        </div>
      </div>
    </div>

    <dialog-box
      ref="collectionBox"
      :title="'Edit collection'"
      :edit="edit"
      :collection="collectionItem"
      :description="'Rename or delete your collection'"
    />
  </section>
</template>

<script>
import { Link } from '@inertiajs/vue3'
import Icon from '@/Components/Shared/Icon.vue'
import GridItemDetails from '@/Components/Shared/GridItemDetails.vue'
import DialogBox from '@/Components/Shared/SavedLessons/DialogBox.vue'

export default {
  components: {
    Link,
    Icon,
    GridItemDetails,
    DialogBox
  },

  props: {
    collections: {
      type: Array,
      default: () => []
    },
    heading: {
      type: String,
      required: true
    },
    canEdit: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      collectionItem: {},
      edit: true
    }
  },

  computed: {
    parsedCollections() {
      return this.collections
        .map(item => ({
          ...item,
          lesson_count: item.lessons?.length || 0
        }))
        .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
    }
  },

  methods: {
    openSettingsDialog(item) {
      this.collectionItem = item
      this.$refs.collectionBox.open(item)
    },

    getFirstThreePosters(lessons) {
      if (!lessons) return Array(3).fill(null)

      const posters = lessons
        .filter(lesson => lesson.poster)
        .map(lesson => lesson.poster)
        .slice(0, 3)

      // If only one lesson, return just one poster and one null
      if (lessons.length === 1) {
        return [posters[0]]
      }

      // If only two lessons, return two posters and no third slot
      if (lessons.length === 2) {
        return [posters[0], posters[1]]
      }

      // Pad with nulls if we don't have 3 posters
      while (posters.length < 3) {
        posters.push(null)
      }

      return posters
    }
  }
}
</script>