const confirmLeave = {
  created() {
    window.addEventListener('beforeunload', this.onBeforeUnload)
  },
  // This cancels a Inertia visit if it returns false.
  // From the Inertia docs:
  // As a convenience, if you register your event listener using Inertia.on(),
  // you can also cancel the event by returning false from the listener.
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.onBeforeUnload)
    this.$inertia.on('before', (event) => {
      // If the visit is the form submission, don't show the confirmation window
      if (this.formSubmitVisit(event)) return true

      return !this.confirmStayInDirtyForm()
    })
  },

  methods: {
    // Check if the Inertia visit event 'e' refers to the form post submission
    formSubmitVisit(e) {
      const visitDetail = e.detail.visit

      // formSubmitPath should be defined in the component that uses this mixin
      return  visitDetail.method === 'post' &&
              this.formSubmitPath.includes(visitDetail.url.pathname)
    },

    confirmLeave() {
      return confirm(this.$I18n.t('confirm_leave', this.i18nScope))
    },

    confirmStayInDirtyForm() {
      return this.formDirty && !this.confirmLeave()
    },

    onBeforeUnload(e) {
      if (this.confirmStayInDirtyForm()) {
        // Cancel the event
        e.preventDefault()
        // Chrome requires returnValue to be set
        e.returnValue = ''
      }
    }
  }
}

export default confirmLeave
