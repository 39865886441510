<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.02564 12.6158L2 13.2051V12.0222V6.17968V5L3.02564 5.58284L8.14446 8.49172L9.17949 9.0799L8.14727 9.673L3.02564 12.6158ZM3.02564 11.4329V6.76252L7.11224 9.08482L3.02564 11.4329ZM10.7179 6.53846H22V5.51282H10.7179V6.53846ZM10.7179 12.6923H12H22V11.6667H12H10.7179V12.6923ZM22 18.8462V17.8205H2V18.8462H22Z"
    />
  </svg>
</template>

<docs>
  To know more information on how to use icons see [here](/packs/#Icon)

  ```jsx
    <Icon name="LessonPlan" class="text-4xl"></Icon>
  ```
</docs>
