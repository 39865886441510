<template>
  <svg
    width="20"
    height="23"
    viewBox="0 0 20 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.77143 19.3857V5.1914M9.77143 19.3857L8.55687 17.9889C7.87102 17.2002 6.94123 16.7571 5.97129 16.7571H2.91349C2.40854 16.7571 2 16.2864 2 15.7057V4.66569C2 4.085 2.40934 3.61426 2.91429 3.61426H6.42802C7.39795 3.61426 8.32816 4.05736 9.01401 4.84608L9.77143 5.71712L10.5288 4.84608C11.2147 4.05736 12.1449 3.61426 13.1148 3.61426H17.0857C17.5907 3.61426 18 4.085 18 4.66569V15.7057C18 16.2864 17.5907 16.7571 17.0857 16.7571H13.572C12.602 16.7571 11.6718 17.2002 10.986 17.9889L9.77143 19.3857Z"
      stroke="black"
      stroke-linecap="square"
    />
  </svg>
</template>

  <docs>
    To know more information on how to use icons see [here](/packs/#Icon)

    ```jsx
      <Icon name="Check" class="text-4xl"></Icon>
    ```
  </docs>
