<template>
  <component
    :is="href ? 'Link' : 'div'"
    :href="href"
    :data-testid="containerTestId"
    :class="{ 'cursor-pointer': href || hasClickListener }"
    @click="$emit('click')"
  >
    <div class="flex flex-row no-wrap justify-between">
      <h2
        :data-testid="headingTestId"
        class="title-medium text-blue-800 mb-0"
      >
        <div class="subheading mb-1">
          {{ subheading }}
        </div>
        {{ mainTitle }}
      </h2>
      <slot name="subtitle">
        <Icon
          v-if="hasAction"
          name="Arrow"
          class="text-2xl text-blue-800"
        />
      </slot>
    </div>
    <div class="subheading text-blue-800">
      <slot name="footer" />
    </div>
    <slot />
  </component>
</template>

<script>
  import { Link } from '@inertiajs/vue3'
  import Icon from '@/Components/Shared/Icon.vue'

  export default {
    components: {
      Link,
      Icon
    },
    props: {
      containerTestId: {
        type: String,
        required: false,
        default: null
      },
      headingTestId: {
        type: String,
        required: false,
        default: null
      },
      mainTitle: {
        type: String,
        required: true
      },
      subheading: {
        type: String,
        required: false,
        default: ''
      },
      href: {
        type: String,
        required: false,
        default: null
      }
    },
    computed: {
      hasAction() {
        return !!this.href || this.hasClickListener
      },
      hasClickListener() {
        return this.$attrs.onClick !== undefined
      }
    }
  }
</script>
