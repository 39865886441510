<template>
  <Link
    as="button"
    class="button"
    :class="classNames"
    :href="href"
    @click="$emit('click')"
  >
    <template v-if="icon">
      <Icon
        :name="icon"
        class="button__icon relative -top-0.5"
        :class="vertical ? 'mt-1 mb-2' : 'mr-3'"
      />
    </template>

    <slot />
  </Link>
</template>

<script>
  import button from '@/mixins/button'

  import { Link } from '@inertiajs/vue3'

  /**
   * ButtonLink component for Create Academy
   * @displayName ButtonLink
   */
  export default {
    name: 'ButtonLink',

    components: {
      Link
    },

    mixins: [button],

    props: {
      /**
       * The link attribute of the button
       */
      href: {
        type: String,
        required: true
      }
    },

    emits: ['click']
  }
</script>


<docs>
  ### ✎ Variants

  ```jsx
    <div class="flex flex-row flex-wrap">
      <ButtonLink class="m-2">Hello World</ButtonLink>
      <ButtonLink variant="red" class="m-2">Hello World</ButtonLink>
      <ButtonLink variant="green" class="m-2">Hello World</ButtonLink>
      <ButtonLink variant="yellow" class="m-2">Hello World</ButtonLink>
      <ButtonLink variant="danger" class="m-2">Hello World</ButtonLink>
      <ButtonLink variant="inactive" class="m-2">Hello World</ButtonLink>
    </div>
  ```
  ---

  ### ✎ Size

  ```jsx
    <ButtonLink class="mb-2">I'm default size</ButtonLink>
    <ButtonLink size="small">I'm small size</ButtonLink>
  ```

  ---

  ### ✎ Outline

  ```jsx
    <div class="flex flex-row flex-wrap">
      <ButtonLink outline class="m-2">Hello World</ButtonLink>
      <ButtonLink variant="red" outline class="m-2">Hello World</ButtonLink>
      <ButtonLink variant="green" outline class="m-2">Hello World</ButtonLink>
      <ButtonLink variant="yellow" outline class="m-2">Hello World</ButtonLink>
    </div>
  ```

  ---

  ### ✎ Inverse ButtonLink
  This button is used under dark backgrounds

  ```jsx
    <div class="flex flex-row flex-wrap bg-grayscale-700">
      <ButtonLink inverse class="m-2">Hello World</ButtonLink>
      <ButtonLink variant="red" inverse class="m-2">Hello World</ButtonLink>
      <ButtonLink variant="green" inverse class="m-2">Hello World</ButtonLink>
      <ButtonLink variant="yellow" inverse class="m-2">Hello World</ButtonLink>
    </div>

    <div class="flex flex-row flex-wrap bg-grayscale-700">
      <ButtonLink outline inverse class="m-2">Hello World</ButtonLink>
      <ButtonLink variant="red" outline inverse class="m-2">Hello World</ButtonLink>
      <ButtonLink variant="green" outline inverse class="m-2">Hello World</ButtonLink>
      <ButtonLink variant="yellow" outline inverse class="m-2">Hello World</ButtonLink>
    </div>
  ```

  ---

  ### ✎ With Icon
  The icon select inherits the color of the button text. To consult which icons are avaible go [here](/packs/#Icon).

  ```jsx
    <ButtonLink icon="basket" class="mb-4">Add to basket</ButtonLink>
    <ButtonLink icon="close" size="small" class="mr-4">Not this way</ButtonLink>
  ```

  ---

  ### ✎ Ocuppy entire width of the container [block]

  ```jsx
    <ButtonLink block> I'm sooooo big </ButtonLink>
  ```

  ---

  ### ✎ Inactive

  ```jsx
    <ButtonLink disabled> I'm inactive </ButtonLink>
  ```

  ---

  ### ✎ Loading

  ```jsx
    <ButtonLink loading> Creating something </ButtonLink>
  ```
</docs>
