<template>
  <div
    data-testid="payment-details-section"
    class="mb-8"
  >
    <h2
      data-testid="payment-details-section-title"
      class="title-medium text-blue-800 mb-6"
    >
      {{ $I18n.t('payment_details.title', i18nScope) }}
    </h2>

    <!-- Apple/Google Pay Services provided by Stripe which activate when available-->
    <template v-if="paymentRequest.canMakePayment">
      <div class="flex flex-row justify-between items-center">
        <Radio
          v-model="localSubmitters.active"
          testid="input-submitter-paymentRequest"
          :option="localSubmitters.paymentRequest"
          :label="$I18n.t(`payment_details.wallet_selector.${paymentRequest.type}`, i18nScope)"
          class="mt-4"
        />

        <img
          loading="lazy"
          :src="identifierServiceMark()"
          :alt="paymentRequest.type"
        >
      </div>

      <Divider
        label="or"
        block
      />

      <div class="flex flex-row justify-between items-center">
        <Radio
          v-model="localSubmitters.active"
          testid="input-submitter-card"
          :option="localSubmitters.card"
          :label="$I18n.t('payment_details.card_section_title', i18nScope)"
          class="mt-4"
        />

        <img
          loading="lazy"
          :src="creditCardsMark"
          :alt="$I18n.t('form.inputs.card.label', i18nScope)"
        >
      </div>
    </template>

    <div v-show="submitters.active === submitters.card">
      <div
        class="input-group"
        required
      >
        <div class="flex flex-row justify-between mb-2">
          <label class="label">{{ $I18n.t('form.inputs.card.label', i18nScope) }}</label>
          <img
            v-if="!paymentRequest.canMakePayment"
            loading="lazy"
            :src="creditCardsMark"
            :alt="$I18n.t('form.inputs.card.label', i18nScope)"
          >
        </div>
        <div class="input">
          <div data-card-element />
        </div>
      </div>
      <InputError
        testid="card-errors"
        :errors="cardErrorsFormatted"
      />
    </div>
  </div>
</template>

<script>
import Radio from '@/Components/Shared/Forms/Radio'
import InputError from '@/Components/Shared/Forms/InputError'
import Divider from '@/Components/Shared/Divider'

export default {
  components: {
    Radio,
    InputError,
    Divider
  },

  props: {
    cardErrors: Object,
    form: {
      type: Object,
      required: true
    },
    submitters: {
      type: Object,
      required: true
    },
    paymentRequest: {
      type: Object,
      required: true
    },
    i18nScope: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      localSubmitters: this.submitters
    }
  },

  computed: {
    cardErrorsFormatted() {
      if (this.cardErrors) return [this.cardErrors.message]

      return undefined
    }
  },

  created() {
    this.creditCardsMark = require('@/assets/images/CreditCardsMark.svg')
  },

  methods: {
    identifierServiceMark() {
      if (this.paymentRequest.type == 'apple') {
        return require('@/assets/images/ApplePayMark.svg')
      }
      else if (this.paymentRequest.type == 'google') {
        return require('@/assets/images/GooglePayMark.svg')
      }
      else if (this.paymentRequest.type == 'card') {
        return require('@/assets/images/GooglePayMark.svg')
      }
    }
  }
}
</script>
