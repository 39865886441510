<template>
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.47619 0.875291V0.875H8.88095V0.875291H4.11905H3.52381H0.845238H0.25V1.47053V11.5896V12.1848H0.845238H12.1548H12.75V11.5896V1.47053V0.875291H12.1548H9.47619ZM8.88095 1.47053L8.88095 11.5893H9.47619L9.47619 1.47053H12.1548V11.5896H4.11905V1.47053H8.88095ZM3.52381 11.5896V1.47053H0.845238V11.5896H3.52381ZM2.80379 2.51709C2.80379 2.81932 2.55879 3.06432 2.25656 3.06432C1.95433 3.06432 1.70932 2.81932 1.70932 2.51709C1.70932 2.21486 1.95433 1.96985 2.25656 1.96985C2.55879 1.96985 2.80379 2.21486 2.80379 2.51709ZM2.25656 8.1718C2.55879 8.1718 2.80379 7.9268 2.80379 7.62457C2.80379 7.32234 2.55879 7.07733 2.25656 7.07733C1.95433 7.07733 1.70932 7.32234 1.70932 7.62457C1.70932 7.9268 1.95433 8.1718 2.25656 8.1718ZM2.80379 5.07097C2.80379 5.3732 2.55879 5.61821 2.25656 5.61821C1.95433 5.61821 1.70932 5.3732 1.70932 5.07097C1.70932 4.76875 1.95433 4.52374 2.25656 4.52374C2.55879 4.52374 2.80379 4.76875 2.80379 5.07097ZM2.25656 10.7257C2.55879 10.7257 2.80379 10.4807 2.80379 10.1785C2.80379 9.87623 2.55879 9.63122 2.25656 9.63122C1.95433 9.63122 1.70932 9.87623 1.70932 10.1785C1.70932 10.4807 1.95433 10.7257 2.25656 10.7257ZM10.3691 10.2742C10.3691 9.97194 10.6141 9.72694 10.9164 9.72694C11.2186 9.72694 11.4636 9.97194 11.4636 10.2742C11.4636 10.5764 11.2186 10.8214 10.9164 10.8214C10.6141 10.8214 10.3691 10.5764 10.3691 10.2742ZM10.9164 4.61946C10.6141 4.61946 10.3691 4.86446 10.3691 5.16669C10.3691 5.46892 10.6141 5.71393 10.9164 5.71393C11.2186 5.71393 11.4636 5.46892 11.4636 5.16669C11.4636 4.86446 11.2186 4.61946 10.9164 4.61946ZM10.3691 7.72028C10.3691 7.41805 10.6141 7.17305 10.9164 7.17305C11.2186 7.17305 11.4636 7.41806 11.4636 7.72029C11.4636 8.02251 11.2186 8.26752 10.9164 8.26752C10.6141 8.26752 10.3691 8.02251 10.3691 7.72028ZM10.9164 2.06557C10.6141 2.06557 10.3691 2.31058 10.3691 2.6128C10.3691 2.91503 10.6141 3.16004 10.9164 3.16004C11.2186 3.16004 11.4636 2.91503 11.4636 2.6128C11.4636 2.31058 11.2186 2.06557 10.9164 2.06557Z"
      fill="black"
    />
  </svg>
</template>

<docs>
  To know more information on how to use icons see [here](/packs/#Icon)

  ```jsx
    <Icon name="Linkedin" class="text-4xl"></Icon>
  ```
</docs>
