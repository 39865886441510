<template>
  <div>
    <div class="customInputGroup relative">
      <InputGroup
        v-model.trim="voucherCode"
        testid="onboarding_checkout_code"
        placeholder="Enter discount code"
        :input-class="'custom-input-class'"
        type="code"
        autofocus
        :errors="errors"
        :success-message="successMessage"
        autocapitalize="off"
        @keydown.enter="onEnter"
      />
      <a
        :disabled="disabled || null"
        class="link--small link--blue cursor-pointer underline text-base font-normal tracking-wide absolute right-0 top-0"
        :class="{ 'cursor-not-allowed': disabled }"
        @click="onButtonClick"
      >
        {{ validated ? 'Applied' : 'Apply' }}
      </a>
    </div>
    <!-- <div class="flex flex-row mb-4 2xl:mb-8">
      <InputGroup
        class="flex-grow"
        testid="coupon"
        :label="$I18n.t('form.inputs.coupon.label', i18nScope)"
        :placeholder="$I18n.t('form.inputs.coupon.placeholder', i18nScope)"
        autocapitalize="off"
        v-model.trim="voucherCode"
        :errors="errors"
        :successMessage="successMessage"
        @keydown.enter="onEnter"
      >
        <Button
          type="button"
          :variant="buttonState"
          size="small"
          class="ml-2 mt-1"
          data-testid="apply-coupon-button"
          :disabled="disabled"
          @click="onButtonClick"
          outline
        >
          {{ validated ? 'Applied' : 'Apply' }}
        </Button>
      </InputGroup>
    </div> -->
  </div>
</template>

<script>

import EventBus from '@/event-bus'
import featureFlags from '@/mixins/featureFlags'

import InputGroup from '@/Components/Shared/Forms/InputGroup'

export default {
  components: {
    InputGroup
  },

  mixins: [featureFlags],

  props: {
    validateVoucherUrl: {
      type: String,
      default: '/basket/orders/validate_discounts'
    }
  },

  emits: ['validated', 'reset'],

  data() {
    return {
      voucherCode: '',
      validated: false,
      changed: false,
      errors: undefined,
      validating: false
    }
  },

  computed: {
    disabled() {
      return this.validating || !this.changed || !this.voucherCode.length
    },

    buttonState() {
      return this.disabled ? 'inactive' : 'blue'
    },

    successMessage() {
      return this.validated ? 'Coupon Accepted' : null
    }
  },

  watch: {
    voucherCode() {
      EventBus.emit('buyOneGiftOneCoupon', false)

      // Reset payment intent on input change
      if (this.validated) {
        this.validated = false
        this.$emit('reset')
        this.validateAndEmitDiscounts()
      }
      this.changed = true
    }
  },

  created() {
    this.i18nScope = { scope: 'pages.orders.new' }
  },

  mounted() {
    // force validation for gtm purposes
    this.validateAndEmitDiscounts()
  },

  methods: {
    async update(path, options = {}) {
      try {
        const response = await fetch(path, {
          method: 'put',
          headers: {
            'Content-Type': 'application/json'
          },
          ...options
        })
        return response.json()
      } catch {
        return {
          valid: false,
          reason: this.$I18n.t('vouchers.validate.error', this.i18nScope)
        }
      }
    },

    async validateDiscounts(code = '') {
      this.validating = true
      const response = await this.update(this.validateVoucherUrl, {
        body: JSON.stringify({
          voucher: { code }
        })
      })

      const isGift =
        response.valid && code ? (await this.checkGift(code)).status : 404

      this.validating = false

      return {
        ...response,
        isGift: isGift === 200,
        redeemableCode: this.redeemableCode(response)
      }
    },

    async checkGift(code) {
      return await fetch('/membership/check_gift', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          voucher: { code }
        })
      })
    },

    async onButtonClick(event) {
      if (this.disabled) {
        event.preventDefault()
        return
      }
        this.changed = false
        const code = this.voucherCode.trim()
        const result = await this.validateAndEmitDiscounts(code)

        if (result.valid) {
          this.validated = !!result.redeemableCode
          this.errors = this.validated ? undefined : ['Coupon not applicable']

          if (this.showBuyOneGiftOne) this.checkCodePrefix(code)
        } else {
          this.errors = [result.reason]
        }
      
    },

    async validateAndEmitDiscounts(code = '') {
      const result = await this.validateDiscounts(code)
      this.$emit('validated', result)
      return result
    },

    redeemableCode(payload) {
      // Find redeemable for current basket that is voucher (not promotion tier)
      return payload?.redeemables?.find(
        (each) =>
          // applicable status is not enough
          // we also need to check if there is applied discount from the voucher
          each.status === 'APPLICABLE' &&
          each.object === 'voucher' &&
          each.order.total_applied_discount_amount
      )
    },

    onEnter(e) {
      // Prevent form submit
      e.preventDefault()

      // Validate voucher
      this.onButtonClick()
    },

    checkCodePrefix(code, prefix = 'MOTHER\'S-DAY') {
      // Send event notifying other components
      // that the current code input belongs to a buy-one-gift-one campaign
      const isBuyOneGiftOneCoupon = code.startsWith(prefix)
      EventBus.emit('buyOneGiftOneCoupon', isBuyOneGiftOneCoupon)
      if (isBuyOneGiftOneCoupon) {
        EventBus.emit('flashMessage', {
          success:
            'Thank you! Please be aware, you will not receive a free gift voucher with this order'
        })
      }
    }
  }
}
</script>
