<template>
  <div
    data-testid="order-summary-row"
    class="cart-summary__row w-full py-4 border-b border-blue-800 last:border-b-0 border-opacity-20"
  >
    <component
      :is="itemComponent"
      class="w-full flex flex-col sm:flex-row items-start"
      :class="{'link--blue ' : shouldLinkToCourse}"
      :href="itemLink"
      as="button"
      block
    >
      <div class="flex flex-row sm:flex-grow">
        <div class="relative w-16 sm:w-24 h-14 sm:h-16 mr-4 flex-shrink-0">
          <img
            loading="lazy"
            :data-testid="`cart-item-${item.slug}-image`"
            class="image-cover rounded-sm lazyload"
            :data-src="item.imageThumbnailPath"
            :alt="item.name"
          >
        </div>

        <div class="w-full flex flex-col items-start sm:flex-grow mr-2">
          <h1
            :data-testid="`cart-item-${item.slug}-name`"
            class="title-small text-blue-800 "
          >
            {{ item.name }}
          </h1>
          <p
            v-if="cartItemCourse"
            :data-testid="`cart-item-${item.slug}-teacher`"
            class="font-light"
          >
            {{ $I18n.t('with', i18nScope) }} {{ item.teacher.fullName }}
          </p>
          <p
            v-if="cartItemCampaign"
            :data-testid="`cart-item-${item.slug}-voucher`"
          >
            {{ $I18n.t('gift_voucher', i18nScope) }}
          </p>
        </div>
      </div>

      <div
        :data-testid="`cart-item-${item.slug}-price`"
        class="w-full sm:w-auto flex items-center sm:justify-start mt-2 sm:mt-0"
        :class="{'justify-between': allowRemoval, 'justify-end': !allowRemoval }"
      >
        <p
          :data-goptimize-price="cartItem.unformattedPrice"
          class="font-normal text-lg"
          :class="{ 'sm:mr-4': allowRemoval }"
        >
          {{ monetisedGeoPrice(cartItem.geoPrice.price, 2) }}
        </p>

        <template v-if="shouldLinkToCourse">
          <Icon
            name="Arrow"
            class="text-xl ml-4"
          />
        </template>

        <Link
          v-if="allowRemoval"
          fake
          :data-testid="`cart-item-${item.slug}-remove-button`"
          class="hover:text-danger transition-colors"
          :class="{'text-danger animate-pulse': removing}"
          as="button"
          @click="onRemove"
        >
          <Icon
            name="Close"
            class="text-lg hover:text-danger transition-colors"
          />
        </Link>
      </div>
    </component>

    <template v-if="showGiftInfo">
      <div
        v-if="cartItemCampaign"
        class="flex flex-col border-l-2 border-opacity-50 border-gift pl-2 mt-4"
      >
        <template v-if="cartItem.recipientEmail">
          <div class="flex flex-row items-center">
            <Icon
              name="Email"
              class="text-yellow-800 mr-2"
            />
            <p
              :data-testid="`cart-item-${item.slug}-recipient-email`"
              class="font-normal"
            >
              {{ cartItem.recipientEmail }}
            </p>
          </div>
          <p
            class="font-normal text-sm mt-2"
            :data-testid="`cart-item-${item.slug}-voucher-message`"
          >
            <slot name="with_recipient">
              {{ $I18n.t('send_voucher_code', i18nScope) }} {{ $I18n.t('recipient_email', i18nScope) }}
            </slot>
          </p>
        </template>

        <div
          v-else
          class="flex flex-row items-center"
        >
          <Icon
            name="Email"
            class="text-yellow-800 mr-2"
          />
          <p :data-testid="`cart-item-${item.slug}-voucher-message`">
            <slot name="without_recipient">
              {{ $I18n.t('send_voucher_code', i18nScope) }} <span class="font-normal">{{ $I18n.t('your_email', i18nScope) }}</span>
            </slot>
          </p>
        </div>

        <div
          v-if="cartItem.recipientMessage"
          :data-testid="`cart-item-${item.slug}-recipient-message`"
        >
          <p class="mt-2">
            "{{ cartItem.recipientMessage }}"
          </p>
        </div>
      </div>

      <div
        v-else-if="false"
        :data-testid="`cart-item-${item.slug}-gift-message`"
        class="flex flex-row border-l-2 border-opacity-50 border-yellow-500 pl-2 mt-4"
      >
        <Icon
          name="Gift"
          class="flex-shrink-0 text-lg text-yellow-500 mr-2"
        />
        <p>{{ $I18n.t('free_gift', i18nScope) }}</p>
      </div>
    </template>

    <div
      v-if="buyOneGiftOneEnabled"
      class="mt-2 py-2 border-l border-green-500 pl-2"
    >
      <div class="w-full flex flex-col sm:flex-row items-start">
        <div class="flex flex-row sm:flex-grow">
          <div class="relative w-16 sm:w-24 h-14 sm:h-16 mr-4 flex-shrink-0">
            <img
              loading="lazy"
              :data-testid="`gift-item-${item.slug}-image`"
              class="image-cover rounded-sm lazyload"
              :src="item.imageThumbnailPath"
              :alt="item.name"
            >

            <Icon
              name="Gift"
              class="absolute top-0 left-0 text-lg text-grayscale-100 bg-green-500"
            />
          </div>

          <div class="w-full flex flex-col items-start sm:flex-grow mr-2">
            <h1
              :data-testid="`gift-item-${item.slug}-name`"
              class="title-small"
            >
              {{ $I18n.t('gift_voucher', i18nScope) }} - {{ item.name }} *
            </h1>
            <p
              :data-testid="`gift-item-${item.slug}-teacher`"
              class="font-light"
            >
              {{ $I18n.t('with', i18nScope) }} {{ item.teacher.fullName }}
            </p>
          </div>
        </div>

        <div
          class="flex flex-row sm:flex-col items-center sm:items-end mt-2 sm:mt-0"
          :class="{ 'sm:mr-8': allowRemoval }"
        >
          <p
            :data-goptimize-price="0.0"
            :data-testid="`gift-item-${item.slug}-price`"
            class="font-normal text-lg mr-2 sm:mr-0"
          >
            £00.00
          </p>
          <p
            :data-goptimize-price="cartItem.unformattedPrice"
            class="font-normal text-sm opacity-70 line-through"
          >
            {{ cartItem.humanizedAmount }}
          </p>
        </div>
      </div>
      <p class="text-sm mt-2 ml-2">
        * {{ $I18n.t('campaign', i18nScope) }}
      </p>
    </div>
  </div>
</template>

<script>
import EventBus from '@/event-bus'

import cart from '@/mixins/cart'
import mixpanel from '@/mixins/mixpanel'

import cartItemTypes from '@/mixins/cartItemTypes'
import featureFlag from '@/mixins/featureFlags'
import gtm from '@/mixins/gtm'
import Icon from '@/Components/Shared/Icon'
import Link from '@/Components/Shared/CustomLink'
import monetize from '@/mixins/monetize'


export default {

  components: {
    Link,
    Icon
  },
  mixins: [
    cart,
    mixpanel,
    cartItemTypes,
    featureFlag,
    gtm,
    monetize
  ],

  props: {
    cartItem: {
      type: Object,
      required: true
    },
    activeSale: { // presence of a site-wide sale
      type: Object,
      required: false
    },
    linkToCourse: {
      type: Boolean,
      default: false
    },
    recipientInfoMessage: {
      type: String,
      required: false
    },
    noRecipientMessage: {
      type: String,
      required: false
    },
    showGiftInfo: {
      type: Boolean,
      required: true
    },
    enableBuyOneGiftOne: {
      type: Boolean,
      default: true
    },
    allowRemoval: {
      type: Boolean,
      default: false
    },
    gtmPageName: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      buyOneGiftOneCoupon: false,
      removing: false
    }
  },

  computed: {
    buyOneGiftOneEnabled() {
      return this.cartItemCourse && this.showBuyOneGiftOne && !this.buyOneGiftOneCoupon && this.enableBuyOneGiftOne
    },

    item() {
      return this.cartItem.item
    },

    shouldLinkToCourse() {
      return this.linkToCourse && this.cartItemCourse
    },

    itemComponent() {
      return this.shouldLinkToCourse ? 'Link' : 'div'
    },

    itemLink() {
      if (this.shouldLinkToCourse) {
        return this.$routes.course_lessons(this.item.slug)
      }
      return null
    }
  },

  created() {
    this.i18nScope = { scope: 'pages.carts.show.sections.order_summary_row' }
  },

  mounted() {
    EventBus.on('buyOneGiftOneCoupon', this.setBuyOneGiftOneCoupon)
  },

  beforeUnmount() {
    EventBus.off('buyOneGiftOneCoupon', this.setBuyOneGiftOneCoupon)
  },

  methods: {
    monetisedGeoPrice(price, decimalPlaces = 2) {
      return this.geoMonetize(price, this.cartItem.geoPrice ? this.cartItem.geoPrice.currencySymbol : '£', decimalPlaces)
    },  
    setBuyOneGiftOneCoupon(value) {
      this.buyOneGiftOneCoupon = value
    },
    onRemove() {
      // Avoid multiple requests
      if (this.removing) return

      this.removing = true

      const path = this.$routes.cart_cart_item(this.cartItem.id)
      this.$inertia.delete(path, {
        preserveScroll: true,
        onSuccess: () => {
          this.removing = false
          this.trackEvent('remove_from_basket', this.mixpanelCartData(this.orderable))
          if (this.gtmPageName) this.gtmRemoveFromCart(this.gtmPageName, this.cartItem)
        },
        onError: () => {
          this.removing = false
        }
      })
    }
  }
}
</script>
