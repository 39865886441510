<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 14.2286V6M24 42V33.7714M33.7714 24H42M6 24H14.2286M30.9101 17.0909L36.7286 11.2724M11.2708 36.7282L17.0893 30.9097M30.9101 30.9091L36.7286 36.7276M11.2708 11.2718L17.0893 17.0903"
      stroke="black"
      stroke-linecap="square"
    />
  </svg>
</template>

  <docs>
    To know more information on how to use icons see [here](/packs/#Icon)

    ```jsx
      <Icon name="Linkedin" class="text-4xl"></Icon>
    ```
  </docs>
