<template>
  <div>
    <Question
      v-for="question in questions"
      :key="question.id"
      :question="question"
      :gtm-page-name="gtmPageName"
      class=""
    />
  </div>
</template>
<script>
  import Question from '@/Components/Shared/Faq/Show'

  export default {
    components: {
      Question
    },

    props: {
      questions: {
        type: Array,
        default: () => [
          {
            'q': 'When and where do these courses take place?',
            'a': 'All of our courses are online, meaning that you are able to watch them whenever and wherever suits you best. There is no beginning or finish date and they’re completely self-paced so that you can stop, start and pause each lesson to fit around your own schedule. You can begin watching a course as soon as you purchase it – it’ll be waiting for you on your Create Academy account whenever you have a quiet moment to yourself. If you’ve pre-ordered a course before it’s launched, you’ll be notified by email as soon as the course is available to watch.'
          },
          {
            'q': 'Can I rewatch the lessons?',
            'a': 'Yes! You can rewatch each lesson as many times as you’d like. Once you purchase a course you will have lifetime access to it, so you’ll be able to rewatch and dip in and out of each lesson – perfect if you have a busy schedule!'
          },
          {
            'q': 'Can I take the course if I don’t live in the UK?',
            'a': 'Yes – all our courses are available internationally. Please note, all our courses are taught in English.'
          },
          {
            'q': 'How long do I have access for?',
            'a': 'An annual subscription gives access to all courses for 12 months from the date of purchase, including those released during that period. If you renew your subscription after 12 months you\'ll keep that access. If not, you will no longer have access to the platform. Buying a course individually gives you lifetime access to that course.'
          },
          {
            'q': 'How do I get a VAT receipt for my payment?',
            'a': 'Please contact us at <a href="mailto:info@createacademy.com">info@createacademy.com</a> for a VAT receipt.'
          },
          {
            'q': 'How do I download the course workbook?',
            'a': 'Below each video lesson you’ll find the link to download the corresponding workbook pages.<br>If you’d like to download the whole workbook at once, simply navigate to the very first lesson in the course and click \'Download Workbook\'.'
          },
          {
            'q': 'What is Create Academy?',
            'a': 'We’re on a mission to help you build your creative confidence and live a more creatively fulfilled life. Through beautifully curated video courses, we give you unprecedented access to the expertise of today’s best designers, makers, gardeners and chefs - allowing you to unlock your creativity whenever and wherever you want.'
          }
        ]
      },
      gtmPageName: {
        type: String,
        required: true
      }
    },

    mounted() {
      this.buildStructuredData()
    },

    methods: {
      buildStructuredData() {
        // remove the previous faq schema
        let scripts = document.querySelectorAll('script[type="application/ld+json"]')

        scripts.forEach((script) => {
          if (JSON.parse(script.text)['@type'] === 'FAQPage') {
            script.remove()
          }
        })

        // add a new faq schema
        let newScript = document.createElement('script')
        newScript.type = 'application/ld+json'

        let schema = {
          '@context': 'https://schema.org',
          '@type': 'FAQPage'
        }

        schema.mainEntity = this.questions.map(question => {
          return {
            '@type': 'Question',
            name: question.q,
            acceptedAnswer: {
              '@type': 'Answer',
              text: question.a
            }
          }
        })

        newScript.innerHTML = JSON.stringify(schema)
        document.head.appendChild(newScript)
      }
    }
  }
</script>
