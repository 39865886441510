<template>
  <span
    v-if="genAnnouncement.ctaBodyText"
    class="inline-flex flex-row items-center"
  >
    <span data-testid="general-announcement-cta-body-text">{{ genAnnouncement.ctaBodyText }} </span>
    <Icon
      name="Chevron"
      :class="campaignTextColor"
      class="text-sm chevron-right"
    />
  </span>
</template>

<script>
import generalAnnouncement from '@/mixins/generalAnnouncement'

import Icon from '@/Components/Shared/Icon'

export default {

  components: {
    Icon
  },
  mixins: [
    generalAnnouncement
  ],

  props: {
    genAnnouncement: {
      type: Object,
      required: true
    }
  }
}
</script>

