<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.77796 18.0334L21.6528 4.64014L22.3473 5.35962L7.79141 19.4103L1.65991 13.7189L2.34023 12.9859L7.77796 18.0334Z"
    />
  </svg>
</template>

<docs>
  To know more information on how to use icons see [here](/packs/#Icon)

  ```jsx
    <Icon name="Check" class="text-4xl"></Icon>
  ```
</docs>
