<template>
  <div class="w-full pt-8 sm:pt-12">
    <p
      class="text-center text-2xl sm:text-3xl italic font-medium leading-5 font-serif mb-4"
      :class="[isMembershipGift ? 'text-blue-800' : 'text-red-500']"
    >
      You've been gifted a
    </p>
    <p
      class="text-center text-xl sm:text-2xl font-normal leading-6 sm:leading-5 tracking-wider font-sans uppercase px-4"
      :class="[isMembershipGift ? 'text-blue-800' : 'text-red-500']"
    >
      <template v-if="isMembershipGift">
        <span class="block sm:inline gift-text">CREATE ACADEMY</span>
        <span class="block sm:inline gift-text">SUBSCRIPTION</span>
      </template>
      <template v-else>
        <span class="gift-text">£{{ campaign.unformattedPrice }} GIFT VOUCHER</span>
      </template>
    </p>
  </div>
</template>

<script>
import useGiftType from './useGiftType'

export default {
  mixins: [useGiftType],
  
  props: {
    campaign: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
.gift-text {
  letter-spacing: 0.1875rem;
}
</style> 