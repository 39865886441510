<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 18L17.3376 20.9375L18 20.9375L18 4L6 4L6 20.9375L6.66237 20.9375L12 18ZM6.89844 21.9375L5 21.9375L5 3L19 3L19 21.9375L17.1016 21.9375L12 19.118L6.89844 21.9375Z"
    />
  </svg>
</template>

<docs>
  To know more information on how to use icons see [here](/packs/#Icon)

  ```jsx
    <Icon name="Lesson" class="text-4xl"></Icon>
  ```
</docs>
