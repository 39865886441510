<template>
  <div
    class="input-group"
    :class="{ 'w-full': block }"
    :required="required || null"
  >
    <label
      v-if="label"
      class="label"
      :for="computedId"
    >{{ label }}</label>
    <div class="flex flex-row">
      <input
        v-bind="$attrs"
        :id="computedId"
        ref="input"
        :name="name"
        :data-testid="`input-${testid}`"
        class="input"
        :class="['input', inputClass, { 'error': errors.length }]" 
        :hidden="hidden"
        :type="type"
        :value="modelValue"
        :autofocus="autofocus || null"
        :autocapitalize="autocapitalize"
        :placeholder="placeholder"
        @input="$emit('update:modelValue', $event.target.value)"
        v-on="type === 'number' ? { keydown: inputNumberOnKeyDown } : {}"
      >
      <slot />
    </div>
    <InputError
      :testid="testid"
      :errors="errors"
    />
    <InputSuccess
      :testid="testid"
      :success-message="successMessage"
    />
  </div>
</template>

<script>
import InputError from '@/Components/Shared/Forms/InputError'
import InputSuccess from '@/Components/Shared/Forms/InputSuccess'

/**
 * InputGroup component for Create Academy
 * @displayName InputGroup
 */
export default {
  name: 'InputGroup',

  components: {
    InputError,
    InputSuccess
  },

  props: {
    /**
     * The id for the input
     */
    id: {
      type: String,
      default: null
    },
    /**
     * The name for the input
     */
    name: {
      type: String,
      default: null
    },
    /**
     * The type of the input
     * @values text, select, text-area, ...
     */
    type: {
      type: String,
      default: 'text'
    },
    /**
     * The value for the input
     */
    modelValue: {
      type: [String, Number],
      default: null
    },
    /**
     * The label for the input - this should be more short and informative.
     */
    label: {
      type: String,
      default: null
    },
    /**
     * The placeholder for the input - this should be more explanatory of the desired input information
     */
    placeholder: {
      type: String,
      default: null
    },
    /**
     * Ability for the button to ocupy the all parent
     */
    block: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    hidden: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: String,
      default: null
    },
    autocapitalize: {
      type: String,
      default: null
    },
    errors: {
      type: Array,
      default: () => []
    },
    successMessage: {
      type: String,
      default: null
    },
    inputClass: {
      type: [String, Object, Array],
      default: ''
    },
    testid: String
  },
  emits: ['update:modelValue'],
  computed: {
    computedId() {
      return this.id || `text-input-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`
    }
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    },
    select() {
      this.$refs.input.select()
    },
   
    setSelectionRange(start, end) {
      this.$refs.input.setSelectionRange(start, end)
    },
    inputNumberOnKeyDown(event) {
      // By default number inputs accept the letter 'e' because it stands for
      // exponential. This prevents our number inputs from accepting the
      // letter 'e'.
      if (event.keyCode === 69) event.preventDefault()
    }
  }
}
</script>

<docs>
  ### ✎ Regular Input

  ```jsx
    <InputGroup
      placeholder="Insert your email"
      label="Email"
    ></InputGroup>
  ```
  ---

  ### ✎ Error Input

  ```jsx
    <InputGroup
      placeholder="Insert your email"
      label="Email"
      :errors="['The email you inserted is not valid']"
    ></InputGroup>
  ```
  ---

  ### ✎ Date Input

  ```jsx
    <InputGroup
      type="date"
      label="Insert the desired date"
    ></InputGroup>
  ```
  ---

  ### ✎ Number Input

  ```jsx
    <InputGroup
      type="number"
      label="Insert the desired date"
    ></InputGroup>
  ```
  ---

  ### ✎ Password Input

  ```jsx
    <InputGroup
      type="password"
      label="Insert your password"
    ></InputGroup>
  ```
</docs>
