<template>
  <div>
    <MetaTags :meta-tags="metaTags" />

    <!-- Hero Component -->
    <div class="bg-yellow-100">
      <div class="container pt-4 lg:pt-14 mx-auto flex flex-col items-center">
        <div
          ref="swiperContainer"
          class="designer-carousel lesson-banner-swiper"
        >
          <div class="swiper-wrapper">
            <div
              v-for="(card, index) in sliderImages"
              :key="index"
              class="swiper-slide !w-auto"
            >
              <a
                :href="card.href"
                class="block h-full"
              >
                <div class="relative overflow-hidden h-80 2xl:h-96">
                  <img
                    :src="card.image"
                    :alt="card.name"
                    class="w-full h-full object-cover object-center"
                  >
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="w-full lg:max-w-4xl pt-12 flex flex-col justify-center items-center">
          <div class="text-sm font-semibold">
            <span class="mr-1 text-ratingGreen">★★★★★</span>
            <span class="text-black text-sm font-medium">Rated 4.8/5 on Trustpilot</span>
          </div>
          <h1 class="text-4xl lg:text-5xl font-serif my-3.5 text-blue-800 text-center">
            Start your learning journey
          </h1>
          <p
            class="text-grayscale-700 tracking-wide lg:text-black text-lg lg:text-2xl text-center font-normal"
          >
            Watch a selection of free lessons from each of our courses so you can meet your new
            instructors and enjoy a sample of what you'll learn on the full course.
          </p>
        </div>
      </div>
    </div>

    <div class="w-full relative py-6 lg:py-11">
      <div class="container pt-0 pb-6 lg:pb-11">
        <h2
          class="text-2xl text-center lg:text-left lg:text-3xl text-yellow-800 lg:text-black font-normal lg:font-medium mb-6"
        >
          {{ totalFreeLessons }} Free Lessons
        </h2>
        <!-- Category Tabs -->
        <div class="relative">
          <div class="flex overflow-x-auto border-b border-tabBorder scrollRemove">
            <button
              :class="[
                'py-2.5 px-4 font-medium focus:outline-none flex-none',
                activeCategory === null
                  ? 'border-b-2 border-tabHighlight text-tabHighlight'
                  : 'text-grayscale-700 hover:text-grayscale-700'
              ]"
              @click="activeCategory = null"
            >
              All ({{ totalFreeLessons }})
            </button>
            <button
              v-for="category in sortedCategories"
              :key="category.id"
              :class="[
                'py-2.5 px-4 font-medium focus:outline-none flex-none',
                activeCategory === category.id
                  ? 'border-b-2 border-tabHighlight text-tabHighlight'
                  : 'text-grayscale-700 hover:text-grayscale-700'
              ]"
              @click="activeCategory = category.id"
            >
              {{ category.name }} ({{ category.lessonCount }})
            </button>
          </div>
        </div>
      </div>
      <div class="w-full relative">
        <!-- Category Content -->
        <div
          v-for="category in sortedFreeLessonsByCategory"
          :key="category.id"
        >
          <div
            v-show="activeCategory === null || activeCategory === category.id"
            :id="`category-${category.id}`"
          >
            <div class="relative bg-blue-100">
              <div class="container py-2 lg:py-6">
                <h2 class="text-xs leading-7 lg:text-2xl font-medium text-black tracking-widest">
                  {{ category.name.toUpperCase() }}
                </h2>
              </div>
            </div>
            <div class="container py-5 lg:py-8">
              <div class="flex flex-col gap-8">
                <div
                  v-for="course in category.courses"
                  :key="course.id"
                  class="relative"
                >
                  <h3 class="text-xl font-medium text-blue-800 mb-1.5 lg:mb-0">
                    {{ course.name }}
                  </h3>
                  <p class="text-gray-600 font-normal mb-5 lg:mb-4">
                    Instructor: {{ course.teacherName }}
                  </p>
                  <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 lg:gap-5">
                    <div
                      v-for="lesson in course.lessons"
                      :key="lesson.id"
                      class="bg-white overflow-hidden border border-tabBorder rounded-md"
                    >
                      <div
                        class="cursor-pointer"
                        @click="handleLessonClick(course.slug, lesson.slug)"
                      >
                        <BrightcovePlayer
                          :id="`free-lesson-${lesson.id}`"
                          :options="{
                            videoId: lesson.videoId,
                            ...brightcove.lessonPlayer,
                            autoplay: false
                          }"
                          class="w-full aspect-video pointer-events-none"
                        />
                      </div>
                      <div class="p-4 text-center lg:text-left">
                        <h4 class="text-lg text-center lg:text-left font-medium text-blue-800 mb-2">
                          {{ lesson.title }}
                        </h4>
                        <p
                          v-clamp="3"
                          class="text-gray-600 font-normal hidden lg:block mb-2 line-clamp-3"
                        >
                          {{ lesson.description }}
                        </p>
                        <a
                          :href="`/courses/${course.slug}/lessons/${lesson.slug}`"
                          class="text-yellow-800 text-sm lg:text-base hover:underline"
                        >
                          View full lesson
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent, computed, ref, directive, onMounted } from 'vue'
  import BrightcovePlayer from '@/vendor/brightcove/BrightcovePlayer.vue'
  import Swiper from 'swiper/bundle'
  import 'swiper/swiper-bundle.css'
  import MetaTags from '@/Components/Shared/MetaTags'
  import { getS3Url } from '../../s3utils'

  export default defineComponent({
    components: {
      BrightcovePlayer,
      MetaTags
    },

    directives: {
      clamp: {
        mounted(el, binding) {
          const lines = binding.value || 3
          el.style.overflow = 'hidden'
          el.style.textOverflow = 'ellipsis'
          el.style.display = '-webkit-box'
          el.style.webkitLineClamp = lines
          el.style.webkitBoxOrient = 'vertical'
        }
      }
    },
    props: {
      freeLessonsByCategory: {
        type: Array,
        required: true
      },
      brightcove: {
        type: Object,
        required: true
      }
    },
    setup(props) {
      const swiperContainer = ref(null)
      let swiper = null

      const metaTags = computed(() => ({
        title: 'Free Lessons',
        meta: [
          {
            name: 'description',
            content: 'Explore our selection of free lessons from various courses.'
          }
        ]
      }))

      const sliderImages = ref([
        { image: getS3Url('0.jpg') },
        { image: getS3Url('1.jpg') },
        { image: getS3Url('2.jpg') },
        { image: getS3Url('7.jpg') },
        { image: getS3Url('14.jpg') },
        { image: getS3Url('8.jpg') },
        { image: getS3Url('30.jpg') },
        { image: getS3Url('6.jpg') },
        { image: getS3Url('gabby.jpg') },
        { image: getS3Url('18.jpg') },
        { image: getS3Url('3.jpg') },
        { image: getS3Url('11.jpg') },
        { image: getS3Url('4.jpg') },
        { image: getS3Url('13.jpg') },
        { image: getS3Url('9.jpg') },
        { image: getS3Url('17.jpg') },
        { image: getS3Url('16.jpg') },
        { image: getS3Url('10.jpg') },
        { image: getS3Url('12.jpg') }
      ])

      const totalFreeLessons = computed(() =>
        props.freeLessonsByCategory.reduce(
          (total, category) =>
            total +
            category.courses.reduce(
              (courseTotal, course) => courseTotal + course.lessons.length,
              0
            ),
          0
        )
      )

      const sortedFreeLessonsByCategory = computed(() => {
        return [...props.freeLessonsByCategory]
          .sort((a, b) => {
            if (a.name === 'Home & Interiors') return -1
            if (b.name === 'Home & Interiors') return 1
            if (a.name === 'Bestsellers') return -1
            if (b.name === 'Bestsellers') return 1
            return a.name.localeCompare(b.name)
          })
          .map(category => ({
            ...category,
            courses: category.courses
              .sort((a, b) => b.teacherName.localeCompare(a.teacherName))
              .map(course => ({
                ...course,
                lessons: course.lessons.sort((a, b) => (a.sequence || 0) - (b.sequence || 0))
              }))
          }))
      })

      const sortedCategories = computed(() =>
        sortedFreeLessonsByCategory.value.map(category => ({
          id: category.id,
          name: category.name,
          lessonCount: category.courses.reduce((total, course) => total + course.lessons.length, 0)
        }))
      )

      const activeCategory = ref(null)

      const handleLessonClick = (courseSlug, lessonSlug) => {
        window.location.href = `/courses/${courseSlug}/lessons/${lessonSlug}`
      }

      onMounted(() => {
        swiper = new Swiper(swiperContainer.value, {
          slidesPerView: 1.2,
          spaceBetween: 8,
          loop: true,
          autoplay: {
            delay: 3500,
            disableOnInteraction: false
          },
          breakpoints: {
            540: {
              slidesPerView: 2,
              spaceBetween: 8
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 16
            }
          }
        })
      })

      return {
        totalFreeLessons,
        sortedCategories,
        sortedFreeLessonsByCategory,
        activeCategory,
        handleLessonClick,
        swiperContainer,
        sliderImages,
        metaTags
      }
    }
  })
</script>

<style>
  .line-clamp-3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .designer-carousel {
    margin: 0 -5px;
    padding: 0 5px;
  }
  .scrollRemove::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 640px) {
    .designer-carousel {
      margin: 0 -10px;
      padding: 0 10px;
    }
    .line-clamp-3 {
      display: none !important;
    }
  }
  @media (min-width: 1301px) {
    .designer-carousel .swiper-slide {
      width: 25% !important;
    }
  }
  @media (max-width: 1300px) {
    .designer-carousel .swiper-slide {
      width: 25% !important;
    }
  }
  @media (max-width: 930px) {
    .designer-carousel .swiper-slide {
      width: 50% !important;
    }
  }
  @media (max-width: 540px) {
    .designer-carousel .swiper-slide {
      width: 100% !important;
    }
  }
</style>
