<template>
  <div>
    <MetaTags :meta-tags="metaTags" />

    <div data-testid="banner-section">
      <Banner
        uid="support"
        :image-src="SupportBanner"
        :image-alt="$I18n.t('support_banner', i18nScope)"
        height="medium"
        content-align="center"
      >
        <h1
          data-testid="support-title"
          class="banner__title"
        >
          {{ $I18n.t('faq', i18nScope) }}
        </h1>
        <p
          data-testid="support-description"
          class="banner__subtitle"
        >
          {{ $I18n.t('description', i18nScope) }}
        </p>
      </Banner>
    </div>
    <div class="container-sm">
      <template
        v-for="section in faq"
        :key="section.id"
      >
        <h2
          v-if="section.section.title"
          :key="section.id"
          class="subheading--medium mb-8 text-yellow-800"
        >
          {{ section.section.title }}
        </h2>
        <Faq
          class="mb-14"
          :questions="section.section.questions"
          :gtm-page-name="currentPage"
        />
      </template>

      <Divider spacing="xl" />

      <h2 class="font-normal text-2xl text-blue-800 text-center mb-2">
        {{ $I18n.t('need_help', i18nScope) }}
      </h2>

      <p class="text-xl text-center">
        {{ $I18n.t('contact_us_on', i18nScope) }}
        <a :href="'mailto:' + $I18n.t('help_email', i18nScope)">{{ $I18n.t('help_email', i18nScope) }}</a>
      </p>
    </div>
  </div>
</template>

<script>
import { metaTags } from '@/mixins/metaTags'

import MetaTags from '@/Components/Shared/MetaTags'
import Banner from '@/Components/Shared/Banner'
import Divider from '@/Components/Shared/Divider'
import Faq from '@/Components/Shared/Faq/Index'
import Layout from '@/Layouts/TransparentLayout'

export default {

  components: {
    Banner,
    Divider,
    Faq,
    MetaTags
  },

  mixins: [
    metaTags
  ],
  layout: Layout,

  props: {
    faq: {
      type: Array,
      default: () => [
        {
          'section':
          {
            'questions': [
              {
                'q': 'When and where do these courses take place?',
                'a': 'All of our courses are online, meaning that you are able to watch them whenever and wherever suits you best. There is no beginning or finish date and they’re completely self-paced so that you can stop, start and pause each lesson to fit around your own schedule. You can begin watching a course as soon as you purchase it – it’ll be waiting for you on your Create Academy account whenever you have a quiet moment to yourself. If you’ve pre-ordered a course before it’s launched, you’ll be notified by email as soon as the course is available to watch.'
              },
              {
                'q': 'Can I rewatch the lessons?',
                'a': 'Yes! You can rewatch each lesson as many times as you’d like. Once you purchase a course you will have lifetime access to it, so you’ll be able to rewatch and dip in and out of each lesson – perfect if you have a busy schedule!'
              },
              {
                'q': 'How do I download the course workbook?',
                'a': 'Below each video lesson you’ll find the link to download the corresponding workbook pages.<br>If you’d like to download the whole workbook at once, simply navigate to the very first lesson in the course and click \'Download Workbook\'.'
              },
              {
                'q': 'How long do I have access for?',
                'a': 'How does lifetime access sound? After purchasing a course, you’ll have unlimited access to it for as long as you like – across all of your devices.'
              },
              {
                'q': 'Can I take the course if I don’t live in the UK?',
                'a': 'Yes – all our courses are available internationally. Please note, all our courses are taught in English.'
              },
              {
                'q': 'I have been given a course as a gift. How do I access it?',
                'a': 'To redeem your gift, simply select the course you’d like to take then enter the code from your voucher at the checkout. Please contact us at <a href="mailto:info@createacademy.com">info@createacademy.com</a> if you need further support.'
              },
              {
                'q': 'Can I have a certificate once completing a course?',
                'a': 'We do not currently provide certificates for our courses.'
              }
            ]
          }
        },
        {
          'section':
          {
            'title': 'Purchasing and payment issues',
            'questions': [
              {
                'q': 'How do I use my discount code on the checkout?',
                'a': 'Once you’ve added a course to your basket, go to the <a href="/basket">‘Your Basket’</a> page and click ‘Complete My Order’. On this checkout page in the ‘Payment Details’ section, you’ll see a box which says ‘Apply Your Gift Code or Discount Code’. Paste your code into this box and click ‘Apply’. If your code is valid you’ll see a green message saying ‘Coupon Accepted’, and you’ll see the discount applied to your total.'
              },
              {
                'q': 'How do I get a VAT receipt for my payment?',
                'a': 'Please contact us at <a href="mailto:info@createacademy.com">info@createacademy.com</a> for a VAT receipt.'
              }
            ]
          }
        },
        {
          'section':
          {
            'title': 'Login, email address and password issues',
            'questions': [
              {
                'q': 'How do I login and watch my course?',
                'a': 'Go to our <a href="/sign_in">‘Log In’</a> page and enter the email address and password created when you purchased the course. Once logged in, scroll down the page to see a section called ‘My Courses’ with all your purchased courses. You can also find a link to <a href="/#my-courses">‘My Courses’</a> in the menu on the top right of the page once logged in.'
              },
              {
                'q': 'I’ve forgotten my email address.',
                'a': 'No problem. Please contact us at <a href="mailto:info@createacademy.com">info@createacademy.com</a> with the full name on your account and we’ll help you find it.'
              },
              {
                'q': 'I’ve forgotten my password.',
                'a': 'If you have forgotten your password, but can remember your email address, please go to the <a href="/forgot_password">‘Forgot Password?’</a> page and enter the email address on your Create Academy account. This will send instructions via email to reset your password. If you cannot remember your password or email address then please contact us at <a href="mailto:info@createacademy.com">info@createacademy.com</a> so we can help find your details.'
              },
              {
                'q': 'I would like to change my email address.',
                'a': 'You can update your email address in the <a href="/profile">‘My Profile’</a> section of your account. Simply enter your new email address on this page and click ‘Update Information’ to save the changes. If you’ve created an account using Google Sign-in or Facebook Sign-in you won’t be able to change your email address on this page. Contact us at <a href="mailto:info@createacademy.com">info@createacademy.com</a> if you need any help with this.'
              },
              {
                'q': 'I have signed-up with the wrong email address. Now I can’t login to watch the course!',
                'a': 'If you don’t have access to the email address you purchased the course with then please contact us at <a href="mailto:info@createacademy.com">info@createacademy.com</a> and we can reset your email address to give you access.'
              },
              {
                'q': 'I would like to change my password.',
                'a': 'To update your password, go to the <a href="/password">‘Change Password’</a> section of your account, enter your current and new password, and click ‘Update Password’.'
              }
            ]
          }
        },
        {
          'section':
          {
            'title': 'Refunds',
            'questions': [
              {
                'q': 'I would like a refund for the course.',
                'a': 'We’re sorry to hear your course didn’t meet your expectations. Please <a href="https://survey.typeform.com/to/s1hcuOO0" target="_blank">click here</a> to fill out our refund form, and we’ll get back to you within two working days.'
              }
            ]
          }
        }
      ]
    }
  },

  computed: {
    currentPage() {
      return 'Support'
    }
  },

  created() {
    this.SupportBanner = {
      url: require('@/assets/images/backgrounds/support-background.jpg'),
      sizes: {
        large: require('@/assets/images/backgrounds/support-background.jpg'),
        medium: require('@/assets/images/backgrounds/support-background-medium.jpg'),
        small: require('@/assets/images/backgrounds/support-background-small.jpg')
      }
    }

    this.i18nScope = { scope: 'pages.static' }
    this.metaTags = {
      title: 'Support & FAQ | Create Academy',
      description: 'Get help and find answers to frequently asked questions about Create Academy\'s online courses, platform features, and learning experience.',
      robots: 'index, follow',
      og: {
        title: 'Support & FAQ | Create Academy',
        description: 'Get help and find answers to frequently asked questions about Create Academy\'s online courses, platform features, and learning experience.',
        type: 'website'
      }
    }
  }
}
</script>
