<template>
  <main>
    <GeneralAnnouncement v-if="showGeneralAnnouncement" />
    <TopBanner
      v-if="!showGeneralAnnouncement && !currentUser"
      slider-id="home-top-banner-slider"
    />

    <modals-container class="modals-container" />

    <slot />
  </main>
</template>

<script>
  import { container } from 'jenesius-vue-modal'
  import currentUser from '@/mixins/currentUser'
  import paramAuthModal from '@/mixins/paramAuthModal'
  import mixpanel from '@/mixins/mixpanel'
  import generalAnnouncement from '@/mixins/generalAnnouncement'
  import TopBanner from '@/Components/Shared/TopBanner/Index'
  import GeneralAnnouncement from '@/Components/Shared/Campaigns/GeneralAnnouncement/Show'

  export default {

    components: {
      GeneralAnnouncement,
      TopBanner,
      ModalsContainer: container
    },
    mixins: [currentUser, generalAnnouncement, mixpanel, paramAuthModal],

    computed: {
      pageTitle() {
        return this.$page?.props?.metaTitle || 'Create Academy'
      }
    },

    watch: {
      '$page.props.metaTitle': {
        immediate: true,
        handler(title) {
          if (title) {
            document.title = title
          }
        }
      }
    },
    mounted() {
      this.$nextTick(() => {
        // Code that will run only after the
        // entire view has been rendered
        this.setMixpanelProfile()
        window.dataLayer.push({ event: 'optimize.activate' })
      })
    },

    // we use here the beforemount because we want to make sure pageview events
    // are triggered before specific page events that will be defined on the mount hook
    // beforeMount() {
    //   this.$once(
    //     'hook:destroyed',
    //     this.$inertia.on('navigate', () => {
    //       // return if the gtm base props are not defined
    //       if (typeof this.$page?.props?.gtm == 'undefined') {
    //         return
    //       }

    //       window.dataLayer = window.dataLayer || []
    //       window.dataLayer.push({
    //         event: 'pageview',
    //         hostname: window.location.host,
    //         url: window.location.href,
    //         path: window.location.pathname,
    //         path_query: window.location.search,
    //         page_title:
    //           this.$page?.props?.metaTitle ||
    //           this.$page?.props?.metaTags?.title ||
    //           'Create Academy',
    //         environment: this.$page.props.gtm.gtmEnv,
    //         auth_status: this.$page.props.gtm.gtmAuthStatus,
    //         page_type: this.$page.props.gtm.gtmPageType
    //       })
    //     })
    //   )
    // },

    updated() {
      this.$nextTick(() => {
        this.setMixpanelProfile()
        // check if there are any params to open the auth modal
        this.openModalWithParam()
        window.dataLayer.push({ event: 'optimize.activate' })
      })
    },

    methods: {
      setMixpanelProfile() {
        if (this.currentUser) {
          const { id, email, account, tier } = this.currentUser
          this.identify(id)
          this.trackProfile({ $email: email, $name: account?.name, tier })
        }
      }
    }
  }
</script>
