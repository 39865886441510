<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.51282 3H2V3.51282V17.6339V18.1467H2.51282H12.7384V17.1211H5.80055L12.4413 9.91117L14.8507 12.4369L15.5928 11.729L12.8058 8.80737L12.4282 8.41151L12.0575 8.81392L4.40616 17.1211H3.02564V4.02564H18.9453V11.4206H19.971V3.51282V3H19.4581H2.51282ZM18.0981 20.458V17.5818H15.2219V16.5562H18.0981V13.6799H19.1238V16.5562H22V17.5818H19.1238V20.458H18.0981ZM7.10222 9.86736C8.07708 9.86736 8.86736 9.07708 8.86736 8.10222C8.86736 7.12736 8.07708 6.33708 7.10222 6.33708C6.12736 6.33708 5.33708 7.12736 5.33708 8.10222C5.33708 9.07708 6.12736 9.86736 7.10222 9.86736Z"
    />
  </svg>
</template>

<docs>
  To know more information on how to use icons see [here](/packs/#Icon)

  ```jsx
    <Icon name="Upload" class="text-4xl"></Icon>
  ```
</docs>
