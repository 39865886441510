<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.02632 6.76316L4.5 6.76316L4.5 7.28947L4.5 20.9737L4.5 21.5L5.02632 21.5L20.2895 21.5L20.8158 21.5L20.8158 20.9737L20.8158 7.28947L20.8158 6.76316L20.2895 6.76316L17.8711 6.76316C17.6328 5.79786 17.2679 4.92376 16.8091 4.17719C15.8753 2.56026 14.3879 1.5 12.7065 1.5C11.1058 1.5 9.72308 2.45672 8.76388 3.88412C8.21775 4.69684 7.79648 5.67687 7.53458 6.76316L5.02632 6.76316ZM7.33609 7.81579C7.26553 8.32663 7.22872 8.85474 7.22872 9.39474L8.28135 9.39474C8.28135 8.84938 8.32246 8.32099 8.39973 7.81579L17.0102 7.81579C17.0892 8.31901 17.1317 8.84706 17.1317 9.39474L18.1843 9.39474C18.1843 8.85208 18.1463 8.32429 18.0744 7.81579L19.7632 7.81579L19.7632 20.4474L5.55263 20.4474L5.55263 7.81579L7.33609 7.81579ZM16.783 6.76316L8.62095 6.76316C8.85724 5.88966 9.2068 5.11228 9.63757 4.47123C10.4674 3.23633 11.5604 2.55263 12.7065 2.55263C13.9141 2.55263 15.0995 3.31809 15.9008 4.70929L15.9047 4.71602L15.9088 4.72264C16.2703 5.30952 16.5705 5.99747 16.783 6.76316Z"
    />
  </svg>
</template>

<docs>
  To know more information on how to use icons see [here](/packs/#Icon)

  ```jsx
    <Icon name="Basket" class="text-4xl"></Icon>
  ```
</docs>
