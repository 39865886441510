import stringHelpers from '@/mixins/stringHelpers'
import cartItemTypes from '@/mixins/cartItemTypes'
import productTypes from '@/mixins/productTypes'

import gtmV3 from '@/mixins/gtm/gtmV3'
import gtmV4 from '@/mixins/gtm/gtmV4'

export const gtm = {
  mixins: [
    stringHelpers,
    cartItemTypes,
    productTypes,
    gtmV3,
    gtmV4
  ],

  computed: {
    gtmGetLastImpressionClicked(){
      const clickedImpressions = window.dataLayer.filter((item) => {
        return item['event'] === 'eec.impressionClick'
      })

      const clickedImpressionsLength = clickedImpressions.length

      let clickedList = undefined

      if (clickedImpressionsLength > 0) {
        clickedList = clickedImpressions[clickedImpressionsLength - 1]['ecommerce']['click']['actionField']['list']
      }

      return clickedList
    }
  },

  methods: {
    /********************************************
     * Internal methods                         *
     ********************************************/
    gtmAddGaEvent(category, action, label, value) {
      const content = {
        event: 'gaEvent',
        eventCategory: category,
        eventAction: action,
        eventLabel: label,
        eventValue: value
      }

      this.gtmAddEvent(content)
    },

    gtmAddEvent(content) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push(this.removeNull(content))
    },

    // to force events to be added after the pageview event
    // TODO: Find Vue 3-compatible solution
    //gtmAddToDataLayerAfterPageview(content) {
    //  this.$once(
    //    'hook:destroyed',
    //    this.$inertia.on('navigate', () => {
    //      this.gtmAddEvent(content)
    //    })
    //  )
    //},

    // changes all the null values to undefined
    removeNull(obj) {
      Object.keys(obj).forEach(k => {
        if (obj[k] && typeof obj[k] === 'object') {
          obj[k] = this.removeNull(obj[k])
        } else if (obj[k] === null || obj[k] === '') {
          obj[k] = undefined
        }
      })
      return obj
    },

    /********************************************
     * Events senders                           *
     ********************************************/
    gtmAddToCart(context, product, section = null) {
      this.gtmAddEvent(this.gtmV3AddToCart(context, product, section))
      this.gtmAddEvent(this.gtmV4AddToCart(context, product))
    },

    gtmRemoveFromCart(context, cartItem) {
      this.gtmAddEvent(this.gtmV3RemoveFromCart(context, cartItem))
      this.gtmAddEvent(this.gtmV4RemoveFromCart(context, cartItem))
    },

    gtmAddViewCart(cart, cartType) {
      this.gtmAddEvent(this.gtmV4AddViewCart(cart, cartType))
    },

    gtmAddCheckoutBegin(cart) {
      this.gtmAddEvent(this.gtmV3CheckoutBegin(cart))
      this.gtmAddEvent(this.gtmV4CheckoutBegin(cart))
    },

    gtmAddPaymentInfo(cart, paymentType) {
      this.gtmAddEvent(this.gtmV4PaymentInfo(cart, paymentType))
    },

    gtmAddPurchaseStep(cart, order, voucherPayload) {
      this.gtmAddEvent(this.gtmV3PurchaseStep(cart, order, voucherPayload))
      this.gtmAddEvent(this.gtmV4PurchaseStep(cart, order, voucherPayload))
    },

    gtmAddProductListingImpressions(page, sections) {
      //this.gtmAddToDataLayerAfterPageview(this.gtmV3ProductListingImpressions(page, sections))
      //this.gtmAddToDataLayerAfterPageview(this.gtmV4ProductListingImpressions(page, sections))
    },

    gtmAddProductListingImpressionClick(page, section, impression, index) {
      this.gtmAddEvent(this.gtmV3ProductListingImpressionClick(page, section, impression, index))
      this.gtmAddEvent(this.gtmV4ProductListingImpressionClick(page, section, impression, index))
    },

    gtmAddProductDetailView(product) {
      //this.gtmAddToDataLayerAfterPageview(this.gtmV3ProductDetailView(product))
      //this.gtmAddToDataLayerAfterPageview(this.gtmV4ProductDetailView(product))
    },

    gtmAddAboutPromo(page, action) {
      this.gtmAddGaEvent(`${page} | About Promo`, action, undefined, undefined)
    },

    gtmAddTrailerTracking(page, section, action, progress) {
      this.gtmAddGaEvent('Trailer Viewed', action, `${page} | ${section}`, progress)
    },

    gtmAddRegistrationTracking(action) {
      this.gtmAddGaEvent('CompleteRegistration', action, undefined, undefined)
    },  

    gtmAddSubscriptionAnnualTracking(action) {
      this.gtmAddGaEvent('SubscribedAnnual', action, undefined, undefined)
    },  

    gtmAddCategoryInteraction(page, action) {
      this.gtmAddGaEvent(`${page} | Category Banner`, action, undefined, undefined)
    },

    gtmAddFaqInteraction(action, label) {
      this.gtmAddGaEvent('FAQ Interaction', this.truncateByWords(action, 10), label, undefined)
    },

    gtmAddSocialProofInteraction(action, label) {
      this.gtmAddGaEvent('Social Proof Interaction', action, label, undefined)
    },

    gtmAddFreeLesson(action, label) {
      this.gtmAddGaEvent('Free Lesson', action, label, undefined)
    },

    gtmAddFooterNavigation(action) {
      this.gtmAddGaEvent('Footer Navigation', action, undefined, undefined)
    },

    gtmAddSocialInteraction(action) {
      this.gtmAddGaEvent('Social Interaction', action, undefined, undefined)
    },

    gtmAddGiftThisCourse(action) {
      this.gtmAddGaEvent('Gift this course click', action, undefined, undefined)
    },

    /********************************************
     * Getters                                  *
     ********************************************/
    gtmGetVoucherData(payload) {
      const products = {}
      const applyToItemsCodes = {}
      const applyToOrderCodes = []

      // Map order items to applicable codes
      payload.redeemables?.forEach(redeemable => {
        // Ignore redeemables not applicable to cart/items
        if (redeemable.status !== 'APPLICABLE') {
          return
        }
        // Redeemable applied to one or more items
        if (redeemable.applicable_to.total > 0) {
          redeemable.applicable_to.data.forEach(({ source_id }) => {
            const hasItem = source_id in applyToItemsCodes
            if (!hasItem) applyToItemsCodes[source_id] = []

            applyToItemsCodes[source_id].push(redeemable.id)
          })
        }
        // Redeemable applied to order
        else {
          applyToOrderCodes.push(redeemable.id)
        }
      })

      if (payload.order) {
        const cartAmount = payload.order.amount
        const cartAppliedDiscount = payload.order.applied_discount_amount || 0

        // Map order items to final price after discounts
        payload.order.items.forEach(({ source_id, price, subtotal_amount }) => {
          const hasItem = source_id in products
          if (!hasItem) products[source_id] = { coupons: applyToItemsCodes[source_id] }

          products[source_id].discounted = true

          // We calculate the weight (percentage) of the item price on the total cart price
          // Then, we use that weight to calculate the proportion of the total cart discount that should
          // be applied to the item
          // This way, we ensure that the discount is spread proportionally across all cart items
          const subtotal_amount_weight = (price * 100) / cartAmount / 100
          products[source_id].price = subtotal_amount - (cartAppliedDiscount * subtotal_amount_weight)
        })
      }

      return {
        cartCoupons: applyToOrderCodes,
        products
      }
    },

    gtmGetPriceWithDiscountDetails(product, voucherValidation) {
      const { discounted = false, price, coupons = [] } = voucherValidation[product.slug] || {}
      const amount = discounted ? (price / 100.0) : product.unformatted_price
      const discountValue = product.unformatted_price - amount

      return {
        amount: amount,
        discounted: discounted,
        discountValue: discountValue,
        coupons: coupons
      }
    }
  }
}

export default gtm
