<template>
  <div data-testid="course-upcoming">
    <Link
      :data-testid="`course-upcoming-${course.id}-link`"
      :href="$routes.course(course.slug)"
      class="flex flex-row flex-nowrap"
      @click="addImpressionClickToGtm"
    >
      <div class="w-36 h-40 overflow-hidden flex-shrink-0">
        <img
          loading="lazy"
          :data-testid="`course-upcoming-${course.id}-image`"
          :data-src="course.image"
          :alt="`${course.name} ${$I18n.t('talents.thumbnail', i18nScope)}`"
          class="lazyload image-cover"
        >
      </div>

      <div class="w-full flex flex-col justify-between ml-4 py-1">
        <div class="flex flex-col">
          <h2
            :data-testid="`course-upcoming-${course.id}-name`"
            class="title-medium text-blue-800 mb-0"
          >
            {{ course.name }}
          </h2>
          <p
            :data-testid="`course-upcoming-${course.id}-teacher`"
            class="subheading text-grayscale-700"
          >
            {{ course.teacher.name }}
          </p>
        </div>

        <Icon
          name="Arrow"
          class="text-2xl text-grayscale-700 self-end"
        />
      </div>
    </Link>
  </div>
</template>

<script>
import gtm from '@/mixins/gtm'

import { Link } from '@inertiajs/vue3'
import Icon from '@/Components/Shared/Icon'

export default {

  components: {
    Link,
    Icon
  },
  mixins: [
    gtm
  ],

  props: {
    course: {
      type: Object,
      required: true
    },
    gtmPageName: {
      type: String,
      required: true
    },
    gtmSectionName: {
      type: String,
      required: true
    },
    gtmPosition: {
      type: Number,
      required: true
    }
  },
  created() {
    this.i18nScope = { scope: 'pages.home' }
  },

  methods: {
    addImpressionClickToGtm() {
      this.gtmAddProductListingImpressionClick(this.gtmPageName, this.gtmSectionName, this.course, this.gtmPosition)
    }
  }
}
</script>
