<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 1L21.6 11.9392L4 23V1ZM5.00898 2.81512V21.1742L19.6963 11.944L5.00898 2.81512Z"
    />
  </svg>
</template>

<docs>
  To know more information on how to use icons see [here](/packs/#Icon)

  ```jsx
    <Icon name="Play" class="text-4xl"></Icon>
  ```
</docs>
