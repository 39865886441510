<template>
  <div class="container">
    <MetaTags :meta-tags="metaTags" />

    <!-- bump -->
    <LessonHeader
      :course="course"
      :lesson="lesson"
    />

    <div class="flex flex-row flex-wrap mb-4 lg:mb-6">
      <Lesson360TourSection
        v-if="embedID"
        :embed-i-d="embedID"
        :course="course"
        :lesson="lesson"
        gtm-page-name="Lessons"
      />
      <PlayerSection
        v-else
        :course="course"
        :lesson="lesson"
        :membership-plan="membershipPlan"
        :lesson-enrollments="lessonEnrollments"
        :enable-upsell="true"
        :brightcove="brightcove"
        gtm-page-name="Lessons"
      />

      <Curriculum
        :course="course"
        :lesson="lesson"
        :lesson-enrollments="lessonEnrollments"
      />
    </div>

    <LessonTabMenu
      :course="course"
      :lesson="lesson"
      :is-mobile="isMobile"
    />
  </div>
</template>

<script>
import { metaTags } from '@/mixins/metaTags'
import urlParam from '@/mixins/urlParam'
import currentUser from '@/mixins/currentUser'
import gtm from '@/mixins/gtm'

import MetaTags from '@/Components/Shared/MetaTags'
import LessonHeader from '@/Components/Courses/Lessons/LessonHeader'
import LessonTabMenu from '@/Components/Courses/Lessons/LessonTabMenu'
import { defineAsyncComponent } from 'vue'

export default {
  mixins: [
    metaTags,
    urlParam,
    currentUser,
    gtm
  ],

  computed: {
    currentPath() {
      return window.location.pathname
    },
    embedID() {
      return this.currentPath.split('/')
        .find(part => part.includes('360-tour-'))?.split('360-tour-').pop() ?? null
    },
    isFreeLessonGtmEvent() {
      const freeLessonParam = this.getUrlParam('free_lesson')
      return freeLessonParam && !this.enrolled && this.lesson.free
    }
  },

  beforeUnmount() {
    if (this.isFreeLessonGtmEvent) {
      const authParam = this.getUrlParam('authentication')

      this.gtmAddGaEvent(
        'Free Lesson',
        'Authentication - ' + authParam,
        this.course.teacher.fullName,
        undefined
      )
    }
  },

  components: {
    MetaTags,
    LessonHeader,
    Curriculum: defineAsyncComponent(() => import('@/Components/Courses/Lessons/Curriculum')),
    Lesson360TourSection: defineAsyncComponent(() => import('@/Components/Courses/Lessons/Lesson360TourSection')),
    PlayerSection: defineAsyncComponent(() => import('@/Components/Courses/Lessons/PlayerSection')),
    LessonTabMenu
  },

  props: {
    course: {
      type: Object,
      required: true
    },
    lesson: {
      type: Object,
      required: true
    },
    lessonEnrollments: {
      type: Array,
      required: true
    },
    membershipPlan: {
      type: Object,
      default: null
    },
    brightcove: {
      type: Object,
      required: true
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    metaTags: {
      type: Object,
      required: true
    }
  }
}
</script>
