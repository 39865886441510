<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.01153 11C1.01153 5.48352 5.48352 1.01153 11 1.01153C16.5165 1.01153 20.9885 5.48352 20.9885 11C20.9885 16.5165 16.5165 20.9885 11 20.9885C5.48352 20.9885 1.01153 16.5165 1.01153 11ZM11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0ZM16.339 7.22261L9.49382 13.8302L6.91905 11.4402L6.23088 12.1816L9.15655 14.8973L9.50743 15.223L9.85189 14.8905L17.0416 7.95039L16.339 7.22261Z"
      fill="black"
    />
  </svg>
</template>

<docs>
  To know more information on how to use icons see [here](/packs/#Icon)

  ```jsx
    <Icon name="Linkedin" class="text-4xl"></Icon>
  ```
</docs>
