<template>
  <div
    data-testid="course-talent"
    class="relative w-full sm:w-[320px] lg:w-[437px]"
  >
    <div
      class="relative h-64 w-full overflow-hidden mb-4"
      :style="{ background: backgroundColor }"
    >
      <div class="flex flex-col items-center justify-center w-full h-full p-4 card-body">
        <h2 class="card-title">
          {{ title }}
        </h2>
        <p class="card-description text-white text-center">
          {{ description }}
        </p>
      </div>
    </div>

    <div
      data-testid="upsell-offer-card-cta"
      class="cursor-pointer"
      @click="onCtaClick()"
    >
      <div class="flex flex-row no-wrap justify-between">
        <component
          :is="ctaHeadingLevel"
          class="title-medium text-blue-800 mb-0"
        >
          {{ ctaTitle }}
        </component>
        <Icon
          name="Arrow"
          class="text-2xl"
        />
      </div>
      <p class="subheading">
        {{ ctaSubtitle }}
      </p>
    </div>
  </div>
</template>

<script>
import Icon from '@/Components/Shared/Icon'

export default {
    name: 'Card',
    components: {
        Icon
    },
    props: {
        title: {
            type: String,
            required: true
        },
        description: {
            type: String,
            required: true
        },
        backgroundColor: {
            type: String,
            default: '#18336C'
        },
        ctaTitle: {
            type: String,
            default: ''
        },
        ctaSubtitle: {
            type: String,
            default: ''
        },
        ctaHeadingLevel: {
            type: String,
            default: 'h2'
        },
        headingLevel: {
            type: String,
            default: 'h2'
        }
    },
    methods: {
        onCtaClick() {
            window.location.href = this.$routes.onboarding()
        }
    }
}
</script>

<style scoped>
.card-body .card-title {
    color: var(--9E6C21, #FFF);
    text-align: center;
    font-family: "Saol CA";
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 42px;
}

.card-body .card-subtitle {
    color: #FFF;
    text-align: center;
    font-family: "Brandon Grotesque";
    font-size: 20px;
    font-style: normal;
    font-weight: 390;
    line-height: 160%;
    letter-spacing: 0.4px;
}
</style>