import { currentUser } from './currentUser'
import axios from 'axios'

const mixpanelMixin = {
  mixins: [currentUser],

  computed: {
    mixpanelToken() {
      return this.$page.props.mixpanel.token
    }
  },

  mounted() {
    // Track chatbot variant if it exists
    if (window.chatbotTestVariant && this.mixpanelToken) {
      this.loadMixpanel().then(mixpanel => {
        // Set as both a super property and user profile property
        mixpanel.register({
          'chatbot_variant': window.chatbotTestVariant
        })
        
        mixpanel.people.set({
          'chatbot_variant': window.chatbotTestVariant
        })

        // Check if this is a new session
        const sessionKey = 'chatbot_session_tracked'
        if (!sessionStorage.getItem(sessionKey)) {
          mixpanel.track('Chatbot Test Assignment', {
            'variant': window.chatbotTestVariant,
            'timestamp': new Date().toISOString()
          })
          sessionStorage.setItem(sessionKey, 'true')
        }
      })
    }

    // Track user session start
    if (this.mixpanelToken) {
      this.loadMixpanel().then(mixpanel => {
        const sessionKey = 'session_started'
        if (!sessionStorage.getItem(sessionKey)) {
          this.trackEvent('User Session Started', {
            'timestamp': new Date().toISOString(),
            'landing_page': window.location.pathname,
            'referrer': document.referrer
          })
          sessionStorage.setItem(sessionKey, 'true')
        }
      })
    }
  },

  methods: {
    shouldTrackActiveCampaign() {
      // If no allowlist is set, track everyone
      if (!window.ENV.AC_EMAIL_WHITELIST) {
        return true
      }

      // If user has no email, don't track
      if (!this.currentUser?.email) {
        return false
      }

      // Validate allowlist format
      const allowlistStr = window.ENV.AC_EMAIL_WHITELIST
      if (typeof allowlistStr !== 'string') {
        console.error('[AC_TRACKING] Invalid allowlist format')
        return false
      }

      // Check if user's email is in allowlist
      const allowlist = allowlistStr
        .split(',')
        .map(email => email.trim())
        .filter(email => email.includes('@')) // Basic email validation

      return allowlist.includes(this.currentUser.email.trim())
    },

    async trackActiveCampaign(eventName, eventData) {
      // Add allowlist check
      if (!this.shouldTrackActiveCampaign()) {
        console.log('[AC_TRACKING] Skipping ActiveCampaign tracking - email not in allowlist')
        return
      }

      try {
        console.log('[AC_TRACKING] Making POST request to:', this.$routes.active_campaign_track())
        console.log('[AC_TRACKING] With payload:', { event_name: eventName, event_data: eventData })
        let payload = { event_name: eventName, event_data: eventData }

        await axios.post(
          this.$routes.active_campaign_track(),
          payload,
          {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          }
        )
      } catch (error) {
        if (!error.response) {
          console.error('[AC_TRACKING] Network error occurred:', error)
        } else if (error.response.status === 401) {
          console.log('[AC_TRACKING] Unauthorized - user likely not authenticated')
        } else {
          console.error('[AC_TRACKING] Request failed:', error.response?.status, error.response?.data)
        }
      }
    },

    async trackEvent(eventName, eventData) {
      const enrichedEventData = {
        ...eventData,
        user_id: this.currentUser?.id,
        is_subscribed: this.isSubscribed,
        is_admin: this.isAdmin,
        chatbot_variant: window.chatbotTestVariant || 'not_set'
      }

      if (this.mixpanelToken) {
        this.loadMixpanel().then(mixpanel => {
          mixpanel.track(eventName, enrichedEventData)
        })
      }

      // Find and calculate duration if this was a timed event
      if (window.mixpanelTimedEvents) {
        const timedEventIndex = window.mixpanelTimedEvents.findIndex(e => e.name === eventName)
        if (timedEventIndex !== -1) {
          const timedEvent = window.mixpanelTimedEvents[timedEventIndex]
          const durationSeconds = (Date.now() - timedEvent.startTime) / 1000

          // Remove the event from our tracking array
          window.mixpanelTimedEvents.splice(timedEventIndex, 1)

          // Add duration to eventData
          enrichedEventData.duration = durationSeconds
        }
      }

      this.trackActiveCampaign(eventName, enrichedEventData)
    },

    async loadMixpanel() {
      if (!this.mixpanelToken) return
      if (this._mixpanel) return this._mixpanel

      // load mixpanel dynamically so it's fetched in a separate chunk
      const mixpanel = await import('mixpanel-browser')
      this._mixpanel = mixpanel.default

      // https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelinit
      const { debug } = this.$page.props.mixpanel
      this._mixpanel.init(this.mixpanelToken, { debug })

      return this._mixpanel
    },

    identify(userId) {
      if (!this.mixpanelToken) return

      this.loadMixpanel().then(mixpanel => {
        // https://developer.mixpanel.com/docs/javascript#identify
        mixpanel.identify(userId)
      })
    },

    trackSignUp(user) {
      if (!this.mixpanelToken || !user) return

      this.loadMixpanel().then(mixpanel => {
        // https://developer.mixpanel.com/docs/javascript#alias
        mixpanel.alias(user.id)
        this.trackProfile({ '$email': user.email })
      })
    },

    trackProfile(profile) {
      if (!this.mixpanelToken) return

      this.loadMixpanel().then(mixpanel => {
        // https://developer.mixpanel.com/docs/javascript#setting-profile-properties
        mixpanel.people.set(profile)
      })
    },

    trackLogout() {
      if (!this.mixpanelToken) return

      this.loadMixpanel().then(mixpanel => {
        // https://developer.mixpanel.com/docs/javascript#call-reset-at-logout
        mixpanel.reset()
      })
    },

    timeEvent(eventName) {
      if (!this.mixpanelToken) return

      // Initialize the global array if it doesn't exist
      window.mixpanelTimedEvents = window.mixpanelTimedEvents || []

      // Store the event name and timestamp
      window.mixpanelTimedEvents.push({
        name: eventName,
        startTime: Date.now()
      })

      this.loadMixpanel().then(mixpanel => {
        // https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpaneltime_event
        mixpanel.time_event(eventName)
      })
    }

  }
}

export default mixpanelMixin
