<template>
  <svg
    width="31"
    height="1"
    viewBox="0 0 31 1"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.839844 0.5C10.9932 0.500001 31.217 0.500002 30.8847 0.500002"
      stroke="#6D1623"
    />
  </svg>
</template>

  <docs>
    To know more information on how to use icons see [here](/packs/#Icon)

    ```jsx
      <Icon name="Check" class="text-4xl"></Icon>
    ```
  </docs>
