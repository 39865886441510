<template>
  <!-- <div class="container mx-auto px-4 py-6 2xl:py-8">
    <MetaTags :metaTags="metaTags" />

    <div class="text-center px-4 md:px-0 mb-6 2xl:mb-12">
      <div class="title-largest mb-1">Become a member</div>
      <div class="font-normal text-2xl mb-0" v-html="selectedPlan.description"></div>
    </div>

    <div class="flex flex-col justify-center mb-0 w-full sm:w-4/5 lg:w-2/3 xl:w-1/2 mx-auto">
      <div class=" relative">
        <div class="flex flex-col gap-3">
          <div class="flex items-center justify-between">
            <p class="heading--medium heading--widest">CREATE ACADEMY ANNUAL MEMBERSHIP</p>
            <p class="heading--medium heading--widest">{{ getGeoPrice(selectedPlan.price) }}</p>
          </div>

          <ul class="text-sm leading-6 font-normal pl-2 flex flex-col gap-2" style="list-style-type: '\2713';">
            <li class="pl-2 text-xl">Access all current and new courses for a full year</li>
            <li class="pl-2 text-xl">Learn from expert instructors</li>
            <li class="pl-2 text-xl">Search over 1000 lessons and save your favourites</li>
            <li class="pl-2 text-xl">Watch and rewatch at any time</li>
            <li class="pl-2 text-xl">Enjoy new content each month</li>
          </ul>

          <CouponDetails
            :validateVoucherUrl="validateVoucherUrl"
            @validated="onCouponValidated"
            @reset="onCouponReset"
            class="mt-4 2xl:mt-9"
          />

          <div class="flex items-start justify-between gap-3 text-base font-normal text-yellow-800">
            <p class="uppercase font-medium tracking-wide">{{ selectedPlan.checkoutDescription }}</p>
            <p class="uppercase font-medium ">-{{ getGeoPrice(selectedPlan.price - selectedPlan.discountedPrice) }}</p>
          </div>

          <OrderBreakdown
            :geo-price="getGeoPrice(selectedPlan.discountedPrice)"
            :geo-discount="getGeoPrice(discount)"
            :geo-grand-total="getGeoPrice(selectedPlan.discountedPrice - discount)"
            :has-discount="discount > 0"
            :voucher-code="voucherCode"
            :i18n-scope="{ scope: 'pages.orders.new' }"
          />

        </div>
      </div>

      <form @submit.prevent="handleSubmit">
        <div class="mx-auto text-center">
          <button type="submit" class="button button--blue mx-auto mt-2">{{ proceedButton }}</button>
          <div class="mt-2 font-normal mx-auto">30-day money back guarantee</div>
        </div>
      </form>

      <div v-if="isLoading" class="fixed inset-0 bg-gray-700 bg-opacity-50 flex items-center justify-center">
        <div class="spinner"></div>
      </div>
    </div>

    <ErrorModal v-if="errorMessage" :errorMessage="errorMessage" />
  </div> -->
  <div class="flex flex-col items-center py-6 2xl:py-10">
    <MetaTags :meta-tags="metaTags" />

    <div class="w-full max-w-full xl:px-0 xl:max-w-screen-lg">
      <div class="flex flex-col gap-5 px-4 lg:px-0 lg:gap-9 w-full">
        <div class="flex flex-col gap-5 lg:gap-3 w-full">
          <h4
            v-if="isLifetimePlan"
            class="max-w-lg mx-auto font-serif text-3xl leading-9 lg:text-4xl lg:leading-10 font-medium text-black text-center px-4 lg:px-0"
          >
            Buy a <span class="text-yellow-800">Lifetime Pass</span> to own every Create Academy course, forever.
          </h4>
          <h4
            v-else
            class="font-serif text-3xl leading-9 lg:text-4xl lg:leading-10 font-medium text-black text-center px-4 lg:px-0"
          >
            Become a Member of the UK's most-loved creative learning platform
          </h4>
        </div>
      </div>
      <div class="flex items-stretch justify-start mt-2 lg:mt-9">
        <div class="flex-1 hidden lg:block bg-blue-300 ">
          <div class="w-full flex flex-col h-full">
            <img
              loading="lazy"
              class="image-cover w-auto max-w-full max-h-80 h-80rem"
              src="@/assets/images/backgrounds/membership-checkout-image.png"
            >
            <div class="flex flex-col gap-4 p-12 flex-1">
              <div class="mt-4 text-7xl title-gigantic transform rotate-180 text-right tracking-tighter mb-0">
                ,,
              </div>
              <p class="-mt-10 text-xl leading-8 tracking-wide text-black font-normal">
                The selection of interior designers and creatives on this site is really 'The crème de la crème'!
              </p>
              <div class="flex items-center gap-3 text-base tracking-wider uppercase font-normal text-blue-800">
                <span>from GABRIELLE</span>
                <span>|</span>
                <span>TRUSTPILOT</span>
              </div>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="109"
                  height="15"
                  viewBox="0 0 109 15"
                  fill="none"
                >
                  <path
                    d="M7.58015 11.0952L10.8743 10.2484L12.2499 14.5492L7.58015 11.0952ZM15.1603 5.53661H9.36237L7.58015 0L5.79794 5.53661H0L4.69267 8.96737L2.91045 14.504L7.60313 11.0732L10.4906 8.96874L15.1603 5.53661Z"
                    fill="#00B67A"
                  />
                  <path
                    d="M31.045 11.0952L34.3392 10.2484L35.7147 14.5492L31.045 11.0952ZM38.6251 5.53661H32.8272L31.045 0L29.2628 5.53661H23.4648L28.1575 8.96737L26.3753 14.504L31.068 11.0732L33.9555 8.96874L38.6251 5.53661Z"
                    fill="#00B67A"
                  />
                  <path
                    d="M54.4984 11.0952L57.7926 10.2484L59.1681 14.5492L54.4984 11.0952ZM62.0785 5.53661H56.2806L54.4984 0L52.7161 5.53661H46.9182L51.6109 8.96737L49.8287 14.504L54.5213 11.0732L57.4088 8.96874L62.0785 5.53661Z"
                    fill="#00B67A"
                  />
                  <path
                    d="M77.97 11.0952L81.2642 10.2484L82.6397 14.5492L77.97 11.0952ZM85.5502 5.53661H79.7522L77.97 0L76.1878 5.53661H70.3899L75.0826 8.96737L73.3003 14.504L77.993 11.0732L80.8805 8.96874L85.5502 5.53661Z"
                    fill="#00B67A"
                  />
                  <path
                    d="M101.42 11.0952L104.714 10.2484L106.089 14.5492L101.42 11.0952ZM109 5.53661H103.202L101.42 0L99.6375 5.53661H93.8396L98.5323 8.96737L96.7501 14.504L101.443 11.0732L104.33 8.96874L109 5.53661Z"
                    fill="#00B67A"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-1 bg-white lg:bg-neutral-100 px-0 py-0 lg:px-8 lg:py-9">
          <div
            v-if="isLifetimePlan"
            class="w-full px-4 pt-10 lg:pt-0 lg:px-0 bg-neutral-100 lg:bg-transparent"
          >
            <p
              v-if="selectedPlan.discountPercent > 0"
              data-testid="membership_discount_percent"
              class="text-yellow-800 text-lg lg:text-base font-normal uppercase leading-5 tracking-wider mb-4 lg:mb-2.5"
            >
              {{ selectedPlan.discountPercent }}% off
            </p>
            <p class="text-yellow-800 text-lg lg:text-base font-semibold uppercase leading-5 tracking-wider mb-4 lg:mb-2.5">
              Lifetime Pass
            </p>
            <div class="flex flex-col pb-2 border-b border-yellow-800 mb-0 lg:mb-2">
              <div class="flex items-baseline justify-start">
                <p
                  class="text-blue-500 font-serif text-6xl font-medium tracking-wider"
                  data-testid="membership_lifetime_price"
                >
                  {{ getGeoRoundPrice(selectedPlan.discountedPrice) }}
                </p>
              </div>
              <p class="text-blue-800 text-sm font-normal tracking-wide">
                (One time payment)
              </p>
            </div>
          </div>
          <div
            v-else
            class="w-full px-4 pt-10 lg:pt-0 lg:px-0 bg-neutral-100 lg:bg-transparent"
          >
            <p
              v-if="selectedPlan.discountPercent > 0"
              data-testid="membership_discount_percent"
              class="text-yellow-800 text-lg lg:text-base font-normal uppercase leading-5 tracking-wider mb-4 lg:mb-2.5"
            >
              {{ selectedPlan.discountPercent }}% off your first year
            </p>
            <div class="flex flex-col pb-2 border-b border-yellow-800 mb-0 lg:mb-2">
              <div class="flex items-baseline justify-start">
                <p
                  class="text-blue-500 font-serif text-6xl font-medium tracking-wider"
                  data-testid="membership_monthly_price"
                >
                  {{ getGeoRoundPrice(selectedPlan.monthlyPrice) }}
                </p>
                <p class="text-blue-500 text-sm font-normal tracking-wide">
                  / MONTH
                </p>
              </div>
              <p class="text-blue-800 text-sm font-normal tracking-wide">
                (Billed {{ selectedPlan.slug == 'annual' ? 'Annually' : 'Monthly' }})
              </p>
            </div>
          </div>
          <div class="flex flex-col gap-0 lg:gap-2">
            <div class="flex flex-col gap-1 bg-neutral-100 px-4 pb-10 lg:pb-0  lg:py-3">
              <p class="text-lg font-medium text-black mt-7 lg:mt-0">
                {{ isLifetimePlan ? 'Your Lifetime Pass includes' : 'Your subscription includes' }}:
              </p>
              <ul class="text-sm leading-6 font-normal pl-0 flex flex-col gap-1">
                <li
                  v-for="(merit, index) in merits"
                  :key="index"
                  class="text-base leading-7  lg:text-lg text-blue-800 font-normal tracking-wide flex items-start"
                >
                  <Icon
                    name="Check"
                    class="mr-3 lg:mr-2 mem-flex-24"
                  />{{ merit }}
                </li>
              </ul>
            </div>
            <div class="flex flex-col gap-7 lg:gap-4 pt-10 px-4 lg:px-0 pb-5 lg:pb-0 lg:pt-4 w-full">
              <div class="flex items-center justify-between">
                <p class="text-base lg:leading-5 lg:text-lg font-medium capitalize">
                  Create Academy {{ selectedPlan.slug }} Membership
                </p>
                <p
                  class="text-base lg:leading-5 lg:text-lg font-medium"
                  data-testid="membership_recurring_membership_price"
                >
                  {{ getGeoPrice(selectedPlan.price) }}
                </p>
              </div>
              <div
                v-if="selectedPlan.discountPercent > 0"
                class="flex items-center justify-between"
              >
                <p class="text-base lg:leading-5 lg:text-lg font-normal">
                  {{ selectedPlan.checkoutDescription }}
                </p>
                <p
                  class="text-base lg:leading-5 lg:text-lg font-normal"
                  data-testid="membership_discount_amount"
                >
                  {{ getGeoPrice(selectedPlan.price - selectedPlan.discountedPrice) }}
                </p>
              </div>
              <div class="flex flex-col gap-2">
                <div class="flex items-center justify-between">
                  <p class="text-base lg:leading-5 lg:text-lg font-normal">
                    Discount
                  </p>
                  <p
                    class="text-base lg:leading-5 lg:text-lg font-normal"
                    data-testid="membership_coupen_discount_amount"
                  >
                    {{ getGeoPrice(discount||0) }}
                  </p>
                </div>
                <CouponDetails
                  :validate-voucher-url="validateVoucherUrl"
                  class=""
                  @validated="onCouponValidated"
                  @reset="onCouponReset"
                />
              </div>
              <div class="flex items-center justify-between">
                <p class="text-base lg:leading-5 lg:text-lg font-semibold">
                  Total
                </p>
                <p
                  class="text-base lg:leading-5 lg:text-lg font-semibold"
                  data-testid="membership_total_amount"
                >
                  {{ getGeoPrice(totalAmount) }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="px-4 lg:px-0 text-center">
        <form @submit.prevent="handleSubmit">
          <div class="mx-auto text-center">
            <button
              type="submit"
              class="button button--blue mx-auto mt-1 lg:mt-9"
            >
              {{ proceedButton }}
            </button>
            <!-- <div class="mt-2 font-normal mx-auto">30-day money back guarantee</div> -->
          </div>
        </form>
      </div>
      <div
        v-if="isLoading"
        class="fixed inset-0 bg-gray-700 bg-opacity-50 flex items-center justify-center"
      >
        <div class="spinner" />
      </div>
    </div>
    <ErrorModal
      v-if="errorMessage"
      :error-message="errorMessage"
    />
  </div>
</template>

<script>
import axios from 'axios'

import MetaTags from '@/Components/Shared/MetaTags'
import ErrorModal from '@/Components/Membership/CheckoutErrorModal'
// import OrderBreakdown from '@/Components/Membership/OrderBreakdown'
import CouponDetails from '@/Components/Orders/CouponDetails'

import { metaTags } from '@/mixins/metaTags'
import monetize from '@/mixins/monetize'
import mixpanel from '@/mixins/mixpanel'
import currentUser from '@/mixins/currentUser'
import signInModal from '@/mixins/signInModal'
import { openModal } from 'jenesius-vue-modal'
import Icon from '@/Components/Shared/Icon'
import authForm from '@/mixins/authForm'

export default {

  components: {
    MetaTags,
    CouponDetails,
    // OrderBreakdown,
    Icon
  },
  mixins: [
    metaTags,
    monetize,
    mixpanel,
    currentUser,
    signInModal,
    authForm
  ],

  props: {
    membershipPlans: {
      type: Array
    },
    countries: {
      type: Array,
      required: true
    },
    geoProps: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      selectedPlan: {
        discountPercent: 0,
        monthlyPrice: 0,
        price: 0,
        discountedPrice: 0,
        checkoutDescription: ''
      },
      isGift: false,
      cardNumber: '',
      errorMessage: '',
      processing: false,
      isLoading: false,
      voucherCode: null,
      discount: 0,
      totalAmount: 0
    }
  },

  computed: {
    validateVoucherUrl() {
      return `/membership/${this.selectedPlan.id}/validate_voucher`
    },

    proceedButton() {
      return this.currentUser
        ? this.isGift ? 'Claim Gift' : 'Proceed to Payment'
        : 'Sign in to Proceed'
    },

    isLifetimePlan() {
      return this.selectedPlan.slug == 'lifetime'
    },

    merits() {
      return this.isLifetimePlan ? [
          'Lifetime access to every course on Create Academy',
          'New content added each month',
          'Access to all subscriber benefits, events and offers',
          'Enjoy our best content and features without ever paying again'
        ] : [
          'Unlimited access to over 1000 lessons',
          'Expert instruction from designers, makers, growers and chefs',
          'Downloadable workbooks and resources',
          'New content added each month'
      ]
    }
  },

  watch: {
    cardNumber() {
      this.errorMessage = '' // Reset error message on input change
    }
  },

  created() {
    this.i18nScope = {
      scope: 'pages.membership'
    }

    this.setDefaultPlan()
  },

  methods: {
    setDefaultPlan() {
      const selectedPlan =
        this.membershipPlans.find(plan => plan.slug === (this.getQueryParam('type') || 'annual')) ||
        this.membershipPlans.find(plan => plan.slug === 'annual')

      if (selectedPlan) {
        this.selectedPlan = selectedPlan
        this.totalAmount = selectedPlan.discountedPrice || selectedPlan.price
      }
    },

    getGeoPrice(gbpBasePrice) {
      return this.geoMonetize(gbpBasePrice * this.geoProps.exchangeRate, this.geoProps.currencySymbol, 2)
    },

    getGeoRoundPrice(gbpBasePrice) {
      return this.geoMonetizeRound(gbpBasePrice * this.geoProps.exchangeRate, this.geoProps.currencySymbol, 2)
    },

    getUserType() {
      return this.currentUser ? 'authed' : 'anonymous'
    },

    openErrorModal(errorMessage) {
      openModal(ErrorModal, {
        errorMessage
      }// {
//        width: '95%',
//        maxWidth: 500
//      })
      )
    },

    async handleSubmit() {
      this.errorMessage = '' // Reset error message

      if (!this.currentUser) {
        this.openSignInModal('/membership/checkout') // Pass the desired redirect route after sign-in
        return
      }

      this.isLoading = true

      await this.submit(this.isGift ? '/membership/redeem_gift' : '/membership/checkout')

      this.isLoading = false
    },

    async submit(path) {
      try {
        this.trackEvent('membership_proceed', {
          userType: this.getUserType()
        })

        const response = await axios.post(path, {
          voucher: { code: this.voucherCode },
          plan: this.selectedPlan
        })

        if (response.data.status === 'success') {
          window.location.href = response.data.url
        } else if (response.data.status === 'error') {
          this.openErrorModal('An error occurred while processing your request.')
        }
      } catch (error) {
        this.openErrorModal(error.message || 'An error occurred while processing your request.')
      }
    },

    formatPrice(price) {
      if (typeof x != 'number') {
        price = parseFloat(price)
      }

      if (!Number.isInteger(price)) {
        return price.toFixed(2) // Show with 2 decimal places if there are decimal values
      } else {
        return price.toString() // Show as integer if there are no decimal values
      }
    },

    onCouponValidated(payload) {
      if (payload.valid && payload.order) {
        this.isGift = payload.isGift

        if (this.isGift) {
          this.discount = (payload.order.amount || 0) / 100
          this.totalAmount = 0
        } else {
          this.discount = (payload.order.total_discount_amount || 0) / 100
          this.totalAmount = (payload.order.total_amount||0) / 100
        }
        this.voucherCode = payload.redeemableCode?.id || null
      } else {
        this.onCouponReset()
      }
    },

    onCouponReset() {
      this.discount = 0
      this.totalAmount = this.selectedPlan.discountedPrice
      this.voucherCode = null
    }
  }
}
</script>

<style scoped>
.spinner {
  border: 8px solid #f3f4f6; /* Light grey */
  border-top: 8px solid #3490dc; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg) }
  100% { transform: rotate(360deg) }
}
</style>
