<template>
  <div class="text-center mb-16">
    <h1 class="font-serif text-6xl text-blue-800 font-medium mb-8">
      Studio Notes
    </h1>

    <div class="mb-8">
      <h2 class="font-serif text-xl text-[#B17F4A] tracking-wider uppercase mb-8">
        Explore by Category
      </h2>
      <div class="flex justify-center items-center gap-8 text-lg">
        <Link
          href="/blog"
          class="uppercase hover:text-blue-800 transition-colors"
          :class="{ 'underline font-medium': !currentCategory }"
        >
          ALL
        </Link>
        <Link
          v-for="category in categories"
          :key="category.id"
          :href="`/blog/categories/${category.slug}`"
          class="uppercase hover:text-blue-800 transition-colors"
          :class="{ 'underline font-medium': currentCategory === category.id }"
        >
          {{ category.name }}
        </Link>
      </div>
    </div>
  </div>
</template>

<script>
  import { Link } from '@inertiajs/vue3'

  export default {
    components: {
      Link
    },

    props: {
      categories: {
        type: Array,
        required: true
      },
      currentCategory: {
        type: [Number, null],
        default: null
      }
    }
  }
</script>
