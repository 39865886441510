<template>
  <button
    :disabled="buttonDisabled || null"
    class="button"
    :class="classNames"
    @click="handleClick"
  >
    <template v-if="loading">
      <CircleSpinner class="text-white" />
    </template>

    <template v-if="icon && !loading">
      <Icon
        :name="icon"
        class="button__icon relative -top-0.5"
        :class="vertical ? ' mt-1 mb-2' : 'mr-3'"
      />
    </template>

    <template v-if="variant && variant=='facebook' && !loading">
      <img
        loading="lazy"
        :data-src="facebookLogo"
        alt="Facebook Logo"
        class="mr-3 lazyload"
      >
    </template>

    <template v-if="variant && variant=='google' && !loading">
      <img
        loading="lazy"
        :data-src="googleLogo"
        alt="Google Logo"
        class="mr-3 lazyload"
      >
    </template>
    <slot />
  </button>
</template>

<script>
  import CircleSpinner from '@/Components/Icons/CircleSpinner'
  import button from '@/mixins/button'

  /**
   * Button component for Create Academy
   * @displayName Button
   */
  export default {
    name: 'Button',

    components: {
      CircleSpinner
    },

    mixins: [button],
    emits: ['click'],

    created() {

      this.facebookLogo = require('@/assets/images/FacebookAuth.svg')
      this.googleLogo = require('@/assets/images/GoogleAuth.svg')
    },

    methods: {
      handleClick(event) {
        // Stop propagation at the base button level
        event.stopPropagation()
        this.$emit('click', event)
      }
    }
  }
</script>

<docs>
  ### ✎ Variants

  ```jsx
    <div class="flex flex-row flex-wrap">
      <Button class="m-2">Hello World</Button>
      <Button variant="red" class="m-2">Hello World</Button>
      <Button variant="green" class="m-2">Hello World</Button>
      <Button variant="yellow" class="m-2">Hello World</Button>
      <Button variant="danger" class="m-2">Hello World</Button>
      <Button variant="inactive" class="m-2">Hello World</Button>
    </div>
  ```
  ---

  ### ✎ Size

  ```jsx
    <Button class="mb-2">I'm default size</Button>
    <Button size="small">I'm small size</Button>
  ```

  ---

  ### ✎ Outline

  ```jsx
    <div class="flex flex-row flex-wrap">
      <Button outline class="m-2">Hello World</Button>
      <Button variant="red" outline class="m-2">Hello World</Button>
      <Button variant="green" outline class="m-2">Hello World</Button>
      <Button variant="yellow" outline class="m-2">Hello World</Button>
    </div>
  ```

  ---

  ### ✎ Inverse Button
  This button is used under dark backgrounds

  ```jsx
    <div class="flex flex-row flex-wrap bg-grayscale-700">
      <Button inverse class="m-2">Hello World</Button>
      <Button variant="red" inverse class="m-2">Hello World</Button>
      <Button variant="green" inverse class="m-2">Hello World</Button>
      <Button variant="yellow" inverse class="m-2">Hello World</Button>
    </div>

    <div class="flex flex-row flex-wrap bg-grayscale-700">
      <Button outline inverse class="m-2">Hello World</Button>
      <Button variant="red" outline inverse class="m-2">Hello World</Button>
      <Button variant="green" outline inverse class="m-2">Hello World</Button>
      <Button variant="yellow" outline inverse class="m-2">Hello World</Button>
    </div>
  ```

  ---

  ### ✎ With Icon
  The icon select inherits the color of the button text. To consult which icons are avaible go [here](/packs/#Icon).

  ```jsx
    <Button icon="basket" class="mb-4">Add to basket</Button>
    <Button icon="close" size="small" class="mr-4">Not this way</Button>
  ```

  ---

  ### ✎ Ocuppy entire width of the container [block]

  ```jsx
    <Button block> I'm sooooo big </Button>
  ```

  ---

  ### ✎ Inactive

  ```jsx
    <Button disabled> I'm inactive </Button>
  ```

  ---

  ### ✎ Loading

  ```jsx
    <Button loading> Creating something </Button>
  ```

  ---

  ### ✎ Google // Facebook Authentication

  ```jsx
    <Button variant="google" block class="mb-2">Sign in with Google</Button>
    <Button variant="facebook" block>Sign in with Facebook</Button>
  ```
</docs>
