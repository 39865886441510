<template>
  <div
    class="input-group"
    :required="required || null"
  >
    <label
      v-if="label"
      class="label"
      :for="computedId"
    >{{ label }}</label>

    <select
      v-bind="$attrs"
      :data-testid="testid"
      :value="modelValue"
      class="input"
      :class="{ 'error': errors.length }"
      @input="$emit('update:modelValue', $event.target.value)"
    >
      <option
        value=""
        disabled
        selected
      >
        {{ placeholder }}
      </option>
      <option
        v-for="option in options"
        :key="option.value"
        :value="option.value"
      >
        {{ option.text }}
      </option>
    </select>

    <Icon
      name="Chevron"
      class="text-xs text-blue-800 absolute top-8 right-2"
    />

    <InputError
      :testid="testid"
      :errors="errors"
    />
  </div>
</template>

<script>
import Icon from '@/Components/Shared/Icon'
import InputError from '@/Components/Shared/Forms/InputError'

/**
 * SelectGroup component for Create Academy
 * @displayName SelectGroup
 */
export default {
  name: 'SelectGroup',

  components: {
    Icon,
    InputError
  },

  props: {
    /**
     * The id for the input
     */
    id: {
      type: String,
      default: null
    },
    /**
     * The name for the input
     */
    name: {
      type: String,
      default: null
    },
    /**
     * The value for the input
     */
    modelValue: {
      type: String,
      default: null
    },
    /**
     * The label for the input - this should be more short and informative.
     */
    label: {
      type: String,
      default: null
    },
    /**
     * The placeholder for the select input - this should be more explanatory of the desired input information
     */
    placeholder: {
      type: String,
      default: null
    },
    /**
     * Required field
     */
    required: {
      type: Boolean,
      default: false
    },
    /**
     * The select options
     */
    options: {
      type: Array,
      default: () => []
    },
    errors: {
      type: Array,
      default: () => []
    },
    testid: String
  },
  emits: ['update:modelValue'],
  computed: {
    computedId() {
      return this.id || `select-input-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`
    }
  }
}
</script>


<docs>
  ### ✎ Regular Select Box

  ```jsx
    <SelectGroup
      placeholder="Select your country"
      label="Country"
    ></SelectGroup>
  ```
</docs>
