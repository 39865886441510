<template>
  <div class="bannerTop-slide ">
    <!-- Overflow on the Page -->
    <div class="pt-10 sm:pt-12" />
    <div
      data-testid="banner-top-announcement"
      class="fixed bg-neutral-400 h-10 sm:h-12 top-0 left-0 w-full z-40 flex justify-center items-center"
    >
      <div
        :id="sliderId"
        class="swiper-press-references relative w-full h-full lg:max-w-screen-2xl  overflow-hidden"
      >
        <div class="swiper-wrapper w-100">
          <div
            class="swiper-slide flex items-center justify-center"
            data-testid="topbanner-slide1"
          >
            <div class="flex justify-center align-center gap-2">
              <Icon
                name="Key"
                class="text-black "
              />
              <p class=" text-base leading-5 font-normal">
                <span class=" tracking-widest">50</span> <span class="tracking-wide uppercase">FREE</span> Lessons To Try Out
              </p>
            </div>
          </div>
          <div
            class="swiper-slide flex items-center justify-center"
            data-testid="topbanner-slide2"
          >
            <div class="flex justify-center align-center gap-2">
              <Icon
                name="Education"
                class="text-black "
              />
              <p class=" text-base leading-5 font-normal">
                Learn From World Renowned Instructors
              </p>
            </div>
          </div>
          <div
            class="swiper-slide flex items-center justify-center"
            data-testid="topbanner-slide3"
          >
            <div class="flex justify-center align-center gap-2">
              <Icon
                name="Infinity"
                class="text-black "
              />
              <p class=" text-base leading-5 font-normal">
                Subscribe For Unlimited Access
              </p>
            </div>
          </div>
        </div>

        <!-- Slide navigation -->
        <div
          data-testid="pressReference-button-prev"
          class="swiper-button-prev"
        />
        <div
          data-testid="pressReference-button-next"
          class="swiper-button-next"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'

// Configure Swiper to use modules
import SwiperCore, { Navigation, Pagination } from 'swiper/core'
SwiperCore.use([ Navigation, Pagination])

import Icon from '@/Components/Shared/Icon'

export default {

  components: {
    Icon
  },


  props: {
    sliderId: {
      type: String,
      required: true
    }
  },

  mounted() {
    // init Swiper
    this.swiper = new Swiper(`#${this.sliderId}`, {

      pagination: {
        clickable: true
      },

      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },

      watchOverflow: true,
      slidesPerView: 1,
      spaceBetween: 32,

      autoplay: {
        delay: 3000,
        disableOnInteraction: false
      },

      breakpoints: {
        640: {
          slidesPerView: 1
        },
        1024: {
          slidesPerView: 3
        }
      },

      modules: [Pagination]
    })
  }
}
</script>
