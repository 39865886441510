<template>
  <svg
    width="19"
    height="20"
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5674 11.7812L9.89551 10.8906V7.16652M17.0205 10C17.0205 13.935 13.8305 17.125 9.89551 17.125C5.96048 17.125 2.77051 13.935 2.77051 10C2.77051 6.06497 5.96048 2.875 9.89551 2.875C13.8305 2.875 17.0205 6.06497 17.0205 10Z"
      stroke="black"
      stroke-linecap="square"
    />
  </svg>
</template>

  <docs>
    To know more information on how to use icons see [here](/packs/#Icon)

    ```jsx
      <Icon name="Check" class="text-4xl"></Icon>
    ```
  </docs>
