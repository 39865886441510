// Adapted from https://github.com/uto-usui/vue-brightcove/
// More info on player-loader here https://github.com/brightcove/player-loader

<template>
  <div class="bc-player">
    <div ref="video" class="bp__inner"></div>
  </div>
</template>

<script>

import bc from '@brightcove/player-loader'
import env from '@/mixins/env'

export default {
  name: 'BrightcovePlayer',

  mixins: [
    env
  ],

  emits: ['onPlay', 'onAutoPlay', 'onEnded', 'onTimeupdate', 'onCanPlayThrough', 'onError', 'onPause', 'onFullscreenChange', 'onWaiting', 'onPlaying'],

  props: {
    options: {
      type: Object,
      default: null
    },
    isHideControls: {
      type: Boolean,
      default: false
    },
    isAutoPlay: {
      type: Boolean,
      default: false
    },
    isPlaysInline: {
      type: Boolean,
      default: true
    },
    autoPlayAt: {
      type: Number,
      default: null
    }
  },

  data() {
    return {
      player: null,
      animId: 0,
      viewportIn: false
    }
  },

  mounted() {
    this.init()
  },

  beforeUnmount() {
    if (this.player) {
      this.disposePlayer()
      // this.player = null
    }
  },

  methods: {
    async init() {
      // Don't load player in test environment
      if (this.isTestEnv) { return }

      const { ref } = await bc({
        refNode: this.$refs.video,
        ...this.options
      })

      // video.js object - https://docs.videojs.com/player
      this.player = ref
      this.eventAttach()
      this.setPlaysInline()
      this.isHideControls && this.hideControls()
      this.setAspectRatio()
      if (this.isAutoPlay) {
        await this.play(this.autoPlayAt, () => {
          this.$emit('onAutoPlay', this.player)
        })
      }
      // attach play event last so it doesn't get mixed up with auto-play
      // this solution may not be bulletproof but is enough for now
      this.player?.on('play', this.onPlay)
      // this.scrollAutoPlay()
    },

    /**
     * destroy
     */
    disposePlayer() {
      this.eventDetach()
      cancelAnimationFrame(this.animId)

      // Nothing to dispose.
      if (!this.player) {
        return
      }

      // Dispose an in-page player.
      if (this.player.dispose) {
        this.pause()
        this.player.dispose()

        // Dispose an iframe player.
      } else if (this.player.parentNode) {
        this.player.parentNode.removeChild(this.player)
      }
    },

    /**
     * attach event handlers
     */
    eventAttach() {
      if (this.player) {
        this.player.on('loadedmetadata', this.onLoadedMetaData)
        this.player.on('canplaythrough', this.onCanPlayThrough)
        // this.player.on('play', this.onPlay)
        this.player.on('timeupdate', this.onTimeupdate)
        this.player.on('pause', this.onPause)
        this.player.on('ended', this.onEnded)
        this.player.on('fullscreenchange', this.onFullscreenChange)
        this.player.on('error', this.onError)
        this.player.on('stalled', this.onStalled)
        this.player.on('suspend', this.onSuspend)
        this.player.on('abort', this.onAbort)
        this.player.on('emptied', this.onEmptied)
        this.player.on('waiting', this.onWaiting)
        this.player.on('playing', this.onPlaying)
      }
    },

    /**
     * detach event handlers
     */
    eventDetach() {
      if (this.player) {
        this.player.off('loadedmetadata', this.onLoadedMetaData)
        this.player.off('canplaythrough', this.onCanPlayThrough)
        this.player.off('play', this.onPlay)
        this.player.off('timeupdate', this.onTimeupdate)
        this.player.off('pause', this.onPause)
        this.player.off('ended', this.onEnded)
        this.player.off('fullscreenchange', this.onFullscreenChange)
        this.player.off('error', this.onError)
        this.player.off('stalled', this.onStalled)
        this.player.off('suspend', this.onSuspend)
        this.player.off('abort', this.onAbort)
        this.player.off('emptied', this.onEmptied)
        this.player.off('waiting', this.onWaiting)
        this.player.off('playing', this.onPlaying)
      }
    },

    /**
     * handlers
     */
    onCanPlayThrough(e) {
      this.$emit('onCanPlayThrough', e, this.player)
    },
    onPlay(e) {
      this.$emit('onPlay', e, this.player)
    },
    onTimeupdate(e) {
      this.$emit('onTimeupdate', e, this.player)
    },
    onPause(e) {
      this.$emit('onPause', e, this.player)
    },
    onEnded(e) {
      this.$emit('onEnded', e, this.player)
    },
    onFullscreenChange(e) {
      this.$emit('onFullscreenChange', e, this.player)
    },
    onLoadedMetaData(e) {
      if (this.autoPlayAt) {
        this.player?.currentTime(this.autoPlayAt)
      }
    },
    onError(e) {
      this.$emit('onError', 'error')
    },
    onStalled(e) {
      this.$emit('onError', 'stalled')
    },
    onSuspend(e) {
      this.$emit('onError', 'suspend')
    },
    onAbort(e) {
      this.$emit('onError', 'abort')
    },
    onEmptied(e) {
      this.$emit('onError', 'emptied')
    },
    onWaiting(e) {
      this.$emit('onWaiting', e, this.player)
    },
    onPlaying(e) {
      this.$emit('onPlaying', e, this.player)
    },

    /**
     * audit scroll position
     */
    // scrollAutoPlay() {
    //   // element rect
    //   const rect = this.$el.getBoundingClientRect()
    //   // scroll position
    //   const scrollTop =
    //     window.scrollY ||
    //     document.documentElement.scrollTop ||
    //     document.body.scrollTop
    //   const top = rect.top + scrollTop - document.documentElement.clientTop
    //   // viewport height
    //   const windowHeight = window.innerHeight
    //   // dist - from window bottom to content top
    //   const contentTop = scrollTop + windowHeight - top
    //   // ratio - Until the element is visible and then invisible 0 ~ 1
    //   const ratio =
    //     Math.trunc((contentTop / (rect.height + windowHeight)) * 100) / 100

    //   if (ratio > 0.03 && ratio < 1) {
    //     !this.viewportIn && this.play()
    //     this.viewportIn = true
    //   } else {
    //     this.pause()
    //     this.viewportIn = false
    //   }

    //   this.animId = requestAnimationFrame(this.scrollAutoPlay)
    // },

    /**
     * currentTime: time in seconds to play the video. if not given, video starts from the beginning
     */
    async play(currentTime, successCallback) {
      if (this.player && this.player.paused()) {
        try {
          await this.player.play()
          successCallback?.()
        }
        catch (err) {
          // Autoplay may not be possible in some scenarios,
          // see: https://blog.videojs.com/autoplay-best-practices-with-video-js/
          // This catch prevents a console error
        }
      }
    },

    setPlaysInline() {
      this.player.playsinline(this.isPlaysInline)
    },

    hideControls() {
      this.player.controlBar.hide()
      this.player.tech_.off('mouseup')
    },

    pause() {
      this.player && !this.player.paused() && this.player.pause()
    },

    setAspectRatio() {
      this.player.fluid(true)
      this.player.aspectRatio('16:9')
    }
  }
}
</script>
