<template>
  <div class="flex flex-col text-center items-center">
    <Icon
      name="Basket"
      class="title-xl text-blue-800 mb-2"
    />
    <p class="subheading--medium text-blue-800 mb-6">
      {{ $I18n.t('order_no', i18nScope) }}{{ order.id }}
    </p>

    <h1
      data-testid="confirmation-title"
      class="title-medium text-blue-800 mb-6"
    >
      {{ $I18n.t('thank_you', i18nScope) }}
    </h1>
    <p
      data-testid="confirmation-instructions"
      class="mb-4"
    >
      {{ $I18n.t('instructions', i18nScope) }}
    </p>
    <p
      data-testid="confirmation-support"
      class="mb-8"
    >
      <span>{{ $I18n.t('support', i18nScope) }}</span>

      <span
        v-if="showBuyOneGiftOne"
        class="font-normal"
      >{{ $I18n.t('campaign', i18nScope) }}</span>
    </p>

    <Button
      data-testid="button-link"
      block
      @click="onClick"
    >
      {{ $I18n.t('action', i18nScope) }}
    </Button>
  </div>
</template>

<script>
import featureFlags from '@/mixins/featureFlags'

import Button from '@/Components/Shared/Button'
import Icon from '@/Components/Shared/Icon'

export default {

  components: {
    Button,
    Icon
  },
  mixins: [
    featureFlags
  ],

  props: {
    order: {
      type: Object,
      required: true
    },
    orderable: {
      type: Object,
      required: true
    }
  },

  created() {
    this.i18nScope = { scope: 'pages.orders.confirmation' }
  },

  methods: {
    onClick() {
      this.$inertia.visit(this.$routes.root())
    }
  }
}
</script>
