<template>
  <div>
    <!-- If pay with credit card show Button component -->
    <!-- Else, show PaymentButton component -->
    <template v-if="submitters.active === submitters.card">
      <Button
        :data-testid="submitters.active"
        :data-goptimize-buttonPrice-price="unmonetize(humanizedAmount)"
        :data-goptimize-buttonPrice-text="$I18n.t(&quot;form.button&quot;, { ...i18nScope, price: &quot;&quot; })"
        :name="submitters.active"
        :variant="buttonVariant"
        :loading="localForm.processing"
        type="submit"
        class="panel-form__submit"
        block
      >
        {{ $I18n.t('form.button', { ...i18nScope, price: humanizedAmount }) }}
      </Button>
    </template>
    <template v-else>
      <PaymentButton
        :data-testid="submitters.active"
        :name="submitters.active"
        :service="paymentRequest.type"
        :loading="localForm.processing"
        type="submit"
        class="panel-form__submit-service"
        block
      >
        {{ $I18n.t(`payment_details.wallet_button.${paymentRequest.type}`, i18nScope) }}
      </PaymentButton>
    </template>

    <Divider
      color="light"
      spacing="lg"
      block
    />

    <div class="flex flex-row items-start mb-6">
      <Icon
        name="SecureBadge"
        class="text-2xl text-yellow-800 mr-2"
      />
      <div>
        <p class="font-normal">
          {{ $I18n.t('guarantee.title', i18nScope) }}
        </p>
        <p class="text-sm">
          {{ $I18n.t('guarantee.info', i18nScope) }}
        </p>
      </div>
    </div>
    <div class="flex flex-row items-start">
      <Icon
        name="Charity"
        class="text-2xl text-yellow-800 mr-2"
      />
      <div>
        <p class="font-normal">
          {{ $I18n.t('charity.title', i18nScope) }}
        </p>
        <p class="text-sm">
          {{ $I18n.t('charity.info', i18nScope) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import monetize from '@/mixins/monetize'

import Icon from '@/Components/Shared/Icon'
import Button from '@/Components/Shared/Button'
import PaymentButton from '@/Components/Shared/PaymentButton'
import Divider from '@/Components/Shared/Divider'

export default {
  components: {
    Icon,
    Button,
    PaymentButton,
    Divider
  },
  mixins: [monetize],

  props: {
    errors: {
      type: Object,
      required: true
    },
    humanizedAmount: {
      type: String,
      required: true
    },
    form: {
      type: Object,
      required: true
    },
    i18nScope: {
      type: Object,
      required: true
    },
    buttonVariant: {
      type: String,
      required: true
    },
    submitters: {
      type: Object,
      required: true
    },
    paymentRequest: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      localForm: this.form
    }
  }
}
</script>
