<template>
  <component
    :is="componentName"
    :href="href"
    class="cursor-pointer"
    @click.stop="$emit('click')"
  >
    <slot />
  </component>
</template>

<script>
import { Link } from '@inertiajs/vue3'

export default {
  components: {
    Link
  },

  props: {
    fake: {
      type: Boolean,
      default: false
    },
    href: {
      type: String,
      required: false
    }
  },

  emits: ['click'],

  computed: {
    componentName() {
      return this.fake ? 'span' : 'Link'
    }
  }
}
</script>
