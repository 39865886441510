<template>
  <div class="bg-green-500">
    <MetaTags :meta-tags="metaTags" />

    <GiftPreviewModal
      id="element-to-convert"
      :value="gift.unformattedPrice"
      :message="gift.recipientMessage"
      :code="gift.code"
    />

    <div class="fixed top-0 left-0 h-full w-full bg-green-500">
      <div class="absolute top-0 left-0 w-screen h-screen bg-green-500 text-grayscale-100 flex flex-col justify-center items-center p-8">
        <Logotype class="w-auto h-10" />

        <div class="h-px w-8 bg-grayscale-100 mt-10 mb-14 mx-auto" />

        <div class="text-center mb-8">
          <p class="subheading--medium mb-2">
            {{ $I18n.t('error_message', i18nScope) }}
          </p>
          <p class="subheading">
            {{ $I18n.t('info_text', i18nScope) }}
          </p>
        </div>

        <Button
          data-testid="button-export-pdf"
          :loading="loading"
          type="button"
          variant="green"
          size="small"
          inverse
          @click="exportPDF"
        >
          {{ $I18n.t('cta', i18nScope) }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import html2pdf from 'html2pdf.js'

import { metaTags } from '@/mixins/metaTags'

import Button from '@/Components/Shared/Button'
import GiftPreviewModal from '@/Components/Shared/Gifts/PreviewModalPdf'
import MetaTags from '@/Components/Shared/MetaTags'
import Logotype from '@/Components/Shared/Logotype'

export default {

  components: {
    Button,
    GiftPreviewModal,
    MetaTags,
    Logotype
  },

  mixins: [
    metaTags
  ],
  layout: null,

  props: {
    gift: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      loading: false
    }
  },

  mounted() {
    this.exportToPDF()
  },

  created() {
    this.i18nScope = { scope: 'pages.gifts.pdf_export' }
  },

  methods: {
    exportPDF() {
      this.loading = true
      this.exportToPDF()
    },

    exportToPDF() {
      html2pdf(
        document.getElementById('element-to-convert'),
        {
          filename: `Create Academy Gift Voucher - ${this.gift.code}`,
          margin: 0,
          image: { type: 'jpg', quality: 1 },
          html2canvas: { dpi: 192, letterRendering: true },
          jsPDF: {
            // format: 'a5' with reduced height
            format: [150, 208]
          }
        }
      )

      this.loading = false
    }
  }
}
</script>
