<template>
  <div
    data-testid="auth-modal"
    class="auth-modal bg-grayscale-100 p-8 relative"
  >
    <div class="absolute top-6 right-6">
      <button
        data-testid="auth-modal-close"
        @click="handleClose"
      >
        <Icon
          name="close"
          class="text-2xl text-blue-800"
        />
      </button>
    </div>
    <component
      :is="componentClass"
      v-bind="$attrs"
      :notifications-required="notificationsRequired"
      @toggle-sign-in-up="toggle"
    />
  </div>
</template>

<script>
import cart from '@/mixins/cart'
import mixpanel from '@/mixins/mixpanel'
import SignInForm from '@/Components/Auth/SignInForm'
import SignUpForm from '@/Components/Auth/SignUpForm'
import Icon from '@/Components/Shared/Icon'
import { closeModal } from 'jenesius-vue-modal'

export default {

  components: {
    SignInForm,
    SignUpForm,
    Icon
  },
  mixins: [
    cart,
    mixpanel
  ],

  props: {
    notificationsRequired: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      showSignIn: false
    }
  },

  computed: {
    componentClass() {
      return this.showSignIn ? SignInForm : SignUpForm
    }
  },

  mounted() {
    this.trackEvent('auth_modal_opened', this.mixpanelCartData(this.orderable))
  },

  beforeUnmount() {
    closeModal()
  },

  methods: {
    toggle() {
      this.showSignIn = !this.showSignIn
    },

    handleClose() {
      closeModal()
    }
  }
}
</script>

<style>
/* Implement the styles that were previously passed to openModal */
.modal-item:has(.auth-modal) {
  width: 95% !important;
  max-width: 500px !important;
}
</style>
