<template>
  <Button
    v-if="course.trailer"
    v-bind="$attrs"
    data-testid="button-watch-trailer"
    icon="play"
    @click="openTrailerModal"
  >
    {{ $I18n.t('watch_trailer', i18nScope) }}
  </Button>
</template>

<script>
  import brightcove from '@/mixins/brightcove'
  import mixpanel from '@/mixins/mixpanel'

  import Button from '@/Components/Shared/Button'
  import TrailerModal from '@/Components/Shared/Trailer/Modal'
  import { openModal } from 'jenesius-vue-modal'

  export default {

    components: {
      Button
    },
    mixins: [brightcove, mixpanel],

    props: {
      course: {
        type: Object,
        required: true
      },
      gtmPageName: {
        type: String,
        required: true
      },
      gtmSectionName: {
        type: String,
        required: true
      },
      membershipPlan: {
        type: String,
        required: false,
        default: null
      }
    },

    created() {
      this.i18nScope = { scope: 'pages.courses.show' }
    },

    methods: {
      openTrailerModal() {
        openModal(
          TrailerModal,
          {
            course: this.course,
            brightcove: this.brightcove,
            gtmPageName: this.gtmPageName,
            gtmSectionName: 'Trailer Modal',
            enableUpsellModal: true,
            membershipPlan: this.membershipPlan
          }
//        {
//          width: '95%',
//          maxWidth: 940,
//          classes: 'bg-opacity-0'
//        }
        )

        this.trackEvent('click_trailer_button', {
          page: window.location.pathname,
          component: this.gtmSectionName,
          course: this.course.name,
          teacher: this.course.teacher.fullName
        })
      }
    }
  }
</script>
