<template>
  <div
    v-if="shouldShowBanner"
    data-testid="seasonal-banner"
    class="mt-6 mb-14 lg:mt-14"
  >
    <div
      data-testid="seasonal-banner-cta"
      class="cursor-pointer"
      @click="onClick"
    >
      <picture>
        <source
          media="(min-width: 1024px)"
          :srcset="seasonalBanner.imageDesktop"
        >
        <source
          media="(min-width: 640px)"
          :srcset="seasonalBanner.imageTablet"
        >
        <img 
          loading="lazy" 
          :src="seasonalBanner.imageMobile" 
          data-testid="seasonal-banner-image" 
          :alt="seasonalBanner.imageAltTag" 
          class="w-full h-auto"
        >
      </picture>
    </div>
  </div>
</template>

<script>
import mixpanel from '@/mixins/mixpanel'

export default {
  mixins: [mixpanel],

  props: {
    seasonalBanner: {
      type: Object,
      required: true
    }
  },

  computed: {
    shouldShowBanner() {
      if (!this.seasonalBanner || !this.seasonalBanner.active) {
        return false
      }
      
      const now = new Date()
      const startsAt = this.seasonalBanner.starts_at ? new Date(this.seasonalBanner.starts_at) : null
      const endsAt = this.seasonalBanner.ends_at ? new Date(this.seasonalBanner.ends_at) : null
      
      // If no dates set, show banner
      if (!startsAt && !endsAt) return true
      
      // Check start date
      if (startsAt && now < startsAt) {
        return false
      }
      
      // Check end date
      if (endsAt && now > endsAt) {
        return false
      }
      
      return true
    },

    ctaWindowOpenParam() {
      return this.seasonalBanner.ctaOpenNewTab ? '_blank' : '_self'
    }
  },

  methods: {
    onClick() {
      if (this.seasonalBanner?.mixpanel?.tag) {
        this.trackEvent(this.seasonalBanner.mixpanel.event, {
          tag: this.seasonalBanner.mixpanel.tag,
          campaign: this.seasonalBanner.campaignName,
          page: window.location.pathname
        })
      }

      window.open(this.seasonalBanner.cta, this.ctaWindowOpenParam)
    }
  }
}
</script>
