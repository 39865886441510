<template>
  <div>
    <h3 class="subheading--medium text-yellow-800 mb-6">
      {{ $I18n.t('title', i18nScope) }}
    </h3>

    <div class="flex flex-row justify-between mb-4">
      <div class="flex flex-row items-center">
        <img
          loading="lazy"
          :data-src="googleLogo"
          alt="Google Logo"
          class="mr-2 lazyload"
        >
        <span>{{ $I18n.t('identities.google', i18nScope) }}</span>
      </div>

      <span
        data-testid="connected-google"
        class="text-inactive"
      >{{ connectedMessage('google_oauth2') }}</span>
    </div>

    <div class="flex flex-row justify-between">
      <div class="flex flex-row items-center">
        <img
          loading="lazy"
          :data-src="facebookLogo"
          alt="Facebook Logo"
          class="mr-2 lazyload"
        >
        <span>{{ $I18n.t('identities.facebook', i18nScope) }}</span>
      </div>

      <span
        data-testid="connected-facebook"
        class="text-inactive"
      >{{ connectedMessage('facebook') }}</span>
    </div>
  </div>
</template>

<script>

export default {

  props: {
    identities: {
      type: Object,
      required: true
    }
  },
  created() {
    this.i18nScope = { scope: 'pages.users.profile.social_accounts' }

    this.googleLogo = require('@/assets/images/GoogleAuth.svg')
    this.facebookLogo = require('@/assets/images/FacebookAuthColor.svg')
  },

  methods: {
    connectedMessage(provider) {
      if (this.identities[provider]) {
        return this.$I18n.t('identities.connected', this.i18nScope)
      } else {
        return this.$I18n.t('identities.not_connected', this.i18nScope)
      }
    }
  }
}
</script>
