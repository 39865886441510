<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M21.8026 10.125C21.9303 10.7936 22 11.4937 22 12.2249C22 17.9375 18.0974 22 12.2043 22C6.56652 22 2 17.5249 2 12C2 6.4751 6.56652 2 12.2043 2C14.9597 2 17.2616 2.99367 19.0286 4.60619L16.1517 7.4251V7.41867C15.0805 6.41857 13.7221 5.90619 12.2043 5.90619C8.83703 5.90619 6.10096 8.69367 6.10096 11.9939C6.10096 15.2934 8.83698 18.0876 12.2043 18.0876C15.2593 18.0876 17.3382 16.375 17.7661 14.0251H12.2043V10.125H21.8026Z" />
  </svg>
</template>

<docs>
  To know more information on how to use icons see [here](/packs/#Icon)

  ```jsx
    <Icon name="Google" class="text-4xl"></Icon>
  ```
</docs>
