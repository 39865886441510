<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.04372 6.88863C8.03858 4.69809 9.75985 2.93966 11.8619 2.93446C13.9639 2.92925 15.6934 4.67914 15.6986 6.86968C15.7037 9.06022 13.9825 10.8186 11.8804 10.8239C9.77836 10.8291 8.04886 9.07917 8.04372 6.88863ZM11.8597 2.00001C9.21801 2.00655 7.10299 4.20821 7.10928 6.89094C7.11557 9.57367 9.2409 11.7648 11.8826 11.7583C14.5243 11.7518 16.6393 9.5501 16.633 6.86737C16.6267 4.18464 14.5014 1.99347 11.8597 2.00001ZM3.93447 21.9977C3.92408 17.5703 7.40624 13.9941 11.6857 13.9836C15.9651 13.973 19.464 17.5318 19.4744 21.9592L20.4089 21.9569C20.3973 17.0373 16.5026 13.0372 11.6835 13.0491C6.8644 13.061 2.98849 17.0804 3.00003 22L3.93447 21.9977Z"
    />
  </svg>
</template>

<docs>
  To know more information on how to use icons see [here](/packs/#Icon)

  ```jsx
    <Icon name="Profile" class="text-4xl"></Icon>
  ```
</docs>
