<template>
  <svg
    width="41"
    height="40"
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.6673 6.6665L24.6271 16.04L34.0007 19.9998L24.6271 23.9596L20.6673 33.3332L16.7075 23.9596L7.33398 19.9998L16.7075 16.04L20.6673 6.6665Z"
      fill="#0E1E38"
    />
  </svg>
</template>

<docs>
        To know more information on how to use icons see [here](/packs/#Icon)

        ```jsx
          <Icon name="Instagram" class="text-4xl"></Icon>
        ```
      </docs>
