<template>
  <div class="w-full comments">
    <div class="comments-editor">
      <CommentInput />
    </div>

    <Divider
      spacing="lg"
      block
    />

    <!-- COMMENTS LIST = FOR EACH COMMENT -->
    <div
      v-for="index in 2"
      :key="index"
      class="comments-list"
    >
      <CommentItem />

      <!-- COMMENT REPLY'S LIST = FOR EACH REPLY TO THIS COMMENT -->
      <div
        v-for="index in 1"
        :key="index"
        class="comment-reply-list ml-10 mt-8 border-l border-grayscale-300 pl-4"
      >
        <CommentItem class="comment-reply" />
      </div>

      <!-- COMMENT REPLY TO THE REPLY -->
      <div class="comment-reply-input ml-10 mt-6 border-l border-grayscale-300 pl-4">
        <CommentInput />
      </div>

      <Divider
        color="light"
        spacing="lg"
        block
      />
    </div>
  </div>
</template>

<script>
import Divider from '@/Components/Shared/Divider'

import CommentInput from '@/Components/Courses/Lessons/CommentInput'
import CommentItem from '@/Components/Courses/Lessons/CommentItem'

export default {
  components: {
    Divider,
    CommentInput,
    CommentItem
  }
}
</script>
