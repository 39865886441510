<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.9229 3.24707L11.9224 3.24632C11.8583 3.15925 11.6983 3.04029 11.6067 3.00823C11.5379 2.99658 11.4234 2.99852 11.3791 3.00645C11.3042 3.02333 11.2514 3.05404 11.2389 3.06127L11.2382 3.06169C11.2053 3.08075 11.1815 3.09989 11.1767 3.10372L11.1762 3.1041C11.1629 3.11467 11.1521 3.12448 11.1499 3.12644L11.1497 3.12667L11.1332 3.14174C11.1244 3.14987 11.1111 3.16186 11.0922 3.17773C11.0544 3.20958 10.9902 3.2604 10.8948 3.32427C10.7041 3.45198 10.3841 3.63468 9.89449 3.82174C8.91482 4.19603 7.24945 4.59012 4.57349 4.59012H4.10817L4.07523 5.05427C3.31805 15.7211 8.45824 20.1113 11.3451 20.9483L11.5233 21L11.692 20.9228C14.1797 19.785 15.8763 18.0114 16.9997 16.0178L16.1293 15.5274C15.1229 17.3133 13.628 18.8922 11.4486 19.9338C9.08357 19.1308 4.50209 15.298 5.0426 5.58518C7.55018 5.5421 9.20049 5.15636 10.251 4.75499C10.8023 4.54437 11.1869 4.33002 11.442 4.16023C11.5344 4.23131 11.637 4.29877 11.7471 4.36255C12.0679 4.5483 12.5001 4.72992 13.0606 4.89008C14.1141 5.1911 15.6538 5.42559 17.834 5.45279C17.9445 6.2444 18.0075 7.20413 17.9799 8.25608L18.9786 8.28227C19.0128 6.9782 18.9151 5.7989 18.7542 4.8706L18.6824 4.45639H18.262C15.9148 4.45639 14.341 4.2169 13.335 3.92948C12.832 3.78574 12.4803 3.63264 12.2478 3.49801C12.1318 3.43082 12.0503 3.37105 11.9961 3.32326C11.9691 3.29957 11.9506 3.28028 11.9384 3.2661C11.9276 3.2535 11.9233 3.24663 11.9228 3.24593L11.9229 3.24602L11.9233 3.24683L11.9229 3.24707ZM11.0528 3.73694L11.0527 3.73669L11.0527 3.7367L11.0528 3.73694ZM19.485 9.47913H10.4936V10.9777H19.485V9.47913ZM20.484 10.9777V11.9767V14.4743V15.4734H19.485H10.4936H9.49455V14.4743V9.47913V8.48009H10.4936H19.485H20.484V9.47913V10.9777ZM19.485 11.9767H10.4936V14.4743H19.485V11.9767Z"
    />
  </svg>
</template>

<docs>
  To know more information on how to use icons see [here](/packs/#Icon)

  ```jsx
    <Icon name="SecureBadge" class="text-4xl"></Icon>
  ```
</docs>
