<template>
  <svg
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.10386 2.59776C7.45919 1.13408 9.54081 1.13408 9.89615 2.59776C10.1257 3.54327 11.209 3.99198 12.0398 3.48571C13.3261 2.70199 14.798 4.17392 14.0143 5.46015C13.508 6.29105 13.9567 7.37431 14.9022 7.60386C16.3659 7.95919 16.3659 10.0408 14.9022 10.3961C13.9567 10.6257 13.508 11.709 14.0143 12.5398C14.798 13.8261 13.3261 15.298 12.0398 14.5143C11.209 14.008 10.1257 14.4567 9.89615 15.4022C9.54081 16.8659 7.45919 16.8659 7.10386 15.4022C6.87431 14.4567 5.79105 14.008 4.96016 14.5143C3.67392 15.298 2.20199 13.8261 2.98571 12.5398C3.49198 11.709 3.04327 10.6257 2.09776 10.3961C0.634081 10.0408 0.63408 7.95919 2.09776 7.60386C3.04327 7.37431 3.49198 6.29105 2.98571 5.46015C2.20199 4.17392 3.67392 2.70199 4.96015 3.48571C5.79105 3.99198 6.87431 3.54327 7.10386 2.59776Z"
      stroke="#313131"
      stroke-width="1"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11 9C11 10.3807 9.88071 11.5 8.5 11.5C7.11929 11.5 6 10.3807 6 9C6 7.61929 7.11929 6.5 8.5 6.5C9.88071 6.5 11 7.61929 11 9Z"
      stroke="#313131"
      stroke-width="1"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<docs>
  To know more information on how to use icons see [here](/builds/#Icon)

  ```jsx
    <Icon name="Google" class="text-4xl"></Icon>
  ```
</docs>
