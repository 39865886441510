<template>
  <div>
    <MetaTags :meta-tags="metaTags" />

    <CategoryLandingHero />

    <div class="my-12">
      <PressReferences
        :press-references="pressReferences"
        slider-id="alt-landing-press-references-slider"
      />
    </div>
    <Learn />

    <Instructors />
    <div class="container p-4 lg:p-0 flex flex-col">
      <div class="flex items-center justify-between">
        <div class="text-center flex-grow mx-2 sm:mx-4">
          <h2 class="text-4xl lg:text-5xl text-blue-800 font-serif">
            Join over 100,000 creative learners.
          </h2>
          <p class="text-lg lg:text-2xl text-black font-normal tracking-wider lg:tracking-normal">
            Our courses are loved by thousands of creative learners all over the world.
          </p>
        </div>
      </div>
    </div>
    <TestimonialGrid />

    <div class="container p-4 lg:p-0 flex flex-col">
      <div class="flex items-center justify-between">
        <div class="text-center flex-grow mx-2 sm:mx-4">
          <h2 class="text-4xl lg:text-5xl text-blue-800 font-serif">
            Expand your knowledge
          </h2>
        </div>
      </div>
    </div>
    <FourSquares :squares="squaresData" />
    <div class="my-12 px-2">
      <Testimonials class="my-8" />
    </div>
    <div class="w-full flex justify-center my-12">
      <ButtonLink
        data-testid="button-join-now"
        type="submit"
        class="text-sm border-transparent !text-white bg-yellow-800"
        href="/free-lessons"
        variant="bronze"
        @click="trackFreeLessonsClick"
      >
        WATCH FREE LESSONS NOW
      </ButtonLink>
    </div>
  </div>
</template>

<script>
  import { defineComponent } from 'vue'
  import CategoryLandingHero from '@/Components/Category/LandingTest/CategoryLandingHero.vue'
  import Learn from '@/Components/Category/LandingTest/Learn.vue'
  import Instructors from '@/Components/Category/LandingTest/Instructors.vue'
  import Testimonials from '@/Components/Shared/Testimonials/Show.vue'
  import FourSquares from '@/Components/Category/LandingTest/FourSquares.vue'
  import PressReferences from '@/Components/Shared/PressReferences/Index.vue'
  import TestimonialCard from '@/Components/Category/LandingTest/TestimonialCard.vue'
  import TestimonialGrid from '../../Components/Category/LandingTest/TestimonialGrid.vue'
  import ButtonLink from '@/Components/Shared/ButtonLink.vue'
  import MetaTags from '@/Components/Shared/MetaTags'
  import mixpanel from '@/mixins/mixpanel'
  import { metaTags } from '@/mixins/metaTags'

  export default defineComponent({
    name: 'AltLandingPage',
    components: {
      ButtonLink,
      CategoryLandingHero,
      Learn,
      Instructors,
      MetaTags,
      TestimonialGrid,
      Testimonials,
      FourSquares,
      PressReferences
    },
    mixins: [mixpanel, metaTags],
    props: {
      pressReferences: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        squaresData: [
          {
            imageSrc: '/images/interior-landing/1.jpg',
            altText: 'Description of image 1',
            title: 'Gardening & Floristry',
            description: 'Description for square 1',
            link: this.$routes.category('online-gardening-and-floristry-courses')
          },
          {
            imageSrc: '/images/interior-landing/2.jpg',
            altText: 'Description of image 2',
            title: 'Decorative arts & craft',
            description: 'Description for square 2',
            link: this.$routes.category('online-arts-and-craft-courses')
          },
          {
            imageSrc: '/images/interior-landing/3.jpg',
            altText: 'Description of image 4',
            title: 'Food & Drink',
            description: 'Description for square 4',
            link: this.$routes.category('online-food-and-cooking-courses')
          },
          {
            imageSrc: '/images/interior-landing/4.jpg',
            altText: 'Description of image 3',
            title: 'Bestsellers',
            description: 'Bestellers',
            link: this.$routes.category('best-selling-courses')
          }
        ]
      }
    },
    mounted() {
      console.log('Press References:', this.pressReferences)
    },
    methods: {
      trackFreeLessonsClick() {
        this.trackEvent('free_lessons_button_clicked', {
          location: 'AltLandingPage',
          position: 'bottom'
        })
      }
    }
  })
</script>
